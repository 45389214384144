@use 'sass:list';
@use 'sass:map';
@use './token-utils';

$typography-properties: (
  'font-family',
  'font-weight',
  'line-height',
  'font-size',
  'letter-spacing' // NOTE: tokens available, but not used
  //"paragraph-spacing",
  //"paragraph-indent",
  //"text-case",
  //"text-decoration"
);

@function get-typography-token-name($typography-style, $property) {
  @if not list.index($typography-properties, $property) {
    @error "#{$property} is not a valid typography property";
  }

  @return 'rafl-typography-#{$typography-style}-#{$property}';
}

@function get-typography-token-names($typography-style) {
  $names: ();

  @each $property in $typography-properties {
    $names: map.merge(
      $names,
      (
        $property: get-typography-token-name($typography-style, $property),
      )
    );
  }

  @return $names;
}

// https://www.figma.com/file/DpxIvo67YaeJgX3AmyV9fh/Typography-%2B-Tokens?type=design&node-id=0-1&mode=design&t=nVy8YAWc0nqtbjyh-0
// TODO: this does not capture all the current typography levels (the value part of the map)
$typography-map: (
  'headline-lg': 'display-3',
  'headline-md': 'display-2',
  'headline-sm': 'display-1',
  'title-lg': 'headline',
  'title-md': 'title',
  'title-sm': 'subheading-2',
  'title-xs': 'subheading-1',
  'body-lg': 'body-3',
  'body-md': 'body-2',
  'body-sm': 'body-1',
  'utility-md': 'body-2',
  'utility-sm': 'body-1',
  'utility-xs': 'caption',
);

@mixin rafl-typography(
  $typography-style,
  $legacy-custom-typography,
  $token-names-overrides: ()
) {
  $token-names: map.merge(
    get-typography-token-names($typography-style),
    $token-names-overrides
  );

  $legacy-custom-typography-key: map.get($typography-map, $typography-style);

  @if not $legacy-custom-typography-key {
    @error "The typography does not define the legacy key for #{$typography-style}";
  }

  $legacy-custom-typography-value: map.get(
    $legacy-custom-typography,
    $legacy-custom-typography-key
  );

  @if not $legacy-custom-typography-value {
    @error "The legacy typography does not define styles for #{$legacy-custom-typography-key}";
  }

  @each $property, $token-name in $token-names {
    #{$property}: map.get($legacy-custom-typography-value, $property);
  }
}
