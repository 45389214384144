$ra-ag-grid-header-row-bg: #f9f9f9;
$ra-ag-grid-row-selection-color: #f5f5f5;
$ra-disabled-fill-outline: rgba(0, 0, 0, 0.12);
$ra-ag-grid-header-cell-text: #707070;
$ra-ag-grid-label-header: #757575;
$ra-ag-grid-cell-text-title: #242a2d;
$ra-navbar-side-nav-close: #f5f5f5;
$ra-primary-body-text: #242a2d;
$ra-second-body-text: #707070;
$ra-white: #fff;
$ra-black: #000;
$ra-disabled-grey: rgba(0, 0, 0, 56%);

/* Badges */
$ra-badge-dark-blue: #184685;
$ra-badge-light-blue: #82c3ea;
$ra-badge-blue: #3178b9;
$ra-badge-purple: #7e5986;
$ra-warning-text: rgba(255, 204, 0, 25%);
