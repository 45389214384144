@use '../helpers/colors';

$font-family: 'roboto', 'Helvetica Neue', 'sans-serif';

.body-2 {
  color: colors.$ra-second-body-text;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.body-2-bold {
  color: colors.$ra-primary-body-text;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
}

.body-2-normal {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
}
