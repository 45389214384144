@use 'ra-flourish-design-tokens/build/scss/tokens-compact-FT-native' as rafl;
@use 'sass:math';
@use '@angular/material' as mat;
@use '@angular/cdk' as cdk;
@use 'sass:map';
@use 'design-tokens-utils/src' as dtu;
// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-basic-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $text: ra-ui-color($theme, foreground, text);

  ra-ui-basic-button {
    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
      @include _immediate-arrow-width($custom-typography);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %pointerEvents;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-button {
      &.mat-primary {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          primary,
          default
        );
      }

      &.mat-accent {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          accent,
          default
        );
      }

      &.mat-warn {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          warn,
          default
        );
      }

      &.default-color {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          background,
          focused-button
        );
        .static-text:not([disabled='true']) {
          color: $text;
        }
      }
    }

    .mat-button {
      @include _ra-ui-override-mat-button-focus($theme);
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-fab-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-fab-button {
    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %pointerEvents;

    .mat-fab {
      @extend %fabButtonText;
      @include _ra-ui-override-mat-button-focus($theme);
      @extend %raisedFabShadow;
      background-color: ra-ui-color($theme, background, fab-background);
    }

    // rules for inactive state and inactive press state
    &.ra-ui-inactive-state {
      .mat-fab {
        background-color: ra-ui-color($theme, background, inactive);

        .static-text,
        mat-icon {
          color: ra-ui-contrast($theme, background, inactive);
        }

        &.ra-ui-active-state {
          background-color: ra-ui-color($theme, background, inactive-pressed);

          .static-text,
          mat-icon {
            color: ra-ui-contrast($theme, background, inactive-pressed);
          }
        }
      }
    }

    $text: ra-ui-color($theme, foreground, text);

    /**
    * Explanation for the following solution:
    * It seems it is not possible to use hex color var in rgba in box-shadow, this combination simply doesn't work.
    * That is we cannot simply use:
    * box-shadow: 0px 3px 5px -1px rgba(var(--my-hex-color), 0.2)
    * With this, the project correctly compiles, the correct color is visible in browser developer tools (!),
    * but the color is not actually applied in the viewport.
    *
    * See https://stackoverflow.com/questions/59250927/box-shadow-with-rgba-variable
    * Unlike the solution suggested in the Stack Overflow thread, we cannot simply use rgb definition instead of hex definition
    * due to how colors are provided by Flourish.
    *
    * Mixing the intended color and intended opacity with transparent color using color-mix function workarounds the problem.
    *
    * Note the issue seems to be exclusive to this specific combination of css rule, css functions and hex color definition!
    */
    $box-shadow-color-1: color-mix(in srgb, $text 20%, transparent);
    $box-shadow-color-2: color-mix(in srgb, $text 14%, transparent);
    $box-shadow-color-3: color-mix(in srgb, $text 12%, transparent);

    & button.mat-fab.mat-button-base.ra-ui-button:not(.is-disabled) {
      // override Material box shadow for default appearance
      box-shadow:
        0px 3px 5px -1px $box-shadow-color-1,
        0px 6px 10px 0px $box-shadow-color-2,
        0px 1px 18px 0px $box-shadow-color-3;

      &.ra-ui-active-state {
        // override Material box shadow for pressed appearance
        box-shadow:
          0px 7px 8px -4px $box-shadow-color-1,
          0px 12px 17px 2px $box-shadow-color-2,
          0px 5px 22px 4px $box-shadow-color-3;
      }
    }

    @include disabled-icon-color($theme);
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-icon-button-size($custom-typography, $icon-size, $padding) {
  $icon-fs: ra-ui-font-size($custom-typography, $icon-size);
  $font-size: ra-ui-font-size($custom-typography, $icon-size);
  $line-height: ra-ui-line-height($custom-typography, $icon-size);
  width: $icon-fs;
  height: $icon-fs;
  line-height: $line-height;
  padding: $padding;
  .mat-icon {
    width: $icon-fs;
    height: $icon-fs;
    font-size: $font-size;
    line-height: $line-height;
  }

  $base-icon-font-sizes: (
    icon-s: 12px,
    icon-m: 16px,
    icon-l: 20px,
    icon-xl: 28px,
  );

  .mat-button-focus-overlay {
    font-size: $font-size;
  }
  // overrides default setting: base font-size by typography-level button
  &.ra-ui-keyboard-focus:not(.mat-button-disabled) .mat-button-focus-overlay {
    border-width: getRelative(2px, map-get($base-icon-font-sizes, $icon-size));
  }
}

@mixin ra-ui-icon-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $text: ra-ui-color($theme, foreground, text);
  $icon-button-enabled-icon: ra-ui-contrast(
    $theme,
    background,
    interactive-component-03
  );
  $icon-button-disabled-icon: ra-ui-color($theme, foreground, text-disabled);
  $icon-button-alt-disabled-icon: ra-ui-color(
    $theme,
    foreground,
    text-primary,
    0.35
  );
  $icon-button-hover-background: ra-ui-color($theme, primary, 200);
  $icon-button-hover-icon: ra-ui-contrast($theme, primary, 200);
  $icon-button-active-background: ra-ui-color($theme, primary, 800);
  $icon-button-active-icon: ra-ui-contrast($theme, primary, 800);

  $inverted-color: ra-ui-contrast($theme, background, interactive-component-07);
  $inverted-disabled-color: ra-ui-color($theme, foreground, text-disabled);
  $inverted-hover-background-color: ra-ui-color($theme, primary, 200);
  $inverted-hover-color: ra-ui-contrast($theme, primary, 200);
  $inverted-press-background-color: ra-ui-color($theme, primary, 800);
  $inverted-press-color: ra-ui-contrast($theme, primary, 800);
  $inverted-keyboard-focus-background-color: rgba(
    ra-ui-contrast($theme, background, interactive-component-07),
    $flourish-hover-opacity
  );

  $inactive-inverted-color: ra-ui-color($theme, background, interactive-alt-01);
  $inactive-inverted-hover-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05,
    400
  );
  $inactive-inverted-hover-background-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05,
    $flourish-hover-opacity
  );
  $inactive-inverted-press-color: ra-ui-color(
    $theme,
    background,
    interactive-alt-02
  );
  $inactive-inverted-press-background-color: ra-ui-color(
    $theme,
    background,
    interactive-alt-02,
    $flourish-active-opacity
  );

  $badge-selector: '.ra-ui-button:not(.is-disabled) ra-ui-badge.ra-ui-badge';

  ra-ui-icon-button {
    vertical-align: middle;

    &,
    .ra-ui-button,
    #{$badge-selector},
    #{$badge-selector} .ra-ui-badge--content,
    #{$badge-selector} .ra-ui-badge--content .static-text-inner {
      @include getCursor($custom-global-config);

      @include _immediate-arrow-width($custom-typography);
    }

    & {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %pointerEvents;
    @extend %disableShadow;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-icon-button {
      &.ra-ui-icon-size-s {
        @include ra-ui-icon-button-size(
          $custom-typography,
          icon-s,
          $small-icon-button-padding
        );
      }

      &.ra-ui-icon-size-m {
        @include ra-ui-icon-button-size(
          $custom-typography,
          icon-m,
          $small-icon-button-padding
        );
      }

      &.ra-ui-icon-size-l {
        @include ra-ui-icon-button-size(
          $custom-typography,
          icon-l,
          $large-icon-button-padding
        );
      }

      &.ra-ui-icon-size-xl {
        @include ra-ui-icon-button-size(
          $custom-typography,
          icon-xl,
          $large-icon-button-padding
        );
      }
    }
    .mat-icon-button {
      &.default-color {
        color: transparent;
        .mat-icon {
          color: $icon-button-enabled-icon;
        }
        &[disabled] .mat-icon {
          color: $icon-button-disabled-icon;
        }
      }
    }

    .mat-icon-button.is-disabled .ra-ui-badge {
      // using color of disabled material badge
      background-color: rgb(185, 185, 185);
    }

    .mat-icon-button.alt-disabled-color {
      &[disabled] .mat-icon {
        color: $icon-button-alt-disabled-icon;
      }
    }

    .mat-icon-button {
      @include _ra-ui-override-mat-button-focus($theme);
    }

    // colored shadow over icons during click
    .mat-icon-button {
      &.mat-primary:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state {
          background-color: ra-ui-color(
            $theme,
            primary,
            default,
            $hover-opacity
          );

          &:hover {
            background-color: ra-ui-color(
              $theme,
              primary,
              default,
              $active-opacity
            );
          }
        }
      }

      &.mat-accent:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state {
          background-color: ra-ui-color(
            $theme,
            accent,
            default,
            $hover-opacity
          );

          &:hover {
            background-color: ra-ui-color(
              $theme,
              accent,
              default,
              $active-opacity
            );
          }
        }
      }

      &.mat-warn:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state {
          background-color: ra-ui-color($theme, warn, default, $hover-opacity);

          &:hover {
            background-color: ra-ui-color(
              $theme,
              warn,
              default,
              $active-opacity
            );
          }
        }
      }

      &.default-color:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state,
        &:active:hover,
        &.ra-ui-active-state:hover {
          background-color: $icon-button-active-background;

          .mat-icon {
            color: $icon-button-active-icon;
          }
        }
      }

      &.default-color:not(.is-disabled):hover {
        background-color: $icon-button-hover-background;

        .mat-icon {
          color: $icon-button-hover-icon;
        }
      }
    }

    // inactive state styles
    &.ra-ui-inactive-state .mat-icon-button {
      &.mat-button-base {
        .mat-icon {
          color: ra-ui-color($theme, foreground, text-label);
        }

        &:hover {
          background-color: ra-ui-color(
            $theme,
            background,
            inactive-hover,
            $flourish-hover-opacity
          );
          .mat-icon {
            color: ra-ui-color($theme, background, inactive-hover);
          }
        }

        &:active,
        &:active:hover,
        &.ra-ui-active-state,
        &.ra-ui-active-state:hover {
          background-color: ra-ui-color(
            $theme,
            background,
            interactive-component-07,
            $flourish-active-opacity
          );
          .mat-icon {
            color: ra-ui-color($theme, background, interactive-component-07);
          }
        }
      }
    }

    &.ra-ui-inverted-mode {
      .ra-ui-button.default-color {
        &:not(.is-disabled) .ra-ui-icon {
          color: $inverted-color;
        }
        &.is-disabled .ra-ui-icon {
          color: $inverted-disabled-color;
        }

        &:not(.is-disabled):hover {
          background-color: $inverted-hover-background-color;

          .ra-ui-icon {
            color: $inverted-hover-color;
          }
        }

        &:not(.is-disabled):active,
        &:not(.is-disabled):active:hover,
        &:not(.is-disabled).ra-ui-active-state,
        &:not(.is-disabled).ra-ui-active-state:hover {
          background-color: $inverted-press-background-color;

          .ra-ui-icon {
            color: $inverted-press-color;
          }
        }
      }

      &.ra-ui-inactive-state .ra-ui-button.ra-ui-button {
        &.default-color {
          &:not(.is-disabled) .ra-ui-icon,
          .static-text:not([disabled='true']) {
            background-color: transparent;
            color: $inactive-inverted-color;
          }
        }

        &:hover {
          background-color: $inactive-inverted-hover-background-color;
          &:not(.is-disabled) .ra-ui-icon,
          .static-text:not([disabled='true']) {
            color: $inactive-inverted-hover-color;
          }
        }

        &:active,
        &:active:hover,
        &.ra-ui-active-state,
        &.ra-ui-active-state:hover {
          background-color: $inactive-inverted-press-background-color;

          &:not(.is-disabled) .ra-ui-icon,
          .static-text:not([disabled='true']) {
            color: $inactive-inverted-press-color;
          }
        }
      }
    }

    @include disabled-icon-color($theme);
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-icon-label-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $text: ra-ui-color($theme, foreground, text);

  ra-ui-icon-label-button {
    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
      @include _immediate-arrow-width($custom-typography);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    .ra-ui-icon-with-text-button {
      ra-ui-static-text {
        font-size: 0.72em; //10px;
        line-height: 1.2em; //12px
      }
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %basicButtonOpacity;
    @extend %pointerEvents;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-button {
      &.mat-primary {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          primary,
          default
        );
      }

      &.mat-accent {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          accent,
          default
        );
      }

      &.mat-warn {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          warn,
          default
        );
      }

      &.default-color {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          background,
          focused-button
        );
        .static-text:not([disabled='true']) {
          color: $text;
        }
      }
    }

    .mat-button {
      @include _ra-ui-override-mat-button-focus($theme);
    }

    .ra-ui-icon-with-text-button {
      &.default-color:not(.is-disabled) mat-icon {
        color: $text;
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-mini-fab-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $text: ra-ui-color($theme, foreground, text);

  ra-ui-mini-fab-button {
    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %pointerEvents;

    .mat-mini-fab {
      @extend %fabButtonText;
      @include _ra-ui-override-mat-button-focus($theme);
      @extend %raisedFabShadow;
    }

    & .mat-mini-fab.ra-ui-active-state {
      // used default Material mat-mini-fab component :active style
      box-shadow:
        0px 7px 8px -4px rgb(0 0 0 / 20%),
        0px 12px 17px 2px rgb(0 0 0 / 14%),
        0px 5px 22px 4px rgb(0 0 0 / 12%);
    }

    @include disabled-icon-color($theme);
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-raised-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-raised-button {
    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
      @include _immediate-arrow-width($custom-typography);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %pointerEvents;
    @extend %buttonPosition;
    @extend %immediateArrowCursor;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-raised-button {
      @include _ra-ui-override-mat-button-focus($theme);
      @extend %raisedFabShadow;

      &.default-color {
        background-color: ra-ui-color(
          $theme,
          foreground,
          text-primary-contrast
        );
      }

      &.ra-ui-active-state {
        // used default Material mat-raized-button component :active style
        box-shadow:
          0px 5px 5px -3px rgb(0 0 0 / 20%),
          0px 8px 10px 1px rgb(0 0 0 / 14%),
          0px 3px 14px 2px rgb(0 0 0 / 12%);
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin set-button($theme, $color) {
  border: 1px solid ra-ui-color($theme, $color, 600);

  // label color in basic buttons
  &.mat-#{$color} .static-text:not([disabled='true']),
  .ra-ui-outlined-button-icon {
    color: ra-ui-color($theme, $color);
  }

  &.ra-ui-keyboard-focus {
    background-color: ra-ui-color($theme, $color, 600, 0.1);
    &.ra-ui-button .static-text,
    .ra-ui-outlined-button-icon {
      color: ra-ui-color($theme, $color, 600);
    }
  }
  &:hover:not([disabled='true']) {
    background-color: ra-ui-color($theme, $color, 700, $flourish-hover-opacity);
    border: 1px solid ra-ui-color($theme, $color, 700);

    &.ra-ui-button .static-text,
    .ra-ui-outlined-button-icon {
      color: ra-ui-color($theme, $color, 700);
    }
  }
  &:active:not([disabled='true']),
  &.ra-ui-active-state:not([disabled='true']) {
    background-color: ra-ui-color(
      $theme,
      $color,
      800,
      $flourish-active-opacity
    );
    border: 1px solid ra-ui-color($theme, $color, 800);

    &.ra-ui-button .static-text,
    .ra-ui-outlined-button-icon {
      color: ra-ui-color($theme, $color, 800);
    }
  }
  &.default-color {
    .static-text:not([disabled='true']),
    .ra-ui-outlined-button-icon {
      color: ra-ui-color($theme, $color, 600);
    }
  }
  &:disabled {
    border: 1px solid ra-ui-color($theme, background, disabled);

    .ra-ui-outlined-button-icon {
      color: ra-ui-color($theme, foreground, text-disabled);
    }
  }
}

@mixin ra-ui-outlined-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-outlined-button {
    $buttonFontSize: 14px;
    $icon-s-fz: ra-ui-font-size($custom-typography, icon-s);
    $icon-padding: getRelative(8px, 12px);

    $inverted-background-color: transparent;
    $inverted-color: ra-ui-color($theme, primary, 200);
    $inverted-hover-background-color: ra-ui-color(
      $theme,
      primary,
      100,
      $flourish-hover-opacity
    );
    $inverted-hover-color: ra-ui-color($theme, primary, 100);
    $inverted-focused-background-color: ra-ui-color(
      $theme,
      primary,
      200,
      $flourish-hover-opacity
    );
    $inverted-focused-color: ra-ui-color($theme, primary, 200);
    $inverted-focused-border: getRelative(2px, 14px) solid
      ra-ui-color($theme, primary, A200);
    $inverted-pressed-background-color: ra-ui-color(
      $theme,
      primary,
      300,
      $flourish-active-opacity
    );
    $inverted-pressed-color: ra-ui-color($theme, primary, 300);
    $inverted-disabled-background-color: transparent;
    $inverted-disabled-color: ra-ui-color($theme, foreground, text-disabled);

    $inactive-background-color: transparent;
    $inactive-color: ra-ui-color($theme, foreground, text-label);
    $inactive-border: 1px solid ra-ui-color($theme, background, inactive);
    $inactive-pressed-color: ra-ui-color(
      $theme,
      background,
      interactive-component-07
    );
    $inactive-pressed-background-color: ra-ui-color(
      $theme,
      background,
      interactive-component-07,
      $flourish-active-opacity
    );
    $inactive-hover-color: ra-ui-color($theme, background, inactive-hover);
    $inactive-hover-background-color: ra-ui-color(
      $theme,
      background,
      inactive-hover,
      $flourish-hover-opacity
    );
    $inactive-inverted-color: ra-ui-color(
      $theme,
      background,
      interactive-alt-01
    );
    $inactive-inverted-background-color: transparent;
    $inactive-inverted-hover-background-color: ra-ui-color(
      $theme,
      background,
      interactive-component-05,
      $flourish-hover-opacity
    );
    $inactive-inverted-hover-color: ra-ui-color(
      $theme,
      background,
      interactive-component-05
    );
    $inactive-inverted-pressed-color: ra-ui-color(
      $theme,
      background,
      interactive-alt-02
    );
    $inactive-inverted-pressed-background-color: ra-ui-color(
      $theme,
      background,
      interactive-alt-02,
      $flourish-active-opacity
    );

    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
      @include _immediate-arrow-width($custom-typography);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %pointerEvents;
    @extend %lineHeight;
    @extend %disableShadow;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-badge-medium.mat-badge-after,
    .mat-badge-medium.mat-badge-before {
      border: 1px solid ra-ui-color($theme, primary, 600);
    }
    .mat-stroked-button {
      @include set-button($theme, primary);
      @include _ra_ui-override-mat-button-focus($theme);

      min-width: getRelative(72, $buttonFontSize);
      min-height: getRelative(24, $buttonFontSize);
      border-radius: $classic-border-radius;
    }

    .mat-stroked-button.mat-primary {
      @include set-button($theme, primary);
    }
    .mat-stroked-button.mat-accent {
      @include set-button($theme, accent);
    }
    .mat-stroked-button.mat-warn {
      @include set-button($theme, warn);
    }

    .ra-ui-outlined-button-icon {
      height: $icon-s-fz;
      width: $icon-s-fz;
      font-size: $icon-s-fz;
      padding-right: $icon-padding;
      color: inherit;
      overflow: visible;

      &.ra-ui-icon-after-text {
        padding-left: $icon-padding;
        padding-right: 0;
      }

      &.ra-ui-icon-without-text {
        padding: 0;
      }
    }

    .ra-ui-outlined-button-text {
      overflow: hidden;
    }

    .ra-ui-inverted-mode {
      &.mat-stroked-button.ra-ui-button {
        &:not([disabled='true']) {
          background-color: $inverted-background-color;
          border: 1px solid $inverted-color;
          .static-text,
          .ra-ui-outlined-button-icon {
            color: $inverted-color;
          }
        }

        &:hover:not([disabled='true']) {
          background-color: $inverted-hover-background-color;
          border: 1px solid $inverted-hover-color;

          .static-text:not([disabled='true']),
          .ra-ui-outlined-button-icon {
            color: $inverted-hover-color;
          }
        }

        &:active:not([disabled='true']),
        &.ra-ui-active-state:not([disabled='true']) {
          background-color: $inverted-pressed-background-color;
          border: 1px solid $inverted-pressed-color;

          .static-text:not([disabled='true']),
          .ra-ui-outlined-button-icon {
            color: $inverted-pressed-color;
          }
        }

        &.ra-ui-keyboard-focus {
          background-color: $inverted-focused-background-color;
          border: $inverted-focused-border;

          .static-text:not([disabled='true']) {
            color: $inverted-focused-color;
          }
          &:not(.mat-button-disabled) .mat-button-focus-overlay {
            border: $inverted-focused-border;
          }
        }
        &:disabled {
          background-color: $inverted-disabled-background-color;
          .static-text {
            color: $inverted-disabled-color;
          }
        }
      }
    }

    &.ra-ui-inactive-state {
      .mat-stroked-button {
        border: $inactive-border;

        &.default-color .static-text:not([disabled='true']),
        .ra-ui-outlined-button-icon {
          background-color: $inactive-background-color;
          color: $inactive-color;
        }

        &:hover {
          .static-text:not([disabled='true']),
          .ra-ui-outlined-button-icon {
            color: $inactive-hover-color;
          }
          background-color: $inactive-hover-background-color;
          color: $inactive-hover-color;
          border: 1px solid $inactive-hover-color;
        }

        &:active,
        &:active:hover,
        &.ra-ui-active-state,
        &.ra-ui-active-state:hover {
          .static-text:not([disabled='true']),
          .ra-ui-outlined-button-icon {
            color: $inactive-pressed-color;
          }

          background-color: $inactive-pressed-background-color;
          color: $inactive-pressed-color;
          border: 1px solid $inactive-pressed-color;
        }

        &.ra-ui-inverted-mode {
          &:not([disabled='true']) {
            background-color: $inactive-inverted-background-color;
            border: 1px solid $inactive-inverted-color;
            .static-text:not([disabled='true']),
            .ra-ui-outlined-button-icon {
              color: $inactive-inverted-color;
            }
          }
          &:hover:not([disabled='true']) {
            background-color: $inactive-inverted-hover-background-color;
            border: 1px solid $inactive-inverted-hover-color;
            .static-text:not([disabled='true']),
            .ra-ui-outlined-button-icon {
              color: $inactive-inverted-hover-color;
            }
          }

          &:active:not([disabled='true']),
          &.ra-ui-active-state:not([disabled='true']) {
            background-color: $inactive-inverted-pressed-background-color;
            border: 1px solid $inactive-inverted-pressed-color;

            .static-text:not([disabled='true']),
            .ra-ui-outlined-button-icon {
              color: $inactive-inverted-pressed-color;
            }
          }
        }
      }
    }

    .ra-ui-outlined-button-icon-static-text-wrapper {
      position: inherit;
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin _set-main-button-color-and-background-by-pallet($theme, $pallet, $hue) {
  background-color: ra-ui-color($theme, $pallet, $hue);

  .static-text:not([disabled='true']),
  .ra-ui-main-button-icon {
    color: ra-ui-contrast($theme, $pallet, $hue);
  }
}

@mixin _set-main-button-colors-by-pallet(
  $theme,
  $pallet,
  $hue: 500,
  $hue-hover: 700,
  $hue-active: 800
) {
  @include _set-main-button-color-and-background-by-pallet(
    $theme,
    $pallet,
    $hue
  );

  &:hover:not([disabled='true']) {
    @include _set-main-button-color-and-background-by-pallet(
      $theme,
      $pallet,
      $hue-hover
    );
  }
  &:active:not([disabled='true']),
  &.ra-ui-active-state:not([disabled='true']) {
    @include _set-main-button-color-and-background-by-pallet(
      $theme,
      $pallet,
      $hue-active
    );
  }
}

// We cannot access private mixins since angular 15, so this redefines the private one. The private elevation mixin has advantage,
// that it can extract elevation color from custom theme.
@mixin private-theme-overridable-elevation($zValue, $config) {
  $foreground: map-get($config, foreground);
  $elevation-color: map-get($foreground, elevation);

  @include mat.overridable-elevation($zValue, $elevation-color);
}

@mixin _set-main-button-colors-general($theme) {
  &:active:not([disabled='true']),
  &.ra-ui-active-state:not([disabled='true']) {
    @include private-theme-overridable-elevation(0, $theme);
  }

  &:disabled {
    background-color: ra-ui-color($theme, background, disabled, 0.24);
    color: ra-ui-color($theme, foreground, text-disabled);

    .ra-ui-main-button-icon {
      color: ra-ui-color($theme, foreground, text-disabled);
    }
  }

  @include _set-main-button-colors-by-pallet($theme, primary, 600);
}

@mixin ra-ui-main-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-main-button {
    $buttonFontSize: 14px;
    $icon-s-fz: ra-ui-font-size($custom-typography, icon-s);
    $icon-padding: getRelative(8px, 12px);

    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);

      height: getRelative(24, $buttonFontSize);
      @include _immediate-arrow-width($custom-typography);
    }

    .ra-ui-main-button-static-text-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %pointerEvents;
    @extend %disableShadow;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-raised-button {
      &.ra-ui-button {
        @include _set-main-button-colors-general($theme);
      }

      &.mat-primary {
        @include _set-main-button-colors-by-pallet($theme, primary);
      }

      &.mat-accent {
        @include _set-main-button-colors-by-pallet($theme, accent);
      }

      &.mat-warn {
        @include _set-main-button-colors-by-pallet($theme, warn);
      }

      min-width: getRelative(72, $buttonFontSize);
      border-radius: $classic-border-radius;

      @include _ra-ui-override-mat-button-focus($theme);
    }

    .ra-ui-main-button-icon {
      height: $icon-s-fz;
      width: $icon-s-fz;
      font-size: $icon-s-fz;
      padding-right: $icon-padding;
      color: inherit;
      overflow: visible;

      &.ra-ui-icon-after-text {
        padding-left: $icon-padding;
        padding-right: 0;
        order: 1;
      }

      &.ra-ui-icon-without-text {
        padding: 0;
      }
    }

    &.ra-ui-inactive-state {
      .mat-raised-button.ra-ui-button {
        background-color: ra-ui-color($theme, background, inactive);
        color: ra-ui-contrast($theme, background, inactive);

        &:hover {
          background-color: ra-ui-color($theme, background, inactive-hover);
          color: ra-ui-contrast($theme, background, inactive-hover);
        }

        &:active:not([disabled='true']),
        &.ra-ui-active-state:not([disabled='true']) {
          background-color: ra-ui-color(
            $theme,
            background,
            interactive-component-07
          );
          color: ra-ui-contrast($theme, background, interactive-component-07);
        }

        &.ra-ui-inverted-mode {
          &:not([disabled='true']) {
            background-color: ra-ui-color(
              $theme,
              background,
              interactive-alt-01
            );
            color: ra-ui-contrast($theme, background, interactive-alt-01);
          }
          &:hover:not([disabled='true']) {
            background-color: ra-ui-color(
              $theme,
              background,
              interactive-component-05
            );
            color: ra-ui-contrast($theme, background, interactive-component-05);
          }

          &:active:not([disabled='true']),
          &.ra-ui-active-state:not([disabled='true']) {
            background-color: ra-ui-color(
              $theme,
              background,
              interactive-alt-02
            );
            color: ra-ui-contrast($theme, background, interactive-alt-02);
          }
        }
      }
    }

    .ra-ui-main-button-text {
      overflow: hidden;
    }

    .ra-ui-inverted-mode {
      $inverted-background-color: ra-ui-color($theme, primary, 200);
      $inverted-color: ra-ui-contrast($theme, primary, 200);
      $inverted-hover-background-color: ra-ui-color($theme, primary, 100);
      $inverted-hover-color: ra-ui-contrast($theme, primary, 100);
      $inverted-focused-background-color: ra-ui-color($theme, primary, 200);
      $inverted-focused-color: ra-ui-contrast($theme, primary, 200);
      $inverted-focused-border: getRelative(2px, 14px) solid
        ra-ui-color($theme, primary, A200);
      $inverted-pressed-background-color: ra-ui-color($theme, primary, 300);
      $inverted-pressed-color: ra-ui-contrast($theme, primary, 300);
      $inverted-disabled-background-color: ra-ui-color(
        $theme,
        background,
        disabled
      );
      $inverted-disabled-color: ra-ui-color($theme, foreground, text-label);
      &.mat-raised-button.ra-ui-button {
        &:not([disabled='true']) {
          background-color: $inverted-background-color;
          .static-text,
          .ra-ui-main-button-icon {
            color: $inverted-color;
          }
        }
        &:hover:not([disabled='true']) {
          background-color: $inverted-hover-background-color;

          .static-text:not([disabled='true']),
          .ra-ui-main-button-icon {
            color: $inverted-hover-color;
          }
        }

        &:active:not([disabled='true']),
        &.ra-ui-active-state:not([disabled='true']) {
          background-color: $inverted-pressed-background-color;

          .static-text:not([disabled='true']),
          .ra-ui-main-button-icon {
            color: $inverted-pressed-color;
          }
        }

        &.ra-ui-keyboard-focus {
          background-color: $inverted-focused-background-color;

          .static-text:not([disabled='true']),
          .ra-ui-main-button-icon {
            color: $inverted-focused-color;
          }
          &:not(.mat-button-disabled) .mat-button-focus-overlay {
            border: $inverted-focused-border;
          }
        }
        &:disabled {
          background-color: $inverted-disabled-background-color;
          .static-text,
          .ra-ui-main-button-icon {
            color: $inverted-disabled-color;
          }
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-set-text-button-colors(
  $theme,
  $palette,
  $hue: 500,
  $hue-hover: 700,
  $hue-active: 800
) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &.ra-ui-keyboard-focus {
    background-color: ra-ui-color($theme, $palette, 600, 0.1);
  }

  &:not(.is-disabled):active,
  &:not(.is-disabled):active:hover,
  &:not(.is-disabled).ra-ui-active-state,
  &:not(.is-disabled).ra-ui-active-state:hover {
    background-color: ra-ui-color(
      $theme,
      $palette,
      $hue-active,
      $flourish-active-opacity
    );

    .static-text,
    .ra-ui-text-button-icon {
      color: ra-ui-color($theme, $palette, $hue-active);
    }
  }

  &:not(.is-disabled):hover {
    background-color: ra-ui-color(
      $theme,
      $palette,
      $hue-hover,
      $flourish-hover-opacity
    );

    .static-text,
    .ra-ui-text-button-icon {
      color: ra-ui-color($theme, $palette, $hue-hover);
    }
  }

  &.is-disabled {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin ra-ui-text-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $button-font-size: 14px;
  $text-button-padding: getRelative(4, $button-font-size);

  $icon-s-fz: ra-ui-font-size($custom-typography, icon-s);
  $icon-padding: getRelative(4px, 12px);

  $inverted-color: ra-ui-color($theme, primary, 200);
  $inverted-disabled-color: ra-ui-color($theme, foreground, text-disabled);
  $inverted-hover-background-color: ra-ui-color(
    $theme,
    primary,
    100,
    $flourish-hover-opacity
  );
  $inverted-hover-color: ra-ui-color($theme, primary, 100);
  $inverted-press-background-color: ra-ui-color(
    $theme,
    primary,
    300,
    $flourish-active-opacity
  );
  $inverted-press-color: ra-ui-color($theme, primary, 300);
  $inverted-keyboard-focus-background-color: ra-ui-color(
    $theme,
    primary,
    200,
    $flourish-hover-opacity
  );
  $inverted-keyboard-focus-color: ra-ui-color($theme, primary, 200);

  $inactive-inverted-color: ra-ui-color($theme, background, interactive-alt-01);
  $inactive-inverted-hover-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05,
    400
  );
  $inactive-inverted-hover-background-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05,
    $flourish-hover-opacity
  );
  $inactive-inverted-press-color: ra-ui-color(
    $theme,
    background,
    interactive-alt-02
  );
  $inactive-inverted-press-background-color: ra-ui-color(
    $theme,
    background,
    interactive-alt-02,
    $flourish-active-opacity
  );

  ra-ui-text-button {
    // only in the text button; the height of ra-ui-text-button is not the same as its child's element button
    // this fix correct the height based on comparison of button's min-height and line-height
    @include firefox-only-specific() {
      $min-height: calc(
        strip-unit($text-button-min-height) *
          ra-ui-font-size($custom-typography, button)
      );
      $line-height: ra-ui-line-height($custom-typography, button);
      height: max($min-height, $line-height);
    }

    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
      @include _immediate-arrow-width($custom-typography);

      &.has-icon {
        min-width: getRelative(64, $button-font-size);
      }
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %pointerEvents;
    @extend %lineHeight;
    @extend %disableShadow;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-button {
      min-width: getRelative(52px, $button-font-size);
      min-height: $text-button-min-height;
      border-radius: $classic-border-radius;
      padding: 0 $text-button-padding 0 $text-button-padding;
      vertical-align: baseline;
      &.ra-ui-button.immediate-arrow.ra-ui-button {
        vertical-align: baseline;
      }
    }

    .mat-button {
      @include _ra-ui-override-mat-button-focus($theme);
    }

    .mat-button {
      &.default-color {
        @include ra-ui-set-text-button-colors($theme, primary);

        .static-text:not([disabled='true']),
        &:not(.is-disabled) .ra-ui-text-button-icon {
          color: ra-ui-color($theme, primary, 600);
        }
      }

      &.mat-primary {
        @include ra-ui-set-text-button-colors($theme, primary);
      }

      &.mat-accent {
        @include ra-ui-set-text-button-colors($theme, accent);
      }

      &.mat-warn {
        @include ra-ui-set-text-button-colors($theme, warn);
      }
    }

    .mat-button .mat-badge-content {
      z-index: 1;
      font-size: 0.821em;
    }

    .ra-ui-text-button-icon {
      height: $icon-s-fz;
      width: $icon-s-fz;
      font-size: $icon-s-fz;
      padding-right: $icon-padding;
      color: inherit;
      overflow: visible;

      &.ra-ui-icon-after-text {
        padding-left: $icon-padding;
        padding-right: 0;
      }
    }

    .ra-ui-text-button-text {
      overflow: hidden;
    }

    &.ra-ui-inactive-state .mat-button.ra-ui-button {
      &.default-color {
        .static-text:not([disabled='true']),
        &:not(.is-disabled) .ra-ui-text-button-icon {
          background-color: transparent;
          color: ra-ui-color($theme, foreground, text-label);
        }
      }

      &:hover {
        background-color: ra-ui-color(
          $theme,
          background,
          inactive-hover,
          $flourish-hover-opacity
        );

        .static-text:not([disabled='true']),
        &:not(.is-disabled) .ra-ui-text-button-icon {
          color: ra-ui-color($theme, background, inactive-hover);
        }
      }

      &:active,
      &:active:hover,
      &.ra-ui-active-state,
      &.ra-ui-active-state:hover {
        background-color: ra-ui-color(
          $theme,
          background,
          interactive-component-07,
          $flourish-active-opacity
        );

        .static-text:not([disabled='true']),
        &:not(.is-disabled) .ra-ui-text-button-icon {
          color: ra-ui-color($theme, background, interactive-component-07);
        }
      }
    }

    &.ra-ui-inverted-mode {
      .mat-button.default-color {
        .static-text:not([disabled='true']),
        &:not(.is-disabled) .ra-ui-text-button-icon {
          color: $inverted-color;
        }
        .static-text[disabled='true'],
        &.is-disabled .ra-ui-text-button-icon {
          color: $inverted-disabled-color;
        }
        &:not(.is-disabled):hover {
          background-color: $inverted-hover-background-color;
          .static-text:not([disabled='true']),
          .ra-ui-text-button-icon {
            color: $inverted-hover-color;
          }
        }

        &:not(.is-disabled):active,
        &:not(.is-disabled):active:hover,
        &:not(.is-disabled).ra-ui-active-state,
        &:not(.is-disabled).ra-ui-active-state:hover {
          background-color: $inverted-press-background-color;

          .static-text:not([disabled='true']),
          mat-icon.ra-ui-text-button-icon {
            color: $inverted-press-color;
          }
        }

        &.ra-ui-keyboard-focus {
          background-color: $inverted-keyboard-focus-background-color;
          .static-text:not([disabled='true']),
          &:not(.is-disabled) .ra-ui-text-button-icon {
            color: $inverted-keyboard-focus-color;
          }
        }
      }

      &.ra-ui-inactive-state .mat-button.ra-ui-button {
        &.default-color {
          &:not(.is-disabled) .ra-ui-text-button-icon,
          .static-text:not([disabled='true']) {
            background-color: transparent;
            color: $inactive-inverted-color;
          }
        }

        &:hover {
          background-color: $inactive-inverted-hover-background-color;
          &:not(.is-disabled) .ra-ui-text-button-icon,
          .static-text:not([disabled='true']) {
            color: $inactive-inverted-hover-color;
          }
        }

        &:active,
        &:active:hover,
        &.ra-ui-active-state,
        &.ra-ui-active-state:hover {
          background-color: $inactive-inverted-press-background-color;

          &:not(.is-disabled) mat-icon.ra-ui-text-button-icon,
          .static-text:not([disabled='true']) {
            color: $inactive-inverted-press-color;
          }
        }
      }
    }
  }
}

@mixin ra-ui-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include ra-ui-basic-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-fab-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-icon-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-icon-label-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-mini-fab-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-raised-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-outlined-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-main-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-text-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-basic-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $text: ra-ui-color($theme, foreground, text);

  ra-ui-basic-button {
    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
      @include _immediate-arrow-width($custom-typography);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %pointerEvents;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-button {
      &.mat-primary {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          primary,
          default
        );
      }

      &.mat-accent {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          accent,
          default
        );
      }

      &.mat-warn {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          warn,
          default
        );
      }

      &.default-color {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          background,
          focused-button
        );
        .static-text:not([disabled='true']) {
          color: $text;
        }
      }
    }

    .mat-button {
      @include _ra-ui-override-mat-button-focus($theme);
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-fab-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-fab-button {
    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %pointerEvents;

    .mat-fab {
      @extend %fabButtonText;
      @include _ra-ui-override-mat-button-focus($theme);
      @extend %raisedFabShadow;
      background-color: ra-ui-color($theme, background, fab-background);
    }

    // rules for inactive state and inactive press state
    &.ra-ui-inactive-state {
      .mat-fab {
        background-color: ra-ui-color($theme, background, inactive);

        .static-text,
        mat-icon {
          color: ra-ui-contrast($theme, background, inactive);
        }

        &.ra-ui-active-state {
          background-color: ra-ui-color($theme, background, inactive-pressed);

          .static-text,
          mat-icon {
            color: ra-ui-contrast($theme, background, inactive-pressed);
          }
        }
      }
    }

    $text: ra-ui-color($theme, foreground, text);

    /**
    * Explanation for the following solution:
    * It seems it is not possible to use hex color var in rgba in box-shadow, this combination simply doesn't work.
    * That is we cannot simply use:
    * box-shadow: 0px 3px 5px -1px rgba(var(--my-hex-color), 0.2)
    * With this, the project correctly compiles, the correct color is visible in browser developer tools (!),
    * but the color is not actually applied in the viewport.
    *
    * See https://stackoverflow.com/questions/59250927/box-shadow-with-rgba-variable
    * Unlike the solution suggested in the Stack Overflow thread, we cannot simply use rgb definition instead of hex definition
    * due to how colors are provided by Flourish.
    *
    * Mixing the intended color and intended opacity with transparent color using color-mix function workarounds the problem.
    *
    * Note the issue seems to be exclusive to this specific combination of css rule, css functions and hex color definition!
    */
    $box-shadow-color-1: color-mix(in srgb, $text 20%, transparent);
    $box-shadow-color-2: color-mix(in srgb, $text 14%, transparent);
    $box-shadow-color-3: color-mix(in srgb, $text 12%, transparent);

    & button.mat-fab.mat-button-base.ra-ui-button:not(.is-disabled) {
      // override Material box shadow for default appearance
      box-shadow:
        0px 3px 5px -1px $box-shadow-color-1,
        0px 6px 10px 0px $box-shadow-color-2,
        0px 1px 18px 0px $box-shadow-color-3;

      &.ra-ui-active-state {
        // override Material box shadow for pressed appearance
        box-shadow:
          0px 7px 8px -4px $box-shadow-color-1,
          0px 12px 17px 2px $box-shadow-color-2,
          0px 5px 22px 4px $box-shadow-color-3;
      }
    }

    @include disabled-icon-color($theme);
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-icon-button-size($custom-typography, $icon-size, $padding) {
  $icon-fs: ra-ui-font-size($custom-typography, $icon-size);
  $font-size: ra-ui-font-size($custom-typography, $icon-size);
  $line-height: ra-ui-line-height($custom-typography, $icon-size);
  width: $icon-fs;
  height: $icon-fs;
  line-height: $line-height;
  padding: $padding;
  .mat-icon {
    width: $icon-fs;
    height: $icon-fs;
    font-size: $font-size;
    line-height: $line-height;
  }

  $base-icon-font-sizes: (
    icon-s: 12px,
    icon-m: 16px,
    icon-l: 20px,
    icon-xl: 28px,
  );

  .mat-button-focus-overlay {
    font-size: $font-size;
  }
  // overrides default setting: base font-size by typography-level button
  &.ra-ui-keyboard-focus:not(.mat-button-disabled) .mat-button-focus-overlay {
    border-width: getRelative(2px, map-get($base-icon-font-sizes, $icon-size));
  }
}

@mixin ra-ui-icon-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $text: ra-ui-color($theme, foreground, text);
  $icon-button-enabled-icon: ra-ui-contrast(
    $theme,
    background,
    interactive-component-03
  );
  $icon-button-disabled-icon: ra-ui-color($theme, foreground, text-disabled);
  $icon-button-alt-disabled-icon: ra-ui-color(
    $theme,
    foreground,
    text-primary,
    0.35
  );
  $icon-button-hover-background: ra-ui-color($theme, primary, 200);
  $icon-button-hover-icon: ra-ui-contrast($theme, primary, 200);
  $icon-button-active-background: ra-ui-color($theme, primary, 800);
  $icon-button-active-icon: ra-ui-contrast($theme, primary, 800);

  $inverted-color: ra-ui-contrast($theme, background, interactive-component-07);
  $inverted-disabled-color: ra-ui-color($theme, foreground, text-disabled);
  $inverted-hover-background-color: ra-ui-color($theme, primary, 200);
  $inverted-hover-color: ra-ui-contrast($theme, primary, 200);
  $inverted-press-background-color: ra-ui-color($theme, primary, 800);
  $inverted-press-color: ra-ui-contrast($theme, primary, 800);
  $inverted-keyboard-focus-background-color: rgba(
    ra-ui-contrast($theme, background, interactive-component-07),
    $flourish-hover-opacity
  );

  $inactive-inverted-color: ra-ui-color($theme, background, interactive-alt-01);
  $inactive-inverted-hover-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05,
    400
  );
  $inactive-inverted-hover-background-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05,
    $flourish-hover-opacity
  );
  $inactive-inverted-press-color: ra-ui-color(
    $theme,
    background,
    interactive-alt-02
  );
  $inactive-inverted-press-background-color: ra-ui-color(
    $theme,
    background,
    interactive-alt-02,
    $flourish-active-opacity
  );

  $badge-selector: '.ra-ui-button:not(.is-disabled) ra-ui-badge.ra-ui-badge';

  ra-ui-icon-button {
    vertical-align: middle;

    &,
    .ra-ui-button,
    #{$badge-selector},
    #{$badge-selector} .ra-ui-badge--content,
    #{$badge-selector} .ra-ui-badge--content .static-text-inner {
      @include getCursor($custom-global-config);

      @include _immediate-arrow-width($custom-typography);
    }

    & {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %pointerEvents;
    @extend %disableShadow;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-icon-button {
      &.ra-ui-icon-size-s {
        @include ra-ui-icon-button-size(
          $custom-typography,
          icon-s,
          $small-icon-button-padding
        );
      }

      &.ra-ui-icon-size-m {
        @include ra-ui-icon-button-size(
          $custom-typography,
          icon-m,
          $small-icon-button-padding
        );
      }

      &.ra-ui-icon-size-l {
        @include ra-ui-icon-button-size(
          $custom-typography,
          icon-l,
          $large-icon-button-padding
        );
      }

      &.ra-ui-icon-size-xl {
        @include ra-ui-icon-button-size(
          $custom-typography,
          icon-xl,
          $large-icon-button-padding
        );
      }
    }
    .mat-icon-button {
      &.default-color {
        color: transparent;
        .mat-icon {
          color: $icon-button-enabled-icon;
        }
        &[disabled] .mat-icon {
          color: $icon-button-disabled-icon;
        }
      }
    }

    .mat-icon-button.is-disabled .ra-ui-badge {
      // using color of disabled material badge
      background-color: rgb(185, 185, 185);
    }

    .mat-icon-button.alt-disabled-color {
      &[disabled] .mat-icon {
        color: $icon-button-alt-disabled-icon;
      }
    }

    .mat-icon-button {
      @include _ra-ui-override-mat-button-focus($theme);
    }

    // colored shadow over icons during click
    .mat-icon-button {
      &.mat-primary:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state {
          background-color: ra-ui-color(
            $theme,
            primary,
            default,
            $hover-opacity
          );

          &:hover {
            background-color: ra-ui-color(
              $theme,
              primary,
              default,
              $active-opacity
            );
          }
        }
      }

      &.mat-accent:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state {
          background-color: ra-ui-color(
            $theme,
            accent,
            default,
            $hover-opacity
          );

          &:hover {
            background-color: ra-ui-color(
              $theme,
              accent,
              default,
              $active-opacity
            );
          }
        }
      }

      &.mat-warn:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state {
          background-color: ra-ui-color($theme, warn, default, $hover-opacity);

          &:hover {
            background-color: ra-ui-color(
              $theme,
              warn,
              default,
              $active-opacity
            );
          }
        }
      }

      &.default-color:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state,
        &:active:hover,
        &.ra-ui-active-state:hover {
          background-color: $icon-button-active-background;

          .mat-icon {
            color: $icon-button-active-icon;
          }
        }
      }

      &.default-color:not(.is-disabled):hover {
        background-color: $icon-button-hover-background;

        .mat-icon {
          color: $icon-button-hover-icon;
        }
      }
    }

    // inactive state styles
    &.ra-ui-inactive-state .mat-icon-button {
      &.mat-button-base {
        .mat-icon {
          color: ra-ui-color($theme, foreground, text-label);
        }

        &:hover {
          background-color: ra-ui-color(
            $theme,
            background,
            inactive-hover,
            $flourish-hover-opacity
          );
          .mat-icon {
            color: ra-ui-color($theme, background, inactive-hover);
          }
        }

        &:active,
        &:active:hover,
        &.ra-ui-active-state,
        &.ra-ui-active-state:hover {
          background-color: ra-ui-color(
            $theme,
            background,
            interactive-component-07,
            $flourish-active-opacity
          );
          .mat-icon {
            color: ra-ui-color($theme, background, interactive-component-07);
          }
        }
      }
    }

    &.ra-ui-inverted-mode {
      .ra-ui-button.default-color {
        &:not(.is-disabled) .ra-ui-icon {
          color: $inverted-color;
        }
        &.is-disabled .ra-ui-icon {
          color: $inverted-disabled-color;
        }

        &:not(.is-disabled):hover {
          background-color: $inverted-hover-background-color;

          .ra-ui-icon {
            color: $inverted-hover-color;
          }
        }

        &:not(.is-disabled):active,
        &:not(.is-disabled):active:hover,
        &:not(.is-disabled).ra-ui-active-state,
        &:not(.is-disabled).ra-ui-active-state:hover {
          background-color: $inverted-press-background-color;

          .ra-ui-icon {
            color: $inverted-press-color;
          }
        }
      }

      &.ra-ui-inactive-state .ra-ui-button.ra-ui-button {
        &.default-color {
          &:not(.is-disabled) .ra-ui-icon,
          .static-text:not([disabled='true']) {
            background-color: transparent;
            color: $inactive-inverted-color;
          }
        }

        &:hover {
          background-color: $inactive-inverted-hover-background-color;
          &:not(.is-disabled) .ra-ui-icon,
          .static-text:not([disabled='true']) {
            color: $inactive-inverted-hover-color;
          }
        }

        &:active,
        &:active:hover,
        &.ra-ui-active-state,
        &.ra-ui-active-state:hover {
          background-color: $inactive-inverted-press-background-color;

          &:not(.is-disabled) .ra-ui-icon,
          .static-text:not([disabled='true']) {
            color: $inactive-inverted-press-color;
          }
        }
      }
    }

    @include disabled-icon-color($theme);
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-icon-label-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $text: ra-ui-color($theme, foreground, text);

  ra-ui-icon-label-button {
    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
      @include _immediate-arrow-width($custom-typography);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    .ra-ui-icon-with-text-button {
      ra-ui-static-text {
        font-size: 0.72em; //10px;
        line-height: 1.2em; //12px
      }
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %basicButtonOpacity;
    @extend %pointerEvents;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-button {
      &.mat-primary {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          primary,
          default
        );
      }

      &.mat-accent {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          accent,
          default
        );
      }

      &.mat-warn {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          warn,
          default
        );
      }

      &.default-color {
        @include _ra-ui-set-transparent-background-button-colors(
          $theme,
          background,
          focused-button
        );
        .static-text:not([disabled='true']) {
          color: $text;
        }
      }
    }

    .mat-button {
      @include _ra-ui-override-mat-button-focus($theme);
    }

    .ra-ui-icon-with-text-button {
      &.default-color:not(.is-disabled) mat-icon {
        color: $text;
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-mini-fab-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $text: ra-ui-color($theme, foreground, text);

  ra-ui-mini-fab-button {
    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %pointerEvents;

    .mat-mini-fab {
      @extend %fabButtonText;
      @include _ra-ui-override-mat-button-focus($theme);
      @extend %raisedFabShadow;
    }

    & .mat-mini-fab.ra-ui-active-state {
      // used default Material mat-mini-fab component :active style
      box-shadow:
        0px 7px 8px -4px rgb(0 0 0 / 20%),
        0px 12px 17px 2px rgb(0 0 0 / 14%),
        0px 5px 22px 4px rgb(0 0 0 / 12%);
    }

    @include disabled-icon-color($theme);
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-raised-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-raised-button {
    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
      @include _immediate-arrow-width($custom-typography);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %pointerEvents;
    @extend %buttonPosition;
    @extend %immediateArrowCursor;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-raised-button {
      @include _ra-ui-override-mat-button-focus($theme);
      @extend %raisedFabShadow;

      &.default-color {
        background-color: ra-ui-color(
          $theme,
          foreground,
          text-primary-contrast
        );
      }

      &.ra-ui-active-state {
        // used default Material mat-raized-button component :active style
        box-shadow:
          0px 5px 5px -3px rgb(0 0 0 / 20%),
          0px 8px 10px 1px rgb(0 0 0 / 14%),
          0px 3px 14px 2px rgb(0 0 0 / 12%);
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin set-button($theme, $color) {
  border: 1px solid ra-ui-color($theme, $color, 600);

  // label color in basic buttons
  &.mat-#{$color} .static-text:not([disabled='true']),
  .ra-ui-outlined-button-icon {
    color: ra-ui-color($theme, $color);
  }

  &.ra-ui-keyboard-focus {
    background-color: ra-ui-color($theme, $color, 600, 0.1);
    &.ra-ui-button .static-text,
    .ra-ui-outlined-button-icon {
      color: ra-ui-color($theme, $color, 600);
    }
  }
  &:hover:not([disabled='true']) {
    background-color: ra-ui-color($theme, $color, 700, $flourish-hover-opacity);
    border: 1px solid ra-ui-color($theme, $color, 700);

    &.ra-ui-button .static-text,
    .ra-ui-outlined-button-icon {
      color: ra-ui-color($theme, $color, 700);
    }
  }
  &:active:not([disabled='true']),
  &.ra-ui-active-state:not([disabled='true']) {
    background-color: ra-ui-color(
      $theme,
      $color,
      800,
      $flourish-active-opacity
    );
    border: 1px solid ra-ui-color($theme, $color, 800);

    &.ra-ui-button .static-text,
    .ra-ui-outlined-button-icon {
      color: ra-ui-color($theme, $color, 800);
    }
  }
  &.default-color {
    .static-text:not([disabled='true']),
    .ra-ui-outlined-button-icon {
      color: ra-ui-color($theme, $color, 600);
    }
  }
  &:disabled {
    border: 1px solid ra-ui-color($theme, background, disabled);

    .ra-ui-outlined-button-icon {
      color: ra-ui-color($theme, foreground, text-disabled);
    }
  }
}

@mixin ra-ui-outlined-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-outlined-button {
    $buttonFontSize: 14px;
    $icon-s-fz: ra-ui-font-size($custom-typography, icon-s);
    $icon-padding: getRelative(8px, 12px);

    $inverted-background-color: transparent;
    $inverted-color: ra-ui-color($theme, primary, 200);
    $inverted-hover-background-color: ra-ui-color(
      $theme,
      primary,
      100,
      $flourish-hover-opacity
    );
    $inverted-hover-color: ra-ui-color($theme, primary, 100);
    $inverted-focused-background-color: ra-ui-color(
      $theme,
      primary,
      200,
      $flourish-hover-opacity
    );
    $inverted-focused-color: ra-ui-color($theme, primary, 200);
    $inverted-focused-border: getRelative(2px, 14px) solid
      ra-ui-color($theme, primary, A200);
    $inverted-pressed-background-color: ra-ui-color(
      $theme,
      primary,
      300,
      $flourish-active-opacity
    );
    $inverted-pressed-color: ra-ui-color($theme, primary, 300);
    $inverted-disabled-background-color: transparent;
    $inverted-disabled-color: ra-ui-color($theme, foreground, text-disabled);

    $inactive-background-color: transparent;
    $inactive-color: ra-ui-color($theme, foreground, text-label);
    $inactive-border: 1px solid ra-ui-color($theme, background, inactive);
    $inactive-pressed-color: ra-ui-color(
      $theme,
      background,
      interactive-component-07
    );
    $inactive-pressed-background-color: ra-ui-color(
      $theme,
      background,
      interactive-component-07,
      $flourish-active-opacity
    );
    $inactive-hover-color: ra-ui-color($theme, background, inactive-hover);
    $inactive-hover-background-color: ra-ui-color(
      $theme,
      background,
      inactive-hover,
      $flourish-hover-opacity
    );
    $inactive-inverted-color: ra-ui-color(
      $theme,
      background,
      interactive-alt-01
    );
    $inactive-inverted-background-color: transparent;
    $inactive-inverted-hover-background-color: ra-ui-color(
      $theme,
      background,
      interactive-component-05,
      $flourish-hover-opacity
    );
    $inactive-inverted-hover-color: ra-ui-color(
      $theme,
      background,
      interactive-component-05
    );
    $inactive-inverted-pressed-color: ra-ui-color(
      $theme,
      background,
      interactive-alt-02
    );
    $inactive-inverted-pressed-background-color: ra-ui-color(
      $theme,
      background,
      interactive-alt-02,
      $flourish-active-opacity
    );

    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
      @include _immediate-arrow-width($custom-typography);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %pointerEvents;
    @extend %lineHeight;
    @extend %disableShadow;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-badge-medium.mat-badge-after,
    .mat-badge-medium.mat-badge-before {
      border: 1px solid ra-ui-color($theme, primary, 600);
    }
    .mat-stroked-button {
      @include set-button($theme, primary);
      @include _ra_ui-override-mat-button-focus($theme);

      min-width: getRelative(72, $buttonFontSize);
      min-height: getRelative(24, $buttonFontSize);
      border-radius: $classic-border-radius;
    }

    .mat-stroked-button.mat-primary {
      @include set-button($theme, primary);
    }
    .mat-stroked-button.mat-accent {
      @include set-button($theme, accent);
    }
    .mat-stroked-button.mat-warn {
      @include set-button($theme, warn);
    }

    .ra-ui-outlined-button-icon {
      height: $icon-s-fz;
      width: $icon-s-fz;
      font-size: $icon-s-fz;
      padding-right: $icon-padding;
      color: inherit;
      overflow: visible;

      &.ra-ui-icon-after-text {
        padding-left: $icon-padding;
        padding-right: 0;
      }

      &.ra-ui-icon-without-text {
        padding: 0;
      }
    }

    .ra-ui-outlined-button-text {
      overflow: hidden;
    }

    .ra-ui-inverted-mode {
      &.mat-stroked-button.ra-ui-button {
        &:not([disabled='true']) {
          background-color: $inverted-background-color;
          border: 1px solid $inverted-color;
          .static-text,
          .ra-ui-outlined-button-icon {
            color: $inverted-color;
          }
        }

        &:hover:not([disabled='true']) {
          background-color: $inverted-hover-background-color;
          border: 1px solid $inverted-hover-color;

          .static-text:not([disabled='true']),
          .ra-ui-outlined-button-icon {
            color: $inverted-hover-color;
          }
        }

        &:active:not([disabled='true']),
        &.ra-ui-active-state:not([disabled='true']) {
          background-color: $inverted-pressed-background-color;
          border: 1px solid $inverted-pressed-color;

          .static-text:not([disabled='true']),
          .ra-ui-outlined-button-icon {
            color: $inverted-pressed-color;
          }
        }

        &.ra-ui-keyboard-focus {
          background-color: $inverted-focused-background-color;
          border: $inverted-focused-border;

          .static-text:not([disabled='true']) {
            color: $inverted-focused-color;
          }
          &:not(.mat-button-disabled) .mat-button-focus-overlay {
            border: $inverted-focused-border;
          }
        }
        &:disabled {
          background-color: $inverted-disabled-background-color;
          .static-text {
            color: $inverted-disabled-color;
          }
        }
      }
    }

    &.ra-ui-inactive-state {
      .mat-stroked-button {
        border: $inactive-border;

        &.default-color .static-text:not([disabled='true']),
        .ra-ui-outlined-button-icon {
          background-color: $inactive-background-color;
          color: $inactive-color;
        }

        &:hover {
          .static-text:not([disabled='true']),
          .ra-ui-outlined-button-icon {
            color: $inactive-hover-color;
          }
          background-color: $inactive-hover-background-color;
          color: $inactive-hover-color;
          border: 1px solid $inactive-hover-color;
        }

        &:active,
        &:active:hover,
        &.ra-ui-active-state,
        &.ra-ui-active-state:hover {
          .static-text:not([disabled='true']),
          .ra-ui-outlined-button-icon {
            color: $inactive-pressed-color;
          }

          background-color: $inactive-pressed-background-color;
          color: $inactive-pressed-color;
          border: 1px solid $inactive-pressed-color;
        }

        &.ra-ui-inverted-mode {
          &:not([disabled='true']) {
            background-color: $inactive-inverted-background-color;
            border: 1px solid $inactive-inverted-color;
            .static-text:not([disabled='true']),
            .ra-ui-outlined-button-icon {
              color: $inactive-inverted-color;
            }
          }
          &:hover:not([disabled='true']) {
            background-color: $inactive-inverted-hover-background-color;
            border: 1px solid $inactive-inverted-hover-color;
            .static-text:not([disabled='true']),
            .ra-ui-outlined-button-icon {
              color: $inactive-inverted-hover-color;
            }
          }

          &:active:not([disabled='true']),
          &.ra-ui-active-state:not([disabled='true']) {
            background-color: $inactive-inverted-pressed-background-color;
            border: 1px solid $inactive-inverted-pressed-color;

            .static-text:not([disabled='true']),
            .ra-ui-outlined-button-icon {
              color: $inactive-inverted-pressed-color;
            }
          }
        }
      }
    }

    .ra-ui-outlined-button-icon-static-text-wrapper {
      position: inherit;
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin _set-main-button-color-and-background-by-pallet($theme, $pallet, $hue) {
  background-color: ra-ui-color($theme, $pallet, $hue);

  .static-text:not([disabled='true']),
  .ra-ui-main-button-icon {
    color: ra-ui-contrast($theme, $pallet, $hue);
  }
}

@mixin _set-main-button-colors-by-pallet(
  $theme,
  $pallet,
  $hue: 500,
  $hue-hover: 700,
  $hue-active: 800
) {
  @include _set-main-button-color-and-background-by-pallet(
    $theme,
    $pallet,
    $hue
  );

  &:hover:not([disabled='true']) {
    @include _set-main-button-color-and-background-by-pallet(
      $theme,
      $pallet,
      $hue-hover
    );
  }
  &:active:not([disabled='true']),
  &.ra-ui-active-state:not([disabled='true']) {
    @include _set-main-button-color-and-background-by-pallet(
      $theme,
      $pallet,
      $hue-active
    );
  }
}

// We cannot access private mixins since angular 15, so this redefines the private one. The private elevation mixin has advantage,
// that it can extract elevation color from custom theme.
@mixin private-theme-overridable-elevation($zValue, $config) {
  $foreground: map-get($config, foreground);
  $elevation-color: map-get($foreground, elevation);

  @include mat.overridable-elevation($zValue, $elevation-color);
}

@mixin _set-main-button-colors-general($theme) {
  &:active:not([disabled='true']),
  &.ra-ui-active-state:not([disabled='true']) {
    @include private-theme-overridable-elevation(0, $theme);
  }

  &:disabled {
    background-color: ra-ui-color($theme, background, disabled, 0.24);
    color: ra-ui-color($theme, foreground, text-disabled);

    .ra-ui-main-button-icon {
      color: ra-ui-color($theme, foreground, text-disabled);
    }
  }

  @include _set-main-button-colors-by-pallet($theme, primary, 600);
}

@mixin ra-ui-main-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-main-button {
    $buttonFontSize: 14px;
    $icon-s-fz: ra-ui-font-size($custom-typography, icon-s);
    $icon-padding: getRelative(8px, 12px);

    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);

      height: getRelative(24, $buttonFontSize);
      @include _immediate-arrow-width($custom-typography);
    }

    .ra-ui-main-button-static-text-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %pointerEvents;
    @extend %disableShadow;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-raised-button {
      &.ra-ui-button {
        @include _set-main-button-colors-general($theme);
      }

      &.mat-primary {
        @include _set-main-button-colors-by-pallet($theme, primary);
      }

      &.mat-accent {
        @include _set-main-button-colors-by-pallet($theme, accent);
      }

      &.mat-warn {
        @include _set-main-button-colors-by-pallet($theme, warn);
      }

      min-width: getRelative(72, $buttonFontSize);
      border-radius: $classic-border-radius;

      @include _ra-ui-override-mat-button-focus($theme);
    }

    .ra-ui-main-button-icon {
      height: $icon-s-fz;
      width: $icon-s-fz;
      font-size: $icon-s-fz;
      padding-right: $icon-padding;
      color: inherit;
      overflow: visible;

      &.ra-ui-icon-after-text {
        padding-left: $icon-padding;
        padding-right: 0;
        order: 1;
      }

      &.ra-ui-icon-without-text {
        padding: 0;
      }
    }

    &.ra-ui-inactive-state {
      .mat-raised-button.ra-ui-button {
        background-color: ra-ui-color($theme, background, inactive);
        color: ra-ui-contrast($theme, background, inactive);

        &:hover {
          background-color: ra-ui-color($theme, background, inactive-hover);
          color: ra-ui-contrast($theme, background, inactive-hover);
        }

        &:active:not([disabled='true']),
        &.ra-ui-active-state:not([disabled='true']) {
          background-color: ra-ui-color(
            $theme,
            background,
            interactive-component-07
          );
          color: ra-ui-contrast($theme, background, interactive-component-07);
        }

        &.ra-ui-inverted-mode {
          &:not([disabled='true']) {
            background-color: ra-ui-color(
              $theme,
              background,
              interactive-alt-01
            );
            color: ra-ui-contrast($theme, background, interactive-alt-01);
          }
          &:hover:not([disabled='true']) {
            background-color: ra-ui-color(
              $theme,
              background,
              interactive-component-05
            );
            color: ra-ui-contrast($theme, background, interactive-component-05);
          }

          &:active:not([disabled='true']),
          &.ra-ui-active-state:not([disabled='true']) {
            background-color: ra-ui-color(
              $theme,
              background,
              interactive-alt-02
            );
            color: ra-ui-contrast($theme, background, interactive-alt-02);
          }
        }
      }
    }

    .ra-ui-main-button-text {
      overflow: hidden;
    }

    .ra-ui-inverted-mode {
      $inverted-background-color: ra-ui-color($theme, primary, 200);
      $inverted-color: ra-ui-contrast($theme, primary, 200);
      $inverted-hover-background-color: ra-ui-color($theme, primary, 100);
      $inverted-hover-color: ra-ui-contrast($theme, primary, 100);
      $inverted-focused-background-color: ra-ui-color($theme, primary, 200);
      $inverted-focused-color: ra-ui-contrast($theme, primary, 200);
      $inverted-focused-border: getRelative(2px, 14px) solid
        ra-ui-color($theme, primary, A200);
      $inverted-pressed-background-color: ra-ui-color($theme, primary, 300);
      $inverted-pressed-color: ra-ui-contrast($theme, primary, 300);
      $inverted-disabled-background-color: ra-ui-color(
        $theme,
        background,
        disabled
      );
      $inverted-disabled-color: ra-ui-color($theme, foreground, text-label);
      &.mat-raised-button.ra-ui-button {
        &:not([disabled='true']) {
          background-color: $inverted-background-color;
          .static-text,
          .ra-ui-main-button-icon {
            color: $inverted-color;
          }
        }
        &:hover:not([disabled='true']) {
          background-color: $inverted-hover-background-color;

          .static-text:not([disabled='true']),
          .ra-ui-main-button-icon {
            color: $inverted-hover-color;
          }
        }

        &:active:not([disabled='true']),
        &.ra-ui-active-state:not([disabled='true']) {
          background-color: $inverted-pressed-background-color;

          .static-text:not([disabled='true']),
          .ra-ui-main-button-icon {
            color: $inverted-pressed-color;
          }
        }

        &.ra-ui-keyboard-focus {
          background-color: $inverted-focused-background-color;

          .static-text:not([disabled='true']),
          .ra-ui-main-button-icon {
            color: $inverted-focused-color;
          }
          &:not(.mat-button-disabled) .mat-button-focus-overlay {
            border: $inverted-focused-border;
          }
        }
        &:disabled {
          background-color: $inverted-disabled-background-color;
          .static-text,
          .ra-ui-main-button-icon {
            color: $inverted-disabled-color;
          }
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-set-text-button-colors(
  $theme,
  $palette,
  $hue: 500,
  $hue-hover: 700,
  $hue-active: 800
) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &.ra-ui-keyboard-focus {
    background-color: ra-ui-color($theme, $palette, 600, 0.1);
  }

  &:not(.is-disabled):active,
  &:not(.is-disabled):active:hover,
  &:not(.is-disabled).ra-ui-active-state,
  &:not(.is-disabled).ra-ui-active-state:hover {
    background-color: ra-ui-color(
      $theme,
      $palette,
      $hue-active,
      $flourish-active-opacity
    );

    .static-text,
    .ra-ui-text-button-icon {
      color: ra-ui-color($theme, $palette, $hue-active);
    }
  }

  &:not(.is-disabled):hover {
    background-color: ra-ui-color(
      $theme,
      $palette,
      $hue-hover,
      $flourish-hover-opacity
    );

    .static-text,
    .ra-ui-text-button-icon {
      color: ra-ui-color($theme, $palette, $hue-hover);
    }
  }

  &.is-disabled {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin ra-ui-text-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $button-font-size: 14px;
  $text-button-padding: getRelative(4, $button-font-size);

  $icon-s-fz: ra-ui-font-size($custom-typography, icon-s);
  $icon-padding: getRelative(4px, 12px);

  $inverted-color: ra-ui-color($theme, primary, 200);
  $inverted-disabled-color: ra-ui-color($theme, foreground, text-disabled);
  $inverted-hover-background-color: ra-ui-color(
    $theme,
    primary,
    100,
    $flourish-hover-opacity
  );
  $inverted-hover-color: ra-ui-color($theme, primary, 100);
  $inverted-press-background-color: ra-ui-color(
    $theme,
    primary,
    300,
    $flourish-active-opacity
  );
  $inverted-press-color: ra-ui-color($theme, primary, 300);
  $inverted-keyboard-focus-background-color: ra-ui-color(
    $theme,
    primary,
    200,
    $flourish-hover-opacity
  );
  $inverted-keyboard-focus-color: ra-ui-color($theme, primary, 200);

  $inactive-inverted-color: ra-ui-color($theme, background, interactive-alt-01);
  $inactive-inverted-hover-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05,
    400
  );
  $inactive-inverted-hover-background-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05,
    $flourish-hover-opacity
  );
  $inactive-inverted-press-color: ra-ui-color(
    $theme,
    background,
    interactive-alt-02
  );
  $inactive-inverted-press-background-color: ra-ui-color(
    $theme,
    background,
    interactive-alt-02,
    $flourish-active-opacity
  );

  ra-ui-text-button {
    // only in the text button; the height of ra-ui-text-button is not the same as its child's element button
    // this fix correct the height based on comparison of button's min-height and line-height
    @include firefox-only-specific() {
      $min-height: calc(
        strip-unit($text-button-min-height) *
          ra-ui-font-size($custom-typography, button)
      );
      $line-height: ra-ui-line-height($custom-typography, button);
      height: max($min-height, $line-height);
    }

    &,
    .ra-ui-button {
      @include getCursor($custom-global-config);
      @include _immediate-arrow-width($custom-typography);

      &.has-icon {
        min-width: getRelative(64, $button-font-size);
      }
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %pointerEvents;
    @extend %lineHeight;
    @extend %disableShadow;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-button {
      min-width: getRelative(52px, $button-font-size);
      min-height: $text-button-min-height;
      border-radius: $classic-border-radius;
      padding: 0 $text-button-padding 0 $text-button-padding;
      vertical-align: baseline;
      &.ra-ui-button.immediate-arrow.ra-ui-button {
        vertical-align: baseline;
      }
    }

    .mat-button {
      @include _ra-ui-override-mat-button-focus($theme);
    }

    .mat-button {
      &.default-color {
        @include ra-ui-set-text-button-colors($theme, primary);

        .static-text:not([disabled='true']),
        &:not(.is-disabled) .ra-ui-text-button-icon {
          color: ra-ui-color($theme, primary, 600);
        }
      }

      &.mat-primary {
        @include ra-ui-set-text-button-colors($theme, primary);
      }

      &.mat-accent {
        @include ra-ui-set-text-button-colors($theme, accent);
      }

      &.mat-warn {
        @include ra-ui-set-text-button-colors($theme, warn);
      }
    }

    .mat-button .mat-badge-content {
      z-index: 1;
      font-size: 0.821em;
    }

    .ra-ui-text-button-icon {
      height: $icon-s-fz;
      width: $icon-s-fz;
      font-size: $icon-s-fz;
      padding-right: $icon-padding;
      color: inherit;
      overflow: visible;

      &.ra-ui-icon-after-text {
        padding-left: $icon-padding;
        padding-right: 0;
      }
    }

    .ra-ui-text-button-text {
      overflow: hidden;
    }

    &.ra-ui-inactive-state .mat-button.ra-ui-button {
      &.default-color {
        .static-text:not([disabled='true']),
        &:not(.is-disabled) .ra-ui-text-button-icon {
          background-color: transparent;
          color: ra-ui-color($theme, foreground, text-label);
        }
      }

      &:hover {
        background-color: ra-ui-color(
          $theme,
          background,
          inactive-hover,
          $flourish-hover-opacity
        );

        .static-text:not([disabled='true']),
        &:not(.is-disabled) .ra-ui-text-button-icon {
          color: ra-ui-color($theme, background, inactive-hover);
        }
      }

      &:active,
      &:active:hover,
      &.ra-ui-active-state,
      &.ra-ui-active-state:hover {
        background-color: ra-ui-color(
          $theme,
          background,
          interactive-component-07,
          $flourish-active-opacity
        );

        .static-text:not([disabled='true']),
        &:not(.is-disabled) .ra-ui-text-button-icon {
          color: ra-ui-color($theme, background, interactive-component-07);
        }
      }
    }

    &.ra-ui-inverted-mode {
      .mat-button.default-color {
        .static-text:not([disabled='true']),
        &:not(.is-disabled) .ra-ui-text-button-icon {
          color: $inverted-color;
        }
        .static-text[disabled='true'],
        &.is-disabled .ra-ui-text-button-icon {
          color: $inverted-disabled-color;
        }
        &:not(.is-disabled):hover {
          background-color: $inverted-hover-background-color;
          .static-text:not([disabled='true']),
          .ra-ui-text-button-icon {
            color: $inverted-hover-color;
          }
        }

        &:not(.is-disabled):active,
        &:not(.is-disabled):active:hover,
        &:not(.is-disabled).ra-ui-active-state,
        &:not(.is-disabled).ra-ui-active-state:hover {
          background-color: $inverted-press-background-color;

          .static-text:not([disabled='true']),
          mat-icon.ra-ui-text-button-icon {
            color: $inverted-press-color;
          }
        }

        &.ra-ui-keyboard-focus {
          background-color: $inverted-keyboard-focus-background-color;
          .static-text:not([disabled='true']),
          &:not(.is-disabled) .ra-ui-text-button-icon {
            color: $inverted-keyboard-focus-color;
          }
        }
      }

      &.ra-ui-inactive-state .mat-button.ra-ui-button {
        &.default-color {
          &:not(.is-disabled) .ra-ui-text-button-icon,
          .static-text:not([disabled='true']) {
            background-color: transparent;
            color: $inactive-inverted-color;
          }
        }

        &:hover {
          background-color: $inactive-inverted-hover-background-color;
          &:not(.is-disabled) .ra-ui-text-button-icon,
          .static-text:not([disabled='true']) {
            color: $inactive-inverted-hover-color;
          }
        }

        &:active,
        &:active:hover,
        &.ra-ui-active-state,
        &.ra-ui-active-state:hover {
          background-color: $inactive-inverted-press-background-color;

          &:not(.is-disabled) mat-icon.ra-ui-text-button-icon,
          .static-text:not([disabled='true']) {
            color: $inactive-inverted-press-color;
          }
        }
      }
    }
  }
}

@mixin ra-ui-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include ra-ui-basic-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-fab-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-icon-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-icon-label-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-mini-fab-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-raised-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-outlined-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-main-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-text-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

@mixin ra-ui-button-toggle-theme($theme, $custom-typography) {
  //colors
  $disabled-color: ra-ui-color($theme, foreground, text-disabled);
  $toggle-button-active-color: ra-ui-color($theme, primary, 600);

  //color for pane design based on main config
  $background-design-active-icon-color: ra-ui-color(
    $theme,
    background,
    interactive-component-01
  );

  //color for active state via activePaneIndication configuration in IToggleButton
  $single-active-pane-indicator-background-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05
  );

  //colors for pressed state
  $toggle-button-pressed-background-color: ra-ui-color($theme, primary, 700);
  $toggle-button-pressed-icon-color: ra-ui-color(
    $theme,
    background,
    interactive-component-01
  );

  //width
  $active-indicator-big-icons-width: getRelative(4, 14);
  $active-indicator-small-icons-width: getRelative(2, 14);

  ra-ui-icon-button-toggle,
  ra-ui-icon-button-multi-toggle {
    @include ra-ui-typography-level-to-styles($custom-typography, button);

    .container > * {
      position: relative;
      display: inline-flex;
    }

    // override the pressed state of icon buttons
    .mat-icon-button.ra-ui-button.default-color:not(.is-disabled) {
      &:active,
      &.ra-ui-active-state {
        background-color: $toggle-button-pressed-background-color;

        .mat-icon {
          color: $toggle-button-pressed-icon-color;
        }
      }
    }

    .ra-ui-icon-button-toggle-background-design {
      // for the pane design - override the active state of icon buttons
      .active-toggle-button
        .mat-icon-button.ra-ui-button.default-color:not(.is-disabled) {
        background-color: $toggle-button-active-color;

        .mat-icon {
          color: $background-design-active-icon-color;
        }

        &:active,
        &.ra-ui-active-state {
          background-color: $toggle-button-pressed-background-color;

          .mat-icon {
            color: $toggle-button-pressed-icon-color;
          }
        }
      }
    }

    // for the standard design with pane configuration for single button - override the active state of icon buttons
    .ra-ui-icon-button-toggle-standard-design .active-toggle-button {
      &.use-pane-indicator-configuration
        .mat-icon-button.ra-ui-button.default-color:not(.is-disabled) {
        background-color: $single-active-pane-indicator-background-color;

        &:active,
        &.ra-ui-active-state {
          background-color: $toggle-button-pressed-background-color;
        }
      }

      .mat-icon-button.ra-ui-button.default-color:not(.is-disabled) {
        .mat-icon {
          color: $toggle-button-active-color;
        }

        &:active,
        &.ra-ui-active-state {
          .mat-icon {
            color: $toggle-button-pressed-icon-color;
          }
        }
      }
    }

    .vertical-toggle-button {
      display: flex;
      flex-direction: column;
    }

    .ra-ui-icon-button-line-container {
      position: relative;
      .line {
        position: absolute;
        width: 100%;
      }
    }

    .line {
      position: absolute;
      background-color: $toggle-button-active-color;
      cursor: pointer;
      pointer-events: none;
    }
    .is-disabled + .line {
      background-color: $disabled-color;
      cursor: default;
    }

    .active-indicator-left,
    .active-indicator-right {
      .line {
        height: 100%;
        top: 0;
        &.ra-ui-icon-l,
        &.ra-ui-icon-xl {
          width: $active-indicator-big-icons-width;
        }
        &.ra-ui-icon-s,
        &.ra-ui-icon-m {
          width: $active-indicator-small-icons-width;
        }
      }
    }

    .active-indicator-left .line {
      left: 0;
    }

    .active-indicator-right .line {
      left: calc(100% - 0.2857em);
    }

    .active-indicator-top,
    .active-indicator-bottom {
      .line {
        left: 0;
        &.ra-ui-icon-l,
        &.ra-ui-icon-xl {
          height: $active-indicator-big-icons-width;
        }
        &.ra-ui-icon-s,
        &.ra-ui-icon-m {
          height: $active-indicator-small-icons-width;
        }
      }
    }

    .active-indicator-top .line {
      top: 0;
    }

    .active-indicator-bottom .line {
      bottom: 0;
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

/**
 * those styles are used for outlined-button-multi toggle as well
 */
@mixin ra-ui-outlined-button-toggle-styles($theme, $custom-typography) {
  /**
     * INVISION DESIGN - Button toggle V1
     * https://rockwellautomation.atlassian.net/secure/attachment/412207/Button%20Toggle%402x.png
     * typography-name: icon-s, button
     */
  $button-font-size: 14px;
  $active-border-color: ra-ui-color($theme, primary, 600);
  $focus-background-color: transparent;
  $pressed-background-color: ra-ui-color($theme, primary, 700);
  $pressed-typography-color: ra-ui-color(
    $theme,
    background,
    interactive-component-01
  );
  $padding: getRelative(8px, 14px); // button typography level = 14

  .active-toggle-button
    .ra-ui-button.mat-badge-medium.mat-badge-before:not(.is-disabled):not(
      :active
    ):not(.ra-ui-active-state) {
    background-color: ra-ui-color($theme, primary, 600);
    border-color: $active-border-color;

    .static-text-inner {
      color: ra-ui-color($theme, background, interactive-component-01);
    }

    mat-icon {
      color: ra-ui-color($theme, background, interactive-component-01);
    }
  }

  // based on invision design for outlined button toggle - we need to override those styles of outlined button
  ra-ui-outlined-button {
    .mat-stroked-button {
      &:active:not([disabled]),
      &.ra-ui-active-state:not([disabled]) {
        &.default-color .static-text-inner:not([disabled='true']) {
          color: $pressed-typography-color;
        }

        background-color: $pressed-background-color;
        color: $pressed-typography-color;
        border-color: $pressed-background-color;

        mat-icon.ra-ui-outlined-button-icon {
          color: $pressed-typography-color;
        }
      }
      &.ra-ui-keyboard-focus {
        background-color: $focus-background-color;
      }

      padding: 0 $padding;
    }
  }

  .first .ra-ui-button.mat-badge-medium.mat-badge-before {
    border-left-width: 1px;
    border-radius: 4px 0 0 0;
  }
  .last .ra-ui-button.mat-badge-medium.mat-badge-before {
    border-radius: 0 0 4px 0;
  }

  .ra-ui-button.mat-badge-medium.mat-badge-before {
    border-left-width: 0px;
    border-radius: 0 0 0 0;
    min-width: getRelative(60, $button-font-size);

    &.has-icon {
      min-width: getRelative(72, $button-font-size);
    }
  }
}

@mixin ra-ui-outlined-button-toggle-theme($theme, $custom-typography) {
  ra-ui-outlined-button-toggle {
    @include ra-ui-outlined-button-toggle-styles($theme, $custom-typography);
  }
}

@mixin ra-ui-outlined-button-multi-toggle-theme($theme, $custom-typography) {
  ra-ui-outlined-button-multi-toggle {
    @include ra-ui-outlined-button-toggle-styles($theme, $custom-typography);
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

/**
 * those styles are used for outlined-button-multi toggle as well
 */
@mixin ra-ui-outlined-button-toggle-styles($theme, $custom-typography) {
  /**
     * INVISION DESIGN - Button toggle V1
     * https://rockwellautomation.atlassian.net/secure/attachment/412207/Button%20Toggle%402x.png
     * typography-name: icon-s, button
     */
  $button-font-size: 14px;
  $active-border-color: ra-ui-color($theme, primary, 600);
  $focus-background-color: transparent;
  $pressed-background-color: ra-ui-color($theme, primary, 700);
  $pressed-typography-color: ra-ui-color(
    $theme,
    background,
    interactive-component-01
  );
  $padding: getRelative(8px, 14px); // button typography level = 14

  .active-toggle-button
    .ra-ui-button.mat-badge-medium.mat-badge-before:not(.is-disabled):not(
      :active
    ):not(.ra-ui-active-state) {
    background-color: ra-ui-color($theme, primary, 600);
    border-color: $active-border-color;

    .static-text-inner {
      color: ra-ui-color($theme, background, interactive-component-01);
    }

    mat-icon {
      color: ra-ui-color($theme, background, interactive-component-01);
    }
  }

  // based on invision design for outlined button toggle - we need to override those styles of outlined button
  ra-ui-outlined-button {
    .mat-stroked-button {
      &:active:not([disabled]),
      &.ra-ui-active-state:not([disabled]) {
        &.default-color .static-text-inner:not([disabled='true']) {
          color: $pressed-typography-color;
        }

        background-color: $pressed-background-color;
        color: $pressed-typography-color;
        border-color: $pressed-background-color;

        mat-icon.ra-ui-outlined-button-icon {
          color: $pressed-typography-color;
        }
      }
      &.ra-ui-keyboard-focus {
        background-color: $focus-background-color;
      }

      padding: 0 $padding;
    }
  }

  .first .ra-ui-button.mat-badge-medium.mat-badge-before {
    border-left-width: 1px;
    border-radius: 4px 0 0 0;
  }
  .last .ra-ui-button.mat-badge-medium.mat-badge-before {
    border-radius: 0 0 4px 0;
  }

  .ra-ui-button.mat-badge-medium.mat-badge-before {
    border-left-width: 0px;
    border-radius: 0 0 0 0;
    min-width: getRelative(60, $button-font-size);

    &.has-icon {
      min-width: getRelative(72, $button-font-size);
    }
  }
}

@mixin ra-ui-outlined-button-toggle-theme($theme, $custom-typography) {
  ra-ui-outlined-button-toggle {
    @include ra-ui-outlined-button-toggle-styles($theme, $custom-typography);
  }
}

@mixin ra-ui-chip-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $base-fs: 12px;
  $border-width-fix: 1px;
  $icon-padding-fix: 2px;
  $border-width: getRelative($border-width-fix, $base-fs);
  $icons-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $button-fs: ra-ui-font-size($custom-typography, button);
  $body-1-fs: ra-ui-font-size($custom-typography, body-1);
  $side-padding-small: getRelative(4 - $border-width-fix, $base-fs);
  $gap: getRelative(4, $base-fs);
  $side-padding-big: getRelative(8 - $border-width-fix, $base-fs);
  $chip-height: getRelative(20, $base-fs);
  // When min-width changes, DO NOT forget to change it in chip.types.ts for CHIP_MIN_WIDTH.
  $chip-min-width: getRelative(40, $base-fs);
  $chip-max-width: getRelative(184, $base-fs);
  $half-height: $chip-height * 0.5;
  $default-color: ra-ui-color($theme, primary, 600);
  $chip-extra-colors: (
    default: $default-color,
  );
  $chip-extra-colors-contrast: (
    default: ra-ui-contrast($theme, primary, 600),
  );

  ra-ui-chip {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);

    display: inline-flex;
    height: $chip-height;
    box-sizing: border-box;
    min-width: $chip-min-width;
    width: fit-content;
    max-width: $chip-max-width;
    align-items: center;
    justify-content: center;
    position: relative;

    .ra-ui-chip {
      border-radius: $half-height;
      -webkit-border-radius: $half-height;
      overflow: hidden;
      width: 100%;
      height: 100%;
      outline: none;

      // region Sizing and content placing
      .ra-ui-chip-text-wrapper {
        max-width: 100%;
      }
      .ra-ui-chip-icon {
        cursor: default;

        mat-icon {
          display: flex;
          height: $icons-s-fs;
          width: $icons-s-fs;
          font-size: $icons-s-fs;
          overflow: visible;
        }
      }

      &:not(.ra-ui-chip--deletable):not(.ra-ui-chip--icon) {
        .ra-ui-chip-text-wrapper {
          padding: 0 $side-padding-big;
        }
        .ra-ui-chip--text {
          max-width: 100%;
        }
      }
      &.ra-ui-chip--deletable {
        .ra-ui-chip--text:not(.empty) {
          // due to rounding issue and truncation on safari/mac there is added 0.01px to max-width calculation
          max-width: calc(100% - #{$icons-s-fs} - #{$gap} + 0.01px);
          margin-right: calc(#{$gap} + #{$icons-s-fs});
        }
        .ra-ui-chip-text-wrapper {
          padding: 0 $side-padding-small 0 $side-padding-big;
        }
        .ra-ui-chip-close-icon .ra-ui-button {
          $icon-button-fs: 14px;
          $right-body-1-em: $gap;
          $button-padding-em: getRelative($icon-padding-fix, $icon-button-fs);

          /**
                     * the absolute position needs to be determined from two different typography levels
                     * body-1 - the placement should be according to current body-1 font size
                     * button - the padding needs to be excluded from the right placement
                     */
          $body-1-right-size-px: calc(
            strip-unit($right-body-1-em) * $body-1-fs
          );
          $button-padding-px: calc(strip-unit($button-padding-em) * $button-fs);
          right: calc($body-1-right-size-px - $button-padding-px);

          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 50%;
          padding: $button-padding-em;
        }
      }
      &.ra-ui-chip--icon {
        .ra-ui-chip--text:not(.empty) {
          // due to rounding issue and truncation on safari/mac there is added 0.01px to max-width calculation
          max-width: calc(100% - #{$icons-s-fs} - #{$gap} + 0.01px);
          margin-left: calc(#{$gap} + #{$icons-s-fs});
        }
        .ra-ui-chip-text-wrapper {
          padding: 0 $side-padding-big;
        }
        .ra-ui-chip-icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: getRelative(8, $base-fs);
        }
      }
      // endregion

      // region Color handling
      &.ra-ui-chip--color {
        $chip-palette: map-merge(
          ra-ui-status-palette($theme, $status-palette),
          $chip-extra-colors
        );

        $chip-palette-contrast: map-merge(
          ra-ui-status-palette-contrast($theme, $status-palette),
          $chip-extra-colors-contrast
        );

        @each $name, $color in $chip-palette {
          &-#{$name} {
            color: $color;
            border: $border-width solid $color;

            .ra-ui-chip-close-icon
              .mat-icon-button:not(:active):not(.ra-ui-active-state)
              .mat-icon,
            .ra-ui-chip-icon .mat-icon,
            ra-ui-static-text {
              color: $color;
            }

            &.ra-ui-chip--selectable {
              :hover.ra-ui-chip-text-wrapper {
                background-color: color-mix(in srgb, $color 10%, transparent);
              }
              &.ra-ui-chip-pressed {
                background-color: color-mix(in srgb, $color 20%, transparent);
              }
            }

            // case - selected or readonly
            // chip with default color is transparent, however all the other colors looks like selected chip
            &:not(.ra-ui-chip--selectable) {
              @if ($name != default) {
                @include chip-selectable-color-styles(
                  $color,
                  map-get($chip-palette-contrast, $name),
                  $theme
                );
              }
            }
            &.ra-ui-chip--selectable.ra-ui-chip-selected {
              @include chip-selectable-color-styles(
                $color,
                map-get($chip-palette-contrast, $name),
                $theme
              );
            }

            // inverted mode is handled only for default
            @if ($name == default) {
              &.ra-ui-inverted-mode {
                color: #ffffff;
                border: $border-width solid #ffffff;
                ra-ui-static-text {
                  color: #ffffff;
                }
                .ra-ui-chip-close-icon
                  .mat-icon-button:not(:active):not(:hover),
                .ra-ui-chip-icon {
                  .mat-icon {
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
      // endregion

      // region Active state handling
      .ra-ui-chip-close-icon .mat-icon-button {
        &:active,
        &.ra-ui-active-state {
          .mat-icon,
          .mat-icon:hover {
            color: ra-ui-color($theme, primary, 50);
          }
        }
      }
      // endregion

      // region selectable handling
      &.ra-ui-chip--selectable {
        &:focus-visible,
        &.ra-ui-keyboard-focus {
          @include ra-ui-chip-visible-focus($theme, $base-fs);
        }
        cursor: pointer;
        .ra-ui-chip-icon {
          cursor: pointer;
        }

        .ra-ui-chip-text-wrapper {
          height: 100%;
        }
      }
      // endregion
    }
  }
}

@mixin chip-selectable-color-styles($color, $contrast-color, $theme) {
  background-color: $color;

  .ra-ui-chip-close-icon .mat-icon-button:not(:active):not(:hover),
  ra-ui-static-text,
  .ra-ui-chip-icon {
    color: $contrast-color;
    .mat-icon {
      color: $contrast-color;
    }

    &:focus-visible,
    &.ra-ui-keyboard-focus {
      .mat-icon {
        color: ra-ui-color($theme, primary, 50);
      }
    }
  }
}

@mixin ra-ui-chip-visible-focus($theme, $base-fs) {
  & {
    $size: getRelative(2, $base-fs);
    outline: $size solid ra-ui-color($theme, primary, A200);
    outline-offset: -($size * 0.5);
    border-color: transparent;

    &.ra-ui-inverted-mode {
      border-color: transparent;
    }
  }
}

@mixin ra-ui-chip-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $base-fs: 12px;
  $border-width-fix: 1px;
  $icon-padding-fix: 2px;
  $border-width: getRelative($border-width-fix, $base-fs);
  $icons-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $button-fs: ra-ui-font-size($custom-typography, button);
  $body-1-fs: ra-ui-font-size($custom-typography, body-1);
  $side-padding-small: getRelative(4 - $border-width-fix, $base-fs);
  $gap: getRelative(4, $base-fs);
  $side-padding-big: getRelative(8 - $border-width-fix, $base-fs);
  $chip-height: getRelative(20, $base-fs);
  // When min-width changes, DO NOT forget to change it in chip.types.ts for CHIP_MIN_WIDTH.
  $chip-min-width: getRelative(40, $base-fs);
  $chip-max-width: getRelative(184, $base-fs);
  $half-height: $chip-height * 0.5;
  $default-color: ra-ui-color($theme, primary, 600);
  $chip-extra-colors: (
    default: $default-color,
  );
  $chip-extra-colors-contrast: (
    default: ra-ui-contrast($theme, primary, 600),
  );

  ra-ui-chip {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);

    display: inline-flex;
    height: $chip-height;
    box-sizing: border-box;
    min-width: $chip-min-width;
    width: fit-content;
    max-width: $chip-max-width;
    align-items: center;
    justify-content: center;
    position: relative;

    .ra-ui-chip {
      border-radius: $half-height;
      -webkit-border-radius: $half-height;
      overflow: hidden;
      width: 100%;
      height: 100%;
      outline: none;

      // region Sizing and content placing
      .ra-ui-chip-text-wrapper {
        max-width: 100%;
      }
      .ra-ui-chip-icon {
        cursor: default;

        mat-icon {
          display: flex;
          height: $icons-s-fs;
          width: $icons-s-fs;
          font-size: $icons-s-fs;
          overflow: visible;
        }
      }

      &:not(.ra-ui-chip--deletable):not(.ra-ui-chip--icon) {
        .ra-ui-chip-text-wrapper {
          padding: 0 $side-padding-big;
        }
        .ra-ui-chip--text {
          max-width: 100%;
        }
      }
      &.ra-ui-chip--deletable {
        .ra-ui-chip--text:not(.empty) {
          // due to rounding issue and truncation on safari/mac there is added 0.01px to max-width calculation
          max-width: calc(100% - #{$icons-s-fs} - #{$gap} + 0.01px);
          margin-right: calc(#{$gap} + #{$icons-s-fs});
        }
        .ra-ui-chip-text-wrapper {
          padding: 0 $side-padding-small 0 $side-padding-big;
        }
        .ra-ui-chip-close-icon .ra-ui-button {
          $icon-button-fs: 14px;
          $right-body-1-em: $gap;
          $button-padding-em: getRelative($icon-padding-fix, $icon-button-fs);

          /**
                     * the absolute position needs to be determined from two different typography levels
                     * body-1 - the placement should be according to current body-1 font size
                     * button - the padding needs to be excluded from the right placement
                     */
          $body-1-right-size-px: calc(
            strip-unit($right-body-1-em) * $body-1-fs
          );
          $button-padding-px: calc(strip-unit($button-padding-em) * $button-fs);
          right: calc($body-1-right-size-px - $button-padding-px);

          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 50%;
          padding: $button-padding-em;
        }
      }
      &.ra-ui-chip--icon {
        .ra-ui-chip--text:not(.empty) {
          // due to rounding issue and truncation on safari/mac there is added 0.01px to max-width calculation
          max-width: calc(100% - #{$icons-s-fs} - #{$gap} + 0.01px);
          margin-left: calc(#{$gap} + #{$icons-s-fs});
        }
        .ra-ui-chip-text-wrapper {
          padding: 0 $side-padding-big;
        }
        .ra-ui-chip-icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: getRelative(8, $base-fs);
        }
      }
      // endregion

      // region Color handling
      &.ra-ui-chip--color {
        $chip-palette: map-merge(
          ra-ui-status-palette($theme, $status-palette),
          $chip-extra-colors
        );

        $chip-palette-contrast: map-merge(
          ra-ui-status-palette-contrast($theme, $status-palette),
          $chip-extra-colors-contrast
        );

        @each $name, $color in $chip-palette {
          &-#{$name} {
            color: $color;
            border: $border-width solid $color;

            .ra-ui-chip-close-icon
              .mat-icon-button:not(:active):not(.ra-ui-active-state)
              .mat-icon,
            .ra-ui-chip-icon .mat-icon,
            ra-ui-static-text {
              color: $color;
            }

            &.ra-ui-chip--selectable {
              :hover.ra-ui-chip-text-wrapper {
                background-color: color-mix(in srgb, $color 10%, transparent);
              }
              &.ra-ui-chip-pressed {
                background-color: color-mix(in srgb, $color 20%, transparent);
              }
            }

            // case - selected or readonly
            // chip with default color is transparent, however all the other colors looks like selected chip
            &:not(.ra-ui-chip--selectable) {
              @if ($name != default) {
                @include chip-selectable-color-styles(
                  $color,
                  map-get($chip-palette-contrast, $name),
                  $theme
                );
              }
            }
            &.ra-ui-chip--selectable.ra-ui-chip-selected {
              @include chip-selectable-color-styles(
                $color,
                map-get($chip-palette-contrast, $name),
                $theme
              );
            }

            // inverted mode is handled only for default
            @if ($name == default) {
              &.ra-ui-inverted-mode {
                color: #ffffff;
                border: $border-width solid #ffffff;
                ra-ui-static-text {
                  color: #ffffff;
                }
                .ra-ui-chip-close-icon
                  .mat-icon-button:not(:active):not(:hover),
                .ra-ui-chip-icon {
                  .mat-icon {
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
      // endregion

      // region Active state handling
      .ra-ui-chip-close-icon .mat-icon-button {
        &:active,
        &.ra-ui-active-state {
          .mat-icon,
          .mat-icon:hover {
            color: ra-ui-color($theme, primary, 50);
          }
        }
      }
      // endregion

      // region selectable handling
      &.ra-ui-chip--selectable {
        &:focus-visible,
        &.ra-ui-keyboard-focus {
          @include ra-ui-chip-visible-focus($theme, $base-fs);
        }
        cursor: pointer;
        .ra-ui-chip-icon {
          cursor: pointer;
        }

        .ra-ui-chip-text-wrapper {
          height: 100%;
        }
      }
      // endregion
    }
  }
}

@mixin chip-selectable-color-styles($color, $contrast-color, $theme) {
  background-color: $color;

  .ra-ui-chip-close-icon .mat-icon-button:not(:active):not(:hover),
  ra-ui-static-text,
  .ra-ui-chip-icon {
    color: $contrast-color;
    .mat-icon {
      color: $contrast-color;
    }

    &:focus-visible,
    &.ra-ui-keyboard-focus {
      .mat-icon {
        color: ra-ui-color($theme, primary, 50);
      }
    }
  }
}

@mixin ra-ui-chip-visible-focus($theme, $base-fs) {
  & {
    $size: getRelative(2, $base-fs);
    outline: $size solid ra-ui-color($theme, primary, A200);
    outline-offset: -($size * 0.5);
    border-color: transparent;

    &.ra-ui-inverted-mode {
      border-color: transparent;
    }
  }
}

@mixin ra-ui-overflow-chip($theme, $custom-typography, $base-fs) {
  ra-ui-chip.ra-ui-overflow-chip {
    outline: none;
    min-width: unset;
    max-width: unset;

    .ra-ui-chip.ra-ui-chip--readonly {
      border: none;
      cursor: pointer;
      @include ra-ui-overflow-chip-colors($theme, 600);

      ra-ui-static-text {
        @include ra-ui-typography-level-to-styles(
          $custom-typography,
          body-1-bold
        );
      }

      &:hover {
        background-color: ra-ui-color($theme, primary, 700);
      }

      .ra-ui-chip-text-wrapper {
        padding: 0 getRelative(8, $base-fs);
      }
    }

    &.ra-ui-overflow-chip-pressed {
      .ra-ui-chip.ra-ui-chip--readonly {
        @include ra-ui-overflow-chip-colors($theme, 800);
      }
    }

    &:focus-visible,
    &.ra-ui-keyboard-focus {
      .ra-ui-chip {
        @include ra-ui-chip-visible-focus($theme, $base-fs);
      }
    }
  }
}

@mixin ra-ui-overflow-chip-colors($theme, $tone) {
  background-color: ra-ui-color($theme, primary, $tone);

  * {
    color: ra-ui-contrast($theme, primary, $tone);
  }
}

@mixin ra-ui-chip-list-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  // TODO: When base-fs or gap changes, DO NOT forget to change it in chip-list-overflow.types.ts.
  $base-fs: 12px;
  $gap: getRelative(4, $base-fs);

  ra-ui-chip-list {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    @include ra-ui-overflow-chip($theme, $custom-typography, $base-fs);

    > div.ra-ui-chip-list {
      display: flex;
      flex-flow: row wrap;
      gap: $gap;
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

// Gets a color from a theme palette (the output of mat-palette).
// The hue can be one of the standard values (500, A400, etc.), one of the three preconfigured
// hues (default, lighter, darker), or any of the aforementioned prefixed with "-contrast".
//
// @param $theme The theme contains palettes created by material function (foreground, background, primary, secondary, warn).
// @param $palette The key of palette in $theme.
// @param $hue The hue from the palette to use.
// @param $opacity The alpha channel value for the color.
@function ra-ui-color($theme, $palette, $hue: default, $opacity: null) {
  @if (
    index((foreground, background, primary, accent, warn), $palette) == null
  ) {
    @error 'The #{$palette} does not exist';
  }

  $searched-pallet: map-get($theme, $palette);
  $color: map-get($searched-pallet, $hue);

  //A colors does not exist on a material pallets, checks searching in custom pallets.
  @if ($color == null) {
    $defaults: ();
    @if ($palette == foreground) {
      $defaults: (
        text-label:
          var(--rafl-color-foreground-label, rafl.$rafl-color-foreground-label),
        text-disabled:
          var(
            --rafl-color-state-disabled-default,
            rafl.$rafl-color-state-disabled-default
          ),
        text-primary:
          var(
            --rafl-color-foreground-primary,
            rafl.$rafl-color-foreground-primary
          ),
        text-value-disabled: #7e5986,
        // non existing variable token
        force-value-disabled:
          #bd4200,
        // non existing variable token
        immediate-commit:
          var(
            --rafl-color-state-immediate-commit,
            rafl.$rafl-color-state-immediate-commit
          ),
      );
    } @else if($palette == background) {
      $defaults: (
        interactive-component-01: #ffffff,
        interactive-component-02: #f8fbfc,
        interactive-component-03: #f1f4f6,
        interactive-component-04: #e8ebed,
        interactive-component-05: #dbdfe2,
        interactive-component-06: #b8bcbf,
        interactive-component-07: #3e4144,
        interactive-alt-01: #c9d5dd,
        interactive-alt-02: #889cac,
        progress: #57d9a3,

        ui-background-01:
          var(--rafl-color-background-ui, rafl.$rafl-color-background-ui),
        disabled:
          var(
            --rafl-color-state-disabled-default,
            rafl.$rafl-color-state-disabled-default
          ),
        inactive-hover:
          var(
            --rafl-color-state-inactive-hovered,
            rafl.$rafl-color-state-inactive-hovered
          ),
        inactive:
          var(
            --rafl-color-state-inactive-default,
            rafl.$rafl-color-state-inactive-default
          ),
      );
    }

    $color: map-get($defaults, $hue);
  }

  @if (type-of($color) == string) {
    // color is CSS variable
    @if ($opacity == null) {
      @return $color;
    }
    // If the $color resolved to something a CSS variable, color-mix can transparentize the color in CSS variable
    @return color-mix(in srgb, $color $opacity * 100%, transparent);
  }

  @if (type-of($color) != color) {
    // If the $color resolved to something different from a color (e.g. a CSS variable),
    // we can't apply the opacity anyway so we return the value as is, otherwise Sass can
    // throw an error or output something invalid.
    @return $color;
  }

  @return rgba($color, if($opacity == null, opacity($color), $opacity));
}

// For a given hue in a palette, return the contrast color from the map of contrast palettes.
// Contains default contrast color of background pallet
// @param $color-map
// @param $hue
@function ra-ui-contrast($theme, $palette, $hue) {
  @if (index((background, primary, accent, warn), $palette) == null) {
    @error 'The #{$palette} does not exist';
  }

  $searched-pallet: map-get($theme, $palette);
  $contrast-pallet: map-get($searched-pallet, contrast);

  // map of contrast does not exist.
  @if ($contrast-pallet == null) {
    $defaults: ();
    @if ($palette == background) {
      // NOTE - contrast palettes do not have tokens yet (0.2.0)
      $defaults: (
        ui-background-01: #000000,
        interactive-component-01: #000000,
        interactive-component-02: #000000,
        interactive-component-03: #000000,
        interactive-component-04: #000000,
        interactive-component-05: #000000,
        interactive-component-06: #000000,
        interactive-component-07: #ffffff,
        disabled: #000000,
        inactive-hover: #ffffff,
        inactive: #ffffff,

        interactive-alt-01: #000000,
        interactive-alt-02: #000000,
        progress: #000000,
      );
    }

    $color: map-get($defaults, $hue);

    @return $color;
  }

  @return map-get($contrast-pallet, $hue);
}

/**
* TODO: remove $status-palette
* Ideally, this shouldn't be here at all. It serves as a default for the following functions.
* Removing it breaks CI. Reworking the functions would result in severe breaking changes.
* This needs to be tackled when we decide to clean up this whole theme mess.
 */
$status-palette: (
  alarm: #e51400,
  error: #aa0000,
  success: #12850a,
  warning: #ffcc00,
  information: #235d9f,
  pending: #8f2ad2,
);

// Creates a container object for a light theme to be given to individual component theme mixins.
@function ra-ui-light-theme(
  $primary,
  $accent: mat.define-palette(mat.$green-palette, 500, 100, 900),
  $warn: mat.define-palette(mat.$red-palette),
  $foreground: $ra-ui-light-theme-foreground,
  $background: $ra-ui-light-theme-background,
  $status-palette: $status-palette
) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $foreground,
    background: $background,
    status-palette: $status-palette
  );
}

// Creates a container object for a dark theme to be given to individual component theme mixins.
@function ra-ui-dark-theme(
  $primary,
  $accent: mat.define-palette(mat.$pink-palette),
  $warn: mat.define-palette(mat.$red-palette),
  $foreground: mat.$dark-theme-foreground-palette,
  $background: mat.$dark-theme-background-palette,
  $status-palette: $status-palette
) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: true,
    foreground: $foreground,
    background: $background,
    status-palette: $status-palette
  );
}

/**
* Returns status palette WITHOUT the contrast colors.
 */
@function ra-ui-status-palette($theme, $status-palette) {
  $theme-status-palette: map-get($theme, status-palette);
  /**
  * status palette exists in the theme and has contrast defined
  * --> strip contrast and return the theme's status palette without the contrast
   */
  @if $theme-status-palette and map-get($theme-status-palette, contrast) {
    $theme-contrast-status-palette: map-remove($theme-status-palette, contrast);
    @return $theme-contrast-status-palette;
  }
  /**
  * status palette exists in the theme, but has no contrast defined (if it did, previous if-branch would be executed)
  * --> simply return the theme's status palette
   */
  @else if $theme-status-palette {
    @return $theme-status-palette;
  }
  /**
  * when there is no status palette in the theme
   */
  @else {
    @return $status-palette;
  }
}

/**
* Returns ONLY the contrast colors of status palette.
 */
@function ra-ui-status-palette-contrast($theme, $status-palette) {
  $theme-status-palette: map-get($theme, status-palette);
  // when there is no status palette in the theme
  @if ($theme-status-palette == null) {
    @return $status-palette;
  }

  $status-contrast: map-get($theme-status-palette, contrast); //
  @return if($status-contrast, $status-contrast, $status-palette);
}

@mixin buttons-theme($theme, $custom-typography, $custom-global-config: null) {
  @include ra-ui-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-button-toggle-theme($theme, $custom-typography);
  @include ra-ui-outlined-button-multi-toggle-theme($theme, $custom-typography);
  @include ra-ui-outlined-button-toggle-theme($theme, $custom-typography);
  @include ra-ui-chip-theme($theme, $custom-typography, $custom-global-config);
  @include ra-ui-chip-list-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

@mixin truncate-theme() {
  .ra-ui-truncate-singleline {
    white-space: nowrap;
  }

  .ra-ui-truncate-singleline-right {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

// Gets a color from a theme palette (the output of mat-palette).
// The hue can be one of the standard values (500, A400, etc.), one of the three preconfigured
// hues (default, lighter, darker), or any of the aforementioned prefixed with "-contrast".
//
// @param $theme The theme contains palettes created by material function (foreground, background, primary, secondary, warn).
// @param $palette The key of palette in $theme.
// @param $hue The hue from the palette to use.
// @param $opacity The alpha channel value for the color.
@function ra-ui-color($theme, $palette, $hue: default, $opacity: null) {
  @if (
    index((foreground, background, primary, accent, warn), $palette) == null
  ) {
    @error 'The #{$palette} does not exist';
  }

  $searched-pallet: map-get($theme, $palette);
  $color: map-get($searched-pallet, $hue);

  //A colors does not exist on a material pallets, checks searching in custom pallets.
  @if ($color == null) {
    $defaults: ();
    @if ($palette == foreground) {
      $defaults: (
        text-label:
          var(--rafl-color-foreground-label, rafl.$rafl-color-foreground-label),
        text-disabled:
          var(
            --rafl-color-state-disabled-default,
            rafl.$rafl-color-state-disabled-default
          ),
        text-primary:
          var(
            --rafl-color-foreground-primary,
            rafl.$rafl-color-foreground-primary
          ),
        text-value-disabled: #7e5986,
        // non existing variable token
        force-value-disabled:
          #bd4200,
        // non existing variable token
        immediate-commit:
          var(
            --rafl-color-state-immediate-commit,
            rafl.$rafl-color-state-immediate-commit
          ),
      );
    } @else if($palette == background) {
      $defaults: (
        interactive-component-01: #ffffff,
        interactive-component-02: #f8fbfc,
        interactive-component-03: #f1f4f6,
        interactive-component-04: #e8ebed,
        interactive-component-05: #dbdfe2,
        interactive-component-06: #b8bcbf,
        interactive-component-07: #3e4144,
        interactive-alt-01: #c9d5dd,
        interactive-alt-02: #889cac,
        progress: #57d9a3,

        ui-background-01:
          var(--rafl-color-background-ui, rafl.$rafl-color-background-ui),
        disabled:
          var(
            --rafl-color-state-disabled-default,
            rafl.$rafl-color-state-disabled-default
          ),
        inactive-hover:
          var(
            --rafl-color-state-inactive-hovered,
            rafl.$rafl-color-state-inactive-hovered
          ),
        inactive:
          var(
            --rafl-color-state-inactive-default,
            rafl.$rafl-color-state-inactive-default
          ),
      );
    }

    $color: map-get($defaults, $hue);
  }

  @if (type-of($color) == string) {
    // color is CSS variable
    @if ($opacity == null) {
      @return $color;
    }
    // If the $color resolved to something a CSS variable, color-mix can transparentize the color in CSS variable
    @return color-mix(in srgb, $color $opacity * 100%, transparent);
  }

  @if (type-of($color) != color) {
    // If the $color resolved to something different from a color (e.g. a CSS variable),
    // we can't apply the opacity anyway so we return the value as is, otherwise Sass can
    // throw an error or output something invalid.
    @return $color;
  }

  @return rgba($color, if($opacity == null, opacity($color), $opacity));
}

// For a given hue in a palette, return the contrast color from the map of contrast palettes.
// Contains default contrast color of background pallet
// @param $color-map
// @param $hue
@function ra-ui-contrast($theme, $palette, $hue) {
  @if (index((background, primary, accent, warn), $palette) == null) {
    @error 'The #{$palette} does not exist';
  }

  $searched-pallet: map-get($theme, $palette);
  $contrast-pallet: map-get($searched-pallet, contrast);

  // map of contrast does not exist.
  @if ($contrast-pallet == null) {
    $defaults: ();
    @if ($palette == background) {
      // NOTE - contrast palettes do not have tokens yet (0.2.0)
      $defaults: (
        ui-background-01: #000000,
        interactive-component-01: #000000,
        interactive-component-02: #000000,
        interactive-component-03: #000000,
        interactive-component-04: #000000,
        interactive-component-05: #000000,
        interactive-component-06: #000000,
        interactive-component-07: #ffffff,
        disabled: #000000,
        inactive-hover: #ffffff,
        inactive: #ffffff,

        interactive-alt-01: #000000,
        interactive-alt-02: #000000,
        progress: #000000,
      );
    }

    $color: map-get($defaults, $hue);

    @return $color;
  }

  @return map-get($contrast-pallet, $hue);
}

/**
* TODO: remove $status-palette
* Ideally, this shouldn't be here at all. It serves as a default for the following functions.
* Removing it breaks CI. Reworking the functions would result in severe breaking changes.
* This needs to be tackled when we decide to clean up this whole theme mess.
 */
$status-palette: (
  alarm: #e51400,
  error: #aa0000,
  success: #12850a,
  warning: #ffcc00,
  information: #235d9f,
  pending: #8f2ad2,
);

// Creates a container object for a light theme to be given to individual component theme mixins.
@function ra-ui-light-theme(
  $primary,
  $accent: mat.define-palette(mat.$green-palette, 500, 100, 900),
  $warn: mat.define-palette(mat.$red-palette),
  $foreground: $ra-ui-light-theme-foreground,
  $background: $ra-ui-light-theme-background,
  $status-palette: $status-palette
) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $foreground,
    background: $background,
    status-palette: $status-palette
  );
}

// Creates a container object for a dark theme to be given to individual component theme mixins.
@function ra-ui-dark-theme(
  $primary,
  $accent: mat.define-palette(mat.$pink-palette),
  $warn: mat.define-palette(mat.$red-palette),
  $foreground: mat.$dark-theme-foreground-palette,
  $background: mat.$dark-theme-background-palette,
  $status-palette: $status-palette
) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: true,
    foreground: $foreground,
    background: $background,
    status-palette: $status-palette
  );
}

/**
* Returns status palette WITHOUT the contrast colors.
 */
@function ra-ui-status-palette($theme, $status-palette) {
  $theme-status-palette: map-get($theme, status-palette);
  /**
  * status palette exists in the theme and has contrast defined
  * --> strip contrast and return the theme's status palette without the contrast
   */
  @if $theme-status-palette and map-get($theme-status-palette, contrast) {
    $theme-contrast-status-palette: map-remove($theme-status-palette, contrast);
    @return $theme-contrast-status-palette;
  }
  /**
  * status palette exists in the theme, but has no contrast defined (if it did, previous if-branch would be executed)
  * --> simply return the theme's status palette
   */
  @else if $theme-status-palette {
    @return $theme-status-palette;
  }
  /**
  * when there is no status palette in the theme
   */
  @else {
    @return $status-palette;
  }
}

/**
* Returns ONLY the contrast colors of status palette.
 */
@function ra-ui-status-palette-contrast($theme, $status-palette) {
  $theme-status-palette: map-get($theme, status-palette);
  // when there is no status palette in the theme
  @if ($theme-status-palette == null) {
    @return $status-palette;
  }

  $status-contrast: map-get($theme-status-palette, contrast); //
  @return if($status-contrast, $status-contrast, $status-palette);
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

@mixin ra-ui-hidden-styles($theme, $custom-typography) {
  ra-ui-hidden {
    display: none;

    @each $level, $val in $custom-typography {
      @if ($level != font-family) {
        .#{$level} {
          @include ra-ui-typography-level-to-styles($custom-typography, $level);
        }
      }
    }
  }
}

@mixin cdk-theme($theme, $custom-typography, $custom-global-config: null) {
  $material-compatible-theme: mat.define-light-theme(
    (
      color: (
        primary: map.get($theme, primary),
        accent: map.get($theme, accent),
        warn: map.get($theme, warn),
      ),
    )
  );
  $material-compatible-theme: map.set(
    $material-compatible-theme,
    color,
    foreground,
    map.get($theme, 'foreground')
  );
  $material-compatible-theme: map.set(
    $material-compatible-theme,
    color,
    background,
    map.get($theme, 'background')
  );
  $material-compatible-theme: map.set(
    $material-compatible-theme,
    foreground,
    map.get($theme, 'foreground')
  );
  $material-compatible-theme: map.set(
    $material-compatible-theme,
    background,
    map.get($theme, 'background')
  );

  @include mat.core();
  @include mat.legacy-core();
  @include mat.all-component-themes($material-compatible-theme);
  @include mat.all-legacy-component-themes($material-compatible-theme);
  @include mat.all-component-typographies($custom-typography);
  @include mat.all-legacy-component-typographies($custom-typography);

  @include truncate-theme();
  @include ra-ui-hidden-styles($theme, $custom-typography);
  @include material-override-styles(
    $theme,
    $custom-typography,
    $custom-global-config
  );
}

/* Ellipsis override - overrides original overflow to show ellipsis. */
.ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin static-text-component-theme($theme, $custom-typography) {
  // region color variables
  $text-color: dtu.rafl-color(color-foreground-primary, $theme);
  $link-text-color: dtu.rafl-color(color-state-active-default, $theme);
  $link-hover-text-color: dtu.design-token-var(
    rafl-color-state-active-hovered,
    rafl.$rafl-color-state-active-hovered
  );
  $link-pressed-text-color: dtu.rafl-color(color-state-active-pressed, $theme);
  $inverted-text-color: dtu.rafl-color(color-foreground-inverted, $theme);
  $inverted-link-text-color: dtu.rafl-color(
    color-state-active-inverted-default,
    $theme
  );
  $inverted-link-hover-text-color: dtu.design-token-var(
    rafl-color-state-active-inverted-hovered,
    rafl.$rafl-color-state-active-inverted-hovered
  );
  $inverted-link-pressed-text-color: dtu.rafl-color(
    color-state-active-inverted-pressed,
    $theme
  );
  $focus-color: dtu.rafl-color(color-state-focused, $theme);
  $disabled-color: dtu.rafl-color(color-state-disabled-default, $theme);
  $disabled-inverted-color: dtu.rafl-color(
    color-state-disabled-inverted-default,
    $theme
  );
  // endregion

  $error-icon-margin-fallback: getRelative(
    4,
    if(
      unit(rafl.$rafl-typography-body-sm-font-size) == 'rem',
      rafl.$rafl-typography-body-sm-font-size * 16,
      rafl.$rafl-typography-body-sm-font-size
    )
  );
  // endregion

  $focus-rectangle-width: getRelative(rafl.$rafl-dimension-stroke-md, 12);
  $icon-left-padding: dtu.rafl-dimension(gap-md, $label-padding-right);
  $text-margin: dtu.rafl-dimension(gap-sm, $error-icon-margin-fallback);

  ra-ui-static-text.static-text-with-error-message {
    display: flex;

    .mat-icon {
      padding-left: $icon-left-padding;
    }
  }

  h1 ra-ui-static-text {
    @include dtu.rafl-typography(title-lg, $custom-typography);
  }
  h2 ra-ui-static-text {
    @include dtu.rafl-typography(title-md, $custom-typography);
  }
  h3 ra-ui-static-text {
    @include dtu.rafl-typography(title-sm, $custom-typography);
  }
  h4 ra-ui-static-text {
    @include dtu.rafl-typography(title-xs, $custom-typography);
  }

  b ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1-bold);
  }

  ra-ui-static-text {
    @include dtu.rafl-typography(body-sm, $custom-typography);

    color: $text-color;

    &.ra-ui-inverted-mode {
      color: $inverted-text-color;

      > .static-text[disabled='true'] {
        color: $disabled-inverted-color;
      }
    }

    &.ra-ui-link {
      a.ra-ui-keyboard-focus .static-text {
        border: none;
        outline: $focus-rectangle-width solid $focus-color;
        text-decoration: underline;
      }

      a {
        outline: none;
      }

      a,
      a:visited {
        text-decoration: none;

        > .static-text:not([disabled='true']) {
          @include getCursor();
          color: $link-text-color;

          &:hover {
            text-decoration: underline;
            color: $link-hover-text-color;
          }

          &:active,
          &:active:hover {
            text-decoration: underline;
            color: $link-pressed-text-color;
          }
        }
      }

      &.ra-ui-inverted-mode {
        a,
        a:visited {
          > .static-text:not([disabled='true']) {
            color: $inverted-link-text-color;

            &:hover {
              color: $inverted-link-hover-text-color;
            }

            &:active,
            &:active:hover {
              color: $inverted-link-pressed-text-color;
            }
          }
        }
      }
    }

    > .static-text {
      .static-text-inner.static-text-inner-margin {
        margin-left: $text-margin;
      }
    }

    > .static-text[disabled='true'] {
      color: $disabled-color;
    }

    > .static-text-scrollbar {
      overflow-wrap: break-word;
      word-wrap: break-word;
      box-sizing: border-box;
    }

    /* fix Safari tooltip */
    > .static-text::after {
      content: '';
      display: block;
    }

    &.empty:not(.static-text-with-error-message) {
      @include firefox-only-specific() {
        display: block;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-dirty-flag-neighbour($custom-typography) {
  &.dirty-flag {
    width: calc(100% - #{$width-dirty-flag});
    display: inline-block;
    vertical-align: middle;

    &.blank-when-disabled {
      width: 100%;
    }

    &.immediate-arrow {
      $prop: get-immediate-arrow-properties($custom-typography);
      $width-immediate: map-get($prop, width);
      $padding-immediate: map-get($prop, padding);
      width: calc(
        100% - #{$width-dirty-flag} - #{$width-immediate} - #{$padding-immediate} -
          1px
      );
      &.label-container-label-text {
        vertical-align: middle;
      }
      &.blank-when-disabled {
        width: calc(100% - #{$width-immediate-arrow});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-dirty-flag-component-theme($theme) {
  ra-ui-dirty-flag[disabled='true'] {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-immediate-arrow-neighbour($custom-typography) {
  &.immediate-arrow {
    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    display: inline-block;
    vertical-align: middle;
    &.mat-button-base {
      vertical-align: inherit;
    }
    &.label-container-label-text {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: middle;
      @include safari-only-specific() {
        width: calc(100% - #{$width-immediate} - #{$padding-immediate});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-immediate-arrow-width-color($theme, $custom-typography) {
  ra-ui-immediate-arrow {
    color: ra-ui-color($theme, foreground, immediate-commit);

    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(#{$width-immediate} + #{$padding-immediate} + 1px);

    mat-icon.mat-icon.ra-icon-ide-sm-immediate-commit-arrow-left {
      padding-left: $padding-immediate;
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      line-height: ra-ui-font-size($custom-typography, icon-s);
    }
  }
}

@mixin label-container-component-theme($theme, $custom-typography) {
  $horizontal-paddings: getRelative(8px, 14px);
  $vertical-paddings: getRelative(4px, 14px);

  ra-ui-label-container {
    @include ra-ui-typography-level-to-styles($custom-typography, input);

    .ra-ui-container {
      padding-top: $vertical-paddings;
      padding-bottom: $vertical-paddings;

      &.ra-ui-horizontal-paddings {
        padding-left: $horizontal-paddings;
        padding-right: $horizontal-paddings;
      }

      &.horizontal {
        /* label */
        .label-container-label {
          padding-right: getRelative(
            8,
            12
          ); // 12px is form-field-text font-size

          & .label-container-label-placeholder {
            max-width: 100%;
            width: auto;
            transform: perspective(100px) translateZ(0.001px);
          }
        }

        /* content */
        .label-container-content {
          height: 100%;

          > * {
            max-height: 100%;
          }
        }
      }

      &.vertical {
        .label-container-label {
          padding-bottom: getRelative(
            4,
            12
          ); // 12px is form-field-text font-size

          .label-container-label-placeholder {
            vertical-align: middle;
            max-width: 100%;
          }
        }

        // removes a space below a wrapper of mat form field
        .mat-form-field {
          padding-bottom: 0;
          padding-top: 0;
        }
      }

      /* label */
      .label-container-label {
        white-space: nowrap;
        &,
        * {
          @include ra-ui-typography-level-to-styles(
            $custom-typography,
            form-field-text
          );
        }

        ra-ui-static-text,
        ra-ui-dirty-flag[disabled='false'] {
          color: ra-ui-color($theme, foreground, text-label);
        }

        &.focused {
          .static-text,
          ra-ui-dirty-flag {
            color: ra-ui-color($theme, primary, 600);
          }
        }

        &.error-state {
          @include ra-ui-error-message-colors($theme);
        }

        &.overflown .static-text::after {
          content: ':';
          position: absolute;
          right: 0px;
          top: 0px;
        }
      }
      .label-container-label-placeholder {
        width: auto;
        display: inline-block;
      }
      .label-container-label-text {
        @include ra-ui-immediate-arrow-neighbour($custom-typography);
        @include ra-ui-dirty-flag-neighbour($custom-typography);
      }
    }
  }
}

@mixin ra-ui-image-theme($theme, $custom-typography) {
  $color: ra-ui-color($theme, foreground, text);

  ra-ui-image {
    &.padding {
      @include ra-ui-typography-level-to-styles($custom-typography, body-1);
      padding: $label-padding-right;
    }
  }

  ra-ui-not-found-image {
    border: 1px solid $color;

    .not-found-icon {
      fill: $color;
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin tooltip-component-theme($theme, $custom-typography) {
  /**
    * value is defined also in tooltip.component.ts as tooltipArrowHeightEmRatio
    * if the values ever changes, don't forget to change on both places
    */
  $tooltip-arrow-height: getRelative(4px, 12px);
  $tooltip-arrow-width: $tooltip-arrow-height * 2;
  // FIXME: color map not provided yet
  $background-color: dtu.design-token-var(
    rafl-color-background-contrast,
    ra-ui-color($theme, background, interactive-component-07)
  );
  // FIXME: color map not provided yet
  $text-color: dtu.design-token-var(
    rafl-color-foreground-inverted,
    ra-ui-contrast($theme, background, interactive-component-07)
  );
  // FIXME: color map not provided yet
  $background-color-inverted: dtu.design-token-var(
    rafl-color-background-contrast-inverted,
    ra-ui-color($theme, background, background)
  );
  // FIXME: color map not provided yet
  $text-color-inverted: dtu.design-token-var(
    rafl-color-foreground-primary,
    ra-ui-contrast($theme, background, interactive-component-03)
  );
  $arrow-width: dtu.design-token-var(
    rafl-comp-tooltip-caret-size-width,
    $tooltip-arrow-width
  );
  $half-arrow-width: calc($arrow-width / 2);
  $arrow-height: dtu.design-token-var(
    rafl-comp-tooltip-caret-size-height,
    $tooltip-arrow-height
  );

  ra-ui-tooltip {
    @include dtu.rafl-typography(body-sm, $custom-typography);

    .tooltip-message {
      background: $background-color;
      color: $text-color;
      padding: dtu.rafl-dimension(padding-sm, getRelative(8px, 12px));
      border-radius: dtu.rafl-radius(
          container-top-left,
          $border-radius-top-left
        )
        dtu.rafl-radius(container-top-right, $border-radius-top-right)
        dtu.rafl-radius(container-bottom-right, $border-radius-bottom-right)
        dtu.rafl-radius(container-bottom-left, $border-radius-bottom-left);
    }

    &.ra-ui-inverted-mode {
      .tooltip-message {
        background: $background-color-inverted;
        color: $text-color-inverted;
      }

      .arrow-top {
        border-bottom-color: $background-color-inverted;
      }

      .arrow-bottom {
        border-top-color: $background-color-inverted;
      }

      .arrow-right {
        border-left-color: $background-color-inverted;
      }

      .arrow-left {
        border-right-color: $background-color-inverted;
      }
    }

    .tooltip-message .ra-ui-tooltip-icon-container {
      $icon-sizes: s m l xl;
      $token-icon-size-map: (
        s: sm,
        m: md,
        l: lg,
        xl: xl,
      );

      .ra-ui-tooltip-icon {
        @each $size in $icon-sizes {
          &.ra-ui-tooltip-icon--#{$size} {
            $icon-fs: dtu.rafl-dimension(
              icon-#{map.get($token-icon-size-map, $size)},
              ra-ui-font-size($custom-typography, icon-#{$size})
            );
            height: $icon-fs;
            width: $icon-fs;
            font-size: $icon-fs;
          }
        }
      }

      + span.ra-ui-tooltip-text {
        display: block;
        margin-top: dtu.rafl-dimension(gap-sm, getRelative(4, 12));
      }
    }

    .arrow-top,
    .arrow-right,
    .arrow-bottom,
    .arrow-left {
      width: 0;
      height: 0;
    }

    .arrow-top {
      border-left: $half-arrow-width solid transparent;
      border-right: $half-arrow-width solid transparent;
      border-bottom: $arrow-height solid $background-color;
    }
    .arrow-bottom {
      border-left: $half-arrow-width solid transparent;
      border-right: $half-arrow-width solid transparent;
      border-top: $arrow-height solid $background-color;
    }
    .arrow-right {
      border-top: $half-arrow-width solid transparent;
      border-bottom: $half-arrow-width solid transparent;
      border-left: $arrow-height solid $background-color;
    }
    .arrow-left {
      border-top: $half-arrow-width solid transparent;
      border-bottom: $half-arrow-width solid transparent;
      border-right: $arrow-height solid $background-color;
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-immediate-arrow-neighbour($custom-typography) {
  &.immediate-arrow {
    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    display: inline-block;
    vertical-align: middle;
    &.mat-button-base {
      vertical-align: inherit;
    }
    &.label-container-label-text {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: middle;
      @include safari-only-specific() {
        width: calc(100% - #{$width-immediate} - #{$padding-immediate});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-immediate-arrow-width-color($theme, $custom-typography) {
  ra-ui-immediate-arrow {
    color: ra-ui-color($theme, foreground, immediate-commit);

    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(#{$width-immediate} + #{$padding-immediate} + 1px);

    mat-icon.mat-icon.ra-icon-ide-sm-immediate-commit-arrow-left {
      padding-left: $padding-immediate;
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      line-height: ra-ui-font-size($custom-typography, icon-s);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-dirty-flag-neighbour($custom-typography) {
  &.dirty-flag {
    width: calc(100% - #{$width-dirty-flag});
    display: inline-block;
    vertical-align: middle;

    &.blank-when-disabled {
      width: 100%;
    }

    &.immediate-arrow {
      $prop: get-immediate-arrow-properties($custom-typography);
      $width-immediate: map-get($prop, width);
      $padding-immediate: map-get($prop, padding);
      width: calc(
        100% - #{$width-dirty-flag} - #{$width-immediate} - #{$padding-immediate} -
          1px
      );
      &.label-container-label-text {
        vertical-align: middle;
      }
      &.blank-when-disabled {
        width: calc(100% - #{$width-immediate-arrow});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-dirty-flag-component-theme($theme) {
  ra-ui-dirty-flag[disabled='true'] {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin ra-ui-divider-theme($theme, $custom-typography) {
  ra-ui-divider {
    .ra-ui-divider {
      background: ra-ui-color($theme, background, interactive-component-06);

      &.ra-ui-inverted-mode {
        background: ra-ui-color($theme, background, interactive-component-07);
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin ra-ui-flat-mode-error-theme($theme, $custom-typography) {
  $font-size: ra-ui-font-size($custom-typography, input);
  $line-height: ra-ui-line-height($custom-typography, input);
  $error-color: map-get(ra-ui-status-palette($theme, $status-palette), error);

  ra-ui-flat-mode-error {
    .mat-icon {
      // height of form-field component in flat mode
      // - line height + padding between value and underline + mat-form-field vertical (top/bottom) padding
      height: calc(
        $line-height * $font-size + $form-field-vertical-padding * 2
      );
      width: 1em;
      vertical-align: top;
      font-size: $font-size;
      line-height: $line-height;
      cursor: default;

      &.mat-warn {
        color: $error-color;
      }
    }
  }
}

@mixin mat-chips-override($custom-typography) {
  $mat-chip-min-height: 2em; //32px;
  $mat-chip-vertical-padding: 0.4375em; //7px;
  $mat-chip-horizontal-padding: 0.75em; //12px;

  $mat-chip-remove-vertical-padding: 0.4375em; //7px;
  $mat-chip-remove-before-margin: 0.5em; //8px;
  $mat-chip-remove-after-padding: 0.5em; //8px;

  $mat-chip-avatar-vertical-padding: 0;
  $mat-chip-avatar-before-padding: 0;
  $mat-chip-avatar-before-margin: 0.25m; //4px;
  $mat-chip-avatar-after-margin: 0.5em; //8px;

  $mat-chips-chip-margin: 0.25em; //4px;

  $mat-chip-input-width: 9.375em; //150px;
  $mat-chip-input-margin: 0.25em; //4px;

  $mat-chip-avatar-size: 1.5em; //24px;
  $mat-chip-remove-size: 1.125em; //18px;

  .chip-list-container {
    .mat-chip,
    .mat-chip-list-wrapper {
      @include mat.typography-level($custom-typography, input);
    }

    .mat-chip .mat-chip-remove.mat-icon,
    .mat-chip .mat-chip-trailing-icon.mat-icon {
      font-size: mat.font-size($custom-typography, input);
    }

    .mat-standard-chip {
      @include mat.elevation-transition();
      padding: $mat-chip-vertical-padding $mat-chip-horizontal-padding;
      border-radius: 1em; //16px;
      min-height: $mat-chip-min-height;

      .mat-chip-remove.mat-icon {
        width: $mat-chip-remove-size;
        height: $mat-chip-remove-size;
        line-height: $mat-chip-remove-size;
      }

      &.mat-chip-with-trailing-icon.mat-chip-with-avatar,
      &.mat-chip-with-avatar {
        padding-top: $mat-chip-avatar-vertical-padding;
        padding-bottom: $mat-chip-avatar-vertical-padding;
      }

      &.mat-chip-with-trailing-icon.mat-chip-with-avatar {
        padding-right: $mat-chip-remove-after-padding;
        padding-left: $mat-chip-avatar-before-padding;

        [dir='rtl'] & {
          padding-left: $mat-chip-remove-after-padding;
          padding-right: $mat-chip-avatar-before-padding;
        }
      }

      &.mat-chip-with-trailing-icon {
        padding-top: $mat-chip-remove-vertical-padding;
        padding-bottom: $mat-chip-remove-vertical-padding;
        padding-right: $mat-chip-remove-after-padding;
        padding-left: $mat-chip-horizontal-padding;

        [dir='rtl'] & {
          padding-left: $mat-chip-remove-after-padding;
          padding-right: $mat-chip-horizontal-padding;
        }
      }

      &.mat-chip-with-avatar {
        padding-left: $mat-chip-avatar-before-padding;
        padding-right: $mat-chip-horizontal-padding;

        [dir='rtl'] & {
          padding-right: $mat-chip-avatar-before-padding;
          padding-left: $mat-chip-horizontal-padding;
        }
      }

      .mat-chip-avatar {
        width: $mat-chip-avatar-size;
        height: $mat-chip-avatar-size;
        margin-right: $mat-chip-avatar-after-margin;
        margin-left: $mat-chip-avatar-before-margin;

        [dir='rtl'] & {
          margin-left: $mat-chip-avatar-after-margin;
          margin-right: $mat-chip-avatar-before-margin;
        }
      }

      .mat-chip-remove,
      .mat-chip-trailing-icon {
        width: $mat-chip-remove-size;
        height: $mat-chip-remove-size;
      }

      .mat-chip-remove,
      .mat-chip-trailing-icon {
        margin-left: $mat-chip-remove-before-margin;

        [dir='rtl'] & {
          margin-right: $mat-chip-remove-before-margin;
        }
      }
    }

    .mat-chip-list-wrapper {
      margin: -$mat-chips-chip-margin;

      input.mat-input-element,
      .mat-standard-chip {
        margin: $mat-chips-chip-margin;
      }
    }

    input.mat-chip-input {
      width: $mat-chip-input-width;
      margin: $mat-chip-input-margin;
      flex: 1 0 $mat-chip-input-width;
    }
  }
}

// Gets a color from a theme palette (the output of mat-palette).
// The hue can be one of the standard values (500, A400, etc.), one of the three preconfigured
// hues (default, lighter, darker), or any of the aforementioned prefixed with "-contrast".
//
// @param $theme The theme contains palettes created by material function (foreground, background, primary, secondary, warn).
// @param $palette The key of palette in $theme.
// @param $hue The hue from the palette to use.
// @param $opacity The alpha channel value for the color.
@function ra-ui-color($theme, $palette, $hue: default, $opacity: null) {
  @if (
    index((foreground, background, primary, accent, warn), $palette) == null
  ) {
    @error 'The #{$palette} does not exist';
  }

  $searched-pallet: map-get($theme, $palette);
  $color: map-get($searched-pallet, $hue);

  //A colors does not exist on a material pallets, checks searching in custom pallets.
  @if ($color == null) {
    $defaults: ();
    @if ($palette == foreground) {
      $defaults: (
        text-label:
          var(--rafl-color-foreground-label, rafl.$rafl-color-foreground-label),
        text-disabled:
          var(
            --rafl-color-state-disabled-default,
            rafl.$rafl-color-state-disabled-default
          ),
        text-primary:
          var(
            --rafl-color-foreground-primary,
            rafl.$rafl-color-foreground-primary
          ),
        text-value-disabled: #7e5986,
        // non existing variable token
        force-value-disabled:
          #bd4200,
        // non existing variable token
        immediate-commit:
          var(
            --rafl-color-state-immediate-commit,
            rafl.$rafl-color-state-immediate-commit
          ),
      );
    } @else if($palette == background) {
      $defaults: (
        interactive-component-01: #ffffff,
        interactive-component-02: #f8fbfc,
        interactive-component-03: #f1f4f6,
        interactive-component-04: #e8ebed,
        interactive-component-05: #dbdfe2,
        interactive-component-06: #b8bcbf,
        interactive-component-07: #3e4144,
        interactive-alt-01: #c9d5dd,
        interactive-alt-02: #889cac,
        progress: #57d9a3,

        ui-background-01:
          var(--rafl-color-background-ui, rafl.$rafl-color-background-ui),
        disabled:
          var(
            --rafl-color-state-disabled-default,
            rafl.$rafl-color-state-disabled-default
          ),
        inactive-hover:
          var(
            --rafl-color-state-inactive-hovered,
            rafl.$rafl-color-state-inactive-hovered
          ),
        inactive:
          var(
            --rafl-color-state-inactive-default,
            rafl.$rafl-color-state-inactive-default
          ),
      );
    }

    $color: map-get($defaults, $hue);
  }

  @if (type-of($color) == string) {
    // color is CSS variable
    @if ($opacity == null) {
      @return $color;
    }
    // If the $color resolved to something a CSS variable, color-mix can transparentize the color in CSS variable
    @return color-mix(in srgb, $color $opacity * 100%, transparent);
  }

  @if (type-of($color) != color) {
    // If the $color resolved to something different from a color (e.g. a CSS variable),
    // we can't apply the opacity anyway so we return the value as is, otherwise Sass can
    // throw an error or output something invalid.
    @return $color;
  }

  @return rgba($color, if($opacity == null, opacity($color), $opacity));
}

// For a given hue in a palette, return the contrast color from the map of contrast palettes.
// Contains default contrast color of background pallet
// @param $color-map
// @param $hue
@function ra-ui-contrast($theme, $palette, $hue) {
  @if (index((background, primary, accent, warn), $palette) == null) {
    @error 'The #{$palette} does not exist';
  }

  $searched-pallet: map-get($theme, $palette);
  $contrast-pallet: map-get($searched-pallet, contrast);

  // map of contrast does not exist.
  @if ($contrast-pallet == null) {
    $defaults: ();
    @if ($palette == background) {
      // NOTE - contrast palettes do not have tokens yet (0.2.0)
      $defaults: (
        ui-background-01: #000000,
        interactive-component-01: #000000,
        interactive-component-02: #000000,
        interactive-component-03: #000000,
        interactive-component-04: #000000,
        interactive-component-05: #000000,
        interactive-component-06: #000000,
        interactive-component-07: #ffffff,
        disabled: #000000,
        inactive-hover: #ffffff,
        inactive: #ffffff,

        interactive-alt-01: #000000,
        interactive-alt-02: #000000,
        progress: #000000,
      );
    }

    $color: map-get($defaults, $hue);

    @return $color;
  }

  @return map-get($contrast-pallet, $hue);
}

/**
* TODO: remove $status-palette
* Ideally, this shouldn't be here at all. It serves as a default for the following functions.
* Removing it breaks CI. Reworking the functions would result in severe breaking changes.
* This needs to be tackled when we decide to clean up this whole theme mess.
 */
$status-palette: (
  alarm: #e51400,
  error: #aa0000,
  success: #12850a,
  warning: #ffcc00,
  information: #235d9f,
  pending: #8f2ad2,
);

// Creates a container object for a light theme to be given to individual component theme mixins.
@function ra-ui-light-theme(
  $primary,
  $accent: mat.define-palette(mat.$green-palette, 500, 100, 900),
  $warn: mat.define-palette(mat.$red-palette),
  $foreground: $ra-ui-light-theme-foreground,
  $background: $ra-ui-light-theme-background,
  $status-palette: $status-palette
) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $foreground,
    background: $background,
    status-palette: $status-palette
  );
}

// Creates a container object for a dark theme to be given to individual component theme mixins.
@function ra-ui-dark-theme(
  $primary,
  $accent: mat.define-palette(mat.$pink-palette),
  $warn: mat.define-palette(mat.$red-palette),
  $foreground: mat.$dark-theme-foreground-palette,
  $background: mat.$dark-theme-background-palette,
  $status-palette: $status-palette
) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: true,
    foreground: $foreground,
    background: $background,
    status-palette: $status-palette
  );
}

/**
* Returns status palette WITHOUT the contrast colors.
 */
@function ra-ui-status-palette($theme, $status-palette) {
  $theme-status-palette: map-get($theme, status-palette);
  /**
  * status palette exists in the theme and has contrast defined
  * --> strip contrast and return the theme's status palette without the contrast
   */
  @if $theme-status-palette and map-get($theme-status-palette, contrast) {
    $theme-contrast-status-palette: map-remove($theme-status-palette, contrast);
    @return $theme-contrast-status-palette;
  }
  /**
  * status palette exists in the theme, but has no contrast defined (if it did, previous if-branch would be executed)
  * --> simply return the theme's status palette
   */
  @else if $theme-status-palette {
    @return $theme-status-palette;
  }
  /**
  * when there is no status palette in the theme
   */
  @else {
    @return $status-palette;
  }
}

/**
* Returns ONLY the contrast colors of status palette.
 */
@function ra-ui-status-palette-contrast($theme, $status-palette) {
  $theme-status-palette: map-get($theme, status-palette);
  // when there is no status palette in the theme
  @if ($theme-status-palette == null) {
    @return $status-palette;
  }

  $status-contrast: map-get($theme-status-palette, contrast); //
  @return if($status-contrast, $status-contrast, $status-palette);
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin ra-ui-status-icon-theme($theme, $custom-typography, $status-palette) {
  ra-ui-status-icon {
    @include ra-ui-typography-level-to-styles($custom-typography, icon-m);

    &.icon-s-typography {
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
    }

    $contrast-status: ra-ui-status-palette-contrast($theme, $status-palette);

    @each $name, $color in $contrast-status {
      .#{$name}.status-icon .foreground {
        color: $color;
      }
    }

    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      .#{$name}.status-icon .background {
        color: $color;
      }
    }
  }
}

@mixin common-utils-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include static-text-component-theme($theme, $custom-typography);
  @include ra-ui-dirty-flag-component-theme($theme);
  @include ra-ui-divider-theme($theme, $custom-typography);
  @include label-container-component-theme($theme, $custom-typography);
  @include ra-ui-image-theme($theme, $custom-typography);
  @include tooltip-component-theme($theme, $custom-typography);
  @include ra-ui-immediate-arrow-width-color($theme, $custom-typography);
  @include ra-ui-flat-mode-error-theme($theme, $custom-typography);
  @include ra-ui-status-icon-theme($theme, $custom-typography, $status-palette);
}

// Files to include additional scss variables and mixins
// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-data-item-browser-loading-cell-renderer($base-font-size) {
  ra-ui-dib-loading-cell-renderer {
    ra-ui-loading-spinner {
      margin-left: getRelative(8, $base-font-size);
    }
  }
}

@mixin ra-ui-data-item-browser-theme($theme, $custom-typography) {
  $base-font-size: 12px;

  ra-ui-data-item-browser {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    @include ra-ui-data-item-browser-loading-cell-renderer($base-font-size);

    .ra-ui-dib-list-container {
      ra-ui-list
        .ra-ui-list-wrapper
        .ra-ui-list-item
        .ra-ui-dib-list-item-label {
        // no design provided yet - temporary override (custom template) - centering the text based on the icon
        line-height: ra-ui-font-size($custom-typography, icon-l);

        .static-text {
          cursor: pointer;
        }
      }

      .ra-icon-ide-sm-caret-right {
        // no design yet - so just to look better than without any margin between drill-in and label
        margin-right: calc(ra-ui-font-size($custom-typography, icon-l) * 0.5);
      }

      .ra-ui-dib-list-data-icon {
        // no design yet - so just to look better than without any margin between data icon and data label
        margin-right: calc(ra-ui-font-size($custom-typography, icon-l) * 0.25);
      }

      .ra-ui-dib-list-drill-icon-hidden {
        // hidden visibility is passed to mat-icon to provide the same space to items without icon (to have their texts vertically aligned)
        visibility: hidden;
      }
    }

    .ra-ui-dib-list-container,
    .ra-ui-dib-grid-container {
      margin-top: getRelative(8px, $base-font-size);
      margin-bottom: getRelative(16px, $base-font-size);
    }

    .ra-ui-dib-breadcrumb {
      margin-bottom: getRelative(4px, $base-font-size);
    }

    .ag-overlay-loading-wrapper {
      background-color: ra-ui-color($theme, background, background-container);
    }
  }
}

@mixin ra-ui-data-table-theme($theme, $custom-typography) {
  $toolbar-border: getRelative(1, 14) solid
    ra-ui-color($theme, background, interactive-component-06);

  ra-ui-data-table {
    ra-ui-toolbar {
      border-left: $toolbar-border;
      border-top: $toolbar-border;
      border-right: $toolbar-border;
    }

    ra-ui-toolbar {
      background-color: ra-ui-color(
        $theme,
        background,
        interactive-component-01
      ); // requested by UI designers
    }

    .ra-ui-toolbar-left-section
      ra-ui-toolbar-group
      .ra-ui-toolbar-group-items-container {
      $toolbar-group-horizontal-padding: getRelative(11, 28);
      padding-left: $toolbar-group-horizontal-padding;
      padding-right: $toolbar-group-horizontal-padding;
    }
  }
}

@mixin ra-ui-flyout-data-item-browser-theme($theme, $custom-typography) {
  .ra-ui-flyout-data-item-browser {
    .ag-root-wrapper-body.ag-layout-normal {
      height: 100%;
    }

    .ag-root {
      &.ag-layout-normal,
      &.ag-layout-auto-height {
        width: 100%;
      }
    }
  }
}

@mixin ra-ui-main-detail-content-theme($theme, $custom-typography) {
  $containerGapSizePx: getRelative(16px, 14px);

  ra-ui-main-detail-content {
    @include ra-ui-typography-level-to-styles($custom-typography, body-2);
    .ra-ui-main-detail-content {
      &-container {
        background-color: ra-ui-color($theme, primary, contrast-900);

        &-top-divider {
          border-top: 1px solid
            ra-ui-color($theme, background, interactive-component-06);
        }

        &-bottom-divider {
          border-bottom: 1px solid
            ra-ui-color($theme, background, interactive-component-06);
        }
      }
      &-pane {
        border-left: 1px solid
          ra-ui-color($theme, background, interactive-component-06);
        padding: $containerGapSizePx 0 $containerGapSizePx $containerGapSizePx;

        &-padding {
          padding: $containerGapSizePx;
        }
      }
      &-title {
        ra-ui-static-text {
          color: ra-ui-color($theme, foreground, text-primary);
          @include ra-ui-typography-level-to-styles(
            $custom-typography,
            subheading-2-bold
          );
        }

        &-padding {
          padding-bottom: $containerGapSizePx;
        }
      }
      &-subtitle {
        ra-ui-static-text {
          color: ra-ui-color($theme, foreground, text-primary);
          @include ra-ui-typography-level-to-styles($custom-typography, body-1);
        }

        &-padding {
          padding-bottom: $containerGapSizePx;
        }
      }
      &-left {
        padding: $containerGapSizePx $containerGapSizePx $containerGapSizePx 0;

        &-padding {
          padding: $containerGapSizePx;
        }
      }
    }
  }
}

// Files to include additional scss variables and mixins
// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-input-flyout-dib-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-input-flyout-dib {
    display: inline-block;

    &.ra-ui-fill-parent {
      width: 100%;
    }

    ra-ui-input {
      .mat-form-field-suffix > ra-ui-prefix-suffix {
        margin-left: $label-margin;
      }

      ra-ui-prefix-suffix .ra-ui-input-suffix {
        padding-left: 0;
        vertical-align: middle;

        @include getCursor($custom-global-config);

        ra-ui-static-text .static-text {
          @include getCursor($custom-global-config);
        }
      }
    }
  }
}

@mixin ra-ui-overview-theme($theme, $custom-typography) {
  $textForegroundPrimary: ra-ui-color($theme, foreground, text-primary);
  // body-1 typography at flourish is 12px at the moment, lets use it to get relative
  $containerGapSizePx: getRelative(16px, 12px);
  $containerHalfGapSizePx: getRelative(8px, 12px);
  $vendorImageHeightPx: getRelative(36px, 12px);

  ra-ui-overview {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    color: $textForegroundPrimary;

    .ra-ui-overview-content {
      margin: $containerGapSizePx;

      .ra-ui-overview-header {
        margin-bottom: $containerHalfGapSizePx;
        .ra-ui-overview-main-title {
          color: $textForegroundPrimary;
          @include ra-ui-typography-level-to-styles($custom-typography, title);
        }
      }

      > .ra-ui-overview-vendor-image-container {
        margin: $containerHalfGapSizePx $containerHalfGapSizePx
          $containerGapSizePx 0;
        height: $vendorImageHeightPx;

        /* overriding image styles
                 * e.g. SVGs are set to width and height from its XML, we want default height 36px
                 */
        > ra-ui-image.ra-ui-overview-vendor-image {
          height: $vendorImageHeightPx;
          > .image-wrapper {
            > .image-background {
              // TODO remove this CSS after the enhancement with image width/height sizing is solved
              // https://rockwellautomation.atlassian.net/browse/RAIDWTUI01-1588
              width: 100% !important;
              height: $vendorImageHeightPx !important;
            }
          }
        }
      }

      .ra-ui-overview-description {
        margin-bottom: $containerGapSizePx;
        ra-ui-static-text {
          color: ra-ui-color($theme, foreground, text-label);
          @include ra-ui-typography-level-to-styles(
            $custom-typography,
            subheading-2
          );
        }
      }

      .ra-ui-overview-section-title-container {
        margin-bottom: $containerHalfGapSizePx;
        .ra-ui-overview-section-title {
          color: $textForegroundPrimary;
          @include ra-ui-typography-level-to-styles(
            $custom-typography,
            subheading-2-bold
          );
        }
      }

      .ra-ui-overview-data {
        gap: $containerHalfGapSizePx;

        .ra-ui-overview-status-message {
          ra-ui-static-text {
            @include ra-ui-typography-level-to-styles(
              $custom-typography,
              body-2
            );
            color: $textForegroundPrimary;
          }

          .ra-ui-overview-status-message-icon-container {
            // vertically align the icon with the 1st row of the status message text
            $body-2-line-height: ra-ui-line-height($custom-typography, body-2);
            // if line-height is token it is not given as px value, but only as coefficient 1.4
            $body-2-line-height: if(
              type-of($body-2-line-height) == string,
              calc(
                $body-2-line-height *
                  ra-ui-font-size($custom-typography, body-2)
              ),
              $body-2-line-height
            );
            padding-top: calc(
              (
                  $body-2-line-height - ra-ui-font-size(
                      $custom-typography,
                      icon-m
                    )
                ) * 0.5
            );
          }

          > div:first-child {
            // margin after status icon
            margin-right: $containerHalfGapSizePx;
          }
        }

        > .ra-ui-overview-data-section {
          gap: $containerHalfGapSizePx;
        }

        .ra-ui-overview-data-section-items {
          gap: $containerHalfGapSizePx;
        }

        .ra-ui-overview-label-value-pairs-column {
          .ra-ui-overview-label-container-value {
            @include ra-ui-typography-level-to-styles(
              $custom-typography,
              body-2
            );
          }

          &:not(:last-child) {
            margin-right: $containerHalfGapSizePx;
          }

          > div:not(:last-child) {
            margin-bottom: $containerHalfGapSizePx;
          }
        }
      }
    }
  }
}

@mixin ra-ui-stepper-theme($theme, $custom-typography) {
  $containerGapSizePx: getRelative(16px, 14px);
  $containerButtonsGapSizePx: getRelative(8px, 14px);

  ra-ui-stepper {
    @include ra-ui-typography-level-to-styles($custom-typography, body-2);

    .ra-ui-stepper-footer {
      padding: $containerGapSizePx;

      &-buttons {
        gap: $containerButtonsGapSizePx;
      }
    }
  }
}

@mixin common-views-theme($theme, $custom-typography) {
  @include ra-ui-data-item-browser-theme($theme, $custom-typography);
  @include ra-ui-data-table-theme($theme, $custom-typography);
  @include ra-ui-flyout-data-item-browser-theme($theme, $custom-typography);
  @include ra-ui-main-detail-content-theme($theme, $custom-typography);
  @include ra-ui-input-flyout-dib-theme($theme, $custom-typography);
  @include ra-ui-overview-theme($theme, $custom-typography);
  @include ra-ui-stepper-theme($theme, $custom-typography);
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin ra-ui-empty-state-theme($theme, $custom-typography) {
  $margin-vertical-outside-text: 8;
  $margin-vertical-image-bottom: 16;
  $margin-vertical-between-text: 4;
  $margin-horizontal-between-buttons: 4;
  $padding-empty-state-vertical: 24;
  $padding-empty-state-horizontal: 16;
  $text-color: ra-ui-color($theme, foreground, text-label);
  $body-1-font-size: 12;
  $body-2-bold-font-size: 14;

  ra-ui-empty-state {
    // main wrapper
    .empty-state {
      @include ra-ui-typography-level-to-styles($custom-typography, body-1);
      padding: getRelative($padding-empty-state-vertical, $body-1-font-size)
        getRelative($padding-empty-state-horizontal, $body-1-font-size);
    }

    // Illustration part
    .empty-state-image {
      width: 100%;
      @include ra-ui-typography-level-to-styles($custom-typography, body-1);
      margin-bottom: getRelative(
        $margin-vertical-image-bottom,
        $body-1-font-size
      );
      display: flex;
      justify-content: center;

      .empty-state-image-wrapper-container {
        width: 100%;
      }
      ra-ui-image {
        padding: 0px;

        ra-ui-not-found-image {
          border: none;
        }
      }
    }

    // Title part
    .empty-state-title {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        body-2-bold
      );
      margin-bottom: getRelative(
        $margin-vertical-between-text,
        $body-2-bold-font-size
      );
      width: 100%;

      .no-image {
        margin-top: getRelative(
          $margin-vertical-outside-text,
          $body-1-font-size
        );
      }

      ra-ui-static-text .static-text {
        @include ra-ui-typography-level-to-styles(
          $custom-typography,
          body-2-bold
        );
        color: $text-color;
      }
    }

    // Message part
    .empty-state-message {
      width: 100%;

      ra-ui-static-text .static-text {
        @include ra-ui-typography-level-to-styles($custom-typography, body-1);
        color: $text-color;
      }
    }

    // Buttons part
    .empty-state-buttons {
      width: 100%;

      .empty-state-button {
        @include ra-ui-typography-level-to-styles($custom-typography, body-1);
        margin-top: getRelative(
          $margin-vertical-outside-text,
          $body-1-font-size
        );
        margin-left: getRelative(
          $margin-horizontal-between-buttons,
          $body-1-font-size
        );
        margin-right: getRelative(
          $margin-horizontal-between-buttons,
          $body-1-font-size
        );
      }
    }

    .bottom-when-buttons {
      width: 100%;
      @include ra-ui-typography-level-to-styles($custom-typography, body-1);
      margin-bottom: getRelative(
        $margin-vertical-outside-text,
        $body-1-font-size
      );
    }
  }
}

@mixin content-rendering-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include ra-ui-empty-state-theme($theme, $custom-typography);
}

/* You can add global styles to this file, and also import other style files */

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin gauge-component-theme($theme, $custom-typography) {
  // retrieve variables from theme
  $main-opacity: 0.75;
  $main-caption-opacity: $main-opacity;
  $sub-caption-opacity: $main-opacity - 0.25;
  $gauge-opacity: $main-opacity - 0.3;
  $trending-opacity: $main-opacity - 0.6;
  $font-family: ra-ui-font-family($custom-typography);

  ra-ui-horizontal-gauge,
  ra-ui-vertical-gauge,
  ra-ui-radial-gauge {
    // default material theme colors start
    .unit,
    .scale,
    .info-icon,
    .sub-caption,
    .main-caption,
    .multiplication,
    .display-values,
    .trending polygon {
      fill: ra-ui-color($theme, foreground, base);
    }

    .info-circle,
    .set-point line,
    .scale-points,
    .threshold-wrapper,
    .normal-range-wrapper line,
    .normal-range-wrapper path {
      stroke: ra-ui-color($theme, foreground, base);
    }

    .main-caption {
      opacity: $main-caption-opacity;
    }

    .info-icon,
    .sub-caption {
      opacity: $sub-caption-opacity;
    }

    .main-caption,
    .sub-caption,
    .unit,
    .multiplication {
      font-family: $font-family;
    }

    .info-circle {
      stroke-opacity: $sub-caption-opacity;
    }

    .unit,
    .scale,
    .scale-points line,
    .multiplication,
    .display-values {
      opacity: $gauge-opacity;
    }

    .trending polygon {
      opacity: $trending-opacity;
    }

    .primary-color {
      fill: ra-ui-color($theme, primary);
    }
    .background-color {
      fill: ra-ui-color($theme, background, background);
    }
    .stroke-color {
      stroke: ra-ui-color($theme, background, background);
    }
    .main-fill.primary {
      fill: ra-ui-color($theme, background, interactive-component-06);
    }

    .main-stroke.primary {
      stroke: ra-ui-color($theme, background, interactive-component-06);
    }

    .main-fill.primary.grayscale {
      fill: ra-ui-color($theme, background, interactive-component-06);
      filter: grayscale(1);
    }

    .main-stroke.primary.grayscale {
      stroke: ra-ui-color($theme, background, interactive-component-06);
      filter: grayscale(1);
    }

    .main-fill.accent {
      fill: ra-ui-color($theme, primary);
    }

    .main-stroke.accent {
      stroke: ra-ui-color($theme, primary);
    }

    .main-fill.accent.grayscale {
      fill: ra-ui-color($theme, primary);
      filter: grayscale(1);
    }

    .main-stroke.accent.grayscale {
      stroke: ra-ui-color($theme, primary);
      filter: grayscale(1);
    }

    .main-fill.warn {
      fill: ra-ui-color($theme, warn);
    }

    .main-stroke.warn {
      stroke: ra-ui-color($theme, warn);
    }

    .main-fill.warn.grayscale {
      fill: grayscale(ra-ui-color($theme, warn));
    }

    .main-stroke.warn.grayscale {
      stroke: grayscale(ra-ui-color($theme, warn));
    }

    .main-text.primary {
      fill: ra-ui-color($theme, primary, default-contrast);
    }

    .main-text.accent {
      fill: ra-ui-color($theme, primary, default-contrast);
    }

    .main-text.warn {
      fill: ra-ui-color($theme, warn, default-contrast);
    }

    .needle-darker-half.primary {
      fill: ra-ui-color($theme, background, interactive-component-07);
    }

    .needle-darker-half.accent {
      fill: ra-ui-color($theme, primary, 900);
    }

    .needle-darker-half.warn {
      fill: ra-ui-color($theme, warn, 900);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin ra-ui-numeric-display-theme($theme, $custom-typography) {
  $urgent: map-get(ra-ui-status-palette($theme, $status-palette), alarm);
  $high: map-get(ra-ui-status-palette($theme, $status-palette), error);
  $medium: map-get(ra-ui-status-palette($theme, $status-palette), warning);
  $low: map-get(ra-ui-status-palette($theme, $status-palette), pending);

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $subTitleColor: rgba(mat.get-color-from-palette($foreground, text), 0.5);
  $font-family: ra-ui-font-family($custom-typography);
  $main-opacity: 0.75;
  $main-caption-opacity: $main-opacity;
  $sub-caption-opacity: $main-opacity - 0.25;
  $numeric-opacity: $main-opacity - 0.3;
  $trending-opacity: $main-opacity - 0.3;

  ra-ui-numeric-display {
    .unit,
    .scale,
    .info-icon,
    .sub-caption,
    .main-caption,
    .multiplication,
    .display-values,
    .trending polygon {
      fill: ra-ui-color($theme, foreground, base);
    }

    .info-circle,
    .set-point line,
    .scale-points,
    .threshold-wrapper,
    .normal-range-wrapper line,
    .normal-range-wrapper path {
      stroke: ra-ui-color($theme, foreground, base);
    }

    .main-caption {
      opacity: $main-caption-opacity;
    }

    .info-icon,
    .sub-caption {
      opacity: $sub-caption-opacity;
    }

    .main-caption,
    .sub-caption,
    .unit,
    .multiplication,
    .value-text {
      font-family: $font-family;
    }

    .info-circle {
      stroke-opacity: $sub-caption-opacity;
    }

    .unit,
    .scale,
    .scale-points line,
    .multiplication,
    .display-values {
      opacity: $numeric-opacity;
    }

    .trending polygon {
      opacity: $trending-opacity;
    }

    .title-box {
      .sub-title {
        color: $subTitleColor;
      }
    }

    // region alarm states
    .normal {
      // region box style
      &.value-bg {
        fill: mat.get-color-from-palette($background, background);
        stroke: mat.get-color-from-palette($background, background);
      }

      &.value-text {
        fill: #3178b9;
      }
      // end region box style
    }

    .urgent {
      // region icon style
      .icon-text {
        fill: #fff;
      }

      .icon-background-color {
        fill: $urgent;
      }

      .icon-background-border {
        fill: mat.get-color-from-palette($background, background);
      }
      // end region icon style

      // region box style
      &.value-bg {
        fill: $urgent;
      }

      &.value-text {
        fill: #fff;
      }
      // end region box style
    }

    .high {
      // region icon style
      .icon-text {
        fill: #fff;
      }

      .icon-background-color {
        fill: $high;
      }

      .icon-background-border {
        fill: mat.get-color-from-palette($background, background);
      }
      // end region icon style

      // region box style
      &.value-bg {
        fill: $high;
      }

      &.value-text {
        fill: #fff;
      }
      // end region box style
    }

    .medium {
      // region icon style
      .icon-fill {
        fill: $medium;
      }

      .icon-text {
        fill: mat.get-color-from-palette($foreground, text);
      }

      .icon-background-color {
        fill: mat.get-color-from-palette($background, background);
      }
      // end region icon style

      // region box style
      &.value-bg {
        fill: $medium;
      }

      &.value-text {
        fill: mat.get-color-from-palette($foreground, text);
      }
      // end region box style
    }

    .low {
      // region icon style
      .icon-background-color {
        fill: mat.get-color-from-palette($background, background);
      }

      .icon-background-border {
        fill: $low;
      }

      .icon-text {
        fill: $low;
      }
      // end region icon style

      // region box style
      &.value-bg {
        stroke: $low;
        stroke-width: 0.05em;
        fill: mat.get-color-from-palette($background, background);
      }

      &.value-text {
        fill: $low;
      }
      // end region box style
    }

    .uncertain {
      // region icon style
      .icon-background-color {
        fill: rgba(mat.get-color-from-palette($foreground, text), 0.5);
      }

      .icon-text {
        fill: mat.get-color-from-palette($background, background);
        stroke: mat.get-color-from-palette($background, background);
      }

      .icon-background {
        fill: mat.get-color-from-palette($background, background);
      }
      // end region icon style

      // region box style
      &.value-text {
        fill: rgba(mat.get-color-from-palette($foreground, text), 0.5);
      }

      &.value-bg {
        stroke: rgba(mat.get-color-from-palette($foreground, text), 0.5);
        stroke-width: 0.05em;
        fill: mat.get-color-from-palette($background, background);
      }
      // end region box style
    }

    .bad {
      // region icon style
      .icon-background-color {
        fill: rgba(mat.get-color-from-palette($foreground, text), 0.5);
      }

      .icon-text {
        fill: mat.get-color-from-palette($background, background);
      }

      .icon-background {
        fill: mat.get-color-from-palette($background, background);
      }
      // end region icon style

      // region box style
      &.value-text {
        fill: rgba(mat.get-color-from-palette($foreground, text), 0.5);
      }

      &.value-bg {
        stroke: rgba(mat.get-color-from-palette($foreground, text), 0.5);
        stroke-width: 0.05em;
        fill: mat.get-color-from-palette($background, background);
      }
      // end region box style
    }
    // end region alarm states
  }
}

@mixin dashboard-controls-theme($theme, $custom-typography) {
  @include gauge-component-theme($theme, $custom-typography);
  @include ra-ui-numeric-display-theme($theme, $custom-typography);
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin ra-ui-palette-color-picker-theme($theme) {
  ra-ui-palette-color-picker {
    .ra-ui-palette-color-picker-color-container {
      border: 1px solid ra-ui-color($theme, foreground, disabled-text);
    }

    .ra-ui-palette-color-picker-select-button {
      color: ra-ui-color($theme, foreground, icon);
    }
  }

  ra-ui-palette-colorpicker-popup-content {
    @include mat.elevation(8);
    background-color: ra-ui-color($theme, background, card);
    color: ra-ui-color($theme, foreground, text);
  }

  ra-ui-simple-palette-color-picker {
    .ra-ui-simple-palette-selected-color {
      color: ra-ui-color($theme, foreground, text);
    }

    .ra-ui-simple-palette-no-palettes-icon {
      color: ra-ui-color($theme, foreground, disabled-text);
    }

    .ra-ui-simple-palette-color-item {
      &.is-dark .mat-icon {
        color: #fff;
      }

      &.is-light .mat-icon {
        color: #000;
      }
    }
  }
}

@mixin ra-ui-standard-color-picker-theme($theme) {
  ra-ui-standard-color-picker {
    .ra-ui-standard-color-picker-color-container {
      border: 1px solid ra-ui-color($theme, foreground, disabled-text);
    }

    .ra-ui-standard-color-picker-select-button {
      color: ra-ui-color($theme, foreground, icon);
    }
  }
  ra-ui-standard-colorpicker-popup-content {
    @include mat.elevation(8);
    background-color: ra-ui-color($theme, background, card);
    color: ra-ui-color($theme, foreground, text);
  }
  ra-ui-simple-standard-color-picker {
    color-sketch {
      .sketch-picker {
        background-color: ra-ui-color($theme, background, card) !important;
        color: ra-ui-color($theme, foreground, text) !important;
        @include mat.elevation(8);
      }
      .sketch-active {
        box-shadow:
          ra-ui-color($theme, foreground, secondary-text) 0px 0px 0px 1px inset,
          ra-ui-color($theme, foreground, secondary-text) 0px 0px 4px inset !important;
      }
      color-editable-input {
        .wrap > span {
          color: ra-ui-color($theme, foreground, text) !important;
        }
        input {
          box-shadow: inset 0 0 0 1px
            ra-ui-color($theme, foreground, secondary-text) !important;
        }
      }
      color-sketch-preset-colors {
        .sketch-swatches {
          border-top: 1px solid map-get(mat.$grey-palette, 300) !important;
        }
        color-swatch {
          .swatch {
            box-shadow: inset 0 0 0 1px
              ra-ui-color($theme, foreground, secondary-text) !important;
          }
        }
      }
    }
  }
}

@mixin ra-ui-color-pickers-theme($theme) {
  @include ra-ui-palette-color-picker-theme($theme);
  @include ra-ui-standard-color-picker-theme($theme);
}

@mixin ra-ui-simple-icon-picker-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  ra-ui-simple-icon-picker {
    .ra-ui-simple-icon-picker-container {
      .ra-ui-simple-icon-picker-loading-overlay {
        background-color: mat.get-color-from-palette($background, card);
      }
      .ra-ui-simple-icon-picker-message {
        color: mat.get-color-from-palette($foreground, secondary-text);
      }
      .ra-ui-simple-icon-picker-item {
        border: 1px solid mat.get-color-from-palette($foreground, divider);
        &.selected {
          outline: 2px solid mat.get-color-from-palette($foreground, text);
        }
      }
      .ra-ui-simple-icon-picker-clear-item {
        border: 1px dashed mat.get-color-from-palette($foreground, divider);
      }
      .ra-ui-simple-icon-picker-item:hover,
      .ra-ui-simple-icon-picker-clear-item:hover {
        border-color: mat.get-color-from-palette($foreground, secondary-text);
        background-color: mat.get-color-from-palette($background, hover);
      }
    }
  }
}

@mixin ra-ui-icon-picker-theme($theme) {
  @include ra-ui-simple-icon-picker-theme($theme);

  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  ra-ui-icon-picker {
    .ra-ui-icon-picker-icon-container {
      border: 1px solid mat.get-color-from-palette($foreground, secondary-text);
    }
    .disabled {
      color: mat.get-color-from-palette($foreground, disabled-text);

      .ra-ui-icon-picker-icon-container {
        border-color: mat.get-color-from-palette($foreground, disabled-text);
      }
    }

    .ra-ui-icon-picker-select-button {
      color: mat.get-color-from-palette($foreground, icon);
    }
  }
  .ra-ui-icon-picker-popup {
    background-color: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, text);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin ra-ui-indicator-theme($theme, $custom-typography) {
  ra-ui-indicator {
    svg text:not(.indicator-icon) {
      font-family: ra-ui-font-family($custom-typography);
    }

    .ra-ui-indicator-rect {
      stroke-width: 3;
      stroke: ra-ui-color($theme, foreground, outline);
    }
  }
}

@mixin experimental-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include ra-ui-color-pickers-theme($theme);
  @include ra-ui-icon-picker-theme($theme);
  @include ra-ui-indicator-theme($theme, $custom-typography);
}

/* You can add global styles to this file, and also import other style files */

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-immediate-arrow-neighbour($custom-typography) {
  &.immediate-arrow {
    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    display: inline-block;
    vertical-align: middle;
    &.mat-button-base {
      vertical-align: inherit;
    }
    &.label-container-label-text {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: middle;
      @include safari-only-specific() {
        width: calc(100% - #{$width-immediate} - #{$padding-immediate});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-immediate-arrow-width-color($theme, $custom-typography) {
  ra-ui-immediate-arrow {
    color: ra-ui-color($theme, foreground, immediate-commit);

    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(#{$width-immediate} + #{$padding-immediate} + 1px);

    mat-icon.mat-icon.ra-icon-ide-sm-immediate-commit-arrow-left {
      padding-left: $padding-immediate;
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      line-height: ra-ui-font-size($custom-typography, icon-s);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-dirty-flag-neighbour($custom-typography) {
  &.dirty-flag {
    width: calc(100% - #{$width-dirty-flag});
    display: inline-block;
    vertical-align: middle;

    &.blank-when-disabled {
      width: 100%;
    }

    &.immediate-arrow {
      $prop: get-immediate-arrow-properties($custom-typography);
      $width-immediate: map-get($prop, width);
      $padding-immediate: map-get($prop, padding);
      width: calc(
        100% - #{$width-dirty-flag} - #{$width-immediate} - #{$padding-immediate} -
          1px
      );
      &.label-container-label-text {
        vertical-align: middle;
      }
      &.blank-when-disabled {
        width: calc(100% - #{$width-immediate-arrow});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-dirty-flag-component-theme($theme) {
  ra-ui-dirty-flag[disabled='true'] {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-checkbox-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $active-color: ra-ui-color($theme, primary, 600);
  $active-unchecked-color: ra-ui-color($theme, background, inactive);
  $active-hover: ra-ui-color($theme, primary, 700);
  $active-press: ra-ui-color($theme, primary, 800);
  $focus-color: ra-ui-color($theme, primary, A200);
  $disabled-color: ra-ui-color($theme, background, disabled);

  $inactive-color: ra-ui-color($theme, background, inactive);
  $inactive-pressed: ra-ui-color($theme, background, interactive-component-07);
  $inactive-hover: ra-ui-color($theme, background, inactive-hover);

  $horizontal-padding: getRelative(8, 14);

  $checkbox-font-size: 14px;
  $checkbox-base-width: 16px;
  $multiline-label-top-margin: 1.75px;
  $multiline-box-top-margin: 7px;

  ra-ui-checkbox {
    @include ra-ui-typography-level-to-styles($custom-typography, input);

    &.ripple-effect-disabled {
      .mat-checkbox-ripple {
        display: none;
      }
    }

    .ra-ui-disabled-side-paddings .mat-checkbox-layout {
      padding: 0;
      max-width: 100%;
    }

    .mat-checkbox-layout {
      padding: 0 $horizontal-padding;
      height: getRelative(28, 14);
      max-width: calc(100% - #{$horizontal-padding} * 2);

      .mat-checkbox-label {
        padding-top: getRelative(4, 14);
        padding-bottom: getRelative(4, 14);
      }
    }

    @include checkbox-enhancement($theme, $custom-typography);

    .mat-checkbox:not(.mat-checkbox-disabled) {
      @include getCursor($custom-global-config);
    }

    //region inactive state feature rules
    &.ra-ui-inactive-state {
      // checked/indeterminate
      .mat-checkbox.mat-checkbox-checked {
        .mat-checkbox-background {
          background-color: $inactive-color;
        }
        .mat-checkbox-frame {
          border-color: $inactive-color;
        }
      }
      // unchecked
      .mat-checkbox:not(.mat-checkbox-checked) .mat-checkbox-frame {
        border-color: $inactive-color;
      }

      // checked/indeterminate and hovered
      .mat-checkbox.mat-checkbox-checked:hover {
        .mat-checkbox-background {
          background-color: $inactive-hover;
        }
        .mat-checkbox-frame {
          border-color: $inactive-hover;
        }
      }
      // unchecked and hovered
      .mat-checkbox:not(.mat-checkbox-checked):hover .mat-checkbox-frame {
        border-color: $inactive-hover;
      }

      // checked/indeterminate and pressed
      .mat-checkbox.mat-checkbox-checked:active .mat-checkbox-background {
        background-color: $inactive-pressed;
      }
      // pressed
      .mat-checkbox.chckbx:active .mat-checkbox-frame {
        border-color: $inactive-pressed;
      }
    }
    //endregion

    mat-checkbox {
      &.mat-checkbox {
        display: inline;
      }

      label {
        // prevents native browser focus indication
        outline: none;
      }

      &.multi-line-label {
        .mat-checkbox-layout {
          height: max-content;
        }

        .mat-checkbox-inner-container {
          // we need to calculate the scale number and then multiply it by the desired basic margin
          // because the scaling when changing typography affects the margin as well
          $font-size: ra-ui-font-size($custom-typography, input);
          $scale-number: calc(
            #{strip-unit($font-size)} / #{strip-unit($checkbox-base-width)}
          );
          margin-top: calc(
            #{getRelative($multiline-box-top-margin, $checkbox-font-size)} * #{$scale-number}
          );
        }

        .mat-checkbox-label {
          margin-top: getRelative(
            $multiline-label-top-margin,
            $checkbox-font-size
          );
        }
      }

      // checked and indeterminate enabled state
      &.mat-checkbox-checked.chckbx .mat-checkbox-background,
      &.mat-checkbox-indeterminate.chckbx .mat-checkbox-background {
        background-color: $active-color;
      }

      // checked and indeterminate enabled state - hover
      &.mat-checkbox-checked.chckbx:hover .mat-checkbox-background,
      &.mat-checkbox-indeterminate.chckbx:hover .mat-checkbox-background {
        background-color: $active-hover;
      }

      // checked and indeterminate enabled state - pressed
      &:active.mat-checkbox-checked.chckbx .mat-checkbox-background,
      &:active.mat-checkbox-indeterminate.chckbx .mat-checkbox-background {
        background-color: $active-press;
      }

      // enabled state - pressed - frame
      &.mat-checkbox.chckbx:active .mat-checkbox-frame {
        border-color: $active-press;
      }

      // unchecked enabled state
      & .mat-checkbox-frame {
        border-color: $active-unchecked-color;
      }

      // unchecked enabled state - hover
      &:hover .mat-checkbox-inner-container .mat-checkbox-frame {
        border-color: $active-hover;
      }

      // focus indication
      &.ra-ui-keyboard-focus,
      &:active.ra-ui-keyboard-focus {
        label {
          $size: getRelative(2, 14);

          outline: $size solid $focus-color;
          outline-offset: $size;
        }
      }

      // checked and indeterminate disabled state
      &.mat-checkbox.mat-checkbox-checked.mat-checkbox-disabled
        .mat-checkbox-background,
      &.mat-checkbox.mat-checkbox-indeterminate.mat-checkbox-disabled
        .mat-checkbox-background {
        background-color: $disabled-color;
      }

      // unchecked disabled state
      &.mat-checkbox-disabled:not(.mat-checkbox-checked),
      &.mat-checkbox-disabled:not(.mat-checkbox-checked):active {
        .mat-checkbox-frame {
          border-color: $disabled-color;
        }
      }

      //-----------------------------------

      .mat-checkbox-label {
        white-space: nowrap;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: 100%;
          max-width: inherit;
        }
        .static-text-wrapper {
          @include ra-ui-immediate-arrow-neighbour($custom-typography);
        }
      }

      //region readonly feature rules
      &.ra-ui-readonly:not(.ra-ui-disabled).mat-checkbox-disabled.mat-checkbox {
        &.mat-checkbox-checked .mat-checkbox-background {
          background-color: ra-ui-color($theme, foreground, text);
        }
        &:not(.mat-checkbox-checked) .mat-checkbox-frame {
          border-color: ra-ui-color($theme, foreground, text);
        }

        ra-ui-static-text {
          color: ra-ui-color($theme, foreground, text-primary);
        }
      }
      //endregion
    }

    &.ng-dirty .mat-checkbox-label {
      .static-text-wrapper {
        @include ra-ui-dirty-flag-neighbour($custom-typography);
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-checkbox-tree-theme() {
  ra-ui-checkbox-tree .content ra-ui-checkbox {
    display: block;
    padding-left: 21px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-error-message-theme($theme, $custom-typography) {
  $line-height: ra-ui-line-height($custom-typography, form-field-text);
  $error-color: map-get(ra-ui-status-palette($theme, $status-palette), error);
  $icon-height: ra-ui-font-size($custom-typography, icon-s);
  $padding: getRelative(8px, 14px);

  ra-ui-error-message {
    @include ra-ui-error-message-colors($theme);
    height: $line-height;

    .ra-ui-horizontal-paddings {
      @include ra-ui-typography-level-to-styles($custom-typography, input);
      padding-left: $padding;
      padding-right: $padding;
    }

    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );

      .static-text {
        color: $error-color;
      }
      ra-ui-status-icon {
        height: $icon-height;
        padding-top: calc(($line-height - $icon-height) * 0.5);
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-immediate-arrow-neighbour($custom-typography) {
  &.immediate-arrow {
    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    display: inline-block;
    vertical-align: middle;
    &.mat-button-base {
      vertical-align: inherit;
    }
    &.label-container-label-text {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: middle;
      @include safari-only-specific() {
        width: calc(100% - #{$width-immediate} - #{$padding-immediate});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-immediate-arrow-width-color($theme, $custom-typography) {
  ra-ui-immediate-arrow {
    color: ra-ui-color($theme, foreground, immediate-commit);

    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(#{$width-immediate} + #{$padding-immediate} + 1px);

    mat-icon.mat-icon.ra-icon-ide-sm-immediate-commit-arrow-left {
      padding-left: $padding-immediate;
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      line-height: ra-ui-font-size($custom-typography, icon-s);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-dirty-flag-neighbour($custom-typography) {
  &.dirty-flag {
    width: calc(100% - #{$width-dirty-flag});
    display: inline-block;
    vertical-align: middle;

    &.blank-when-disabled {
      width: 100%;
    }

    &.immediate-arrow {
      $prop: get-immediate-arrow-properties($custom-typography);
      $width-immediate: map-get($prop, width);
      $padding-immediate: map-get($prop, padding);
      width: calc(
        100% - #{$width-dirty-flag} - #{$width-immediate} - #{$padding-immediate} -
          1px
      );
      &.label-container-label-text {
        vertical-align: middle;
      }
      &.blank-when-disabled {
        width: calc(100% - #{$width-immediate-arrow});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-dirty-flag-component-theme($theme) {
  ra-ui-dirty-flag[disabled='true'] {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-multiline-input-theme($theme, $custom-typography) {
  ra-ui-multiline-input {
    &.ng-dirty .static-text-wrapper {
      @include ra-ui-dirty-flag-neighbour($custom-typography);
    }

    @include form-field-enhancement($theme, $custom-typography);

    .mat-form-field .ra-ui-placeholder.ra-ui-autosize-disabled {
      width: calc(100% - 1em); //more space for vertical scrollbar
    }

    // based on the material change there is failing e2e test now
    // e2e test - Multiline general: Should not display text area in scrollable mode
    // material change - https://github.com/angular/components/pull/19238/commits/c27d9ba7d710e81a94e9fabae39cf288ff246abf
    // when the CdkTextareaAutosize adds that class without overflow hidden, then the browser counts with the scrollbar
    // and creates extra new line in multiline
    .cdk-textarea-autosize-measuring-firefox {
      overflow: hidden !important;
    }

    .static-text-wrapper {
      @include ra-ui-immediate-arrow-neighbour($custom-typography);
    }

    .mat-form-field-underline.mat-disabled {
      background-image: none;
      background-color: ra-ui-color($theme, foreground, text-disabled);
    }

    .mat-form-field-disabled .mat-input-element:disabled {
      color: ra-ui-color($theme, foreground, text-disabled);
      -webkit-text-fill-color: ra-ui-color(
        $theme,
        foreground,
        text-disabled
      ); // #967 - Safari/Mac fix
      opacity: 1; // #967 - Safari/iOS fix
    }

    //region readonly rules
    .ra-ui-readonly:not(.ra-ui-disabled) {
      //value color
      .mat-input-element:disabled {
        color: ra-ui-color($theme, foreground, text-primary);
        -webkit-text-fill-color: ra-ui-color(
          $theme,
          foreground,
          text-primary
        ); // see #967
        opacity: 1; // see #967
      }

      @include readonly-form-field-appearance($theme);
    }

    //endregion

    /* BEGIN cursors */

    /* applied the label is float on top  */
    .mat-form-field-can-float.mat-form-field-should-float {
      ra-ui-dirty-flag,
      ra-ui-immediate-arrow,
      .static-text-wrapper .static-text {
        cursor: default;
      }
    }

    // set explicit cursor text, safari, edge, IE change default value from auto to text
    textarea:not([disabled]) {
      cursor: text;
    }

    /* text area is disabled */
    .mat-form-field-disabled {
      textarea[disabled],
      .static-text-wrapper .static-text {
        cursor: default;
      }
    }

    /* only the label is set on auto and textarea is empty */
    .mat-form-field-can-float {
      ra-ui-dirty-flag,
      ra-ui-immediate-arrow {
        cursor: text;
      }
    }

    /* END cursors */

    ra-ui-flat-mode-error .ra-ui-flat-mode-error-icon {
      padding-top: $form-field-vertical-padding;
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin static-text-component-theme($theme, $custom-typography) {
  // region color variables
  $text-color: dtu.rafl-color(color-foreground-primary, $theme);
  $link-text-color: dtu.rafl-color(color-state-active-default, $theme);
  $link-hover-text-color: dtu.design-token-var(
    rafl-color-state-active-hovered,
    rafl.$rafl-color-state-active-hovered
  );
  $link-pressed-text-color: dtu.rafl-color(color-state-active-pressed, $theme);
  $inverted-text-color: dtu.rafl-color(color-foreground-inverted, $theme);
  $inverted-link-text-color: dtu.rafl-color(
    color-state-active-inverted-default,
    $theme
  );
  $inverted-link-hover-text-color: dtu.design-token-var(
    rafl-color-state-active-inverted-hovered,
    rafl.$rafl-color-state-active-inverted-hovered
  );
  $inverted-link-pressed-text-color: dtu.rafl-color(
    color-state-active-inverted-pressed,
    $theme
  );
  $focus-color: dtu.rafl-color(color-state-focused, $theme);
  $disabled-color: dtu.rafl-color(color-state-disabled-default, $theme);
  $disabled-inverted-color: dtu.rafl-color(
    color-state-disabled-inverted-default,
    $theme
  );
  // endregion

  $error-icon-margin-fallback: getRelative(
    4,
    if(
      unit(rafl.$rafl-typography-body-sm-font-size) == 'rem',
      rafl.$rafl-typography-body-sm-font-size * 16,
      rafl.$rafl-typography-body-sm-font-size
    )
  );
  // endregion

  $focus-rectangle-width: getRelative(rafl.$rafl-dimension-stroke-md, 12);
  $icon-left-padding: dtu.rafl-dimension(gap-md, $label-padding-right);
  $text-margin: dtu.rafl-dimension(gap-sm, $error-icon-margin-fallback);

  ra-ui-static-text.static-text-with-error-message {
    display: flex;

    .mat-icon {
      padding-left: $icon-left-padding;
    }
  }

  h1 ra-ui-static-text {
    @include dtu.rafl-typography(title-lg, $custom-typography);
  }
  h2 ra-ui-static-text {
    @include dtu.rafl-typography(title-md, $custom-typography);
  }
  h3 ra-ui-static-text {
    @include dtu.rafl-typography(title-sm, $custom-typography);
  }
  h4 ra-ui-static-text {
    @include dtu.rafl-typography(title-xs, $custom-typography);
  }

  b ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1-bold);
  }

  ra-ui-static-text {
    @include dtu.rafl-typography(body-sm, $custom-typography);

    color: $text-color;

    &.ra-ui-inverted-mode {
      color: $inverted-text-color;

      > .static-text[disabled='true'] {
        color: $disabled-inverted-color;
      }
    }

    &.ra-ui-link {
      a.ra-ui-keyboard-focus .static-text {
        border: none;
        outline: $focus-rectangle-width solid $focus-color;
        text-decoration: underline;
      }

      a {
        outline: none;
      }

      a,
      a:visited {
        text-decoration: none;

        > .static-text:not([disabled='true']) {
          @include getCursor();
          color: $link-text-color;

          &:hover {
            text-decoration: underline;
            color: $link-hover-text-color;
          }

          &:active,
          &:active:hover {
            text-decoration: underline;
            color: $link-pressed-text-color;
          }
        }
      }

      &.ra-ui-inverted-mode {
        a,
        a:visited {
          > .static-text:not([disabled='true']) {
            color: $inverted-link-text-color;

            &:hover {
              color: $inverted-link-hover-text-color;
            }

            &:active,
            &:active:hover {
              color: $inverted-link-pressed-text-color;
            }
          }
        }
      }
    }

    > .static-text {
      .static-text-inner.static-text-inner-margin {
        margin-left: $text-margin;
      }
    }

    > .static-text[disabled='true'] {
      color: $disabled-color;
    }

    > .static-text-scrollbar {
      overflow-wrap: break-word;
      word-wrap: break-word;
      box-sizing: border-box;
    }

    /* fix Safari tooltip */
    > .static-text::after {
      content: '';
      display: block;
    }

    &.empty:not(.static-text-with-error-message) {
      @include firefox-only-specific() {
        display: block;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-immediate-arrow-neighbour($custom-typography) {
  &.immediate-arrow {
    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    display: inline-block;
    vertical-align: middle;
    &.mat-button-base {
      vertical-align: inherit;
    }
    &.label-container-label-text {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: middle;
      @include safari-only-specific() {
        width: calc(100% - #{$width-immediate} - #{$padding-immediate});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-immediate-arrow-width-color($theme, $custom-typography) {
  ra-ui-immediate-arrow {
    color: ra-ui-color($theme, foreground, immediate-commit);

    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(#{$width-immediate} + #{$padding-immediate} + 1px);

    mat-icon.mat-icon.ra-icon-ide-sm-immediate-commit-arrow-left {
      padding-left: $padding-immediate;
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      line-height: ra-ui-font-size($custom-typography, icon-s);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-dirty-flag-neighbour($custom-typography) {
  &.dirty-flag {
    width: calc(100% - #{$width-dirty-flag});
    display: inline-block;
    vertical-align: middle;

    &.blank-when-disabled {
      width: 100%;
    }

    &.immediate-arrow {
      $prop: get-immediate-arrow-properties($custom-typography);
      $width-immediate: map-get($prop, width);
      $padding-immediate: map-get($prop, padding);
      width: calc(
        100% - #{$width-dirty-flag} - #{$width-immediate} - #{$padding-immediate} -
          1px
      );
      &.label-container-label-text {
        vertical-align: middle;
      }
      &.blank-when-disabled {
        width: calc(100% - #{$width-immediate-arrow});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-dirty-flag-component-theme($theme) {
  ra-ui-dirty-flag[disabled='true'] {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-radio-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $active-unchecked-color: ra-ui-color($theme, background, inactive);
  $active-checked-color: ra-ui-color($theme, primary, 600);
  $active-color-hover: ra-ui-color($theme, primary, 700);
  $active-color-press: ra-ui-color($theme, primary, 800);
  $disabled-color: ra-ui-color($theme, background, disabled);
  $focus-color: ra-ui-color($theme, primary, A200);

  $inactive-color: ra-ui-color($theme, background, inactive);
  $inactive-color-hover: ra-ui-color($theme, background, inactive-hover);
  $inactive-color-press: ra-ui-color(
    $theme,
    background,
    interactive-component-07
  );

  ra-ui-radio-button {
    @include ra-ui-typography-level-to-styles($custom-typography, input);

    @include radio-button-enhancement($custom-typography);

    .ra-ui-container.vertical .label-container-label {
      @include ra-ui-typography-level-to-styles($custom-typography, input);
      padding: 0 getRelative(8, 14);
    }

    ra-ui-label-container.ra-ui-disabled-side-paddings {
      .ra-ui-container.vertical,
      .ra-ui-container.vertical .label-container-label {
        padding: 0;
      }
    }

    &.ripple-effect-disabled {
      .mat-radio-ripple {
        display: none;
      }
    }

    //disable the default safari tooltip
    mat-radio-group div::after {
      content: '';
      display: block;
    }

    &.ng-dirty .mat-radio-button.mat-radio-checked .static-text-wrapper {
      @include ra-ui-dirty-flag-neighbour($custom-typography);
    }

    .ra-ui-mat-radio-wrapper {
      $padding: getRelative(8, 14);

      height: getRelative(28, 14);
      padding: 0 $padding;

      &.ra-ui-disabled-side-paddings {
        padding: 0;
      }
    }

    .ra-ui-horizontal-direction .ra-ui-mat-radio-wrapper {
      // fixed flex item truncation when width of container is lower as width of item
      min-width: 0;
    }

    .mat-radio-button-wrapper {
      display: block;
      height: 100%;
    }

    mat-radio-button {
      max-width: 100%;

      &:not(.mat-radio-disabled) {
        .mat-radio-label {
          @include getCursor($custom-global-config);
        }
      }

      label {
        outline: none;
      }

      .mat-radio-label {
        max-width: 100%;
        height: 2em;

        .mat-radio-label-content {
          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            width: 100%;
            max-width: inherit;
          }
        }
      }

      &.mat-radio-button.rdbtn {
        height: 100%;

        .static-text-wrapper {
          @include ra-ui-immediate-arrow-neighbour($custom-typography);
        }

        .mat-radio-outer-circle {
          border-color: $active-unchecked-color;
        }

        &.mat-radio-checked {
          .mat-radio-outer-circle {
            border-color: $active-checked-color;
          }
          .mat-radio-inner-circle {
            background-color: $active-checked-color;
            @include firefox-only-specific() {
              //Ugly firefox fix for non-smooth scaling:
              //https://gielberkers.com/how-to-fix-shaking-css-transitions-in-firefox/
              transform: scale(0.5) rotate(0.1deg);
            }
          }
        }

        &:not(.mat-radio-disabled) {
          &:hover:not(:active) {
            .mat-radio-outer-circle {
              border-color: $active-color-hover;
            }

            &.mat-radio-checked {
              .mat-radio-label .mat-radio-container .mat-radio-outer-circle {
                border-color: $active-color-hover;
              }
              .mat-radio-inner-circle {
                background-color: $active-color-hover;
              }
            }
          }

          &:active {
            .mat-radio-container .mat-radio-outer-circle {
              border-color: $active-color-press;
            }

            &.mat-radio-checked {
              .mat-radio-label .mat-radio-container .mat-radio-outer-circle {
                border-color: $active-color-press;
              }
              .mat-radio-inner-circle {
                background-color: $active-color-press;
              }
            }
          }
        }

        &.mat-radio-disabled {
          .mat-radio-outer-circle,
          &.mat-radio-checked .mat-radio-outer-circle {
            border-color: $disabled-color;
          }

          .mat-radio-inner-circle {
            background-color: $disabled-color;
          }
        }
      }
    }

    &:not(.ra-ui-inactive-state) .ra-ui-mat-radio-wrapper {
      $size: getRelative(2, 14);

      &.ra-ui-keyboard-focus,
      &:active.ra-ui-keyboard-focus,
      &:hover:not(:active).ra-ui-keyboard-focus {
        &.isFocused {
          outline: $size solid $focus-color;
          outline-offset: $size;
        }
      }
    }

    //region inactive state feature rules
    &.ra-ui-inactive-state {
      .mat-radio-button-wrapper mat-radio-button.mat-radio-button.rdbtn {
        .mat-radio-outer-circle {
          border-color: $inactive-color;
        }
        .mat-radio-inner-circle {
          background-color: $inactive-color;
        }

        &.mat-radio-checked,
        &.mat-radio-checked.isFocused {
          div.mat-radio-outer-circle {
            border-color: $inactive-color;
          }
        }

        &:hover:not(:active) {
          .mat-radio-outer-circle {
            border-color: $inactive-color-hover;
          }

          &.mat-radio-checked {
            .mat-radio-container .mat-radio-outer-circle {
              border-color: $inactive-color-hover;
            }
            .mat-radio-inner-circle {
              background-color: $inactive-color-hover;
            }
          }
        }

        &:active {
          .mat-radio-label .mat-radio-container .mat-radio-outer-circle {
            border-color: $inactive-color-press;
          }

          &.mat-radio-checked,
          &.mat-radio-checked.isFocused {
            .mat-radio-label div.mat-radio-outer-circle {
              border-color: $inactive-color-press;
            }
            .mat-radio-inner-circle {
              background-color: $inactive-color-press;
            }
          }
        }
      }
    }
    //endregion

    //region readonly feature rules
    mat-radio-group.ra-ui-readonly:not(.ra-ui-disabled)
      .rdbtn.mat-radio-button.mat-radio-disabled {
      .mat-radio-outer-circle {
        border-color: ra-ui-color($theme, foreground, text);
      }
      .mat-radio-inner-circle {
        background-color: ra-ui-color($theme, foreground, text);
      }

      ra-ui-static-text {
        color: ra-ui-color($theme, foreground, text-primary);
      }
    }
    //endregion
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-activity-status-theme($theme, $custom-typography) {
  $icon-dimension: getRelative(16px, 14px);
  $padding: getRelative(8px, 14px);

  ra-ui-activity-status {
    min-width: calc(#{$icon-dimension} + #{$padding} * 2);
    padding: $padding;
    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, body-2);
    }

    .wrapper {
      @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
        &.#{$name} mat-icon {
          color: $color;
        }
      }

      mat-icon {
        @include ra-ui-typography-level-to-styles($custom-typography, icon-m);
        line-height: normal;
        width: 1em;
        height: 1em;
        cursor: default;
      }

      ra-ui-static-text {
        width: calc(100% - #{$icon-dimension} - #{$padding});
        padding-left: $padding;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-dirty-flag-neighbour($custom-typography) {
  &.dirty-flag {
    width: calc(100% - #{$width-dirty-flag});
    display: inline-block;
    vertical-align: middle;

    &.blank-when-disabled {
      width: 100%;
    }

    &.immediate-arrow {
      $prop: get-immediate-arrow-properties($custom-typography);
      $width-immediate: map-get($prop, width);
      $padding-immediate: map-get($prop, padding);
      width: calc(
        100% - #{$width-dirty-flag} - #{$width-immediate} - #{$padding-immediate} -
          1px
      );
      &.label-container-label-text {
        vertical-align: middle;
      }
      &.blank-when-disabled {
        width: calc(100% - #{$width-immediate-arrow});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-dirty-flag-component-theme($theme) {
  ra-ui-dirty-flag[disabled='true'] {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-immediate-arrow-neighbour($custom-typography) {
  &.immediate-arrow {
    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    display: inline-block;
    vertical-align: middle;
    &.mat-button-base {
      vertical-align: inherit;
    }
    &.label-container-label-text {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: middle;
      @include safari-only-specific() {
        width: calc(100% - #{$width-immediate} - #{$padding-immediate});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-immediate-arrow-width-color($theme, $custom-typography) {
  ra-ui-immediate-arrow {
    color: ra-ui-color($theme, foreground, immediate-commit);

    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(#{$width-immediate} + #{$padding-immediate} + 1px);

    mat-icon.mat-icon.ra-icon-ide-sm-immediate-commit-arrow-left {
      padding-left: $padding-immediate;
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      line-height: ra-ui-font-size($custom-typography, icon-s);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-span-input($theme) {
  ra-ui-span-input {
    .mat-focused .span-input[placeholder]:not([placeholder='']):empty:before {
      content: attr(placeholder);
      color: ra-ui-color($theme, foreground, secondary-text);
    }

    .span-input {
      height: 100%;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    @include firefox-only-specific() {
      // https://stackoverflow.com/questions/23530231/contenteditable-true-height-issue-in-firefox
      [contenteditable='true']:before {
        content: '\feff ';
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin abstract-input-theme($theme, $custom-typography) {
  $line-height: ra-ui-line-height($custom-typography, input);
  $font-size: ra-ui-font-size($custom-typography, input);
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  .ra-ui-spacing {
    width: 100%;
    height: 5px;
  }

  .inner-wrapper {
    display: inline-block;
    vertical-align: bottom;
  }

  // abstract-input-inner component styles
  .inner-container {
    color: ra-ui-color($theme, foreground, text-primary);

    * {
      user-select: text; // ag-grid overrides default value for user-select, which causes problems with selecting
    }

    &[disabled] {
      color: ra-ui-color($theme, foreground, text-disabled);

      .separator {
        color: ra-ui-color($theme, foreground, text-disabled);
      }
    }

    @include ra-ui-span-input($theme);

    .separator {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }

  //mat-form-field styles

  label.mat-form-field-label {
    width: 100%;
    height: calc($font-size * $line-height);
    pointer-events: all;

    .static-text-wrapper {
      @include ra-ui-immediate-arrow-neighbour($custom-typography);
      @include ra-ui-dirty-flag-neighbour($custom-typography);
    }

    > mat-label {
      max-width: 100%;
      width: auto;
      text-overflow: clip;
      position: absolute;
      left: 0;
    }
  }

  .mat-form-field-disabled {
    //placeholder and messages color
    &.mat-form-field {
      mat-placeholder .static-text,
      mat-label .static-text,
      mat-hint .static-text {
        color: ra-ui-color($theme, foreground, text-disabled);
      }
    }
  }

  //region readonly rules
  .ra-ui-readonly:not(.ra-ui-disabled) {
    //value color
    .inner-container {
      color: ra-ui-color($theme, foreground, text-primary);
      .separator {
        color: ra-ui-color($theme, foreground, text-primary);
      }
    }

    &.mat-form-field-appearance-legacy .mat-form-field-underline {
      display: none;
    }
    &.mat-form-field,
    &.mat-form-field.mat-form-field-invalid {
      mat-placeholder .static-text,
      mat-label .static-text,
      mat-hint .static-text,
      mat-error .static-text {
        color: ra-ui-color($theme, foreground, text-label);
      }
    }
  }
  //endregion

  .mat-form-field-can-float {
    ra-ui-dirty-flag,
    ra-ui-immediate-arrow,
    label {
      cursor: text;
    }

    &.mat-form-field-should-float {
      ra-ui-dirty-flag,
      ra-ui-immediate-arrow,
      .static-text-wrapper .static-text {
        cursor: default;
      }

      .mat-form-field-label {
        display: flex;
        align-items: center;

        > mat-label {
          width: auto;
          position: absolute;
          left: 0;
        }
      }
    }
  }

  /* input is disabled */
  .mat-form-field-disabled {
    .inner-container,
    .mat-form-field-underline,
    .mat-form-field-label,
    .static-text-wrapper .static-text {
      cursor: default;
    }
  }

  @include form-field-enhancement($theme, $custom-typography);
  @include prefix-suffix(
    $theme,
    $custom-typography,
    '.ra-ui-ip-input-prefix',
    '.ra-ui-ip-input-suffix'
  );
  @include prefix-suffix(
    $theme,
    $custom-typography,
    '.ra-ui-private-ip-input-prefix',
    '.ra-ui-private-ip-input-suffix'
  );

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      width: 100%;
    }
  }
}

@mixin ra-ui-ip-input-theme($theme, $custom-typography) {
  ra-ui-ip-input {
    @include abstract-input-theme($theme, $custom-typography);

    ra-ui-span-input {
      width: $widthSpan;
      min-width: $widthSpan;
      max-width: $widthSpan;
    }

    ra-ui-span-input.ra-ui-disabled-octet {
      color: ra-ui-color($theme, background, disabled);
    }

    .separator {
      width: $widthSeparator;
    }

    .mat-form-field-disabled {
      .ra-ui-ip-input-prefix,
      .ra-ui-ip-input-suffix {
        cursor: default;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-dirty-flag-neighbour($custom-typography) {
  &.dirty-flag {
    width: calc(100% - #{$width-dirty-flag});
    display: inline-block;
    vertical-align: middle;

    &.blank-when-disabled {
      width: 100%;
    }

    &.immediate-arrow {
      $prop: get-immediate-arrow-properties($custom-typography);
      $width-immediate: map-get($prop, width);
      $padding-immediate: map-get($prop, padding);
      width: calc(
        100% - #{$width-dirty-flag} - #{$width-immediate} - #{$padding-immediate} -
          1px
      );
      &.label-container-label-text {
        vertical-align: middle;
      }
      &.blank-when-disabled {
        width: calc(100% - #{$width-immediate-arrow});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-dirty-flag-component-theme($theme) {
  ra-ui-dirty-flag[disabled='true'] {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-immediate-arrow-neighbour($custom-typography) {
  &.immediate-arrow {
    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    display: inline-block;
    vertical-align: middle;
    &.mat-button-base {
      vertical-align: inherit;
    }
    &.label-container-label-text {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: middle;
      @include safari-only-specific() {
        width: calc(100% - #{$width-immediate} - #{$padding-immediate});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-immediate-arrow-width-color($theme, $custom-typography) {
  ra-ui-immediate-arrow {
    color: ra-ui-color($theme, foreground, immediate-commit);

    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(#{$width-immediate} + #{$padding-immediate} + 1px);

    mat-icon.mat-icon.ra-icon-ide-sm-immediate-commit-arrow-left {
      padding-left: $padding-immediate;
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      line-height: ra-ui-font-size($custom-typography, icon-s);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-input-component-theme($theme, $custom-typography) {
  ra-ui-input {
    & .static-text-wrapper {
      @include ra-ui-immediate-arrow-neighbour($custom-typography);
      @include ra-ui-dirty-flag-neighbour($custom-typography);
    }

    @include form-field-enhancement($theme, $custom-typography);
    @include prefix-suffix(
      $theme,
      $custom-typography,
      '.ra-ui-input-prefix',
      '.ra-ui-input-suffix'
    );

    //region readonly rules
    .ra-ui-readonly:not(.ra-ui-disabled) {
      //value color
      .mat-input-element:disabled {
        color: ra-ui-color($theme, foreground, text-primary);
        -webkit-text-fill-color: ra-ui-color(
          $theme,
          foreground,
          text-primary
        ); // see #967
        opacity: 1; // see #967
      }
      @include readonly-form-field-appearance($theme);
    }
    //endregion

    .mat-form-field-disabled .mat-input-element:disabled {
      color: ra-ui-color($theme, foreground, text-disabled);
      -webkit-text-fill-color: ra-ui-color(
        $theme,
        foreground,
        text-disabled
      ); // #967 - Safari/Mac fix
      opacity: 1; // #967 - Safari/iOS fix
    }

    input {
      /* removes reveal icon for password type on Edge browser */
      /* see RAIDWTUI01-1619 */
      &::-ms-reveal,
      &::-ms-clear {
        display: none;
      }
    }

    /* BEGIN cursors */

    input {
      cursor: text;
    }

    /* applied when the label floats on top  */
    .mat-form-field-can-float.mat-form-field-should-float {
      ra-ui-dirty-flag,
      ra-ui-immediate-arrow,
      .static-text-wrapper .static-text {
        cursor: default;
      }
    }

    .mat-form-field-disabled {
      .static-text-wrapper .static-text,
      input,
      .ra-ui-input-prefix,
      .ra-ui-input-suffix {
        cursor: default;
      }
    }

    /* only when the label is set to auto and textarea is empty */
    .mat-form-field-can-float {
      ra-ui-dirty-flag,
      ra-ui-immediate-arrow {
        cursor: text;
      }
    }
    /* END cursors */
  }
}

@mixin ra-ui-date-picker-today-button($theme, $custom-typography) {
  ra-ui-today {
    // get rid of dirty polygon in arrow button
    .mat-calendar-arrow polygon {
      display: none;
    }

    .ra-ui-calendar-today-button {
      position: absolute;
      bottom: getRelative(16px, 14px);
      right: getRelative(16px, 14px);
    }
  }
}

@mixin ra-ui-date-picker-calendar-theme($theme, $custom-typography) {
  @include ra-ui-date-picker-today-button($theme, $custom-typography);

  .mat-datepicker-content {
    //override showing the material close button
    .mat-datepicker-close-button {
      display: none;
    }

    .mat-datepicker-content-container.mat-datepicker-content-container-with-custom-header
      .mat-calendar {
      background-color: ra-ui-color($theme, background, card);
      width: getRelative(315px, 14px);
      height: getRelative(360px, 14px);
      box-shadow:
        0 getRelative(1px, 14px) getRelative(3px, 14px) getRelative(-1px, 14px)
          rgba(26, 27, 36, 0.12),
        0 getRelative(2px, 14px) getRelative(4px, 14px) 0 rgba(26, 27, 36, 0.08);
    }

    .ra-ui-date-picker-panel {
      @include ra-ui-typography-level-to-styles($custom-typography, body-2);

      div.mat-calendar-content {
        //Delete the line under the week abbreviation
        padding: 0;

        .mat-calendar-table-header th {
          // the week abbreviation
          &::after {
            height: 0;
          }
        }

        th.mat-calendar-table-header-divider {
          // the week abbreviation
          height: 0;
        }

        th.ng-star-inserted {
          // Monday to Friday initials
          color: ra-ui-color($theme, background, disabled);
          padding: getRelative(10.75px, 14px) 0 0 0;
          @include ra-ui-typography-level-to-styles($custom-typography, body-2);
        }

        .mat-calendar-body-cell-content {
          // color for calendar body cell
          color: ra-ui-color($theme, foreground, text3);
          @include ra-ui-typography-level-to-styles($custom-typography, body-2);
        }

        .mat-calendar-body-today:not(.mat-calendar-body-selected) {
          border-color: ra-ui-color($theme, primary, 600);
          border-width: getRelative(2px, 14px);
          color: ra-ui-color($theme, foreground, text3);
        }

        .mat-calendar-body-today.mat-calendar-body-selected {
          background-color: ra-ui-color($theme, primary, 600);
          color: ra-ui-contrast($theme, primary, 600);
          box-shadow: inset 0 0 0 0px white;
        }

        div.mat-calendar-body-selected {
          background-color: ra-ui-color($theme, primary, 600);
          color: ra-ui-contrast($theme, primary, 600);
          box-shadow: inset 0 0 0 0px white;
        }

        td.mat-calendar-body-label {
          // delete the month shown
          font-size: 0;
          padding: 0 !important;

          .ng-star-inserted {
            font-size: 0;
          }
        }

        div.mat-calendar-controls {
          // delete the month shown
          margin: 0;
        }

        .mat-calendar-body-disabled {
          .mat-calendar-body-cell-content {
            // color for calendar body cell
            color: ra-ui-color($theme, foreground, disabled-text);
          }
        }
      }

      .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover,
      .cdk-keyboard-focused .mat-calendar-body-active,
      .cdk-program-focused .mat-calendar-body-active {
        & > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
          background-color: ra-ui-color($theme, primary, 200);
        }
      }

      div.mat-calendar-header {
        padding: getRelative(9px, 14px) getRelative(16px, 14px) 0
          getRelative(6px, 14px);
        @include ra-ui-typography-level-to-styles($custom-typography, body-2);

        .mat-calendar-controls {
          margin: 0;
        }

        button.mat-calendar-period-button.mat-mdc-button.mat-mdc-button-base {
          // month and year shown in calendar-header
          @include ra-ui-typography-level-to-styles(
            $custom-typography,
            body-2-bold
          );
          padding-left: getRelative(10px, 14px);
          padding-right: getRelative(23px, 14px);
          color: ra-ui-color($theme, foreground, text3);
          margin: 0; //since angular-mat 15
          height: getRelative(29.156, 14); //since angular-mat 15
        }

        .mat-calendar-arrow {
          //  down arrow in calendar-header
          @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
          display: inline-block;
          position: absolute;
          top: getRelative(4px, 12px); //since angular-mat 15
          border: none;
          border-top-width: getRelative(2px, 12px);
          border-top-style: solid;
          border-left-width: getRelative(2px, 12px);
          border-left-style: solid;
          width: getRelative(6px, 12px);
          height: getRelative(6px, 12px);
          border-color: ra-ui-color($theme, foreground, icon);
          transform: rotate(-135deg);
          margin-left: getRelative(5px, 12px);
        }

        div.mat-calendar-arrow.mat-calendar-invert {
          margin-top: getRelative(4px, 12px);
          transform: rotate(45deg);
        }

        .mat-calendar-next-button::after,
        .mat-calendar-previous-button::after {
          //  left right arrow in calendar-header
          @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
          display: inline-block;
          position: inherit;
          width: 0;
          height: 0;
          margin: 0;
          vertical-align: middle;
          border-top-width: getRelative(8px, 12px);
          border-top-style: solid;
          border-top-color: ra-ui-color($theme, foreground, icon);
        }

        .mat-calendar-next-button::after {
          border-left: 0;
          border-right: getRelative(8px, 12px) solid transparent;
          transform: rotate(135deg);
          left: getRelative(-2px, 12px);
        }

        .mat-calendar-previous-button::after {
          border-left: getRelative(8px, 12px) solid transparent;
          border-right: 0;
          border-right-width: getRelative(2px, 12px);
          transform: rotate(225deg);
          left: getRelative(2px, 12px);
        }

        button.mat-calendar-next-button.mat-mdc-icon-button.mat-mdc-button-base,
        button.mat-calendar-previous-button.mat-mdc-icon-button.mat-mdc-button-base {
          @include ra-ui-typography-level-to-styles(
            $custom-typography,
            body-2-bold
          ); //since angular-mat 15
          width: getRelative(15px, 12px);
          margin-top: getRelative(5px, 12px);
          height: getRelative(20px, 12px);
          line-height: getRelative(20px, 12px);
          border-radius: $classic-border-radius;
          padding: 0; //since angular-mat 15

          span {
            border-radius: inherit; //since angular-mat 15
          }

          button.mat-calendar-next-button.mat-mdc-icon-button.mat-mdc-button-base {
            top: getRelative(3px, 12px);
          }
        }

        .mat-calendar-next-button[disabled]::after,
        .mat-calendar-previous-button[disabled]::after {
          // left/right arrow in calendar-header when out of range
          border-top-color: ra-ui-color($theme, foreground, disabled-button);
        }
      }
    }
  }

  // Eliminate ripple effects
  .date-picker-ripple-effect-disabled {
    .mat-mdc-button-ripple {
      display: none;
    }
  }
}

@mixin ra-ui-date-picker-theme($theme, $custom-typography) {
  @include ra-ui-date-picker-calendar-theme($theme, $custom-typography);

  ra-ui-date-picker {
    &.ra-ui-fill-parent ra-ui-input {
      width: 100%;
    }

    ra-ui-input {
      .ra-ui-input-suffix {
        @include ra-ui-typography-level-to-styles($custom-typography, icon-m);
        width: getRelative(21px, 16px);
        height: getRelative(21px, 16px);
        padding: 0;

        .ra-ui-icon-button {
          height: getRelative(21px, 16px);

          button {
            padding: 0;
          }
        }
      }

      .mat-form-field-infix {
        input {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      .mat-icon-button.default-color:not(.is-disabled) mat-icon {
        color: ra-ui-color($theme, foreground, text3);
      }
    }

    .ra-ui-hidden-input {
      visibility: hidden;
      width: 0;
      position: absolute;
      border: 0;
      padding: 0;
    }
  }

  // Click the calendar icon and change the color dynamically
  .ra-ui-calendar-icon-color {
    .mat-icon-button {
      &.default-color:not(.is-disabled) mat-icon {
        color: ra-ui-color($theme, primary, 600);
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-dirty-flag-neighbour($custom-typography) {
  &.dirty-flag {
    width: calc(100% - #{$width-dirty-flag});
    display: inline-block;
    vertical-align: middle;

    &.blank-when-disabled {
      width: 100%;
    }

    &.immediate-arrow {
      $prop: get-immediate-arrow-properties($custom-typography);
      $width-immediate: map-get($prop, width);
      $padding-immediate: map-get($prop, padding);
      width: calc(
        100% - #{$width-dirty-flag} - #{$width-immediate} - #{$padding-immediate} -
          1px
      );
      &.label-container-label-text {
        vertical-align: middle;
      }
      &.blank-when-disabled {
        width: calc(100% - #{$width-immediate-arrow});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-dirty-flag-component-theme($theme) {
  ra-ui-dirty-flag[disabled='true'] {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-immediate-arrow-neighbour($custom-typography) {
  &.immediate-arrow {
    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    display: inline-block;
    vertical-align: middle;
    &.mat-button-base {
      vertical-align: inherit;
    }
    &.label-container-label-text {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: middle;
      @include safari-only-specific() {
        width: calc(100% - #{$width-immediate} - #{$padding-immediate});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-immediate-arrow-width-color($theme, $custom-typography) {
  ra-ui-immediate-arrow {
    color: ra-ui-color($theme, foreground, immediate-commit);

    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(#{$width-immediate} + #{$padding-immediate} + 1px);

    mat-icon.mat-icon.ra-icon-ide-sm-immediate-commit-arrow-left {
      padding-left: $padding-immediate;
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      line-height: ra-ui-font-size($custom-typography, icon-s);
    }
  }
}

@mixin ra-ui-select-inner($theme, $custom-typography) {
  $input-font-size: mat.font-size($custom-typography, input);
  $input-line-height: mat.line-height($custom-typography, input);
  $foreground: map_get($theme, foreground);

  ra-ui-select-inner {
    overflow: hidden;

    .inner-select {
      height: calc($input-font-size * $input-line-height);

      .ra-ui-custom-template {
        height: 100%;
      }

      .select-arrow {
        @include ra-ui-typography-level-to-styles($custom-typography, icon-s);

        $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
        $font-size-input: ra-ui-font-size($custom-typography, input);
        margin-left: $arrow-padding;
        color: mat.get-color-from-palette($foreground, text-primary);
        position: absolute;
        right: 0;
        // a arrow is aligned to right-bottom
        bottom: 0px;
      }

      //fixes height when value is empty
      .inner-select-value:after {
        content: '';
        display: inline-block;
      }

      &:focus {
        outline: 0 solid transparent;
      }

      .mat-icon {
        width: $input-font-size;
        height: $input-font-size;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-select-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $text: ra-ui-color($theme, foreground, text);

  // this is outside of ra-ui-select element
  mat-option.mat-selected.mat-active .static-text {
    color: ra-ui-color($theme, primary);
  }

  ra-ui-select {
    @include ra-ui-select-inner($theme, $custom-typography);
    mat-form-field:not(.mat-form-field-disabled) {
      .mat-form-field-label,
      .mat-form-field-subscript-wrapper,
      .mat-select .mat-select-trigger,
      .mat-form-field-infix {
        @include getCursor($custom-global-config);
      }
    }

    mat-form-field.mat-form-field-disabled {
      .mat-form-field-label,
      .mat-form-field-subscript-wrapper,
      .mat-select .mat-select-trigger,
      .mat-form-field-infix {
        cursor: default;
      }
    }

    .mat-form-field.custom-item-height .mat-form-field-infix {
      height: auto;
    }

    .inner-select {
      font-size: mat.font-size($custom-typography, button);

      //need more specific rule for overriding style
      &,
      ra-ui-static-text.inner-select-value.ra-ui-placeholder {
        @include select-label-width($custom-typography);
        position: static;
      }
    }

    .disabled {
      ra-ui-select-inner .inner-select .select-arrow {
        color: ra-ui-color($theme, foreground, text-disabled);
      }

      .inner-select-value {
        .static-text-inner {
          cursor: default;
        }
      }
    }

    .inner-select-value {
      .static-text-inner {
        @include getCursor($custom-global-config);
      }
    }

    .mat-form-field-type-mat-select .mat-form-field-label {
      @include select-label-width($custom-typography);
    }

    &.ng-dirty .static-text-wrapper {
      @include ra-ui-dirty-flag-neighbour($custom-typography);
    }

    @include form-field-enhancement($theme, $custom-typography);
    .mat-form-field-appearance-legacy .mat-form-field-label {
      @include select-label-width($custom-typography);
    }

    .static-text-wrapper {
      @include ra-ui-immediate-arrow-neighbour($custom-typography);
    }

    mat-label {
      outline: none;
      ra-ui-static-text {
        display: block;
      }
    }

    //region readonly rules
    .ra-ui-readonly:not(.disabled) {
      //value color and cursor
      ra-ui-select-inner .static-text {
        color: ra-ui-color($theme, foreground, text-primary);

        .static-text-inner {
          cursor: default;
        }
      }

      .select-arrow {
        display: none;
      }

      @include readonly-form-field-appearance($theme);
    }
    //endregion
  }
}

@mixin ra-ui-spinner-input-theme($theme, $custom-typography) {
  ra-ui-spinner-input {
    @include ra-ui-typography-level-to-styles($custom-typography, input);

    .focused {
      .mat-form-field-ripple {
        opacity: 1;
        transform: scaleX(1);
        transition:
          transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
          opacity 0.1s cubic-bezier(0.25, 0.8, 0.25, 1),
          background-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      }
      .mat-form-field:not(.mat-focused):not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .static-text,
        ra-ui-dirty-flag {
          color: ra-ui-color($theme, primary, 600);
        }
        .mat-form-field-underline .mat-form-field-ripple {
          background-color: ra-ui-color($theme, primary, 600);
        }
      }
    }

    .mat-icon-button[disabled] .mat-icon {
      color: ra-ui-color($theme, foreground, disabled-text);
    }

    mat-form-field.ra-ui-readonly:not(.ra-ui-disabled) .ra-ui-input-suffix {
      .button-left,
      .button-right {
        display: none;
      }
    }

    ra-ui-input ra-ui-prefix-suffix .ra-ui-input-prefix {
      padding-right: 0;

      ra-ui-prefix-suffix .ra-ui-spinner-input-prefix {
        // set the padding to correct element, label margin is .5em (8px)
        padding-right: $suffix-prefix-padding;
        display: inline-flex;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-dirty-flag-neighbour($custom-typography) {
  &.dirty-flag {
    width: calc(100% - #{$width-dirty-flag});
    display: inline-block;
    vertical-align: middle;

    &.blank-when-disabled {
      width: 100%;
    }

    &.immediate-arrow {
      $prop: get-immediate-arrow-properties($custom-typography);
      $width-immediate: map-get($prop, width);
      $padding-immediate: map-get($prop, padding);
      width: calc(
        100% - #{$width-dirty-flag} - #{$width-immediate} - #{$padding-immediate} -
          1px
      );
      &.label-container-label-text {
        vertical-align: middle;
      }
      &.blank-when-disabled {
        width: calc(100% - #{$width-immediate-arrow});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-dirty-flag-component-theme($theme) {
  ra-ui-dirty-flag[disabled='true'] {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-immediate-arrow-neighbour($custom-typography) {
  &.immediate-arrow {
    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    display: inline-block;
    vertical-align: middle;
    &.mat-button-base {
      vertical-align: inherit;
    }
    &.label-container-label-text {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: middle;
      @include safari-only-specific() {
        width: calc(100% - #{$width-immediate} - #{$padding-immediate});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-immediate-arrow-width-color($theme, $custom-typography) {
  ra-ui-immediate-arrow {
    color: ra-ui-color($theme, foreground, immediate-commit);

    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(#{$width-immediate} + #{$padding-immediate} + 1px);

    mat-icon.mat-icon.ra-icon-ide-sm-immediate-commit-arrow-left {
      padding-left: $padding-immediate;
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      line-height: ra-ui-font-size($custom-typography, icon-s);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-span-input($theme) {
  ra-ui-span-input {
    .mat-focused .span-input[placeholder]:not([placeholder='']):empty:before {
      content: attr(placeholder);
      color: ra-ui-color($theme, foreground, secondary-text);
    }

    .span-input {
      height: 100%;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    @include firefox-only-specific() {
      // https://stackoverflow.com/questions/23530231/contenteditable-true-height-issue-in-firefox
      [contenteditable='true']:before {
        content: '\feff ';
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin abstract-input-theme($theme, $custom-typography) {
  $line-height: ra-ui-line-height($custom-typography, input);
  $font-size: ra-ui-font-size($custom-typography, input);
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  .ra-ui-spacing {
    width: 100%;
    height: 5px;
  }

  .inner-wrapper {
    display: inline-block;
    vertical-align: bottom;
  }

  // abstract-input-inner component styles
  .inner-container {
    color: ra-ui-color($theme, foreground, text-primary);

    * {
      user-select: text; // ag-grid overrides default value for user-select, which causes problems with selecting
    }

    &[disabled] {
      color: ra-ui-color($theme, foreground, text-disabled);

      .separator {
        color: ra-ui-color($theme, foreground, text-disabled);
      }
    }

    @include ra-ui-span-input($theme);

    .separator {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }

  //mat-form-field styles

  label.mat-form-field-label {
    width: 100%;
    height: calc($font-size * $line-height);
    pointer-events: all;

    .static-text-wrapper {
      @include ra-ui-immediate-arrow-neighbour($custom-typography);
      @include ra-ui-dirty-flag-neighbour($custom-typography);
    }

    > mat-label {
      max-width: 100%;
      width: auto;
      text-overflow: clip;
      position: absolute;
      left: 0;
    }
  }

  .mat-form-field-disabled {
    //placeholder and messages color
    &.mat-form-field {
      mat-placeholder .static-text,
      mat-label .static-text,
      mat-hint .static-text {
        color: ra-ui-color($theme, foreground, text-disabled);
      }
    }
  }

  //region readonly rules
  .ra-ui-readonly:not(.ra-ui-disabled) {
    //value color
    .inner-container {
      color: ra-ui-color($theme, foreground, text-primary);
      .separator {
        color: ra-ui-color($theme, foreground, text-primary);
      }
    }

    &.mat-form-field-appearance-legacy .mat-form-field-underline {
      display: none;
    }
    &.mat-form-field,
    &.mat-form-field.mat-form-field-invalid {
      mat-placeholder .static-text,
      mat-label .static-text,
      mat-hint .static-text,
      mat-error .static-text {
        color: ra-ui-color($theme, foreground, text-label);
      }
    }
  }
  //endregion

  .mat-form-field-can-float {
    ra-ui-dirty-flag,
    ra-ui-immediate-arrow,
    label {
      cursor: text;
    }

    &.mat-form-field-should-float {
      ra-ui-dirty-flag,
      ra-ui-immediate-arrow,
      .static-text-wrapper .static-text {
        cursor: default;
      }

      .mat-form-field-label {
        display: flex;
        align-items: center;

        > mat-label {
          width: auto;
          position: absolute;
          left: 0;
        }
      }
    }
  }

  /* input is disabled */
  .mat-form-field-disabled {
    .inner-container,
    .mat-form-field-underline,
    .mat-form-field-label,
    .static-text-wrapper .static-text {
      cursor: default;
    }
  }

  @include form-field-enhancement($theme, $custom-typography);
  @include prefix-suffix(
    $theme,
    $custom-typography,
    '.ra-ui-ip-input-prefix',
    '.ra-ui-ip-input-suffix'
  );
  @include prefix-suffix(
    $theme,
    $custom-typography,
    '.ra-ui-private-ip-input-prefix',
    '.ra-ui-private-ip-input-suffix'
  );

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      width: 100%;
    }
  }
}

@mixin ra-ui-private-ip-input-theme($theme, $custom-typography) {
  $disabled-text: ra-ui-color($theme, foreground, disabled-text);
  $secondary-text: ra-ui-color($theme, foreground, secondary-text);

  ra-ui-private-ip-input {
    @include abstract-input-theme($theme, $custom-typography);

    .prefix,
    ra-ui-span-input {
      width: $widthSpan;
      min-width: $widthSpan;
      max-width: $widthSpan;
    }

    .prefix-separator {
      width: $widthSeparator;
    }

    .prefix,
    .prefix-separator {
      color: $secondary-text;
      outline: none;
    }

    .mat-form-field-disabled {
      .inner-container > .prefix,
      .prefix + .prefix-separator {
        color: $disabled-text;
      }

      .ra-ui-private-ip-input-prefix,
      .ra-ui-private-ip-input-suffix {
        cursor: default;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-dynamic-form-category-theme($custom-typography) {
  $title-description-margin-bottom: 8;
  $body-1-font-size: 12;

  ra-ui-dynamic-form-category {
    $header-height: getHeightAccordionHeader($custom-typography);

    &.fill-height .accordion-header + div {
      height: calc(100% - #{$header-height});
    }

    // description on category will take 100% width and corresponding margin-bottom
    .dynamic-form-description {
      width: 100%;
      @include ra-ui-typography-level-to-styles($custom-typography, body-1);
      margin-bottom: getRelative(
        $title-description-margin-bottom,
        $body-1-font-size
      );

      // body-1 for category description
      ra-ui-static-text .static-text {
        @include ra-ui-typography-level-to-styles($custom-typography, body-1);
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-dynamic-form-area-theme($custom-typography) {
  $title-description-margin-bottom: 8;
  $body-1-font-size: 12;

  ra-ui-dynamic-form-area {
    // title and description on area will take 100% width and corresponding margin-bottom
    .dynamic-form-area-title,
    .dynamic-form-description {
      width: 100%;
      @include ra-ui-typography-level-to-styles($custom-typography, body-1);
      margin-bottom: getRelative(
        $title-description-margin-bottom,
        $body-1-font-size
      );
    }

    // body-1 for area description
    .dynamic-form-description ra-ui-static-text .static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    }

    // subheading-1 for the area title
    .dynamic-form-area-title ra-ui-static-text .static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        subheading-1
      );
    }
  }
}

@mixin ra-ui-chips-switch-panel-theme($body-1-font-size) {
  ra-ui-chips-switch-panel {
    .chip-list-container {
      gap: getRelative(4, $body-1-font-size);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-dynamic-form-property-theme($custom-typography) {
  ra-ui-dynamic-form-property {
    $header-height: getHeightAccordionHeader($custom-typography);
    $body-1-font-size: 12;
    $property-margin-bottom: 8;

    &.fill-height .accordion-header + div {
      height: calc(100% - #{$header-height});
    }

    .simple-property-cell-margin {
      @include ra-ui-typography-level-to-styles($custom-typography, body-1);
      margin-bottom: getRelative($property-margin-bottom, $body-1-font-size);
    }

    .simple-property-cell {
      .editable-icons-visible {
        @include ra-ui-typography-level-to-styles($custom-typography, button);
        // recalculating the width using typography value of default icon button - icon-l
        // subtracted 1px fixes differences in rounding
        width: calc(
          100% - #{ra-ui-font-size($custom-typography, icon-l)} - #{2 * $large-icon-button-padding} -
            1px
        );
        display: inline-block;
      }
      .ra-ui-dynamic-form-suppress-button {
        display: inline-block;
      }
    }
  }
}

@mixin mat-chips-override($custom-typography) {
  $mat-chip-min-height: 2em; //32px;
  $mat-chip-vertical-padding: 0.4375em; //7px;
  $mat-chip-horizontal-padding: 0.75em; //12px;

  $mat-chip-remove-vertical-padding: 0.4375em; //7px;
  $mat-chip-remove-before-margin: 0.5em; //8px;
  $mat-chip-remove-after-padding: 0.5em; //8px;

  $mat-chip-avatar-vertical-padding: 0;
  $mat-chip-avatar-before-padding: 0;
  $mat-chip-avatar-before-margin: 0.25m; //4px;
  $mat-chip-avatar-after-margin: 0.5em; //8px;

  $mat-chips-chip-margin: 0.25em; //4px;

  $mat-chip-input-width: 9.375em; //150px;
  $mat-chip-input-margin: 0.25em; //4px;

  $mat-chip-avatar-size: 1.5em; //24px;
  $mat-chip-remove-size: 1.125em; //18px;

  .chip-list-container {
    .mat-chip,
    .mat-chip-list-wrapper {
      @include mat.typography-level($custom-typography, input);
    }

    .mat-chip .mat-chip-remove.mat-icon,
    .mat-chip .mat-chip-trailing-icon.mat-icon {
      font-size: mat.font-size($custom-typography, input);
    }

    .mat-standard-chip {
      @include mat.elevation-transition();
      padding: $mat-chip-vertical-padding $mat-chip-horizontal-padding;
      border-radius: 1em; //16px;
      min-height: $mat-chip-min-height;

      .mat-chip-remove.mat-icon {
        width: $mat-chip-remove-size;
        height: $mat-chip-remove-size;
        line-height: $mat-chip-remove-size;
      }

      &.mat-chip-with-trailing-icon.mat-chip-with-avatar,
      &.mat-chip-with-avatar {
        padding-top: $mat-chip-avatar-vertical-padding;
        padding-bottom: $mat-chip-avatar-vertical-padding;
      }

      &.mat-chip-with-trailing-icon.mat-chip-with-avatar {
        padding-right: $mat-chip-remove-after-padding;
        padding-left: $mat-chip-avatar-before-padding;

        [dir='rtl'] & {
          padding-left: $mat-chip-remove-after-padding;
          padding-right: $mat-chip-avatar-before-padding;
        }
      }

      &.mat-chip-with-trailing-icon {
        padding-top: $mat-chip-remove-vertical-padding;
        padding-bottom: $mat-chip-remove-vertical-padding;
        padding-right: $mat-chip-remove-after-padding;
        padding-left: $mat-chip-horizontal-padding;

        [dir='rtl'] & {
          padding-left: $mat-chip-remove-after-padding;
          padding-right: $mat-chip-horizontal-padding;
        }
      }

      &.mat-chip-with-avatar {
        padding-left: $mat-chip-avatar-before-padding;
        padding-right: $mat-chip-horizontal-padding;

        [dir='rtl'] & {
          padding-right: $mat-chip-avatar-before-padding;
          padding-left: $mat-chip-horizontal-padding;
        }
      }

      .mat-chip-avatar {
        width: $mat-chip-avatar-size;
        height: $mat-chip-avatar-size;
        margin-right: $mat-chip-avatar-after-margin;
        margin-left: $mat-chip-avatar-before-margin;

        [dir='rtl'] & {
          margin-left: $mat-chip-avatar-after-margin;
          margin-right: $mat-chip-avatar-before-margin;
        }
      }

      .mat-chip-remove,
      .mat-chip-trailing-icon {
        width: $mat-chip-remove-size;
        height: $mat-chip-remove-size;
      }

      .mat-chip-remove,
      .mat-chip-trailing-icon {
        margin-left: $mat-chip-remove-before-margin;

        [dir='rtl'] & {
          margin-right: $mat-chip-remove-before-margin;
        }
      }
    }

    .mat-chip-list-wrapper {
      margin: -$mat-chips-chip-margin;

      input.mat-input-element,
      .mat-standard-chip {
        margin: $mat-chips-chip-margin;
      }
    }

    input.mat-chip-input {
      width: $mat-chip-input-width;
      margin: $mat-chip-input-margin;
      flex: 1 0 $mat-chip-input-width;
    }
  }
}

@mixin ra-ui-dynamic-form-theme($theme, $custom-typography) {
  $background-color: transparent;
  $column-margin-right: 8;
  $title-description-margin-bottom: 8;
  $category-margin-top: 16;
  $body-1-font-size: 12;

  ra-ui-dynamic-form {
    background-color: $background-color;

    // every building block in dynamic form can be wrapped to another row
    .dynamic-form-wrappable-item {
      // we are setting margin right to all building blocks
      // the value of margin is rounded down to one decimal place after point
      // because of inconsistent browser behavior #RAIDWTUI01-552
      @include ra-ui-typography-level-to-styles($custom-typography, body-1);
      $application-font-size-px: strip-unit(
        ra-ui-font-size($custom-typography, body-1)
      );
      // sass floor() function cannot work with css variables. The '* 16' converts 0.75rem to 12px.
      $application-font-size-px: if(
        type-of($application-font-size-px) == string,
        strip-unit(rafl.$rafl-typography-body-sm-font-size) * 16,
        $application-font-size-px
      );
      $flourish-relative-size-em: strip-unit(
        getRelative($column-margin-right, $body-1-font-size)
      );
      $margin-right-px: $application-font-size-px * $flourish-relative-size-em;

      $normalized-margin-right-px: floor($margin-right-px * 10) * 0.1;
      margin-right: #{$normalized-margin-right-px}px;

      // every directly wrapped category should have margin-top
      &.wrapped > ra-ui-dynamic-form-category > .category-wrapper {
        @include ra-ui-typography-level-to-styles($custom-typography, body-1);
        margin-top: getRelative($category-margin-top, $body-1-font-size);
      }

      // those whose are not directly wrapped but some of the parents are
      // needs to have margin top too
      &.wrapped ra-ui-dynamic-form-category:first-child > .category-wrapper {
        @include ra-ui-typography-level-to-styles($custom-typography, body-1);
        margin-top: getRelative($category-margin-top, $body-1-font-size);
      }

      // those building blocks that are the last item on rows and they are sticked with the right border to its parrent
      // does not need the margin right
      &.no-margin-right {
        margin-right: 0;
      }
    }

    @include ra-ui-dynamic-form-category-theme($custom-typography);
    @include ra-ui-dynamic-form-area-theme($custom-typography);
    @include ra-ui-chips-switch-panel-theme($body-1-font-size);
    @include ra-ui-dynamic-form-property-theme($custom-typography);

    @include mat-chips-override($custom-typography);

    // we need to restyle all accordions in dynamic form
    .dynamic-form-accordion .accordion-header {
      padding: 0; // overrides padding
      @include ra-ui-typography-level-to-styles($custom-typography, body-1);
      margin-bottom: getRelative(
        $title-description-margin-bottom,
        $body-1-font-size
      );

      ra-ui-static-text .static-text {
        @include ra-ui-typography-level-to-styles(
          $custom-typography,
          subheading-2
        );
        color: ra-ui-color($theme, foreground, text-primary); // overrides color
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin static-text-component-theme($theme, $custom-typography) {
  // region color variables
  $text-color: dtu.rafl-color(color-foreground-primary, $theme);
  $link-text-color: dtu.rafl-color(color-state-active-default, $theme);
  $link-hover-text-color: dtu.design-token-var(
    rafl-color-state-active-hovered,
    rafl.$rafl-color-state-active-hovered
  );
  $link-pressed-text-color: dtu.rafl-color(color-state-active-pressed, $theme);
  $inverted-text-color: dtu.rafl-color(color-foreground-inverted, $theme);
  $inverted-link-text-color: dtu.rafl-color(
    color-state-active-inverted-default,
    $theme
  );
  $inverted-link-hover-text-color: dtu.design-token-var(
    rafl-color-state-active-inverted-hovered,
    rafl.$rafl-color-state-active-inverted-hovered
  );
  $inverted-link-pressed-text-color: dtu.rafl-color(
    color-state-active-inverted-pressed,
    $theme
  );
  $focus-color: dtu.rafl-color(color-state-focused, $theme);
  $disabled-color: dtu.rafl-color(color-state-disabled-default, $theme);
  $disabled-inverted-color: dtu.rafl-color(
    color-state-disabled-inverted-default,
    $theme
  );
  // endregion

  $error-icon-margin-fallback: getRelative(
    4,
    if(
      unit(rafl.$rafl-typography-body-sm-font-size) == 'rem',
      rafl.$rafl-typography-body-sm-font-size * 16,
      rafl.$rafl-typography-body-sm-font-size
    )
  );
  // endregion

  $focus-rectangle-width: getRelative(rafl.$rafl-dimension-stroke-md, 12);
  $icon-left-padding: dtu.rafl-dimension(gap-md, $label-padding-right);
  $text-margin: dtu.rafl-dimension(gap-sm, $error-icon-margin-fallback);

  ra-ui-static-text.static-text-with-error-message {
    display: flex;

    .mat-icon {
      padding-left: $icon-left-padding;
    }
  }

  h1 ra-ui-static-text {
    @include dtu.rafl-typography(title-lg, $custom-typography);
  }
  h2 ra-ui-static-text {
    @include dtu.rafl-typography(title-md, $custom-typography);
  }
  h3 ra-ui-static-text {
    @include dtu.rafl-typography(title-sm, $custom-typography);
  }
  h4 ra-ui-static-text {
    @include dtu.rafl-typography(title-xs, $custom-typography);
  }

  b ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1-bold);
  }

  ra-ui-static-text {
    @include dtu.rafl-typography(body-sm, $custom-typography);

    color: $text-color;

    &.ra-ui-inverted-mode {
      color: $inverted-text-color;

      > .static-text[disabled='true'] {
        color: $disabled-inverted-color;
      }
    }

    &.ra-ui-link {
      a.ra-ui-keyboard-focus .static-text {
        border: none;
        outline: $focus-rectangle-width solid $focus-color;
        text-decoration: underline;
      }

      a {
        outline: none;
      }

      a,
      a:visited {
        text-decoration: none;

        > .static-text:not([disabled='true']) {
          @include getCursor();
          color: $link-text-color;

          &:hover {
            text-decoration: underline;
            color: $link-hover-text-color;
          }

          &:active,
          &:active:hover {
            text-decoration: underline;
            color: $link-pressed-text-color;
          }
        }
      }

      &.ra-ui-inverted-mode {
        a,
        a:visited {
          > .static-text:not([disabled='true']) {
            color: $inverted-link-text-color;

            &:hover {
              color: $inverted-link-hover-text-color;
            }

            &:active,
            &:active:hover {
              color: $inverted-link-pressed-text-color;
            }
          }
        }
      }
    }

    > .static-text {
      .static-text-inner.static-text-inner-margin {
        margin-left: $text-margin;
      }
    }

    > .static-text[disabled='true'] {
      color: $disabled-color;
    }

    > .static-text-scrollbar {
      overflow-wrap: break-word;
      word-wrap: break-word;
      box-sizing: border-box;
    }

    /* fix Safari tooltip */
    > .static-text::after {
      content: '';
      display: block;
    }

    &.empty:not(.static-text-with-error-message) {
      @include firefox-only-specific() {
        display: block;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-immediate-arrow-neighbour($custom-typography) {
  &.immediate-arrow {
    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    display: inline-block;
    vertical-align: middle;
    &.mat-button-base {
      vertical-align: inherit;
    }
    &.label-container-label-text {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: middle;
      @include safari-only-specific() {
        width: calc(100% - #{$width-immediate} - #{$padding-immediate});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-immediate-arrow-width-color($theme, $custom-typography) {
  ra-ui-immediate-arrow {
    color: ra-ui-color($theme, foreground, immediate-commit);

    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(#{$width-immediate} + #{$padding-immediate} + 1px);

    mat-icon.mat-icon.ra-icon-ide-sm-immediate-commit-arrow-left {
      padding-left: $padding-immediate;
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      line-height: ra-ui-font-size($custom-typography, icon-s);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-dirty-flag-neighbour($custom-typography) {
  &.dirty-flag {
    width: calc(100% - #{$width-dirty-flag});
    display: inline-block;
    vertical-align: middle;

    &.blank-when-disabled {
      width: 100%;
    }

    &.immediate-arrow {
      $prop: get-immediate-arrow-properties($custom-typography);
      $width-immediate: map-get($prop, width);
      $padding-immediate: map-get($prop, padding);
      width: calc(
        100% - #{$width-dirty-flag} - #{$width-immediate} - #{$padding-immediate} -
          1px
      );
      &.label-container-label-text {
        vertical-align: middle;
      }
      &.blank-when-disabled {
        width: calc(100% - #{$width-immediate-arrow});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-dirty-flag-component-theme($theme) {
  ra-ui-dirty-flag[disabled='true'] {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

$disabled-opacity: 0.38;

@mixin blank-when-disabled-slide-toggle($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    //uncheck the slide-toggle
    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
      transform: translate3d(0, 0, 0);
    }
  }
}

@mixin override-slide-toggle-material($theme, $custom-typography) {
  $border-width: getRelative(1, 14);
  $disabled-bar-border-color: ra-ui-color($theme, foreground, text-disabled);
  $disabled-thumb-background-color: ra-ui-color($theme, background, disabled);
  $basic-color: ra-ui-color($theme, background, interactive-component-01);

  $relative-drift-of-thumb: getRelative(12, 14);

  .mat-slide-toggle.slide-toggle.mat-disabled,
  .mat-slide-toggle.slide-toggle.mat-disabled.mat-checked {
    opacity: 1;

    .mat-slide-toggle-label {
      .mat-slide-toggle-bar {
        background-color: $basic-color;
        border: $border-width solid $disabled-bar-border-color;
      }

      .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
        border: $border-width solid $basic-color;
        background-color: $disabled-thumb-background-color;
      }
    }
  }

  .mat-slide-toggle .mat-slide-toggle-ripple {
    // 17.5px is size of slide toggle after calculated from base size.
    @include container-size(
      $custom-typography,
      $default-slide-toggle-width,
      17.5px
    );
    margin-left: 0;
  }

  .mat-slide-toggle-label {
    cursor: inherit;
  }

  .mat-slide-toggle-bar {
    border-radius: 0.5625em;
    margin-right: 0;
    width: getRelative(28, 14);
    height: getRelative(12, 14);
  }

  ra-ui-static-text {
    @include mat.typography-level($custom-typography, input);
  }

  .mat-slide-toggle-persistent-ripple {
    transform: none;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(0.875em, 0, 0);
  }

  .mat-slide-toggle-thumb {
    box-shadow: none;
  }
}

@mixin slide-toggle-enhancement($theme, $custom-typography) {
  @include override-slide-toggle-material($theme, $custom-typography);
  @include blank-when-disabled-slide-toggle($theme, $custom-typography);
}

@mixin slide-toggle-set-colors(
  $theme,
  $basic-color,
  $pressed-color,
  $hover-color,
  $thumb-border-color,
  $pressed-thumb-border-color,
  $hover-thumb-border-color
) {
  $border-width: getRelative(1, 14);
  $bar-background-color: ra-ui-color(
    $theme,
    background,
    interactive-component-01
  );

  .mat-slide-toggle-thumb {
    background-color: $basic-color;
    border: $border-width solid $thumb-border-color;
  }

  .mat-slide-toggle-bar {
    border: $border-width solid $basic-color;
    background-color: $bar-background-color;
  }

  &:active,
  &:active:hover {
    .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
      background-color: $pressed-color;
      border: $border-width solid $pressed-thumb-border-color;
    }
    .mat-slide-toggle-bar {
      border: $border-width solid $pressed-color;
    }
  }

  &:hover {
    .mat-slide-toggle-thumb {
      background-color: $hover-color;
      border: $border-width solid $hover-thumb-border-color;
    }
    .mat-slide-toggle-bar {
      border: $border-width solid $hover-color;
    }
  }
}

@mixin set-status-colors($theme, $status-color) {
  .mat-slide-toggle:not(.mat-disabled).mat-checked {
    .mat-slide-toggle-thumb,
    &:hover .mat-slide-toggle-thumb,
    &:active .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb,
    &:active:hover .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
      background-color: map-get(
        ra-ui-status-palette($theme, $status-palette),
        $status-color
      );
    }

    .mat-slide-toggle-bar,
    &:hover .mat-slide-toggle-bar,
    &:active .mat-slide-toggle-bar,
    &:active:hover .mat-slide-toggle-bar {
      border-color: map-get(
        ra-ui-status-palette($theme, $status-palette),
        $status-color
      );
    }
  }
}

@mixin ra-ui-slide-toggle-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $foreground: map-get($theme, foreground);
  $focus-color: ra-ui-color($theme, primary, A200);

  $bar-background-color: ra-ui-color(
    $theme,
    background,
    interactive-component-01
  );

  $unchecked-color: ra-ui-color($theme, background, inactive);
  $unchecked-thumb-border-color: ra-ui-contrast($theme, background, inactive);

  $unchecked-hover-color: ra-ui-color($theme, background, inactive-hover);
  $unchecked-hover-thumb-border-color: ra-ui-contrast(
    $theme,
    background,
    inactive-hover
  );

  $unchecked-pressed-color: ra-ui-color(
    $theme,
    background,
    interactive-component-07
  );
  $unchecked-pressed-thumb-border-color: ra-ui-contrast(
    $theme,
    background,
    interactive-component-07
  );

  $checked-color: ra-ui-color($theme, primary, 600);
  $checked-thumb-border-color: ra-ui-contrast($theme, primary, 600);

  $checked-hover-color: ra-ui-color($theme, primary, 700);
  $checked-hover-thumb-border-color: ra-ui-contrast($theme, primary, 700);

  $checked-pressed-color: ra-ui-color($theme, primary, 800);
  $checked-pressed-thumb-border-color: ra-ui-contrast($theme, primary, 800);

  $readonly-thumb-color: ra-ui-color($theme, background, inactive);
  $readonly-thumb-border-color: ra-ui-contrast($theme, background, inactive);
  $readonly-bar-background-color: ra-ui-color(
    $theme,
    background,
    interactive-component-06
  );
  $readonly-bar-border-color: ra-ui-color($theme, background, inactive);
  // .mat-slide-toggle padding-top, padding-bottom
  $vertical-padding: getRelative(2, 14);
  // .mat-slide-toggle padding-left, padding-right
  $horizontal-padding: getRelative(6, 14);
  // Due to different typography level in label (`form-field-text`)
  // and in slide-toggle (`input`), this variable is used for defining
  // same relative padding-left and padding-right in .label-container-label as in .mat-slide-toggle
  $form-field-text-font-size: ra-ui-font-size(
    $custom-typography,
    form-field-text
  );
  $input-font-size: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($form-field-text-font-size) ==
      string or
      type-of($input-font-size) ==
      string
  ) {
    $form-field-text-font-size: strip-unit(rafl.$rafl-core-font-size-75) * 16px;
    $input-font-size: strip-unit(rafl.$rafl-typography-utility-md-font-size) *
      16px;
  }
  $horizontal-label-padding: getRelative(8, 14) *
    strip-unit(math.div($input-font-size, $form-field-text-font-size));

  $size: getRelative(2, 14);

  ra-ui-slide-toggle {
    // will set color rules when custom color from status palette is used
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &:not(.ra-ui-inactive-state).ra-ui-#{$name} {
        @include set-status-colors($theme, $name);
      }
    }

    $border-width: getRelative(1, 14);

    @include mat.typography-level($custom-typography, input);

    @include slide-toggle-enhancement($theme, $custom-typography);

    &.ripple-effect-disabled {
      .mat-slide-toggle-ripple {
        display: none;
      }
    }

    .mat-slide-toggle {
      display: block;
      height: unset;
      border: $size solid transparent;
    }

    // focused state styles
    .ra-ui-keyboard-focus .mat-slide-toggle {
      border: $size solid $focus-color;
    }

    // removes side padding (no space on horizontal line) on the slide toglle
    .ra-ui-disabled-side-paddings ra-ui-label-container {
      .mat-slide-toggle,
      .label-container-label {
        padding-left: 0;
        padding-right: 0;
      }
    }

    ra-ui-label-container {
      .ra-ui-container.vertical {
        .label-container-label {
          padding-bottom: 0; // label-container-label padding-bottom differs from the slide-toggle's
        }
      }

      .label-container-label {
        cursor: default;
        padding: 0 $horizontal-label-padding;
      }

      .mat-slide-toggle {
        padding: $vertical-padding $horizontal-padding;
      }

      .label-container-label-text {
        @include ra-ui-immediate-arrow-neighbour($custom-typography);
        color: ra-ui-color($theme, primary);
      }

      &:not(.ra-ui-disabled) .label-container-label {
        @include getCursor($custom-global-config);
      }

      //region readonly feature rules
      &.ra-ui-readonly:not(.ra-ui-disabled) {
        .label-container-label {
          cursor: default;

          .static-text {
            color: ra-ui-color($theme, foreground, text-label);
          }
        }

        .mat-slide-toggle.mat-disabled .mat-slide-toggle-bar {
          opacity: 1;
        }

        mat-slide-toggle.mat-checked.mat-slide-toggle.slide-toggle,
        mat-slide-toggle:not(.mat-checked).mat-slide-toggle.slide-toggle {
          .mat-slide-toggle-label .mat-slide-toggle-bar {
            background-color: $readonly-bar-background-color;
            border: $border-width solid $readonly-bar-border-color;
          }
          .mat-slide-toggle-label .mat-slide-toggle-thumb {
            background-color: $readonly-thumb-color;
          }
        }

        ra-ui-static-text {
          color: ra-ui-color($theme, foreground, text-primary);
        }
      }
      //endregion
    }

    .mat-slide-toggle:not(.mat-disabled) {
      @include getCursor($custom-global-config);
    }

    .mat-slide-toggle {
      position: relative;

      .mat-slide-toggle-thumb-container {
        /**
                this rule is to center the thumb vertically with the bar
                2 = thumb height - bar height / 2
                -1 is there because we need -top
                in fact, material does this as well, we just need to make this number in em
                 */
        top: calc((#{getRelative(2, 14)} + #{$border-width}) * -1);
        /**
                this rule is to align the thumb horizontally to the left with the bar
                -1 is there because we need -left
                1 = border width of toggle-bar in pixels
                 */
        left: getRelative(1, 14) * -1;
      }

      .mat-slide-toggle-thumb-container {
        width: getRelative(16, 14);
        height: getRelative(16, 14);

        @include override-ripple-effects($default-slide-toggle-width);
      }

      .mat-slide-toggle-thumb {
        width: calc(#{getRelative(16, 14)} - 2 * #{$border-width});
        height: calc(#{getRelative(16, 14)} - 2 * #{$border-width});
        border-radius: 0.75em;
      }

      .mat-slide-toggle-bar {
        box-sizing: border-box;
      }

      &:not(.mat-disabled) {
        //slide toggle unchecked colors
        @include slide-toggle-set-colors(
          $theme,
          $unchecked-color,
          $unchecked-pressed-color,
          $unchecked-hover-color,
          $unchecked-thumb-border-color,
          $unchecked-pressed-thumb-border-color,
          $unchecked-hover-thumb-border-color
        );

        //slide toggle checked colors
        &.mat-checked {
          @include slide-toggle-set-colors(
            $theme,
            $checked-color,
            $checked-pressed-color,
            $checked-hover-color,
            $checked-thumb-border-color,
            $checked-pressed-thumb-border-color,
            $checked-hover-thumb-border-color
          );
        }
      }

      .mat-slide-toggle-label {
        max-width: 100%;
        // set the height to be always minimally as high as the state text, even when state text is empty
        // the line-height for input is also relative, so we have to get the value first
        min-height: calc(
          ra-ui-line-height($custom-typography, input) *
            ra-ui-font-size($custom-typography, input)
        );
      }

      .mat-slide-toggle-content {
        white-space: nowrap;
        margin-left: getRelative(8, 14);
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: 100%;
          max-width: inherit;
        }
      }
    }

    &.ng-dirty &:not(.immediate-arrow) .label-container-label {
      .label-container-label-text {
        @include ra-ui-dirty-flag-neighbour($custom-typography);
      }
    }

    //region inactive state feature rules
    &.ra-ui-inactive-state {
      .mat-slide-toggle.mat-checked,
      .mat-slide-toggle:not(.mat-checked) {
        // inactive colors for both states are the same as unchecked active slide toggle colors
        @include slide-toggle-set-colors(
          $theme,
          $unchecked-color,
          $unchecked-pressed-color,
          $unchecked-hover-color,
          $unchecked-thumb-border-color,
          $unchecked-pressed-thumb-border-color,
          $unchecked-hover-thumb-border-color
        );
      }
    }
    //endregion
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-dirty-flag-neighbour($custom-typography) {
  &.dirty-flag {
    width: calc(100% - #{$width-dirty-flag});
    display: inline-block;
    vertical-align: middle;

    &.blank-when-disabled {
      width: 100%;
    }

    &.immediate-arrow {
      $prop: get-immediate-arrow-properties($custom-typography);
      $width-immediate: map-get($prop, width);
      $padding-immediate: map-get($prop, padding);
      width: calc(
        100% - #{$width-dirty-flag} - #{$width-immediate} - #{$padding-immediate} -
          1px
      );
      &.label-container-label-text {
        vertical-align: middle;
      }
      &.blank-when-disabled {
        width: calc(100% - #{$width-immediate-arrow});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-dirty-flag-component-theme($theme) {
  ra-ui-dirty-flag[disabled='true'] {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-immediate-arrow-neighbour($custom-typography) {
  &.immediate-arrow {
    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    display: inline-block;
    vertical-align: middle;
    &.mat-button-base {
      vertical-align: inherit;
    }
    &.label-container-label-text {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: middle;
      @include safari-only-specific() {
        width: calc(100% - #{$width-immediate} - #{$padding-immediate});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-immediate-arrow-width-color($theme, $custom-typography) {
  ra-ui-immediate-arrow {
    color: ra-ui-color($theme, foreground, immediate-commit);

    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(#{$width-immediate} + #{$padding-immediate} + 1px);

    mat-icon.mat-icon.ra-icon-ide-sm-immediate-commit-arrow-left {
      padding-left: $padding-immediate;
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      line-height: ra-ui-font-size($custom-typography, icon-s);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-span-input($theme) {
  ra-ui-span-input {
    .mat-focused .span-input[placeholder]:not([placeholder='']):empty:before {
      content: attr(placeholder);
      color: ra-ui-color($theme, foreground, secondary-text);
    }

    .span-input {
      height: 100%;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    @include firefox-only-specific() {
      // https://stackoverflow.com/questions/23530231/contenteditable-true-height-issue-in-firefox
      [contenteditable='true']:before {
        content: '\feff ';
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin abstract-input-theme($theme, $custom-typography) {
  $line-height: ra-ui-line-height($custom-typography, input);
  $font-size: ra-ui-font-size($custom-typography, input);
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  .ra-ui-spacing {
    width: 100%;
    height: 5px;
  }

  .inner-wrapper {
    display: inline-block;
    vertical-align: bottom;
  }

  // abstract-input-inner component styles
  .inner-container {
    color: ra-ui-color($theme, foreground, text-primary);

    * {
      user-select: text; // ag-grid overrides default value for user-select, which causes problems with selecting
    }

    &[disabled] {
      color: ra-ui-color($theme, foreground, text-disabled);

      .separator {
        color: ra-ui-color($theme, foreground, text-disabled);
      }
    }

    @include ra-ui-span-input($theme);

    .separator {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }

  //mat-form-field styles

  label.mat-form-field-label {
    width: 100%;
    height: calc($font-size * $line-height);
    pointer-events: all;

    .static-text-wrapper {
      @include ra-ui-immediate-arrow-neighbour($custom-typography);
      @include ra-ui-dirty-flag-neighbour($custom-typography);
    }

    > mat-label {
      max-width: 100%;
      width: auto;
      text-overflow: clip;
      position: absolute;
      left: 0;
    }
  }

  .mat-form-field-disabled {
    //placeholder and messages color
    &.mat-form-field {
      mat-placeholder .static-text,
      mat-label .static-text,
      mat-hint .static-text {
        color: ra-ui-color($theme, foreground, text-disabled);
      }
    }
  }

  //region readonly rules
  .ra-ui-readonly:not(.ra-ui-disabled) {
    //value color
    .inner-container {
      color: ra-ui-color($theme, foreground, text-primary);
      .separator {
        color: ra-ui-color($theme, foreground, text-primary);
      }
    }

    &.mat-form-field-appearance-legacy .mat-form-field-underline {
      display: none;
    }
    &.mat-form-field,
    &.mat-form-field.mat-form-field-invalid {
      mat-placeholder .static-text,
      mat-label .static-text,
      mat-hint .static-text,
      mat-error .static-text {
        color: ra-ui-color($theme, foreground, text-label);
      }
    }
  }
  //endregion

  .mat-form-field-can-float {
    ra-ui-dirty-flag,
    ra-ui-immediate-arrow,
    label {
      cursor: text;
    }

    &.mat-form-field-should-float {
      ra-ui-dirty-flag,
      ra-ui-immediate-arrow,
      .static-text-wrapper .static-text {
        cursor: default;
      }

      .mat-form-field-label {
        display: flex;
        align-items: center;

        > mat-label {
          width: auto;
          position: absolute;
          left: 0;
        }
      }
    }
  }

  /* input is disabled */
  .mat-form-field-disabled {
    .inner-container,
    .mat-form-field-underline,
    .mat-form-field-label,
    .static-text-wrapper .static-text {
      cursor: default;
    }
  }

  @include form-field-enhancement($theme, $custom-typography);
  @include prefix-suffix(
    $theme,
    $custom-typography,
    '.ra-ui-ip-input-prefix',
    '.ra-ui-ip-input-suffix'
  );
  @include prefix-suffix(
    $theme,
    $custom-typography,
    '.ra-ui-private-ip-input-prefix',
    '.ra-ui-private-ip-input-suffix'
  );

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      width: 100%;
    }
  }
}

@mixin ra-ui-time-input-theme($theme, $custom-typography) {
  $text-color: ra-ui-color($theme, foreground, text-primary);
  $disabled-color: ra-ui-color($theme, foreground, text-disabled);

  ra-ui-time-input {
    @include abstract-input-theme($theme, $custom-typography);

    &.blank-when-disabled {
      .meridian {
        visibility: hidden;
      }
    }

    .mat-form-field {
      &.ra-ui-readonly:not(.ra-ui-disabled) {
        .inner-container {
          ra-ui-span-input {
            color: $text-color;
          }

          .meridian {
            .static-text {
              color: $text-color;
            }
          }
        }
      }

      .mat-form-field-label {
        cursor: default;
      }

      .inner-container {
        display: flex;
        height: getRelative(20px, 16px);
        &[disabled] {
          ra-ui-span-input {
            color: $disabled-color;
            .span-input {
              cursor: default;
            }
          }
          .meridian:focus {
            outline: 0 solid transparent;
            background-color: transparent;
            color: $disabled-color;
          }
          .meridian,
          .separator {
            cursor: default;
          }
        }
        ra-ui-span-input {
          color: $text-color;
          width: getRelative(35px, 16px);
          min-width: getRelative(35px, 16px);
          max-width: getRelative(35px, 16px);

          .span-input {
            cursor: text;
          }
        }

        .meridian:not(.ra-ui-disabled):focus {
          .static-text-inner {
            color: ra-ui-contrast($theme, primary, 500);
          }
        }

        .meridian {
          color: $text-color;
          width: getRelative(30px, 16px);
          text-align: center;
          cursor: pointer;
          &:focus {
            outline: 0 solid transparent;
            background-color: ra-ui-color($theme, primary, 500);
          }
          ra-ui-static-text {
            display: inline-block;
            .static-text-inner {
              height: getRelative(20px, 16px);
            }
          }
        }
        .separator {
          width: $widthSeparator;
          cursor: text;
        }
      }
    }

    .mat-form-field-disabled {
      .ra-ui-ip-input-prefix,
      .ra-ui-ip-input-suffix {
        cursor: default;
      }
    }
  }
}

@mixin ra-ui-message-input-theme($theme, $custom-typography) {
  $margin: getRelative(4px, 16px);

  ra-ui-message-input {
    ra-ui-link-menu {
      // fix for Safari due to the use of margin, when setting inline block we have to limit content
      display: inline-block;
      max-width: calc(100% - (2 * #{$margin}));
      // fix end
      margin: 0 $margin;
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-search-input-component-theme($theme, $custom-typography) {
  $search-input-height: getRelative(32px, 14px);
  $padding: getRelative(8px, 14px);
  $counter-padding: getRelative(8px, 10px);
  $padding-half: getRelative(4px, 14px);
  $padding-quarter: getRelative(2px, 14px);
  $padding-spinner: getRelative(11px, 14px);

  ra-ui-search-input {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    @include ra-ui-error-message-colors($theme);

    .fix-height {
      height: $search-input-height;
      min-height: $search-input-height;
      min-width: getRelative(140px, 14px);

      .search-text-container {
        min-width: getRelative(140px, 14px);
      }
    }

    .search-input-bar {
      color: ra-ui-color($theme, foreground, text);

      .ra-ui-icon-search-wrapper mat-icon {
        font-size: ra-ui-font-size($custom-typography, icon-m);
        line-height: ra-ui-line-height($custom-typography, icon-m);
        font-weight: ra-ui-font-weight($custom-typography, icon-m);

        cursor: default;
      }

      .ra-ui-input-suffix {
        padding-left: 0;
      }

      .ra-ui-input-prefix {
        padding-right: 0;
      }

      .ra-ui-input-prefix:not(.with-quick-search) .ra-ui-icon-search-wrapper {
        padding-right: $padding;
      }

      .ra-ui-quick-search {
        padding-left: $padding-quarter;
        padding-right: $padding-half;

        // separated menu trigger that covers the icon button, which "triggers" quick search
        .ra-ui-quick-search-menu-trigger {
          $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
          // height = Size of icon-s + padding top and bottom
          $icon-button-s: calc(
            $font-size-icon-s +
              (strip-unit($small-icon-button-padding) * $font-size-icon-s * 2)
          );

          position: absolute;
          height: calc($icon-button-s + 1px);
          top: 0.5px;
          width: $icon-button-s;
          // ensure the trigger is below the icon button
          z-index: -1;
        }
      }

      .ra-ui-icon-search-wrapper {
        padding-left: $padding;
      }

      .ra-ui-search-input-count-wrapper {
        padding-left: $counter-padding;
        font-size: ra-ui-font-size($custom-typography, caption);

        ra-ui-static-text {
          font-size: ra-ui-font-size($custom-typography, caption);
        }
      }

      .ra-ui-clear-button-wrapper {
        padding: 0 $padding 0 $padding;
      }
      .loading-spinner-in-button-wrapper {
        .ra-icon-ide-xxl-spinner.default-spinner-icon {
          display: flex;
        }
        padding: $padding-half;
        $icon-fs: ra-ui-font-size($custom-typography, icon-s);
        font-size: $icon-fs;
        line-height: $icon-fs;
        width: $icon-fs;
        height: $icon-fs;
        vertical-align: middle;
        display: inline-flex;
        .default-spinner-icon {
          color: ra-ui-color($theme, primary, 500);
        }
      }
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-suffix,
      .mat-form-field-prefix {
        .mat-icon-button {
          font-size: ra-ui-font-size($custom-typography, icon-s);
          line-height: ra-ui-line-height($custom-typography, icon-s);
          font-weight: ra-ui-font-weight($custom-typography, icon-s);
        }
      }
    }

    ra-ui-input {
      &.ra-ui-search-input-error-underline .mat-form-field {
        @include ra-ui-override-mat-error-message-colors($theme);
      }

      .mat-form-field.ra-ui-fill-parent {
        width: 100%;
      }
    }

    .ra-ui-quick-search {
      .ra-ui-quick-search-active {
        .mat-icon-button.default-color {
          &,
          &:hover {
            $icon-button-active-background: ra-ui-color($theme, primary, 800);
            $icon-button-active-icon: ra-ui-contrast($theme, primary, 800);

            background-color: $icon-button-active-background;

            .mat-icon {
              color: $icon-button-active-icon;
            }
          }
        }
      }
    }

    .ra-ui-horizontal-paddings {
      padding-left: $padding;
      padding-right: $padding;
    }
  }

  ra-ui-tooltip {
    .tooltip-highlight .static-text {
      color: ra-ui-color($theme, primary, 100);
    }

    .custom-tooltip-message .static-text {
      color: ra-ui-contrast($theme, background, interactive-component-07);
    }
  }

  ra-ui-static-text.ra-ui-search-input-text-error {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-file-input-list-component-theme($theme, $custom-typography) {
  $icons-gap: getRelative(8px, 14px);
  $list-item-gap: getRelative(8px, 14px);
  ra-ui-file-input-list {
    .ra-ui-list-item {
      .ra-ui-file-list-icon-wrapper {
        gap: $icons-gap;
      }

      .spacer {
        width: $list-item-gap;
      }
    }
  }
}

@mixin ra-ui-file-input-component-theme($theme, $custom-typography) {
  $horizontal-padding: getRelative(8px, 14px);
  $padding-wrapper: getRelative(8px, 14px);
  $error-padding-left: getRelative(4px, 14px);
  $label-padding: getRelative(4px, 14px);
  $margin-bottom-list-wrapper: getRelative(4px, 14px);

  ra-ui-file-input {
    @include ra-ui-error-message-colors($theme);
  }

  .drag-and-drop-zone-style {
    $margin-bottom: getRelative(4, 14);
    $inner-padding: getRelative(16, 14);
    $border: calc(
      strip-unit(getRelative(1, 14)) *
        ra-ui-font-size($custom-typography, subheading-1)
    );

    outline: $border dashed
      ra-ui-color($theme, background, interactive-component-06);
    overflow: hidden;
    padding: $inner-padding;
    box-sizing: border-box;

    $button-height: calc(
      strip-unit($text-button-min-height) *
        ra-ui-font-size($custom-typography, button)
    );
    $text-height: ra-ui-line-height($custom-typography, body-2);

    min-height: getRelative(80, 14);
    min-width: getRelative(160, 14);
    height: 100%;

    &-container {
      width: 100%;
      height: 100%;

      &--text {
        @include ra-ui-typography-level-to-styles($custom-typography, body-2);

        width: 100%;
        margin-bottom: $margin-bottom;
      }
    }
  }

  .ra-ui-file-input-wrapper {
    @include ra-ui-typography-level-to-styles($custom-typography, body-2);
    $form-input-wrapper-padding: getRelative(8px, 14px);

    &.ra-ui-horizontal-paddings {
      padding-left: $form-input-wrapper-padding;
      padding-right: $form-input-wrapper-padding;
    }

    &--dnd-style {
      margin: getRelative(8, 14) 0;
    }
  }

  ra-ui-static-text {
    &.ra-ui-file-input-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: ra-ui-color($theme, foreground, text-label);
      &.ra-ui-file-input-text-error {
        color: map_get(ra-ui-status-palette($theme, $status-palette), error);
        .static-text-inner {
          @include ra-ui-typography-level-to-styles(
            $custom-typography,
            form-field-text
          );
          padding-left: $error-padding-left;
        }
      }
    }
    &.ra-ui-label-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        subheading-1
      );
      color: ra-ui-color($theme, foreground, text-primary);
      .static-text-inner {
        padding: $label-padding 0;
      }
    }
  }
  .ra-ui-file-input-button {
    padding: $horizontal-padding 0;
  }
  .ra-ui-file-input-messages {
    @include ra-ui-typography-level-to-styles($custom-typography, body-2);
  }

  .ra-ui-file-list-wrapper {
    // set the min-width to reflect inner list restrictions
    min-width: getRelative($list-min-width, 14px);
    @include ra-ui-typography-level-to-styles($custom-typography, body-2);
    margin-bottom: $margin-bottom-list-wrapper;

    @include ra-ui-file-input-list-component-theme($theme, $custom-typography);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-dirty-flag-neighbour($custom-typography) {
  &.dirty-flag {
    width: calc(100% - #{$width-dirty-flag});
    display: inline-block;
    vertical-align: middle;

    &.blank-when-disabled {
      width: 100%;
    }

    &.immediate-arrow {
      $prop: get-immediate-arrow-properties($custom-typography);
      $width-immediate: map-get($prop, width);
      $padding-immediate: map-get($prop, padding);
      width: calc(
        100% - #{$width-dirty-flag} - #{$width-immediate} - #{$padding-immediate} -
          1px
      );
      &.label-container-label-text {
        vertical-align: middle;
      }
      &.blank-when-disabled {
        width: calc(100% - #{$width-immediate-arrow});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-dirty-flag-component-theme($theme) {
  ra-ui-dirty-flag[disabled='true'] {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

/* This mixin should be applied on the static text wrapper.
It is also necessary to put the 'immediate-arrow' class on this static text wrapper
when the immediate arrow is enabled to make this mixin work correctly. */
@mixin ra-ui-immediate-arrow-neighbour($custom-typography) {
  &.immediate-arrow {
    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    display: inline-block;
    vertical-align: middle;
    &.mat-button-base {
      vertical-align: inherit;
    }
    &.label-container-label-text {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: middle;
      @include safari-only-specific() {
        width: calc(100% - #{$width-immediate} - #{$padding-immediate});
      }
    }
  }
  & {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ra-ui-immediate-arrow-width-color($theme, $custom-typography) {
  ra-ui-immediate-arrow {
    color: ra-ui-color($theme, foreground, immediate-commit);

    $prop: get-immediate-arrow-properties($custom-typography);
    $width-immediate: map-get($prop, width);
    $padding-immediate: map-get($prop, padding);

    width: calc(#{$width-immediate} + #{$padding-immediate} + 1px);

    mat-icon.mat-icon.ra-icon-ide-sm-immediate-commit-arrow-left {
      padding-left: $padding-immediate;
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      line-height: ra-ui-font-size($custom-typography, icon-s);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-span-input($theme) {
  ra-ui-span-input {
    .mat-focused .span-input[placeholder]:not([placeholder='']):empty:before {
      content: attr(placeholder);
      color: ra-ui-color($theme, foreground, secondary-text);
    }

    .span-input {
      height: 100%;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    @include firefox-only-specific() {
      // https://stackoverflow.com/questions/23530231/contenteditable-true-height-issue-in-firefox
      [contenteditable='true']:before {
        content: '\feff ';
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin abstract-input-theme($theme, $custom-typography) {
  $line-height: ra-ui-line-height($custom-typography, input);
  $font-size: ra-ui-font-size($custom-typography, input);
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  .ra-ui-spacing {
    width: 100%;
    height: 5px;
  }

  .inner-wrapper {
    display: inline-block;
    vertical-align: bottom;
  }

  // abstract-input-inner component styles
  .inner-container {
    color: ra-ui-color($theme, foreground, text-primary);

    * {
      user-select: text; // ag-grid overrides default value for user-select, which causes problems with selecting
    }

    &[disabled] {
      color: ra-ui-color($theme, foreground, text-disabled);

      .separator {
        color: ra-ui-color($theme, foreground, text-disabled);
      }
    }

    @include ra-ui-span-input($theme);

    .separator {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }

  //mat-form-field styles

  label.mat-form-field-label {
    width: 100%;
    height: calc($font-size * $line-height);
    pointer-events: all;

    .static-text-wrapper {
      @include ra-ui-immediate-arrow-neighbour($custom-typography);
      @include ra-ui-dirty-flag-neighbour($custom-typography);
    }

    > mat-label {
      max-width: 100%;
      width: auto;
      text-overflow: clip;
      position: absolute;
      left: 0;
    }
  }

  .mat-form-field-disabled {
    //placeholder and messages color
    &.mat-form-field {
      mat-placeholder .static-text,
      mat-label .static-text,
      mat-hint .static-text {
        color: ra-ui-color($theme, foreground, text-disabled);
      }
    }
  }

  //region readonly rules
  .ra-ui-readonly:not(.ra-ui-disabled) {
    //value color
    .inner-container {
      color: ra-ui-color($theme, foreground, text-primary);
      .separator {
        color: ra-ui-color($theme, foreground, text-primary);
      }
    }

    &.mat-form-field-appearance-legacy .mat-form-field-underline {
      display: none;
    }
    &.mat-form-field,
    &.mat-form-field.mat-form-field-invalid {
      mat-placeholder .static-text,
      mat-label .static-text,
      mat-hint .static-text,
      mat-error .static-text {
        color: ra-ui-color($theme, foreground, text-label);
      }
    }
  }
  //endregion

  .mat-form-field-can-float {
    ra-ui-dirty-flag,
    ra-ui-immediate-arrow,
    label {
      cursor: text;
    }

    &.mat-form-field-should-float {
      ra-ui-dirty-flag,
      ra-ui-immediate-arrow,
      .static-text-wrapper .static-text {
        cursor: default;
      }

      .mat-form-field-label {
        display: flex;
        align-items: center;

        > mat-label {
          width: auto;
          position: absolute;
          left: 0;
        }
      }
    }
  }

  /* input is disabled */
  .mat-form-field-disabled {
    .inner-container,
    .mat-form-field-underline,
    .mat-form-field-label,
    .static-text-wrapper .static-text {
      cursor: default;
    }
  }

  @include form-field-enhancement($theme, $custom-typography);
  @include prefix-suffix(
    $theme,
    $custom-typography,
    '.ra-ui-ip-input-prefix',
    '.ra-ui-ip-input-suffix'
  );
  @include prefix-suffix(
    $theme,
    $custom-typography,
    '.ra-ui-private-ip-input-prefix',
    '.ra-ui-private-ip-input-suffix'
  );

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      width: 100%;
    }
  }
}

@mixin ra-ui-version-input-theme($theme, $custom-typography) {
  ra-ui-version-input {
    @include abstract-input-theme($theme, $custom-typography);

    ra-ui-span-input {
      min-width: $widthSpan;
    }

    .separator {
      width: $widthSeparator;
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-multi-selector-input-theme($theme, $custom-typography) {
  ra-ui-multi-selector-input {
    @include form-field-enhancement($theme, $custom-typography);

    $mat-form-field-height: getRelative(3.2px, 16px);
    $widthSelectInput: getRelative(45px, 16px);
    $widthStaticInput: getRelative(28px, 16px);

    $foreground: map-get($theme, foreground);
    $focused-color: map-get(
      ra-ui-status-palette($theme, $status-palette),
      information
    );
    $text-primary: ra-ui-color($theme, foreground, text-primary);
    $text-disabled: ra-ui-color($theme, foreground, text-disabled);
    $text-label: ra-ui-color($theme, foreground, text-label);
    $font-size-small: ra-ui-font-size($custom-typography, icon-s);

    ra-ui-select-input {
      width: $widthStaticInput;
      min-width: $widthStaticInput;
      max-width: $widthStaticInput;

      &:not(.static-input) {
        width: $widthSelectInput;
        min-width: $widthSelectInput;
        max-width: $widthSelectInput;
      }

      .ra-icon-ide-sm-caret-down {
        font-size: $font-size-small;
        color: $text-primary;
      }

      .ra-icon-ide-sm-caret-down.disabled {
        color: $text-disabled;
      }

      .ra-icon-ide-sm-caret-down.select-arrow-focused {
        color: $focused-color;
      }

      .value-container {
        align-items: center;
      }
    }

    .separator {
      width: $widthSeparator;
      display: inline-block;
      padding: 0px 4px;
    }

    .mat-form-field .static-text[disabled=true],
       // .mat-form-field .ra-ui-read-only-class > .static-text[disabled=true],
        .mat-hint > ra-ui-static-text > div[disabled=true] > div {
      color: $text-disabled;
    }

    .d-flex {
      display: flex;
    }

    // Removes border-top-width added by the form-field-enhancement mixin.
    // Style isn't applicable here because component is using label-container instead of mat-label.
    &:not(.flat-mode):not(.grid-mode)
      .mat-form-field-appearance-legacy
      div.mat-form-field-infix {
      border-top-width: 0;
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin form-system-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include ra-ui-checkbox-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-checkbox-tree-theme();
  @include ra-ui-error-message-theme($theme, $custom-typography);
  @include ra-ui-multiline-input-theme($theme, $custom-typography);
  @include ra-ui-select-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-date-picker-theme($theme, $custom-typography);
  @include ra-ui-radio-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-activity-status-theme($theme, $custom-typography);
  @include ra-ui-ip-input-theme($theme, $custom-typography);
  @include ra-ui-input-component-theme($theme, $custom-typography);
  @include ra-ui-spinner-input-theme($theme, $custom-typography);
  @include ra-ui-private-ip-input-theme($theme, $custom-typography);
  @include ra-ui-dynamic-form-theme($theme, $custom-typography);
  @include ra-ui-slide-toggle-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-time-input-theme($theme, $custom-typography);
  @include ra-ui-message-input-theme($theme, $custom-typography);
  @include ra-ui-search-input-component-theme($theme, $custom-typography);
  @include ra-ui-file-input-component-theme($theme, $custom-typography);
  @include ra-ui-version-input-theme($theme, $custom-typography);
  @include ra-ui-multi-selector-input-theme($theme, $custom-typography);
}

/* You can add global styles to this file, and also import other style files */

/* FIX all input components used material class `.mat-form-field-underline`. There are incorrect render underline height */
/* Chrome 29+ */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .mat-form-field-underline {
    transform: perspective(1px);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-ag-grid-params($theme, $custom-typography) {
  --ra-active-color: #{ra-ui-color($theme, primary, A200)};
  --ra-hover-background-color: #{ra-ui-color($theme, primary, 200)};
  --ra-hover-foreground-color: #{ra-ui-contrast($theme, primary, 200)};
  --ra-selected-background-color: #{ra-ui-color($theme, primary, 600)};
  --ra-selected-foreground-color: #{ra-ui-contrast($theme, primary, 600)};
  --ra-row-background-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-04
    )}; // default row color
  --ra-unfocused-background-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-05
    )};
  --ra-unfocused-border-color: #{ra-ui-color($theme, background, inactive)};
  --ra-card-header-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-05
    )};
  --ra-active-text: #{ra-ui-color($theme, foreground, text-label)};
  --ra-scrollbar-background-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-04
    )};
  --ra-ui-active-focus-border: #{ra-ui-color(
      $theme,
      background,
      interactive-component-01
    )};
  --ra-ui-text-primary: #{ra-ui-color($theme, foreground, text-primary)};
  --ra-ui-header-font-size: #{ra-ui-font-size($custom-typography, subheading-1)};
  --ra-ui-header-font-weight: #{ra-ui-font-weight(
      $custom-typography,
      subheading-1
    )};

  --ag-background-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-01
    )}; // grid background
  --ag-foreground-color: #{ra-ui-contrast(
      $theme,
      background,
      interactive-component-01
    )};
  --ag-data-color: #{ra-ui-contrast(
      $theme,
      background,
      interactive-component-04
    )};
  --ag-header-background-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-05
    )};
  --ag-header-foreground-color: #{ra-ui-contrast(
      $theme,
      background,
      interactive-component-05
    )};
  --ag-border-color: #{ra-ui-color($theme, background, interactive-component-06)};
  --ag-secondary-border-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-06
    )};
  --ag-row-hover-color: var(--ra-hover-background-color);
  --ag-control-panel-background-color: var(--ag-background-color);

  --ag-input-border-color: var(--ag-border-color);

  --ag-checkbox-checked-color: var(--ra-selected-background-color);
  --ag-checkbox-unchecked-color: #{ra-ui-color($theme, background, inactive)};
  --ag-checkbox-indeterminate-color: var(--ra-selected-background-color);
  --ag-checkbox-background-color: var(--ag-background-color);

  --ag-selected-row-background-color: var(--ra-selected-background-color);
  --ag-secondary-foreground-color: #{ra-ui-contrast(
      $theme,
      background,
      interactive-component-05
    )};
  --ag-range-selection-border-color: var(--ra-active-color);

  // BORDERS
  --ag-borders-side-button: none;
  --ag-border-radius: 0px;

  --ag-header-column-resize-handle-display: none;
  --ag-header-column-separator-display: display;
  --ag-header-column-separator-color: var(--ag-border-color);
  --ag-cell-horizontal-border: solid var(--ag-border-color);
  --ag-row-border-color: var(--ag-border-color);

  // SIZING
  --ag-grid-size: 4px;
  --ag-icon-size: 16px;
  --ag-row-height: calc(
    var(--ag-grid-size) * 7
  ); // implied style height - height is calculated and may not be exact
  --ag-header-height: calc(
    var(--ag-grid-size) * 6.25
  ); // implied style height - height is calculated and may not be exact
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2);
  --ag-cell-widget-spacing: calc(var(--ag-grid-size) * 2);
  --ag-list-item-height: calc(var(--ag-grid-size) * 7);
  --ag-column-select-indent-size: var(--ag-icon-size);
  --ag-row-group-indent-size: 10px;

  /* columns menu tab */
  --ag-widget-container-vertical-padding: calc(var(--ag-grid-size) * 2);
  --ag-widget-container-horizontal-padding: calc(var(--ag-grid-size) * 2);
  --ag-widget-horizontal-spacing: calc(var(--ag-grid-size) * 2);
  --ag-widget-vertical-spacing: calc(var(--ag-grid-size) * 1.5);

  // FONTS
  --ag-font-family: #{ra-ui-font-family($custom-typography)};
  --ag-font-size: #{ra-ui-font-size($custom-typography, body-2)};

  /* use alpine icon fonts */
  //--ag-icon-font-family: $ag-theme-alpine-icon-font-family,
  //--ag-icons-data: $ag-theme-alpine-icons-data,
  //--ag-icons-font-codes: $ag-theme-alpine-icons-font-codes,

  --ag-input-focus-border-color: var(--ra-active-color /*, $opacity: 0.4*/);
  --ag-input-focus-box-shadow: 0 0 2px 0.1rem var(--ag-input-focus-border-color);

  // MISC
  --ag-card-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  --ag-popup-shadow: var(--ag-card-shadow);

  // OTHER
  --ra-input-line-height: #{ra-ui-line-height($custom-typography, input)};
  --ra-secondary-text: #{ra-ui-color($theme, foreground, secondary-text)};
  --ra-cell-padding: 0 0.75em 0;
}

/*** IMPORTED FILE NOT FOUND ***/
@import '@ag-grid-community/styles/_index';/*** --- ***/

@mixin _ra-ui-grid-styling-base($theme, $custom-typography) {
  $custom-theme: 'rockwell';

  @include grid-styles(
    (
      theme: $custom-theme,
      extend-theme: alpine,
    )
  );

  /**
    * The following are custom changes to the
    * agGrid classes to meet visual design needs.
    * Its important to note; when upgrading to a
    * new version of agGrid, that the following
    * changes are still valid after the upgrade.
    **/

  .ag-theme-#{$custom-theme} {
    @include ra-ui-ag-grid-params($theme, $custom-typography);

    // The viewport doesn't need to scroll since there is already a horizontal
    // scrolling viewport - unless 'suppressHorizontalScroll' is set to true
    .ag-center-cols-viewport {
      overflow-x: hidden;
    }

    /* formats the in-line editable cells (and any other text inputs) */
    %ag-text-input {
      min-height: calc(var(--ag-grid-size) * 4);
      border-radius: var(--ag-border-radius);
    }

    .ag-cell {
      line-height: var(--ag-row-height);
    }

    .ag-scroller-corner {
      background-color: var(--ra-scrollbar-background-color);
    }

    .ag-cell-label-container {
      height: 100%;
      // This change was added in WTUI toolkit v12.
      // In new version of ag-grid was added vertical padding on headers.
      // This vertical padding cut off characters in lower part.
      padding: 0;
    }

    // keep internal height the same across all rows by using a consistent border
    .ag-row {
      border-bottom-style: solid;
      font-size: var(--ag-font-size);

      // need to overwrite AgGrid formatting
      &:not(.ag-row-first) {
        border-top-style: none;
      }

      &::before {
        display: none;
      }
    }

    /* requirement: cell hover */
    // hover cell if row header is present and row is not selected
    .ag-row-hover.ra-rowheader-row:not(.ag-row-selected)
      .ag-cell:hover:not(.ra-rowheader-cell) {
      background-color: var(--ra-hover-background-color);
      color: var(--ra-hover-foreground-color);
    }

    /* requirement: hover row */
    // hover row if row header is not present and row is not selected
    .ag-row-hover:not(.ra-rowheader-row):not(.ag-row-selected) {
      background-color: var(--ra-hover-background-color);
      color: var(--ra-hover-foreground-color);
    }

    /* requirement: row header and editable (cell) color */
    // cells are tricky as we want to remove the background color to let row/cell
    // hover activation
    .ag-row:not(.ag-row-selected):not(.ag-row-hover):not(.ra-rowheader-row)
      .ra-editable-cell,
    .ag-row:not(.ag-row-selected).ra-rowheader-row:not(.ra-rowheader-hover)
      .ra-editable-cell:not(:hover) {
      background-color: var(--ag-background-color);
    }

    /**
        * requirement: when checkbox selection is not enabled,
        * pinned left column background color must match that of header row (RAIDWTUI01-2329)
        **/
    .ag-row:not(.ag-row-selected) .ag-cell:not(:hover).ra-rowheader-cell {
      background-color: var(--ag-header-background-color);

      &.ra-checkbox-selection-cell {
        background-color: var(--ag-background-color);
      }
    }

    /* requirement: highlight entire row when hovering row header */
    .ag-row:not(.ag-row-selected).ra-rowheader-hover {
      background-color: var(--ra-hover-background-color);
    }

    /* requirement: cell with error */
    .ra-error-cell {
      &:before,
      &:after {
        @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
        font-family: ra-icon-ide;
        position: absolute;
        // 1px instead of 0 to make a separation from the border
        top: 1px;
        right: 1px;
      }
      &:before {
        content: '\C3CC';
        color: map-get(ra-ui-status-palette($theme, $status-palette), error);
      }
      &:after {
        content: '\C3CD';
        color: ra-ui-color($theme, background, interactive-component-01);
      }
    }
    /*
        * This is needed to make the row header to use the whole space.
        * if not specified, it can cause unexpected behaviors (e.g. mouse move events executed more than one time).
        */
    .ra-rowheader-cell {
      width: 100%;
      border: 0;
      padding-left: 0;
      padding-right: 0;
    }

    /* requirement: selected foreground color */
    .ag-row-selected,
    .ag-row-selected .ag-group-expanded,
    .ag-row-selected .ag-group-contracted,
    .ag-rich-select-row-selected {
      color: var(--ra-selected-foreground-color);
      background-color: var(--ra-selected-background-color);
    }

    /* requirement: selected row inline cell edit color */
    .ag-row-selected .ag-cell.ag-cell-inline-editing {
      color: initial;
    }

    /* requirement: un-focused selected row */
    .ag-body-viewport:not(.ag-has-focus) .ag-row-selected,
    .ag-body-viewport:not(.ag-has-focus) .ag-row-selected .ag-group-expanded,
    .ag-body-viewport:not(.ag-has-focus) .ag-row-selected .ag-group-contracted {
      background-color: var(--ra-unfocused-background-color);
      color: ra-ui-color($theme, background, inactive);
    }

    .ag-body-viewport.ag-has-focus {
      background-color: var(--ra-unfocused-background-color);

      .ag-row-selected::before {
        background-color: var(--ra-selected-background-color);
      }
    }

    .ag-body-viewport:not(.ag-has-focus) {
      .ag-row-selected::before {
        background-color: var(--ra-unfocused-background-color);
        background-image: none;
      }
    }

    /* requirement: 2px border focus */
    // Note: the requirement asks for an outer border. The
    // outline-offset: -2px creates an inner border.  The
    // outer border was clipped, seemingly to how the grid
    // positioned/stacked its cells/rows/containers.
    .ag-ltr,
    .ag-rtl {
      .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
      .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
      .ag-cell-range-single-cell.ag-cell-range-handle {
        outline: 2px solid var(--ra-active-color);
        outline-offset: -2px;

        &.ra-rowheader-cell {
          border: none;
          border-right: 1px solid var(--ag-border-color);
        }
      }
    }

    /* requirement: 2px border un-focused */
    // Note: the requirement asks for an outer border. The
    // outline-offset: -2px creates an inner border.  The
    // outer border was clipped, seemingly to how the grid
    // positioned/stacked its cells/rows/containers.
    .ag-ltr,
    .ag-rtl {
      .ag-body-viewport:not(.ag-has-focus)
        .ag-cell-focus:not(.ag-cell-range-selected),
      .ag-body-viewport:not(.ag-has-focus)
        .ag-cell-focus.ag-cell-range-single-cell,
      .ag-cell-range-single-cell.ag-cell-range-handle {
        outline: 2px solid var(--ra-unfocused-border-color);
        outline-offset: -2px;

        &.ra-rowheader-cell {
          border: none;
          border-right: 1px solid var(--ag-border-color);
        }
      }
    }

    /* requirement: Grid has focus, any cell in a row has focus: row header is highlighted */
    // Note: ag-rowheader-cell is a custom class that must be applied to a column
    // definition for this visual design requirement to take affect.
    .ag-has-focus
      .ag-row.ag-row-focus:not(.ag-row-selected)
      .ag-cell.ra-rowheader-cell:not(.ag-cell-focus) {
      background-color: var(--ra-selected-background-color);
    }

    /* requirement: Grid does not have focus, any cell in a row has focus: row header is highlighted */
    // Note: ag-rowheader-cell is a custom class that must be applied to a column
    // definition for this visual design requirement to take affect.
    .ag-body-viewport:not(.ag-has-focus),
    .ag-body-viewport:not(.ag-has-focus)
      .ag-row.ag-row-focus:not(.ag-row-selected):not(
        .ra-checkbox-selection-row
      ),
    .ag-has-focus
      .ag-row.ag-row-focus:not(.ag-row-selected):not(
        .ra-checkbox-selection-row
      ),
    .ag-body-viewport:not(.ag-has-focus)
      .ag-row.ag-row-focus:not(.ag-row-selected)
      .ag-cell.ra-rowheader-cell:not(.ag-cell-focus) {
      background-color: var(--ra-unfocused-background-color);
    }

    /* requirement: Grid does not have focus: unfocused border for multi-selection */
    .ag-body-viewport:not(.ag-has-focus)
      .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell) {
      &.ag-cell-range-top {
        border-top-color: var(--ra-unfocused-border-color);
      }
      &.ag-cell-range-right {
        border-right-color: var(--ra-unfocused-border-color);
      }
      &.ag-cell-range-bottom {
        border-bottom-color: var(--ra-unfocused-border-color);
      }
      &.ag-cell-range-left {
        border-left-color: var(--ra-unfocused-border-color);
      }
    }

    /* requirement: numeric header and cells must be aligned left */
    .ag-right-aligned-header .ag-cell-label-container {
      flex-direction: row-reverse;
    }

    .ag-right-aligned-header .ag-header-cell-label {
      flex-direction: row;
    }

    .ag-root
      .ra-rowheader-hover.ag-row.ag-row-hover.ag-row-focus:not(
        .ag-row-selected
      ):not(.ra-checkbox-selection-row) {
      background-color: var(--ra-unfocused-background-color);
    }

    /* requirement: default row color (read-only) */
    .ag-row:not(.ag-row-selected) {
      background-color: var(--ra-row-background-color);
    }

    /* requirement: header font size */
    .ag-header-row {
      font-size: var(--ra-ui-header-font-size);
      font-weight: var(--ra-ui-header-font-weight);
    }

    /* requirement: popup menu */
    .ag-tab {
      padding: calc(var(--ag-grid-size) + 1px);
      margin-top: calc(var(--ag-grid-size) * 2);
      margin-left: var(--ag-grid-size);
      margin-right: var(--ag-grid-size);
    }

    .ag-tab-selected {
      border-top: 1px solid #{var(--ag-border-color)};
      border-right: 1px solid #{var(--ag-border-color)};
      border-left: 1px solid #{var(--ag-border-color)};

      background-color: var(--ag-background-color);
    }

    .ag-menu {
      border-bottom-right-radius: 4px;
    }

    .ag-menu-header {
      background-color: var(--ra-card-header-color);
      padding: 0 #{var(--ag-grid-size)};
      min-width: 200px;
      max-width: 280px;
    }

    .ag-tabs-header {
      border-bottom: 1px solid #{var(--ag-border-color)};
    }

    .ag-filter {
      .ag-filter-header-container {
        border: none;
      }

      .ag-mini-filter {
        border-bottom: 1px solid #{var(--ag-secondary-border-color)};

        margin: 0;

        // RA search icon - might be an issue hard-coding the content value as it could change
        ::after {
          content: '\F198';
          font-family: 'ra-icon-ide';
          margin-right: calc(var(--ag-grid-size) * 2);
        }
      }

      input[class^='ag-'][type='text'] {
        border: none;
        padding: var(--ra-cell-padding);
        margin: #{var(--ag-grid-size)} 0;
        position: relative;
        box-shadow: none;

        &::placeholder {
          font-style: italic;
        }
      }

      .ag-set-filter-select-all {
        // default size is var(--ag-list-item-height), make it a little larger
        height: var(--ag-list-item-height);
      }

      .ag-set-filter-list {
        max-height: calc(var(--ag-list-item-height) * 6);
        height: auto;
        min-height: 0;
        overflow-y: auto;
      }

      .ag-standard-button {
        background: none;
        border: none;
        color: var(--ra-selected-background-color);
        cursor: pointer;

        // removes focus indication
        &:focus {
          box-shadow: none;
        }
      }
    }

    /* requirement: header cell - hover, icons, etc */
    .ag-header-cell {
      // active color
      .ag-icon-asc,
      .ag-icon-desc,
      .ag-icon-filter,
      .ra-icon-asc,
      .ra-icon-desc,
      .ra-icon-filter {
        color: var(--ra-active-text);
      }

      .ag-icon-none,
      .ra-icon-none {
        opacity: 0;
        transition: opacity 0.2s; // match the transition of the menu
      }

      :hover {
        // for columns that support sorting, show the sort icon when hovering
        .ag-icon-none,
        .ra-icon-none {
          opacity: 1 !important;
        }
      }

      // font doesn't align middle, so add padding to adjust layout
      .ag-header-cell-text {
        padding-top: 0.3em;
      }
    }

    .ag-header-cell,
    .ag-header-group-cell {
      .static-text {
        color: var(--ra-secondary-text);
      }
    }

    .ag-row:not(.ra-rowheader-row) .ag-cell,
    .ag-row:not(.ra-rowheader-row) .ag-cell.ag-cell-inline-editing,
    .ag-header-cell,
    .ag-header-group-cell {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: var(--ra-cell-padding);
    }

    .ag-cell.ag-cell-inline-editing {
      border-radius: 0;
      height: 100%;
      line-height: inherit;
    }

    .ag-header-row,
    .ag-header-cell {
      overflow: visible;
    }

    .ag-header-cell {
      border-top: none !important; // TODO: in future, this violates designs for grid-readonly and DataTable's grid (RAIDWTUI01-2328)
    }

    //set 100%, fix with display flex and align to center
    ra-ui-static-text.multi-line {
      height: 100%;
    }

    ra-ui-static-text-wrapper {
      &,
      ra-ui-static-text {
        @include ra-ui-typography-level-to-styles(
          $custom-typography,
          form-field-text
        );
      }
    }

    .ag-header-cell.custom-font-size ra-ui-static-text,
    .ag-header .ag-header-row ra-ui-static-text {
      font-size: ra-ui-font-size($custom-typography, subheading-1);
    }

    /**
     * AgGrid angular v31 does not set background and border to cell editor popups.
     * That is problem for MessageInputComponent cellEditor which uses cell editor popup that is transparent and without border.
     */
    .ag-popup-editor {
      border: var(--ag-borders) var(--ag-border-color);
      background: var(--ag-background-color);
      border-radius: var(--ag-card-radius);
      background-color: var(--ag-control-panel-background-color);
    }
  }
}

@mixin _ra-ui-grid-styling-view-list($theme, $custom-typography) {
  .ag-pinned-left-cols-container .ra-list-view.ra-rowheader-row .ag-cell {
    padding: 0;
  }

  .ag-ltr,
  .ag-rtl {
    .ag-body-viewport:not(.ag-has-focus)
      .ra-list-view
      .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-body-viewport:not(.ag-has-focus)
      .ra-list-view
      .ag-cell-focus.ag-cell-range-single-cell,
    .ag-cell-range-single-cell.ag-cell-range-handle {
      outline: none;
    }
  }

  .ag-body-viewport:not(.ag-has-focus)
    .ra-list-view.ag-row.ag-row-focus:not(.ag-row-selected)
    .ag-cell.ra-rowheader-cell:not(.ag-cell-focus) {
    background-color: var(--ag-background-color);
  }

  .ra-list-view.ag-row:not(.ag-row-selected) {
    background-color: var(--ag-background-color);
  }

  .ra-rowheader-cell.ag-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ag-body-viewport:not(.ag-has-focus)
    .ra-list-view.ag-row.ag-row-focus:not(.ag-row-selected):not(
      .ra-checkbox-selection-row
    ),
  .ag-has-focus
    .ra-list-view.ag-row.ag-row-focus:not(.ag-row-selected):not(
      .ra-checkbox-selection-row
    ),
  .ag-root
    .ra-list-view.ra-rowheader-hover.ag-row.ag-row-hover.ag-row-focus:not(
      .ag-row-selected
    ):not(.ra-checkbox-selection-row) {
    background-color: var(--ra-hover-background-color);
  }

  .ra-list-view {
    .ag-ltr .ag-cell {
      border-right-color: transparent;
    }

    &.ag-row {
      @include ra-ui-typography-level-to-styles($custom-typography, input);

      color: var(--ra-ui-text-primary);
      border-color: var(--ag-border-color);
    }

    .ag-cell {
      padding: getRelative(4px, 14px) getRelative(8px, 14px);
      line-height: unset;

      display: flex;
      align-items: center;

      border-right: none;
      border-left: none;
    }

    /* requirement: cell hover */
    // hover cell if row header is present and row is not selected
    &.ag-row.ag-row-hover:not(.ag-row-selected) {
      background-color: var(--ra-hover-background-color);
      color: var(--ra-hover-foreground-color);
    }

    &.ag-row:not(.ag-row-selected) {
      .ag-cell:not(:hover).ra-rowheader-cell,
      &:not(.ag-row-hover):not(.ra-rowheader-row) .ag-cell,
      &.ra-rowheader-row:not(.ra-rowheader-hover):not(:hover) {
        background-color: var(--ag-background-color);
      }
    }

    /* requirement: cell hover */
    // hover cell if row header is present and row is not selected
    &.ag-row-hover.ra-rowheader-row:not(.ag-row-selected)
      .ag-cell:not(.ra-rowheader-cell) {
      background-color: var(--ra-hover-background-color);
      color: var(--ra-hover-foreground-color);
    }

    &.ra-rowheader-row:not(.ag-row-selected)
      .ag-cell:not(:hover).ra-rowheader-cell,
    &.ra-rowheader-row:not(.ag-row-selected):not(.ra-rowheader-hover)
      .ag-cell:not(:hover) {
      background-color: var(--ag-background-color);

      &.ra-rowheader-cell:not(.ra-checkbox-selection-cell) {
        background-color: var(--ag-header-background-color);
      }

      z-index: -1;
    }

    &.ag-row-hover.ra-rowheader-row:not(.ag-row-selected).ag-row-hover
      .ag-cell {
      background-color: transparent;
    }
  }

  .ag-has-focus .ra-list-view {
    &.ag-row {
      &.ag-row-selected {
        color: var(--ra-selected-foreground-color);
      }

      &.ag-row-focus {
        border-right-color: var(--ra-ui-active-focus-border);

        outline: 2px solid var(--ra-active-color);
        outline-offset: -2px;

        &.ag-row-selected {
          border-color: var(--ra-ui-active-focus-border);
        }
      }
    }

    .ag-cell
      .ag-cell-last-left-pinned
      :not(.ag-cell-range-right)
      :not(.ag-cell-range-single-cell) {
      border-right-color: var(--ra-ui-active-focus-border);
    }

    &.ag-row.ag-row-focus:not(.ag-row-selected)
      .ag-cell.ra-rowheader-cell:not(.ag-cell-focus) {
      background-color: transparent;
    }
  }

  .ag-ltr,
  .ag-rtl {
    .ag-has-focus .ra-list-view {
      .ag-cell-focus:not(.ag-cell-range-selected),
      .ag-cell-focus.ag-cell-range-single-cell,
      .ag-cell-range-single-cell.ag-cell-range-handle {
        outline: none;
        border: none;
      }

      .ag-cell-focus:not(.ag-cell-range-selected).ra-rowheader-cell {
        border-right: 1px solid var(--ag-border-color);
      }
    }
  }
}

@mixin ra-ui-ag-grid-styling($theme, $custom-typography, $status-palette) {
  @include _ra-ui-grid-styling-base($theme, $custom-typography);

  .ag-theme-rockwell {
    @include _ra-ui-grid-styling-view-list($theme, $custom-typography);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
$container-margin-bottom: 0.5625em; //9px;
$label-margin: 0.5em; //8px;
$suffix-prefix-padding: getRelative(4px, 14px); // input type scale
$iconHeight: 1.25em; //20px

$default-mat-form-field-width: 180px;
$form-field-horizontal-padding: getRelative(8px, 14px);

//IP input width of one span
$widthSpan: getRelative(40px, 16px);
//IP input width of one separator
$widthSeparator: getRelative(4.8px, 16px);

$default-checkbox-width: 16px;
$default-radio-button-width: 20px;
// the value concerns the default width of ripple effect
$default-slide-toggle-width: 20px;
// for size of checkbox, radiobutton
$updated-size: 16px;

$arrow-padding: getRelative(4, 12);
$arrow-width: 1em;

$mat-form-padding: getRelative(4px, 14px);

%remove-message-space {
  .mat-form-field-underline {
    bottom: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 9px;
  }
}

@mixin placeholder-styling($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);

  $foreground: map-get($theme, foreground);

  font-style: italic;
  color: ra-ui-color($theme, foreground, text-label);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;

  //a value is empty
  &-hidden {
    display: none;
  }
}

@mixin caret-color($theme: null) {
  $color: auto;

  @if ($theme != null) {
    $is-dark: map-get($theme, is-dark);
    $color: if($is-dark, white, black);
  }

  .mat-form-field-infix .mat-input-element,
  .span-input {
    caret-color: $color;
  }
}

@mixin remove-message-space-feature() {
  &.ra-ui-remove-message-space:not(.flat-mode):not(.grid-mode)
    .mat-form-field-appearance-legacy {
    @extend %remove-message-space;
  }
}

%flat-mode-styles {
  //hide error, hint message
  .mat-form-field-subscript-wrapper,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  //change position absolute -> static
  .mat-form-field-label-wrapper {
    position: static;
  }

  @extend %remove-message-space;

  .mat-form-field-label {
    top: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
  }
}

@mixin flat-mode-feature() {
  &.flat-mode {
    @extend %flat-mode-styles;
  }
}

@mixin grid-mode-feature() {
  &.grid-mode {
    @extend %flat-mode-styles;

    .mat-form-field {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@mixin hint-messages() {
  .mat-form-field-hint-wrapper {
    .mat-hint {
      max-width: 100%;
      overflow: hidden;

      &.mat-right {
        max-width: calc(100% - 1em);
      }
    }

    .mat-hint:first-of-type:nth-last-of-type(2) {
      max-width: 50%;

      ~ .mat-right {
        max-width: calc(50% - 1em);
      }
    }
  }
}

@function get-mat-form-field-prop-by-caption($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);
  $form-field-line-height: ra-ui-line-height(
    $custom-typography,
    form-field-text
  );
  // CSS tokens cannot be used here, because calculation of top relies on a denominator with unit and final result is converted into em.
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($input-font-size) ==
      string or
      type-of($form-field-line-height) ==
      string
  ) {
    $input-font-size: rafl.$rafl-typography-utility-md-font-size;
    $form-field-line-height: rafl.$rafl-core-dimension-rem-100;
  }

  $top: math.div(
      strip-unit($form-field-line-height),
      strip-unit($input-font-size)
    ) * 1em;

  @return (top: $top, label-top: $form-field-line-height);
}

@mixin create-label-by-caption-from-typography($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $top: map_get($values, top);
  $label-top: map_get($values, label-top);

  .mat-form-field-should-float {
    &.mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-form-field-label {
      max-width: 100%; //percentage(1 / $ratio);
      width: auto;
      transform: translateY(-1em) scale(1) perspective(100px)
        translateZ(0.001px);
    }

    .mat-form-field-label {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
    }
  }

  .mat-form-field-label {
    max-width: 100%;
    width: auto;
    text-overflow: clip;
  }

  // fix after adding explicitly font-size on a static text.
  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-should-float .mat-form-field-label ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );
  }

  &:not(.flat-mode):not(.grid-mode) {
    // A space between the underline and hint messages.
    // We can't add padding/margin to underline (it does not create space between).
    // So the container below the underline needs to have bigger padding.
    // top - size of subtext + 4px top underline padding + 4px bottom underline padding + 1px underline height
    $bottom-with-padding: calc($top + 4px + 4px + 1px);

    .mat-form-field-label-wrapper {
      padding-top: $top;
      top: calc(-1 * $top);
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-infix {
        border-top-width: $top;
      }

      .mat-form-field-wrapper {
        padding-bottom: $bottom-with-padding;
      }

      .mat-form-field-label {
        top: $label-top;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        top: 1em;
      }

      // $top - size of subtext + 4px underline bottom padding
      .mat-form-field-underline {
        bottom: calc($top + 4px);
      }

      .mat-form-field-subscript-wrapper {
        font-size: 100%; //override 75%;
        margin-top: 0;
        top: calc(100% - #{$top});
      }
    }
  }
}

@mixin form-field-colors($theme, $custom-typography) {
  $text-label: ra-ui-color($theme, foreground, text-label);
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $focus: ra-ui-color($theme, primary, 600);
  $underline-enabled: ra-ui-color($theme, background, inactive);
  $error: map-get(ra-ui-status-palette($theme, $status-palette), error);

  .mat-form-field-subscript-wrapper ra-ui-static-text,
  .mat-form-field-label ra-ui-static-text,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $text-label;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $underline-enabled;
  }

  .mat-input-element {
    color: $text-primary;
  }

  .mat-form-field {
    //type of label is Never or Auto (only on the bottom)
    &:not(.mat-form-field-should-float),
    &.mat-form-field-disabled {
      .ra-ui-placeholder {
        visibility: hidden;
      }
    }

    //type of label is Always or Auto (only on the top)
    &.mat-form-field-should-float {
      .ra-ui-placeholder {
        @include placeholder-styling($theme, $custom-typography);
      }
    }
  }

  .mat-form-field {
    .static-text[disabled='true'] {
      color: $text-disabled;
    }

    .mat-form-field-underline .mat-form-field-ripple {
      background-color: $underline-enabled;
    }

    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-form-field-label-wrapper .mat-form-field-label,
        .mat-form-field-label ra-ui-static-text {
          color: $focus;
        }

        .mat-form-field-underline .mat-form-field-ripple {
          background-color: $focus;
        }
      }
    }

    &.mat-form-field-invalid {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $error;
        }
      }

      .mat-form-field-underline .mat-form-field-ripple {
        background-color: $error;
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $error;
      }
    }

    &.mat-form-field-invalid {
      @include ra-ui-override-mat-error-message-colors($theme);
    }

    &.mat-form-field-disabled {
      .mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          $text-disabled 0%,
          $text-disabled 33%,
          transparent 0%
        );

        .mat-form-field-ripple {
          background-color: $text-disabled;
        }
      }

      .mat-input-element {
        color: $text-disabled;
      }

      //hidden placeholder when a component is disabled
      .mat-input-element::placeholder {
        opacity: 0;
      }
    }
  }
}

%hide-dirty-flag {
  ra-ui-dirty-flag {
    display: none;
  }
}

@mixin select-label-width($custom-typography) {
  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-size-input: ra-ui-font-size($custom-typography, input);
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  @if (
    type-of($font-size-icon-s) == string or type-of($font-size-input) == string
  ) {
    $font-size-icon-s: rafl.$rafl-core-font-size-75;
    $font-size-input: rafl.$rafl-typography-utility-md-font-size;
  }
  $relative-size: strip-unit(getRelative($font-size-icon-s, $font-size-input));
  $select-arrow-width: ($arrow-width * $relative-size) +
    ($arrow-padding * $relative-size);

  width: calc(100% - #{$select-arrow-width});
}

@mixin blank-when-disabled($custom-typography) {
  $values: get-mat-form-field-prop-by-caption($custom-typography);
  $label-top: map_get($values, label-top);

  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-form-field-infix {
      > .mat-input-element,
      .mat-select-value,
      ra-ui-span-input,
      .inner-select-value {
        visibility: hidden;
      }
    }

    //label in mat-form-field set to auto
    &.auto {
      .mat-form-field-appearance-legacy.mat-form-field-should-float
        .mat-form-field-label {
        top: $label-top;
        transform: none;
      }

      // fix after adding explicitly font-size on a static text.
      .mat-form-field-should-float .mat-form-field-label {
        &,
        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
        }
      }

      //select
      .mat-form-field-type-mat-select .mat-form-field-label {
        @include select-label-width($custom-typography);
      }
    }

    //label in mat-form-field set to never
    &.never {
      .mat-form-field-label {
        display: block;
      }
    }

    &.auto,
    &.never {
      //hide dot separator for IP input
      .separator {
        display: none;
      }
    }
  }
}

@mixin blank-when-disabled-checkbox($theme, $custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-checkbox-background {
      visibility: hidden;
    }

    .mat-checkbox-frame {
      border-color: ra-ui-color($theme, foreground, disabled-text) !important;
    }
  }
}

@mixin blank-when-disabled-radio-button($custom-typography) {
  &.blank-when-disabled {
    @extend %hide-dirty-flag;

    .mat-radio-inner-circle {
      visibility: hidden;
    }
  }
}

//Base element width for checkbox is 16px, slide-toggle 36px and radiobutton is 20px
@function get-size-element(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $font-size: ra-ui-font-size($custom-typography, input);
  // cannot use css tokens because $scale-ratio must be unit-less. The '* 16px' converts the size into pixels 0.875rem * 16px = 14px
  // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $font-size: if(
    type-of($font-size) == string,
    strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
    $font-size
  );

  // relative value representing how the element default dimensions (font-size 14px) should be increased
  $coefficient: strip-unit(getRelative($new-size, 14));

  // new size of element
  $container-width: $font-size * $coefficient;

  // the ratio is calculated with new size of element relative to the base width of element
  $scale-ratio: strip-unit(getRelative($container-width, $base-element-width));

  // element is aligned to the center, so calculated space is divided by 2 for it to be divided to both sides
  $space: ($container-width - $base-element-width) * 0.5;

  @if ($scale-ratio == 1) {
    $space: 0px;
  }

  @return (width: $container-width, ratio: $scale-ratio, space: $space);
}

@mixin container-size(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $ratio: map_get($sizes, ratio);

  transform: scale($ratio);
  margin-left: $space;
}

@mixin label(
  $custom-typography,
  $base-element-width,
  $new-size: $updated-size
) {
  $sizes: get-size-element($custom-typography, $base-element-width, $new-size);
  $space: map_get($sizes, space);
  $width: map_get($sizes, width);
  $inner-padding: getRelative(8, 14);

  $input-line-height: ra-ui-line-height($custom-typography, input);

  line-height: 1;
  height: calc($input-line-height * ra-ui-font-size($custom-typography, input));
  width: calc(100% - #{$inner-padding} - #{$width} + 1px);
  margin: auto 0 auto $space;
  padding-left: $inner-padding;
}

@mixin override-ripple-effects($width) {
  $double-width: $width * 2;

  .mat-ripple {
    left: calc(50% - #{$width});
    top: calc(50% - #{$width});
    height: $double-width;
    width: $double-width;
  }

  .mat-ripple-element {
    left: 0 !important;
    top: 0 !important;
    width: $double-width !important;
    height: $double-width !important;
  }
}

@mixin override-checkbox-material($custom-typography) {
  .mat-checkbox-inner-container {
    @include container-size($custom-typography, $default-checkbox-width);
    margin-right: 0;

    // 17.5px is size of checkbox after calculated from base size in material of ripple container (20px).
    @include override-ripple-effects(17.5px);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    @include label($custom-typography, $default-checkbox-width);
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-checkbox-persistent-ripple {
    transform: none;
  }
}

@mixin override-radiobutton-material($custom-typography) {
  .mat-radio-container {
    @include container-size($custom-typography, $default-radio-button-width);

    @include override-ripple-effects($default-radio-button-width);
  }

  mat-radio-button .mat-radio-label .mat-radio-label-content {
    @include label($custom-typography, $default-radio-button-width);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(
      0.5
    ); //material's declaration, need defined again, because below declaration override origin material's declaration
  }

  .mat-radio-inner-circle {
    transform: scale(0); //remove a dot when radio-button is unchecked
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
  }

  .mat-radio-persistent-ripple {
    transform: none;
  }
}

@mixin form-field-enhancement($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles($custom-typography, input);
  &.ra-ui-fill-parent,
  &.fill-parent {
    width: 100%;
  }
  .mat-form-field {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding-top: $form-field-vertical-padding;
    padding-bottom: $form-field-vertical-padding;
    width: $default-mat-form-field-width;

    .mat-form-field-infix {
      padding: 0;
      // we need to add em suffix since Input line-height property is restricted to be relative, it cannot use absolute pixels
      height: ra-ui-line-height($custom-typography, input) * 1em;
    }

    @include caret-color();

    @include safari-only-specific() {
      @include caret-color($theme);
    }

    .ra-ui-spacing {
      position: absolute;
      width: 100%;
      height: 5px;
    }

    &.ra-ui-fill-parent {
      // fixes for grid - grid sets `border-box` to all containers
      box-sizing: content-box;
      width: 100%;

      &.ra-ui-horizontal-paddings {
        width: calc(100% - #{$form-field-horizontal-padding * 2});
      }
    }

    &.ra-ui-horizontal-paddings {
      padding-left: $form-field-horizontal-padding;
      padding-right: $form-field-horizontal-padding;
    }
  }

  &.flat-mode .ra-ui-fill-parent.ra-ui-error-icon,
  &.grid-mode .ra-ui-fill-parent.ra-ui-error-icon {
    // fixes for grid - grid sets `border-box` to all containers
    box-sizing: content-box;
    //add more space for preventing to wrap to the next line. /1px/
    width: calc(
      100% - #{$width-flat-mode-error} - #{$form-field-horizontal-padding * 2} -
        1px
    );
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .mat-icon {
      height: 1em;
    }
  }

  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    color: ra-ui-color($theme, foreground, text-primary);
  }

  @include hint-messages();

  @include flat-mode-feature();
  @include grid-mode-feature();
  @include remove-message-space-feature();

  @include create-label-by-caption-from-typography($custom-typography);
  @include form-field-colors($theme, $custom-typography);

  @include blank-when-disabled($custom-typography);
}

@mixin checkbox-enhancement($theme, $custom-typography) {
  @include override-checkbox-material($custom-typography);
  @include blank-when-disabled-checkbox($theme, $custom-typography);
}

@mixin radio-button-enhancement($custom-typography) {
  @include override-radiobutton-material($custom-typography);
  @include blank-when-disabled-radio-button($custom-typography);
}

@mixin prefix-suffix(
  $theme,
  $custom-typography,
  $prefix-selector,
  $suffix-selector
) {
  $text-disabled: ra-ui-color($theme, foreground, text-disabled);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $font-size: ra-ui-font-size($custom-typography, input);
  $selector-padding: calc($font-size * strip-unit($suffix-prefix-padding));

  #{$prefix-selector} {
    box-sizing: border-box;
    padding-right: $selector-padding;
    display: inline-flex;
    vertical-align: top;

    &:empty {
      padding-right: 0;
    }
  }

  #{$suffix-selector} {
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: $selector-padding;

    &:empty {
      padding-left: 0;
    }
  }

  .mat-form-field-suffix,
  .mat-form-field-prefix {
    display: flex;
    color: $text-primary;

    [matprefix],
    [matsuffix] {
      vertical-align: middle;
    }
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        form-field-text
      );
      color: $text-primary;
    }

    .mat-icon,
    mat-icon {
      font-size: ra-ui-font-size($custom-typography, icon-s);
      line-height: ra-ui-line-height($custom-typography, icon-s);
      vertical-align: middle;
      display: inline;

      color: $text-primary;
    }

    .disabled {
      color: $text-disabled;

      ra-ui-static-text,
      .mat-icon,
      mat-icon {
        color: $text-disabled;
      }
    }
  }

  .disabled {
    color: ra-ui-color($theme, foreground, $text-disabled);
  }
}

@mixin readonly-form-field-appearance($theme) {
  //removes underline
  &.mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    display: none;
  }

  //placeholder and messages color
  &.mat-form-field.mat-form-field-invalid {
    mat-placeholder .static-text,
    mat-label .static-text,
    mat-hint .static-text,
    mat-error .static-text {
      color: ra-ui-color($theme, foreground, text-label);
    }

    //set correct color only for a value
    .mat-form-field-infix > *:first-child .static-text {
      color: ra-ui-color($theme, foreground, text-primary);
    }
  }
}

@mixin ra-ui-override-mat-error-message-colors($theme) {
  @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
    &.error-message-color-#{$name} {
      mat-label,
      mat-error {
        .static-text:not([disabled='true']) {
          color: $color;
        }
      }

      .mat-form-field-underline {
        background-color: $color;

        .mat-form-field-ripple {
          background-color: $color;
        }
      }

      &.mat-form-field-can-float .mat-form-field-label {
        color: $color;
      }
    }
  }
}

@mixin ra-ui-ag-grid-params($theme, $custom-typography) {
  --ra-active-color: #{ra-ui-color($theme, primary, A200)};
  --ra-hover-background-color: #{ra-ui-color($theme, primary, 200)};
  --ra-hover-foreground-color: #{ra-ui-contrast($theme, primary, 200)};
  --ra-selected-background-color: #{ra-ui-color($theme, primary, 600)};
  --ra-selected-foreground-color: #{ra-ui-contrast($theme, primary, 600)};
  --ra-row-background-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-04
    )}; // default row color
  --ra-unfocused-background-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-05
    )};
  --ra-unfocused-border-color: #{ra-ui-color($theme, background, inactive)};
  --ra-card-header-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-05
    )};
  --ra-active-text: #{ra-ui-color($theme, foreground, text-label)};
  --ra-scrollbar-background-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-04
    )};
  --ra-ui-active-focus-border: #{ra-ui-color(
      $theme,
      background,
      interactive-component-01
    )};
  --ra-ui-text-primary: #{ra-ui-color($theme, foreground, text-primary)};
  --ra-ui-header-font-size: #{ra-ui-font-size($custom-typography, subheading-1)};
  --ra-ui-header-font-weight: #{ra-ui-font-weight(
      $custom-typography,
      subheading-1
    )};

  --ag-background-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-01
    )}; // grid background
  --ag-foreground-color: #{ra-ui-contrast(
      $theme,
      background,
      interactive-component-01
    )};
  --ag-data-color: #{ra-ui-contrast(
      $theme,
      background,
      interactive-component-04
    )};
  --ag-header-background-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-05
    )};
  --ag-header-foreground-color: #{ra-ui-contrast(
      $theme,
      background,
      interactive-component-05
    )};
  --ag-border-color: #{ra-ui-color($theme, background, interactive-component-06)};
  --ag-secondary-border-color: #{ra-ui-color(
      $theme,
      background,
      interactive-component-06
    )};
  --ag-row-hover-color: var(--ra-hover-background-color);
  --ag-control-panel-background-color: var(--ag-background-color);

  --ag-input-border-color: var(--ag-border-color);

  --ag-checkbox-checked-color: var(--ra-selected-background-color);
  --ag-checkbox-unchecked-color: #{ra-ui-color($theme, background, inactive)};
  --ag-checkbox-indeterminate-color: var(--ra-selected-background-color);
  --ag-checkbox-background-color: var(--ag-background-color);

  --ag-selected-row-background-color: var(--ra-selected-background-color);
  --ag-secondary-foreground-color: #{ra-ui-contrast(
      $theme,
      background,
      interactive-component-05
    )};
  --ag-range-selection-border-color: var(--ra-active-color);

  // BORDERS
  --ag-borders-side-button: none;
  --ag-border-radius: 0px;

  --ag-header-column-resize-handle-display: none;
  --ag-header-column-separator-display: display;
  --ag-header-column-separator-color: var(--ag-border-color);
  --ag-cell-horizontal-border: solid var(--ag-border-color);
  --ag-row-border-color: var(--ag-border-color);

  // SIZING
  --ag-grid-size: 4px;
  --ag-icon-size: 16px;
  --ag-row-height: calc(
    var(--ag-grid-size) * 7
  ); // implied style height - height is calculated and may not be exact
  --ag-header-height: calc(
    var(--ag-grid-size) * 6.25
  ); // implied style height - height is calculated and may not be exact
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2);
  --ag-cell-widget-spacing: calc(var(--ag-grid-size) * 2);
  --ag-list-item-height: calc(var(--ag-grid-size) * 7);
  --ag-column-select-indent-size: var(--ag-icon-size);
  --ag-row-group-indent-size: 10px;

  /* columns menu tab */
  --ag-widget-container-vertical-padding: calc(var(--ag-grid-size) * 2);
  --ag-widget-container-horizontal-padding: calc(var(--ag-grid-size) * 2);
  --ag-widget-horizontal-spacing: calc(var(--ag-grid-size) * 2);
  --ag-widget-vertical-spacing: calc(var(--ag-grid-size) * 1.5);

  // FONTS
  --ag-font-family: #{ra-ui-font-family($custom-typography)};
  --ag-font-size: #{ra-ui-font-size($custom-typography, body-2)};

  /* use alpine icon fonts */
  //--ag-icon-font-family: $ag-theme-alpine-icon-font-family,
  //--ag-icons-data: $ag-theme-alpine-icons-data,
  //--ag-icons-font-codes: $ag-theme-alpine-icons-font-codes,

  --ag-input-focus-border-color: var(--ra-active-color /*, $opacity: 0.4*/);
  --ag-input-focus-box-shadow: 0 0 2px 0.1rem var(--ag-input-focus-border-color);

  // MISC
  --ag-card-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  --ag-popup-shadow: var(--ag-card-shadow);

  // OTHER
  --ra-input-line-height: #{ra-ui-line-height($custom-typography, input)};
  --ra-secondary-text: #{ra-ui-color($theme, foreground, secondary-text)};
  --ra-cell-padding: 0 0.75em 0;
}

@mixin ra-ui-default-cell-renderer-theme($theme, $custom-typography) {
  .ra-ui-default-cell-renderer {
    &__icon {
      @include ra-ui-typography-level-to-styles($custom-typography, icon-m);
    }
  }
}

@mixin grid-theme($theme, $custom-typography, $status-palette) {
  @include ra-ui-ag-grid-styling($theme, $custom-typography, $status-palette);
  @include ra-ui-default-cell-renderer-theme($theme, $custom-typography);
}

// File to include additional scss variables and mixins
// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// File to include additional scss variables and mixins
// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin _ra-ui-badge-directive-theme($theme, $custom-typography) {
  $base-fz: 12px;

  mat-icon.ra-ui-badge-directive {
    overflow: visible;

    &--position-outside-element {
      display: flex;
    }
  }

  .ra-ui-badge-directive {
    position: relative;

    &--position {
      // changes display property adopter's parent container to flex
      &-outside-element {
        display: flex;
        align-items: center;

        // a badge is before the text.
        &--before ra-ui-badge {
          order: -1;
        }

        // fixes the use case when the content overflows from parent container
        > * {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        ra-ui-badge {
          position: static;
          margin: 0 getRelative(8px, $base-fz);
          transform: none;
        }
      }
    }
  }
}

//Mixin sets styles of badge for sizes: small and large.
//The size indicator has own styles is very specific size.
@mixin _setBadgeSizeStyles($custom-typography, $typography-level) {
  $line-height: ra-ui-line-height($custom-typography, $typography-level);
  $half-height: if(
    type-of($line-height) == string,
    // if line-height is token it is not given as px value, but only as coefficient 1.3
    calc(
        $line-height * 0.5 *
          ra-ui-font-size($custom-typography, $typography-level)
      ),
    // "* font-size" converts the coefficient to px
    $line-height * 0.5
  );
  border-radius: $half-height;

  right: $half-height;
  // badge centered to the middle of the container.
  top: calc($half-height * -1);

  &,
  ra-ui-static-text {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      $typography-level
    );
  }
}

@mixin ra-ui-badge-component-theme($theme, $custom-typography) {
  $base-fz: 12px;
  $size: 8px;

  @include _ra-ui-badge-directive-theme($theme, $custom-typography);

  ra-ui-badge {
    &.ra-ui-badge {
      padding: 0 getRelative(4px, $base-fz);
      cursor: default;
      z-index: 999;

      &--color {
        $badge-status-palette: ra-ui-status-palette($theme, $status-palette);
        $contrast-status: ra-ui-status-palette-contrast(
          $theme,
          $status-palette
        );

        // background in status colors
        @each $name, $color in $badge-status-palette {
          &-#{$name} {
            background-color: $color;
          }
        }

        // text and border in contrast status colors
        @each $name, $color in $contrast-status {
          &-#{$name} {
            border: 1px solid #{$color};

            .ra-ui-badge--content {
              color: #{$color};
            }
          }
        }

        // exceptions for status color which is badge-specific
        &-status {
          $status-contrast-color: ra-ui-contrast($theme, background, inactive);
          background-color: ra-ui-color($theme, background, inactive);
          border: 1px solid $status-contrast-color;
          .ra-ui-badge--content {
            color: $status-contrast-color;
          }
        }
      }

      &--size {
        &-indicator {
          $half-size: $size * 0.5;

          border-radius: 50%;
          font-size: $size;
          height: $size;
          width: $size;
          min-width: $size;
          display: inline;
          padding: 0;
          top: -$half-size;
          right: $half-size;

          .ra-ui-badge {
            &--content {
              line-height: 1em;
            }
            padding: 0;
          }
        }

        &-small {
          @include _setBadgeSizeStyles($custom-typography, caption-bold);
        }

        &-medium {
          @include _setBadgeSizeStyles($custom-typography, body-1-bold);
        }
      }

      &--hidden {
        display: none;
      }
    }

    .ra-ui-badge--content {
      height: 100%;
      cursor: default;

      .static-text {
        // disabled to wrap static to the next line.
        white-space: nowrap;
      }
    }
  }
}

@mixin ra-ui-loading-spinner-theme($theme, $custom-typography) {
  ra-ui-loading-spinner {
    display: inline-block;

    // prevent animation from making dimensions greater than the specified size
    overflow: hidden;
    // prevent width cropping in flex layouts
    flex: 0 0 auto;

    .default-spinner-icon {
      color: dtu.design-token-var(
        rafl-color-state-active-default,
        ra-ui-color($theme, primary, 500)
      );
      display: flex;
    }

    .ra-ui-loading-spinner-wrapper {
      width: max-content;
      height: max-content;

      $loading-spinner-sizes: s m l xl xxl;
      $token-size-map: (
        s: sm,
        m: md,
        l: lg,
        xl: xl,
        xxl: xxl,
      );

      @each $size in $loading-spinner-sizes {
        &.ra-ui-loading-spinner-size-#{$size} {
          $loading-spinner-size: ra-ui-font-size(
            $custom-typography,
            icon-#{$size}
          );

          .ra-ui-loading-spinner-flipper,
          .ra-ui-loading-spinner-heartbeat,
          .ra-ui-loading-spinner-heartbeat2,
          .ra-ui-loading-spinner-spinner,
          .ra-ui-loading-spinner-triplet > div {
            width: $loading-spinner-size;
          }

          .ra-ui-loading-spinner-spinner {
            height: $loading-spinner-size;
          }

          .default-spinner-icon {
            width: $loading-spinner-size;
            height: $loading-spinner-size;
            font-size: $loading-spinner-size;
          }
        }
      }
    }
  }
}

@mixin ra-ui-progress-bar-theme($theme, $custom-typography) {
  $bar-background-color: ra-ui-color($theme, background, interactive-alt-01);
  $bar-fill-color: ra-ui-color($theme, background, progress);
  $progress-bar-height: getRelative(8px, 12px);
  $space-between-bar-and-message: getRelative(8px, 12px);
  $progress-bar-min-width: getRelative(40px, 12px);

  ra-ui-progress-bar {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    ra-ui-label-container {
      @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    }

    .ra-ui-progress-bar-label .ra-ui-container,
    .ra-ui-progress-bar-wrapper {
      min-width: $progress-bar-min-width;
    }

    .ra-ui-progress-bar.mat-progress-bar {
      height: $progress-bar-height;
      cursor: default;

      .mat-progress-bar-buffer {
        background-color: $bar-background-color;
      }
      div.mat-progress-bar-element:after {
        background-color: $bar-fill-color;
      }
      .mat-progress-bar-background {
        fill: $bar-background-color;
      }
    }

    .ra-ui-progress-bar-message-wrapper {
      margin-top: $space-between-bar-and-message;
      ra-ui-static-text {
        @include hint-message-appearance($theme, $custom-typography);
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

// Gets a color from a theme palette (the output of mat-palette).
// The hue can be one of the standard values (500, A400, etc.), one of the three preconfigured
// hues (default, lighter, darker), or any of the aforementioned prefixed with "-contrast".
//
// @param $theme The theme contains palettes created by material function (foreground, background, primary, secondary, warn).
// @param $palette The key of palette in $theme.
// @param $hue The hue from the palette to use.
// @param $opacity The alpha channel value for the color.
@function ra-ui-color($theme, $palette, $hue: default, $opacity: null) {
  @if (
    index((foreground, background, primary, accent, warn), $palette) == null
  ) {
    @error 'The #{$palette} does not exist';
  }

  $searched-pallet: map-get($theme, $palette);
  $color: map-get($searched-pallet, $hue);

  //A colors does not exist on a material pallets, checks searching in custom pallets.
  @if ($color == null) {
    $defaults: ();
    @if ($palette == foreground) {
      $defaults: (
        text-label:
          var(--rafl-color-foreground-label, rafl.$rafl-color-foreground-label),
        text-disabled:
          var(
            --rafl-color-state-disabled-default,
            rafl.$rafl-color-state-disabled-default
          ),
        text-primary:
          var(
            --rafl-color-foreground-primary,
            rafl.$rafl-color-foreground-primary
          ),
        text-value-disabled: #7e5986,
        // non existing variable token
        force-value-disabled:
          #bd4200,
        // non existing variable token
        immediate-commit:
          var(
            --rafl-color-state-immediate-commit,
            rafl.$rafl-color-state-immediate-commit
          ),
      );
    } @else if($palette == background) {
      $defaults: (
        interactive-component-01: #ffffff,
        interactive-component-02: #f8fbfc,
        interactive-component-03: #f1f4f6,
        interactive-component-04: #e8ebed,
        interactive-component-05: #dbdfe2,
        interactive-component-06: #b8bcbf,
        interactive-component-07: #3e4144,
        interactive-alt-01: #c9d5dd,
        interactive-alt-02: #889cac,
        progress: #57d9a3,

        ui-background-01:
          var(--rafl-color-background-ui, rafl.$rafl-color-background-ui),
        disabled:
          var(
            --rafl-color-state-disabled-default,
            rafl.$rafl-color-state-disabled-default
          ),
        inactive-hover:
          var(
            --rafl-color-state-inactive-hovered,
            rafl.$rafl-color-state-inactive-hovered
          ),
        inactive:
          var(
            --rafl-color-state-inactive-default,
            rafl.$rafl-color-state-inactive-default
          ),
      );
    }

    $color: map-get($defaults, $hue);
  }

  @if (type-of($color) == string) {
    // color is CSS variable
    @if ($opacity == null) {
      @return $color;
    }
    // If the $color resolved to something a CSS variable, color-mix can transparentize the color in CSS variable
    @return color-mix(in srgb, $color $opacity * 100%, transparent);
  }

  @if (type-of($color) != color) {
    // If the $color resolved to something different from a color (e.g. a CSS variable),
    // we can't apply the opacity anyway so we return the value as is, otherwise Sass can
    // throw an error or output something invalid.
    @return $color;
  }

  @return rgba($color, if($opacity == null, opacity($color), $opacity));
}

// For a given hue in a palette, return the contrast color from the map of contrast palettes.
// Contains default contrast color of background pallet
// @param $color-map
// @param $hue
@function ra-ui-contrast($theme, $palette, $hue) {
  @if (index((background, primary, accent, warn), $palette) == null) {
    @error 'The #{$palette} does not exist';
  }

  $searched-pallet: map-get($theme, $palette);
  $contrast-pallet: map-get($searched-pallet, contrast);

  // map of contrast does not exist.
  @if ($contrast-pallet == null) {
    $defaults: ();
    @if ($palette == background) {
      // NOTE - contrast palettes do not have tokens yet (0.2.0)
      $defaults: (
        ui-background-01: #000000,
        interactive-component-01: #000000,
        interactive-component-02: #000000,
        interactive-component-03: #000000,
        interactive-component-04: #000000,
        interactive-component-05: #000000,
        interactive-component-06: #000000,
        interactive-component-07: #ffffff,
        disabled: #000000,
        inactive-hover: #ffffff,
        inactive: #ffffff,

        interactive-alt-01: #000000,
        interactive-alt-02: #000000,
        progress: #000000,
      );
    }

    $color: map-get($defaults, $hue);

    @return $color;
  }

  @return map-get($contrast-pallet, $hue);
}

/**
* TODO: remove $status-palette
* Ideally, this shouldn't be here at all. It serves as a default for the following functions.
* Removing it breaks CI. Reworking the functions would result in severe breaking changes.
* This needs to be tackled when we decide to clean up this whole theme mess.
 */
$status-palette: (
  alarm: #e51400,
  error: #aa0000,
  success: #12850a,
  warning: #ffcc00,
  information: #235d9f,
  pending: #8f2ad2,
);

// Creates a container object for a light theme to be given to individual component theme mixins.
@function ra-ui-light-theme(
  $primary,
  $accent: mat.define-palette(mat.$green-palette, 500, 100, 900),
  $warn: mat.define-palette(mat.$red-palette),
  $foreground: $ra-ui-light-theme-foreground,
  $background: $ra-ui-light-theme-background,
  $status-palette: $status-palette
) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $foreground,
    background: $background,
    status-palette: $status-palette
  );
}

// Creates a container object for a dark theme to be given to individual component theme mixins.
@function ra-ui-dark-theme(
  $primary,
  $accent: mat.define-palette(mat.$pink-palette),
  $warn: mat.define-palette(mat.$red-palette),
  $foreground: mat.$dark-theme-foreground-palette,
  $background: mat.$dark-theme-background-palette,
  $status-palette: $status-palette
) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: true,
    foreground: $foreground,
    background: $background,
    status-palette: $status-palette
  );
}

/**
* Returns status palette WITHOUT the contrast colors.
 */
@function ra-ui-status-palette($theme, $status-palette) {
  $theme-status-palette: map-get($theme, status-palette);
  /**
  * status palette exists in the theme and has contrast defined
  * --> strip contrast and return the theme's status palette without the contrast
   */
  @if $theme-status-palette and map-get($theme-status-palette, contrast) {
    $theme-contrast-status-palette: map-remove($theme-status-palette, contrast);
    @return $theme-contrast-status-palette;
  }
  /**
  * status palette exists in the theme, but has no contrast defined (if it did, previous if-branch would be executed)
  * --> simply return the theme's status palette
   */
  @else if $theme-status-palette {
    @return $theme-status-palette;
  }
  /**
  * when there is no status palette in the theme
   */
  @else {
    @return $status-palette;
  }
}

/**
* Returns ONLY the contrast colors of status palette.
 */
@function ra-ui-status-palette-contrast($theme, $status-palette) {
  $theme-status-palette: map-get($theme, status-palette);
  // when there is no status palette in the theme
  @if ($theme-status-palette == null) {
    @return $status-palette;
  }

  $status-contrast: map-get($theme-status-palette, contrast); //
  @return if($status-contrast, $status-contrast, $status-palette);
}

@mixin indicators-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include ra-ui-badge-component-theme($theme, $custom-typography);
  @include ra-ui-loading-spinner-theme($theme, $custom-typography);
  @include ra-ui-progress-bar-theme($theme, $custom-typography);
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

@mixin ra-ui-resizable-border-theme($theme) {
  ra-ui-resizable-border {
    .ra-ui-resizable-border {
      &__visual {
        background-color: ra-ui-color(
          $theme,
          background,
          interactive-component-06
        );
      }

      &__handle {
        background-color: ra-ui-color($theme, background, inactive);
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin chips-typography($custom-typography) {
  $mat-chip-min-height: 2em; //32px;
  $mat-chip-vertical-padding: 0.4375em; //7px;
  $mat-chip-horizontal-padding: 0.75em; //12px;

  $mat-chip-remove-vertical-padding: 0.4375em; //7px;
  $mat-chip-remove-before-margin: 0.5em; //8px;
  $mat-chip-remove-after-padding: 0.5em; //8px;

  $mat-chip-avatar-vertical-padding: 0;
  $mat-chip-avatar-before-padding: 0;
  $mat-chip-avatar-before-margin: 0.25m; //4px;
  $mat-chip-avatar-after-margin: 0.5em; //8px;

  $mat-chips-chip-margin: 0.25em; //4px;

  $mat-chip-input-width: 9.375em; //150px;
  $mat-chip-input-margin: 0.25em; //4px;

  $mat-chip-avatar-size: 1.5em; //24px;
  $mat-chip-remove-size: 1.125em; //18px;

  .mat-chip,
  .mat-chip-list-wrapper {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
  }

  .mat-chip .mat-chip-remove.mat-icon,
  .mat-chip .mat-chip-trailing-icon.mat-icon {
    font-size: ra-ui-font-size($custom-typography, body-1);
  }

  .mat-standard-chip {
    @include mat.elevation-transition();
    padding: $mat-chip-vertical-padding $mat-chip-horizontal-padding;
    border-radius: 1em; //16px;
    min-height: $mat-chip-min-height;

    .mat-chip-remove.mat-icon {
      width: $mat-chip-remove-size;
      height: $mat-chip-remove-size;
      line-height: $mat-chip-remove-size;
    }

    &.mat-chip-with-trailing-icon.mat-chip-with-avatar,
    &.mat-chip-with-avatar {
      padding-top: $mat-chip-avatar-vertical-padding;
      padding-bottom: $mat-chip-avatar-vertical-padding;
    }

    &.mat-chip-with-trailing-icon.mat-chip-with-avatar {
      padding-right: $mat-chip-remove-after-padding;
      padding-left: $mat-chip-avatar-before-padding;

      [dir='rtl'] & {
        padding-left: $mat-chip-remove-after-padding;
        padding-right: $mat-chip-avatar-before-padding;
      }
    }

    &.mat-chip-with-trailing-icon {
      padding-top: $mat-chip-remove-vertical-padding;
      padding-bottom: $mat-chip-remove-vertical-padding;
      padding-right: $mat-chip-remove-after-padding;
      padding-left: $mat-chip-horizontal-padding;

      [dir='rtl'] & {
        padding-left: $mat-chip-remove-after-padding;
        padding-right: $mat-chip-horizontal-padding;
      }
    }

    &.mat-chip-with-avatar {
      padding-left: $mat-chip-avatar-before-padding;
      padding-right: $mat-chip-horizontal-padding;

      [dir='rtl'] & {
        padding-right: $mat-chip-avatar-before-padding;
        padding-left: $mat-chip-horizontal-padding;
      }
    }

    .mat-chip-avatar {
      width: $mat-chip-avatar-size;
      height: $mat-chip-avatar-size;
      margin-right: $mat-chip-avatar-after-margin;
      margin-left: $mat-chip-avatar-before-margin;

      [dir='rtl'] & {
        margin-left: $mat-chip-avatar-after-margin;
        margin-right: $mat-chip-avatar-before-margin;
      }
    }

    .mat-chip-remove,
    .mat-chip-trailing-icon {
      width: $mat-chip-remove-size;
      height: $mat-chip-remove-size;
    }

    .mat-chip-remove,
    .mat-chip-trailing-icon {
      margin-left: $mat-chip-remove-before-margin;

      [dir='rtl'] & {
        margin-right: $mat-chip-remove-before-margin;
      }
    }
  }

  .mat-chip-list-wrapper {
    margin: -$mat-chips-chip-margin;

    input.mat-input-element,
    .mat-standard-chip {
      margin: $mat-chips-chip-margin;
    }
  }

  input.mat-chip-input {
    width: $mat-chip-input-width;
    margin: $mat-chip-input-margin;
    flex: 1 0 $mat-chip-input-width;
  }
}

@mixin accordion-item-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $background-color: map-get(map-get($theme, background), background);
  $gap: getRelative(4, 12); //4px

  ra-ui-accordion-item {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);

    // overrides padding for the ra-ui-icon-button with more specific css rule
    ra-ui-icon-button.accordion-icon-button .mat-icon-button {
      padding: 0;
    }

    .accordion-cursor-pointer {
      @include getCursor($custom-global-config);

      ra-ui-static-text .static-text {
        @include getCursor($custom-global-config);
      }
    }

    .accordion-header {
      @include ra-ui-background-palette-colors($theme);
      color: ra-ui-color($theme, foreground, text-primary);
      padding: $accordion-vertical-padding $accordion-horizontal-padding;
      user-select: none;
      top: 0;
      z-index: 2;

      &.sticky-header {
        position: sticky;

        // Safari specific
        &:not(*:root) {
          position: -webkit-sticky;
        }
      }

      &:not(.ra-ui-accordion-item-disable-toggle) {
        &:hover {
          background-color: ra-ui-color($theme, primary, 200);
        }

        &:active {
          background-color: ra-ui-color($theme, primary, 600);
          mat-icon::before,
          * {
            color: ra-ui-contrast($theme, primary, 600);
          }
        }
      }

      &.ra-ui-keyboard-focus {
        $size: getRelative(2, 12);

        outline: $size solid ra-ui-color($theme, primary, A200);
        outline-offset: -$size;
      }

      &:not(.accordion-cursor-pointer) {
        cursor: default;
      }

      &:not(.ra-ui-keyboard-focus):focus {
        outline: none;
      }
    }

    .accordion-title {
      overflow: hidden;
      cursor: inherit;
    }

    .ra-ui-accordion-item-title-container {
      display: grid;
      width: 100%;
      grid-template: 1fr / auto 1fr;
      grid-template-areas:
        'toggle title'
        'empty subtitle';
      grid-column-gap: $gap;
      align-items: center;

      &.ra-ui-accordion-item-title-container-disable-toggle {
        grid-template: 1fr / 1fr;
        grid-template-areas:
          'title'
          'subtitle';
      }

      .accordion-expand-icon {
        grid-area: toggle;
      }

      .ra-ui-accordion-item-title {
        grid-area: title;
      }

      .ra-ui-accordion-item-subtitle {
        @include ra-ui-typography-level-to-styles($custom-typography, caption);
        grid-area: subtitle;
      }

      .ra-ui-accordion-item-title,
      .ra-ui-accordion-item-subtitle {
        min-width: 0;
      }
    }

    // give 1px margin-bottom to body container, so the stacked headers have 1px gap
    .ra-ui-accordion-item-collapsed {
      margin-bottom: getRelative(1, 12); //1px
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// this value is defined in `legacy-nav-bar.component.ts`,
// if you change this one, you should also update value in ts, `iconActionWidthRelative`
$legacy-nav-bar-height: 2.8572em; //40px

//override material's opacity 0.12 on buttons in action-bar and legacy-nav-bar
%override-button-opacity {
  %opacity {
    opacity: 1;
  }

  button.mat-button:hover .mat-button-focus-overlay {
    @extend %opacity;
  }

  $buttons: mat-button mat-icon-button;
  $types-of-focused: cdk-keyboard-focused cdk-program-focused;

  @each $button in $buttons {
    @each $type in $types-of-focused {
      .#{$button}.#{$type} .mat-button-focus-overlay {
        @extend %opacity;
      }
    }
  }
}

@mixin ra-ui-pagination-theme($theme, $custom-typography) {
  $textColor: ra-ui-color($theme, foreground, text-label);
  $font-size: ra-ui-font-size($custom-typography, form-field-text);
  $basic-padding: getRelative(8px, 12px);
  $min-width: getRelative(24px, 12px);
  ra-ui-pagination {
    padding: 0 $basic-padding;
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      form-field-text
    );

    .pagination-label {
      color: $textColor;
      min-width: $min-width;
      padding-right: $basic-padding;

      &.right-text-position {
        padding-right: 0;
        padding-left: $basic-padding;
      }

      ra-ui-static-text {
        @include ra-ui-typography-level-to-styles(
          $custom-typography,
          form-field-text
        );
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$accordion-horizontal-padding: getRelative(8, 12); //8px;
$accordion-vertical-padding: getRelative(4, 12); //4px;

@function getHeightAccordionHeader($custom-typography) {
  $input-font-size: ra-ui-font-size($custom-typography, input);

  $input-line-height: calc(
    ra-ui-line-height($custom-typography, input) * $input-font-size
  );

  @return calc(#{$input-line-height} + #{$accordion-vertical-padding * 2});
}

@mixin ra-ui-card-header-theme($theme, $custom-typography) {
  ra-ui-card-header {
    $body-2-font-size: 14px;
    $header-height: getRelative(40px, $body-2-font-size);
    $basic-padding: getRelative(8px, $body-2-font-size);
    $half-padding: $basic-padding * 0.5;
    $titles-padding-top: getRelative(2px, $body-2-font-size);
    $titles-padding-bottom: getRelative(6px, $body-2-font-size);

    .ra-ui-card-header {
      @include ra-ui-typography-level-to-styles($custom-typography, body-2);
      background-color: ra-ui-color(
        $theme,
        background,
        interactive-component-05
      );
      min-height: $header-height;

      .ra-ui-card-titles {
        padding: $titles-padding-top $basic-padding $titles-padding-bottom;
        color: ra-ui-color($theme, foreground, text-primary);

        &.title-only {
          padding: $basic-padding;
        }

        .ra-ui-card-title {
          @include ra-ui-typography-level-to-styles(
            $custom-typography,
            body-2-bold
          );
        }

        .ra-ui-card-subtitle {
          @include ra-ui-typography-level-to-styles(
            $custom-typography,
            caption
          );
        }
      }

      .ra-ui-card-icons {
        padding: $half-padding $half-padding $half-padding 0;
        cursor: default;
      }
    }
  }
}

@mixin ra-ui-card-header-disabled-theme($theme, $custom-typography) {
  .ra-ui-card-header {
    color: ra-ui-color($theme, foreground, text-disabled);
    background-color: ra-ui-color($theme, background, disabled, 0.24);
  }
}

@mixin ra-ui-card-header-active-theme($theme, $custom-typography) {
  .ra-ui-card-header {
    background-color: ra-ui-color($theme, background, interactive-alt-02);
  }
}

$body-2-font-size: 14;

@mixin ra-ui-card-footer-theme($theme, $custom-typography) {
  ra-ui-card-footer {
    $footer-height: getRelative(36px, $body-2-font-size);
    $basic-padding: getRelative(4px, $body-2-font-size);

    .ra-ui-card-footer {
      @include ra-ui-typography-level-to-styles($custom-typography, body-2);
      padding-left: $basic-padding;
      padding-right: $basic-padding;
      min-height: $footer-height;
      background-color: ra-ui-color(
        $theme,
        background,
        interactive-component-01
      );
      border-top: getRelative(1px, $body-2-font-size) solid
        ra-ui-color($theme, background, interactive-component-05);

      ra-ui-text-button {
        margin-right: $basic-padding;
        margin-left: $basic-padding;
      }
    }
  }
}

@mixin ra-ui-card-footer-disabled-theme($theme, $custom-typography) {
  .ra-ui-card-footer {
    border-top: getRelative(1px, $body-2-font-size) solid
      ra-ui-color($theme, background, disabled, 0.24);
  }
}

@mixin ra-ui-card-footer-active-theme($theme, $custom-typography) {
  .ra-ui-card-footer {
    border-top: getRelative(1px, $body-2-font-size) solid
      ra-ui-color($theme, background, interactive-alt-02);
  }
}

@mixin ra-ui-card-icon-theme($theme, $custom-typography) {
  .ra-ui-card-icon {
    $icon-font-size: ra-ui-font-size($custom-typography, icon-m);
    $icon-padding: getRelative(4px, $body-2-font-size);

    @include ra-ui-typography-level-to-styles($custom-typography, button);
    padding: $icon-padding;
    width: $icon-font-size;
    height: $icon-font-size;
    line-height: $icon-font-size;

    mat-icon {
      width: $icon-font-size;
      height: $icon-font-size;
      font-size: $icon-font-size;
      line-height: $icon-font-size;
    }
  }
}

@mixin ra-ui-card-theme($theme, $custom-typography) {
  ra-ui-card {
    display: inline-flex;
    $body-2-flourish: 14px;
    $basic-padding: getRelative(8px, $body-2-flourish);

    .ra-ui-card {
      @include ra-ui-typography-level-to-styles($custom-typography, body-2);
      @include ra-ui-card-header-theme($theme, $custom-typography);
      @include ra-ui-card-footer-theme($theme, $custom-typography);
      @include ra-ui-card-icon-theme($theme, $custom-typography);

      outline: getRelative(1px, $body-2-flourish) solid
        ra-ui-color($theme, background, interactive-component-05);

      ra-ui-content-projection {
        background-color: ra-ui-color(
          $theme,
          background,
          interactive-component-01
        );

        .ra-ui-content {
          @include ra-ui-typography-level-to-styles($custom-typography, body-2);
          padding: $basic-padding;
        }
      }

      &.disabled {
        @include ra-ui-card-header-disabled-theme($theme, $custom-typography);
        @include ra-ui-card-footer-disabled-theme($theme, $custom-typography);
        outline: getRelative(1px, $body-2-flourish) solid
          ra-ui-color($theme, background, disabled, 0.24);
      }

      &.active {
        @include ra-ui-card-header-active-theme($theme, $custom-typography);
        @include ra-ui-card-footer-active-theme($theme, $custom-typography);
        outline: getRelative(2px, $body-2-flourish) solid
          ra-ui-color($theme, background, interactive-alt-02);
      }

      &.ra-ui-keyboard-focus {
        outline: getRelative(2px, $body-2-flourish) solid
          ra-ui-color($theme, primary, A200);
      }
    }
  }
}

@mixin layout-theme($theme, $custom-typography, $custom-global-config: null) {
  @include accordion-item-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-card-theme($theme, $custom-typography);
  @include ra-ui-resizable-border-theme($theme);
  @include ra-ui-pagination-theme($theme, $custom-typography);
}

/* You can add global styles to this file, and also import other style files */

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
@mixin ra-ui-category-tree-component-theme($theme, $custom-typography) {
  $base-font-size: 14px;

  ra-ui-category-tree {
    &,
    ra-ui-accordion-item,
    .accordion-header ra-ui-static-text {
      @include ra-ui-typography-level-to-styles($custom-typography, body-2);
    }

    .category-tree-root {
      position: relative;
      overflow: auto;
      min-width: getRelative($tree-min-width, $base-font-size) !important;

      .accordion-header .accordion-title > ra-ui-static-text {
        margin-left: $padding-relative-in-lists;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
@mixin _get-background-and-status-colors(
  $theme,
  $dashed: false,
  $tilt-angle: -35deg
) {
  $background-palette: map-get($theme, background);
  $palettes: map_merge(
    get-custom-background-palette($theme),
    $background-palette
  );
  $palettes: map_merge(
    $palettes,
    ra-ui-status-palette($theme, $status-palette)
  );
  $default-color: transparent;

  background: $default-color;

  // the value used in tree.component.ts which has to be the same
  $widthFirstGradientColor: getRelative(4, 14);
  $widthSecondaryGradientColor: $widthFirstGradientColor * 2;

  @if $dashed {
    background: repeating-linear-gradient(
      $tilt-angle,
      $default-color,
      $default-color $widthFirstGradientColor,
      transparent $widthFirstGradientColor,
      transparent $widthSecondaryGradientColor
    );
  }

  @each $hue, $color in $palettes {
    @if $hue != contrast {
      &.#{$hue} {
        background-color: $color;

        @if $dashed {
          background: repeating-linear-gradient(
            $tilt-angle,
            $color,
            $color $widthFirstGradientColor,
            transparent $widthFirstGradientColor,
            transparent $widthSecondaryGradientColor
          );
        }
      }
    }
  }
}
@mixin _ra-ui-tree-node-background-and-text-color(
  $text-color-selected,
  $background-color-selected,
  $background-color-hover
) {
  .ra-ui-tree-node-selected:not(.ra-ui-tree-node-disabled),
  &:not(:focus)
    .ra-ui-tree-node-selected:not(.ra-ui-tree-node-disabled)
    .ra-ui-tree-node-overlay:hover {
    background-color: $background-color-selected;

    ra-ui-badge:not(.ra-ui-badge) .static-text,
    .ra-ui-tree-node .static-text,
    ra-ui-dirty-flag,
    .ra-ui-tree-node-prefix,
    .ra-ui-tree-node-suffix,
    .ra-icon-ide-sm-caret-down,
    .ra-icon-ide-sm-caret-right,
    .ra-ui-tree-icon,
    .ra-ui-tree-extension-icon {
      color: $text-color-selected;
    }
  }

  &:focus {
    .ra-ui-tree-node-selected:not(.ra-ui-tree-node-disabled)
      .ra-ui-tree-node-overlay {
      background-color: $background-color-selected;
      color: $text-color-selected;
    }

    outline: 1px solid transparent;
  }

  .ra-ui-tree-node-selected:not(.ra-ui-tree-node-disabled)
    .mat-progress-spinner,
  .mat-spinner {
    circle {
      stroke: $text-color-selected;
    }
  }

  .ra-ui-tree-node-overlay:hover {
    background-color: $background-color-hover;
  }
}

@mixin ra-ui-tree-component-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $background-color-selected: ra-ui-color($theme, primary, 600);
  $text-contrast-color-selected: ra-ui-contrast($theme, primary, 600);
  $background-color-disabled: transparent;
  $text-color-disabled: ra-ui-color($theme, foreground, text-disabled);
  $background-color-hover: ra-ui-color($theme, primary, 200);
  $border-color-selected: ra-ui-color($theme, primary, A200);

  // inactive
  $inactive-background-color-selected: ra-ui-color(
    $theme,
    background,
    interactive-component-05
  );
  $inactive-text-color-selected: ra-ui-color($theme, foreground, text-primary);
  $inactive-background-color-hover: ra-ui-color(
    $theme,
    background,
    inactive-hover,
    0.1
  );

  $border-color-dragging: ra-ui-color($theme, primary, 900);

  $color-text: ra-ui-contrast($theme, background, interactive-component-03);

  $base-body-2-fz: 14px;
  $base-icon-s-fz: 12px;

  $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
  $font-line-height-s: ra-ui-line-height($custom-typography, icon-s);

  $half-padding-in-px: $padding-relative-in-lists * 0.5;
  $padding-without-border-relative: $padding-relative-in-lists -
    $border-width-relative-in-lists;
  $padding-without-border-half-relative: $half-padding-in-px -
    $border-width-relative-in-lists;

  $padding: $padding-relative-in-lists;
  $padding-half-relative: $half-padding-in-px;
  $padding-without-border: $padding-without-border-relative;
  $padding-without-border-half: $padding-without-border-half-relative;
  $icon-width-and-padding: calc(
    #{$font-size-icon-s} + #{$padding-half-relative}
  );

  $icon-margin-right: getRelative(
    $half-padding-in-px * $base-body-2-fz,
    $base-icon-s-fz
  );

  $height: 2em;
  // calculating a height without vertical borders.
  // Number 2 means top and bottom -> 2 * border width
  $height-without-vertical-border: calc(
    #{$height} - (2 * #{$border-width-relative-in-lists})
  );
  $min-width: getRelative($tree-min-width, $base-body-2-fz);

  @-webkit-keyframes ra-spinner-rotation {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
  }
  @keyframes ra-spinner-rotation {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
  }

  ra-ui-tree {
    &,
    ra-ui-static-text,
    .mat-tree-node,
    .mat-nested-tree-node {
      @include ra-ui-typography-level-to-styles($custom-typography, body-2);
    }

    .mat-tree {
      background: none;
    }

    [class*=' ra-icon-'],
    [class^='ra-icon-'] {
      font-size: $font-size-icon-s;
      line-height: $font-line-height-s;

      width: $font-size-icon-s;
      height: $font-size-icon-s;
    }

    ra-ui-static-text,
    .mat-tree-node,
    .mat-nested-tree-node {
      color: $color-text;
    }

    .ra-ui-tree-root-drag-over {
      @include inner-border(1px, #{$border-color-dragging});
    }

    .ra-ui-tree-no-items-text {
      ra-ui-static-text {
        line-height: $height-without-vertical-border;
      }
    }

    mat-progress-spinner {
      margin-right: $padding-half-relative;

      &,
      > svg {
        width: $font-size-icon-s !important;
        height: $font-size-icon-s !important;
        min-width: $font-size-icon-s !important;
        min-height: $font-size-icon-s !important;
      }
    }

    .mat-progress-spinner,
    .mat-spinner {
      circle {
        stroke: ra-ui-color($theme, background, inactive);
      }
    }

    // this is valid just for IE11
    .ra-ui-tree-node-suffix {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-right: $padding-relative-in-lists;
      }
    }

    .ra-ui-tree-node-prefix,
    .ra-ui-tree-node-suffix {
      overflow: hidden;
    }

    .ra-ui-tree-node-suffix {
      margin-left: $padding-relative-in-lists;
    }

    .ra-ui-tree-node-prefix {
      margin-right: $padding-half-relative;
    }

    .ra-ui-tree-node-prefix:not(.prefix-set) {
      margin-right: 0;
    }

    .ra-ui-tree {
      color: $color-text;

      user-select: none;
      white-space: nowrap;
      min-width: $min-width;

      &.ra-ui-inactive-state {
        @include _ra-ui-tree-node-background-and-text-color(
          $inactive-text-color-selected,
          $inactive-background-color-selected,
          $inactive-background-color-hover
        );
      }

      @include _ra-ui-tree-node-background-and-text-color(
        $text-contrast-color-selected,
        $background-color-selected,
        $background-color-hover
      );

      ra-ui-tree-node {
        width: 100%;

        &,
        .prefix-content,
        .suffix-content {
          min-height: $height-without-vertical-border;
        }
      }

      .ra-icon-ide-sm-caret-right,
      .ra-icon-ide-sm-caret-down {
        padding-left: 0;
        position: relative;
        top: 0;

        margin-right: $icon-margin-right;
      }

      mat-tree-node {
        &.cdk-tree-node {
          align-items: stretch;
        }
      }

      .mat-tree-node {
        @include getCursor($custom-global-config);
        min-height: inherit;
        overflow: hidden;

        .ra-node-children {
          width: 100%;
        }
      }

      .ra-ui-tree-node-overlay {
        padding: 0 $padding-without-border;

        &.ra-ui-tree-node-status-decorator-bar {
          padding-left: $padding-without-border-half;
        }

        // this is valid just for IE11
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          padding-right: 0;
        }

        line-height: $height-without-vertical-border;
        width: 100%;

        ra-ui-tree-node ra-ui-static-text {
          line-height: $height-without-vertical-border;
          overflow: hidden;
        }
      }

      .ra-ui-tree-node-label {
        display: flex;
        align-items: center;
        font-family: ra-ui-font-family($custom-typography);
        overflow: hidden;
      }

      div.ra-ui-tree-node-label > div {
        flex-grow: 1;
      }

      .ra-ui-tree-node-disabled,
      .ra-ui-tree-node-disabled .ra-ui-tree-node-overlay:hover {
        background-color: $background-color-disabled;
        cursor: default;
        ra-ui-dirty-flag,
        .ra-ui-tree-node-prefix,
        .ra-ui-tree-node-suffix,
        .ra-icon-ide-sm-caret-down,
        .ra-icon-ide-sm-caret-right,
        .icon-container > * {
          color: $text-color-disabled;
        }
      }

      &.ra-ui-tree-disabled-expandable-mode .ra-ui-tree-node-disabled,
      &.ra-ui-tree-disabled-expandable-mode
        .ra-ui-tree-node-disabled
        .ra-ui-tree-node-overlay:hover {
        .ra-icon-ide-sm-caret-down,
        .ra-icon-ide-sm-caret-right {
          cursor: pointer;
          color: $color-text;
        }
      }

      .ra-ui-tree-node-drag-over {
        @include inner-border(1px, #{$border-color-dragging});
      }

      .ra-no-icon {
        min-width: $icon-width-and-padding;
        max-width: $icon-width-and-padding;
        width: $icon-width-and-padding;
      }

      .tree-node-icons-wrapper {
        margin-right: $padding-half-relative;
      }

      .ra-ui-tree-extension-icon,
      .no-extension-icon .ra-ui-tree-icon {
        margin-right: $icon-margin-right;
      }

      .ra-ui-tree-node {
        &.ra-icon-ide-sm-caret-right,
        &.ra-icon-ide-sm-caret-down {
          .ra-ui-tree-icon {
            margin-left: getRelative(4px, $base-body-2-fz);
          }
        }
      }

      .mat-tree-node {
        box-sizing: border-box;
      }

      ul,
      li {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }

      .ra-ui-tree-node-overlay {
        border: $border-width-relative-in-lists solid transparent;
      }

      &.ra-ui-keyboard-focus {
        .ra-ui-tree-node-focused.ra-ui-tree-node-disabled
          .ra-ui-tree-node-overlay {
          background-color: $background-color-disabled;
        }
        .ra-ui-tree-node-selected:not(.ra-ui-tree-node-selected-by-mouse)
          .ra-ui-tree-node-overlay,
        .ra-ui-tree-node-focused.ra-ui-tree-node-disabled:not(
            .ra-ui-tree-node-selected-by-mouse
          )
          .ra-ui-tree-node-overlay {
          border-color: $border-color-selected;
        }
        .ra-ui-tree-node-focused.ra-ui-tree-node-disabled:not(
            .ra-ui-tree-node-selected-by-mouse
          )
          .ra-ui-tree-node-overlay {
          ra-ui-badge:not(.ra-ui-badge) .static-text,
          .ra-ui-tree-node .static-text,
          ra-ui-dirty-flag,
          .ra-ui-tree-node-prefix,
          .ra-ui-tree-node-suffix,
          .ra-ui-tree-icon,
          .ra-ui-tree-extension-icon {
            color: $text-color-disabled;
          }
        }
      }

      .ra-ui-tree-node-status-decorator-bar-container {
        $accessibility-border-width: getRelative(1px, 14px);
        $accessibility-border: $accessibility-border-width solid
          ra-ui-color($theme, background, interactive-alt-02);
        $dashed-bar-width: $padding-half-relative - $accessibility-border-width;

        height: auto;
        width: $padding-half-relative;
        display: flex;

        &.full {
          @include _get-background-and-status-colors($theme);
        }

        &.dashed {
          @include _get-background-and-status-colors($theme, true);
        }

        &.straight-dashed {
          @include _get-background-and-status-colors($theme, true, 0deg);
        }

        &.dashed,
        &.straight-dashed {
          border-right: $accessibility-border;
          width: $dashed-bar-width;
        }
      }
    }

    cdk-virtual-scroll-viewport.ra-ui-enable-virtual-scroll {
      min-height: 100%;
      height: 100%;

      &-no-items {
        min-height: calc(100% - #{$height-without-vertical-border});
        height: calc(100% - #{$height-without-vertical-border});
      }

      .cdk-virtual-scroll-content-wrapper {
        max-width: 100%;
      }
    }

    /** Forces the virtual scroll viewport to have size by it's content, used when this feature is turned off. */
    cdk-virtual-scroll-viewport.ra-ui-disable-virtual-scroll {
      display: inherit;
      .cdk-virtual-scroll-content-wrapper {
        position: inherit;
      }
    }

    .ra-caret-wrapper {
      top: 0;
      position: absolute;
      border-left: $border-width-relative-in-lists solid transparent;
      margin-left: $padding-relative-in-lists;
      height: 100%;
      min-height: 100%;
      max-height: 100%;
      width: getRelative(21px, 14px);
      min-width: getRelative(21px, 14px);
      max-width: getRelative(21px, 14px);
    }

    .ra-ui-tree-node-overlay {
      position: relative;
    }

    .ra-ui-tree-loading-spinner {
      @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
      // font-size of icon-s is 12px
      padding-right: getRelative(4px, 12px);
      .default-spinner-icon {
        color: ra-ui-color($theme, primary, 500);
      }
    }

    .ra-ui-tree-node-selected {
      .ra-ui-tree-loading-spinner {
        .default-spinner-icon {
          color: ra-ui-color($theme, background, interactive-component-01);
        }
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
@mixin ra-ui-list-component-theme($theme, $custom-typography) {
  $min-width: getRelative($list-min-width, 14px);
  $icon-width: ra-ui-font-size($custom-typography, icon-m);
  $text-color: ra-ui-color($theme, foreground, text-primary);
  $sublabel-text-color: ra-ui-color($theme, foreground, text-label);
  $disabled-color: ra-ui-color($theme, background, disabled);
  $keyboard-focus-border-color: ra-ui-color($theme, primary, A200);

  ra-ui-list {
    @include ra-ui-typography-level-to-styles($custom-typography, body-2);

    .ra-ui-list-no-items-text,
    .ra-ui-list-wrapper {
      min-width: $min-width;
    }

    .ra-ui-list-wrapper {
      &:focus {
        outline: none;
      }

      &.ra-ui-keyboard-focus
        .ra-ui-list-item.ra-ui-list-item-focused:not(
          .ra-ui-list-item-selected-by-mouse
        ) {
        $size: getRelative(2, 14);

        outline: $size solid $keyboard-focus-border-color;
        outline-offset: -$size;
      }

      .ra-ui-list-item {
        &.ra-ui-default-template-list-item {
          padding: $padding-list-item-vertical $padding-relative-in-lists;

          .ra-ui-list-item-sublabel {
            @include ra-ui-typography-level-to-styles(
              $custom-typography,
              form-field-text
            );
            color: $sublabel-text-color;
          }
        }
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 $padding-relative-in-lists;

        &.ra-ui-list-item-disabled {
          cursor: default;

          ra-ui-static-text {
            color: $disabled-color;
          }
          .ra-ui-list-icon {
            color: $disabled-color;
          }
        }

        &.ra-ui-list-item-with-icon {
          .ra-ui-list-icon-wrapper {
            padding-top: $padding-list-icon;
          }

          .ra-ui-list-item-label-wrapper {
            margin-left: $padding-relative-in-lists;
            max-width: calc(
              100% - (#{$icon-width} + #{$padding-relative-in-lists})
            );
          }
        }

        &.ra-ui-list-item-dirty .ra-ui-list-item-label {
          max-width: calc(100% - #{$width-dirty-flag});
        }

        .ra-ui-list-item-label-wrapper {
          width: 100%;
        }

        .ra-ui-list-item-label,
        .ra-ui-list-item-sublabel {
          max-width: 100%;
        }

        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, body-2);
          /**
                     * We need to ceil the line-height, because body-2 line-height is 19.6px,
                     * which does not work well with scroll or virtual scroll directives, i.e. browser cannot scroll to 0.5px.
                     * OBSERVE: ceiling is also happening in _calcItemHeight() method of list-item-height-store.ts!
                     */
          line-height: math.ceil(
            rafl.$rafl-typography-body-md-line-height *
              strip-unit(rafl.$rafl-typography-body-md-font-size) * 16px
          );
          color: $text-color;
          max-width: 100%;
        }

        .ra-ui-list-icon {
          @include ra-ui-typography-level-to-styles($custom-typography, icon-m);
          color: $text-color;
          display: flex;
        }

        ra-ui-dirty-flag {
          color: $text-color;
        }
        &.ra-ui-list-item-disabled ra-ui-dirty-flag {
          color: $disabled-color;
        }

        &:hover:not(.ra-ui-list-item-disabled) {
          background-color: ra-ui-color($theme, primary, 200);

          &.ra-ui-inactive-state {
            background-color: ra-ui-color(
              $theme,
              background,
              inactive-hover,
              0.1
            );
          }
        }

        &.ra-ui-list-item-selected:not(.ra-ui-list-item-disabled) {
          background-color: ra-ui-color($theme, primary, 600);
          .static-text,
          ra-ui-dirty-flag,
          .ra-ui-list-icon {
            color: ra-ui-contrast($theme, primary, 600);
          }

          &.ra-ui-inactive-state {
            background-color: ra-ui-color(
              $theme,
              background,
              interactive-component-05
            );

            .ra-ui-list-item-label .static-text,
            .ra-ui-list-icon {
              color: $text-color;
            }

            .ra-ui-list-item-sublabel .static-text {
              color: $sublabel-text-color;
            }
          }
        }
      }
    }

    cdk-virtual-scroll-viewport.ra-ui-enable-virtual-scroll {
      min-height: 100%;
      height: 100%;

      .cdk-virtual-scroll-content-wrapper {
        max-width: 100%;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
@mixin ra-ui-card-collection-component-theme($theme, $custom-typography) {
  ra-ui-card-collection {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $card-collection-base-relative-fs: 12;

    /**
            TODO - this style is by design, however the select use case was not provided by design, so it functionality is commented for MVP
            .ra-ui-card-collection-sort-select {
                margin-bottom: getRelative(24px, $card-collection-base-relative-fs);
            }
         */

    .ra-ui-card-collection-page .ra-ui-card-collection-projection {
      gap: getRelative(24px, $card-collection-base-relative-fs);
    }

    .ra-ui-card-collection-pagination {
      margin-top: getRelative(24px, $card-collection-base-relative-fs);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$tree-min-width: 198px;
$list-min-width: 198px;
$padding-relative-in-lists: getRelative(8px, 14px);
$padding-list-item-vertical: getRelative(4px, 14px);
$padding-list-icon: getRelative(2px, 14px);
$border-width-relative-in-lists: getRelative(2px, 14px);
@mixin lists-theme($theme, $custom-typography, $custom-global-config: null) {
  @include ra-ui-category-tree-component-theme($theme, $custom-typography);
  @include ra-ui-tree-component-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-list-component-theme($theme, $custom-typography);
  @include ra-ui-card-collection-component-theme($theme, $custom-typography);
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// this value is defined in `legacy-nav-bar.component.ts`,
// if you change this one, you should also update value in ts, `iconActionWidthRelative`
$legacy-nav-bar-height: 2.8572em; //40px

//override material's opacity 0.12 on buttons in action-bar and legacy-nav-bar
%override-button-opacity {
  %opacity {
    opacity: 1;
  }

  button.mat-button:hover .mat-button-focus-overlay {
    @extend %opacity;
  }

  $buttons: mat-button mat-icon-button;
  $types-of-focused: cdk-keyboard-focused cdk-program-focused;

  @each $button in $buttons {
    @each $type in $types-of-focused {
      .#{$button}.#{$type} .mat-button-focus-overlay {
        @extend %opacity;
      }
    }
  }
}

@mixin ra-ui-action-bar-component-theme($theme, $custom-typography) {
  $contrast: ra-ui-contrast($theme, background, $default-hue);
  $nav-bar-tone: inactive-hover;
  $height: 4.573em; //64px;

  ra-ui-action-bar {
    @include ra-ui-typography-level-to-styles($custom-typography, button);

    @extend %override-button-opacity;

    .action-bar-toolbar.mat-toolbar-single-row {
      font-size: inherit;
      line-height: inherit;
      padding: 0 1em;
      height: $height;
      background: ra-ui-color($theme, background, interactive-component-06);

      .action-bar-container ra-ui-action-bar-button {
        button.mat-button.ra-ui-icon-with-text-button.default-color {
          height: $height;
          width: $height;

          &:not(.is-disabled) {
            .static-text,
            mat-icon {
              color: $contrast;
            }
          }
          &.cdk-focused.mat-button-base:active {
            background-color: rgba(var($contrast), $hover-opacity);
          }

          .mat-button-focus-overlay {
            background: rgba($contrast, $hover-opacity);
          }
          &.is-disabled {
            color: ra-ui-color($theme, foreground, text-disabled);
            .mat-button-focus-overlay {
              background: none;
            }
          }
        }
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// this value is defined in `legacy-nav-bar.component.ts`,
// if you change this one, you should also update value in ts, `iconActionWidthRelative`
$legacy-nav-bar-height: 2.8572em; //40px

//override material's opacity 0.12 on buttons in action-bar and legacy-nav-bar
%override-button-opacity {
  %opacity {
    opacity: 1;
  }

  button.mat-button:hover .mat-button-focus-overlay {
    @extend %opacity;
  }

  $buttons: mat-button mat-icon-button;
  $types-of-focused: cdk-keyboard-focused cdk-program-focused;

  @each $button in $buttons {
    @each $type in $types-of-focused {
      .#{$button}.#{$type} .mat-button-focus-overlay {
        @extend %opacity;
      }
    }
  }
}

@mixin ra-ui-legacy-nav-bar-component-theme($theme, $custom-typography) {
  $nav-bar-tone: background-container;
  $icon-text-height: 2.6429em; //37px;
  $icon-padding: 0.5em; //7px;
  $font-size-button: ra-ui-font-size($custom-typography, button);
  $font-size-weight: ra-ui-font-weight($custom-typography, button);
  $line-height: ra-ui-line-height($custom-typography, button);
  $nav-bar-text-shade: 0.7;
  $nav-bar-disabled-text-shade: 0.26;
  $font-size-static-text: ra-ui-font-size($custom-typography, body-1);
  $more-button-icon-fs: $font-size-button;
  $text: ra-ui-color($theme, foreground, foreground-container);
  $disabled-text: ra-ui-color($theme, background, disabled);

  ra-ui-legacy-nav-bar {
    &,
    .mat-toolbar {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }
  }

  ra-ui-legacy-nav-bar {
    .tab-button-container,
    .more-button-container,
    .action-button-container,
    .more-actions-button-container,
    .logo-button-container,
    .back-button-container {
      @extend %override-button-opacity;
    }

    .logo-button-container {
      $hover-color: ra-ui-color($theme, background, focused-button);
      // logo hover and press should be the same as other buttons
      .mat-button-focus-overlay {
        background: $hover-color; //this handles hover - material element needs to be overridden
      }
      button:active {
        background: rgba($hover-color, $active-opacity);
      }
    }

    .more-button-container .static-text-wrapper {
      // Set the width of icon label button text container so it does not overflow
      width: calc(100% - #{$more-button-icon-fs} - #{$icon-padding});
    }

    .mat-toolbar-multiple-rows {
      min-height: 0; //override material fixed value 64px
    }
    .legacy-nav-bar-toolbar {
      background: ra-ui-color($theme, background, $nav-bar-tone);

      ra-ui-icon-label-button .mat-button.default-color:not(.is-disabled) {
        .static-text,
        mat-icon {
          color: $text;
        }
      }

      ra-ui-icon-button .mat-icon-button.default-color mat-icon {
        color: $text;
      }

      ra-ui-icon-label-button .mat-button.default-color.is-disabled mat-icon {
        color: $disabled-text;
      }

      .legacy-nav-bar-bottom-row .multi-line-tab button {
        height: $legacy-nav-bar-height; //$legacy-nav-bar-height; //40px;
        .static-text-wrapper {
          height: 100%;
          ra-ui-static-text {
            @include ra-ui-typography-level-to-styles(
              $custom-typography,
              button
            );
          }
        }
      }

      .static-logo {
        &.text-logo {
          padding: 7.6px 0 0 16px;
        }

        ra-ui-static-text {
          font-weight: mat.font-weight($custom-typography, button);
        }
      }
    }

    .ra-ui-mat-sidenav {
      max-width: 75%;
    }

    .ra-ui-legacy-nav-bar-content {
      overflow-y: auto;
    }

    .active-tab {
      &:not(.tab-in-side-nav) button {
        border-bottom: 0.1875em solid ra-ui-color($theme, primary);
      }
      &.tab-in-side-nav button {
        border-bottom: 0.1875em solid ra-ui-color($theme, primary);
      }
    }

    .tab-button-container {
      .legacy-nav-bar-tab {
        ra-ui-icon-label-button {
          ra-ui-static-text {
            @include ra-ui-typography-level-to-styles(
              $custom-typography,
              button
            );
          }

          mat-icon {
            font-size: 1.43em;
          }
        }
      }
    }

    .legacy-nav-bar-tab,
    .tab-in-side-nav {
      mat-icon {
        z-index: 1;
      }
    }
  }

  .tab-in-side-nav-with-icon {
    mat-icon {
      padding-right: $icon-padding;
    }
  }

  .tab-in-side-nav
    .mat-button:hover:not(.mat-button-disabled)
    .mat-button-focus-overlay {
    opacity: $hover-opacity;
  }

  .legacy-nav-bar-small-menu-items
    .tab-in-side-nav-with-icon
    .static-text-wrapper,
  .tab-button-container .legacy-nav-bar-tab.tab-with-icon .static-text-wrapper,
  .tab-button-in-menu-container
    .tab-in-side-nav.tab-in-side-nav-with-icon
    .static-text-wrapper {
    width: calc(100% - #{$font-size-button} - #{$icon-padding});
  }

  .tab-button-container .legacy-nav-bar-tab .static-text-wrapper,
  .tab-button-in-menu-container .tab-in-side-nav .static-text-wrapper {
    width: 100%;
  }

  .legacy-nav-bar-small-menu-items .tab-in-side-nav ra-ui-static-text {
    @include ra-ui-typography-level-to-styles($custom-typography, button);
  }

  .legacy-nav-bar-text-icon-button {
    .ra-ui-icon-with-text-button {
      mat-icon {
        display: inline;
      }

      .static-text-wrapper {
        display: inline-block;
      }
    }

    &.legacy-nav-bar-more-side-nav-tab
      .ra-ui-icon-with-text-button
      > .mat-button-wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      height: $icon-text-height;

      ra-ui-static-text {
        @include ra-ui-typography-level-to-styles($custom-typography, button);
      }

      mat-icon {
        padding-left: $icon-padding;
        font-size: $more-button-icon-fs;
      }
    }
  }
}

ra-ui-breadcrumb {
  %ra-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  %ra-flex-column {
    display: flex;
    flex-direction: column;
  }

  %ra-flex-item-static {
    flex: 0 0 auto;
  }

  %ra-flex-item-shrink {
    flex: 0 1 auto;
  }

  %ra-flex-item-dynamic {
    flex: 1 1 auto;
  }

  %ra-flex-align-main-start {
    justify-content: flex-start;
  }

  %ra-flex-align-cross-center {
    align-items: center;
  }

  %ra-scroll {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  %ra-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    word-wrap: normal;
  }

  .ra-breadcrumb {
    @extend %ra-flex-item-static;
    @extend %ra-flex-row;
    @extend %ra-flex-align-main-start;
    @extend %ra-flex-align-cross-center;
  }

  .ra-breadcrumb-chevron {
    @extend %ra-flex-item-static;
  }

  .ra-breadcrumb-items {
    flex-basis: auto;

    @extend %ra-flex-item-shrink;
    @extend %ra-flex-row;
    @extend %ra-flex-align-main-start;
    @extend %ra-scroll;

    .ra-breadcrumb-item-first {
      &,
      .ra-ui-text-button-text {
        @extend %ra-flex-item-shrink;
      }
    }

    :not(.ra-breadcrumb-item-first) {
      &,
      .ra-ui-text-button-text {
        @extend %ra-flex-item-static;
      }
    }

    .ra-breadcrumb-item-first.ra-breadcrumb-item-last {
      min-width: 0;
    }

    .ra-breadcrumb-item-last {
      @extend %ra-flex-align-main-start;
    }

    > .ra-breadcrumb-item-container {
      @extend %ra-flex-row;
      @extend %ra-flex-align-cross-center;

      > .ra-breadcrumb-item {
        @extend %ra-overflow;
      }

      > span {
        padding: 0.3125em; //5px;

        @extend %ra-flex-item-static;
      }
    }
  }

  .ra-breadcrumb-free-space {
    @extend %ra-flex-item-dynamic;
  }

  .ra-breadcrumb-dropdown {
    .ra-breadcrumb-item {
      padding: 0.125em 0.375em; //2px 6px;
    }

    &.ra-breadcrumb-dropdown-hidden-items {
      .ra-breadcrumb-dropdown-container {
        flex-basis: auto;
        padding: 0.8125em 1.125em 0.8125em 0.3125em; //13px 18px 13px 5px;

        @extend %ra-flex-item-dynamic;
        @extend %ra-flex-column;
      }

      .ra-breadcrumb-item {
        @extend %ra-flex-item-static;
      }
    }

    &.ra-breadcrumb-dropdown-children {
      .ra-breadcrumb-dropdown-container {
        @extend %ra-flex-item-dynamic;
        @extend %ra-flex-column;
      }

      .ra-breadcrumb-item {
        @extend %ra-flex-item-static;
        @extend %ra-overflow;
      }
    }
  }
}

@mixin ra-ui-breadcrumb-component-theme($theme, $custom-typography) {
  $text: ra-ui-color($theme, foreground, text-primary);
  $text-inverted: ra-ui-contrast($theme, background, interactive-component-07);
  $icon-button-readonly: ra-ui-color($theme, foreground, text-label);
  $icon-button-readonly-inverted: ra-ui-color(
    $theme,
    background,
    interactive-alt-01
  );

  $icon-button-active-background: ra-ui-color($theme, primary, 800);
  $icon-button-active-icon: ra-ui-contrast($theme, primary, 800);

  $breadcrumb-height: getRelative(32, 14);

  $horizontalPadding: getRelative(8, 14);
  $verticalPadding: getRelative(4, 14);

  $icon-s-fz: ra-ui-font-size($custom-typography, icon-s);
  $icon-padding: getRelative(4px, 12px);
  $icon-size-with-padding: calc(
    $icon-s-fz + (strip-unit($icon-padding) * $icon-s-fz)
  );

  // this value is defined in `breadcrumb.ts`,
  // if you change this one, you should also update value in ts, `minWidthRelative`
  $min-width-without-icon: getRelative(58, 14);

  $collapse-icon-button-width: getRelative(20, 14);

  ra-ui-breadcrumb .ra-breadcrumb {
    padding: $verticalPadding $horizontalPadding;
    height: $breadcrumb-height;
    box-sizing: border-box;

    @include ra-ui-typography-level-to-styles($custom-typography, button);

    &.multi-line {
      height: auto;
      @extend %ra-flex-item-shrink;

      .ra-breadcrumb-item-container.ra-breadcrumb-item-last {
        // if in multiline mode adjust the title item (not text button) height to the text button height
        height: $text-button-min-height;
      }

      .ra-breadcrumb-items {
        flex-wrap: wrap;
      }

      .ra-breadcrumb-items,
      .ra-breadcrumb-item-container,
      .ra-breadcrumb-items ra-ui-text-button,
      .ra-breadcrumb-items .ra-breadcrumb-item-last .ra-breadcrumb-text,
      .ra-breadcrumb-item-container > span {
        @extend %ra-flex-item-shrink;
      }

      .ra-breadcrumb-item-container.ra-breadcrumb-item-last {
        min-width: 0;
      }
    }

    .ra-breadcrumb-item-child,
    .ra-breadcrumb-chevron {
      cursor: pointer;
    }

    .ra-breadcrumb-item + mat-icon:not(.ra-breadcrumb-item-child) {
      cursor: default;
    }

    ra-ui-text-button .mat-button {
      min-width: 0;
    }

    .ra-breadcrumb-items > .ra-breadcrumb-item-container {
      min-width: $min-width-without-icon;

      &.ra-breadcrumb-item-with-icon {
        min-width: calc(
          #{$min-width-without-icon} + #{$icon-size-with-padding}
        );
      }
    }

    .ra-breadcrumb-item-container {
      > .ra-breadcrumb-item-last:not(.ra-breadcrumb-item-has-child)
        .static-text {
        color: $text;
      }

      .ra-breadcrumb-item-clicked {
        .ra-ui-button {
          background-color: ra-ui-color(
            $theme,
            primary,
            800,
            $flourish-active-opacity
          );
        }

        + .ra-breadcrumb-item-child .mat-icon-button {
          &,
          &:hover {
            background: $icon-button-active-background;

            .mat-icon {
              color: $icon-button-active-icon;
            }
          }
        }
      }
    }

    //Fixes truncation the text of an item without an icon
    .ra-ui-text-button-text {
      width: 100%;
    }

    //Fixes truncation of an item with an icon
    .ra-ui-text-button-icon + .ra-ui-text-button-text {
      // the value resolves other ways of rounding through browsers.
      width: calc(100% - $icon-size-with-padding - 1px);
    }

    // same color as in a readonly mode.
    .ra-ui-readonly-caret .mat-icon-button.default-color[disabled] .mat-icon {
      color: $icon-button-readonly;
    }

    .ra-breadcrumb-text-icon {
      font-size: $icon-s-fz;
      height: $icon-s-fz;
      width: $icon-s-fz;
      color: $text;
    }

    .ra-ui-double-chevron-clicked {
      &,
      .mat-icon-button.default-color:not(.is-disabled):hover {
        background: $icon-button-active-background;

        .mat-icon {
          color: $icon-button-active-icon;
        }
      }
    }

    .ra-breadcrumb-text {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
      padding-left: getRelative(4px, 14px);
    }

    &.ra-ui-readonly {
      .ra-breadcrumb-item ra-ui-static-text > .static-text,
      .ra-breadcrumb-text-icon,
      .ra-ui-text-button-icon {
        color: $text;
      }
    }

    &.ra-ui-inverted-mode {
      .ra-breadcrumb-item-container {
        > .ra-breadcrumb-item-last:not(.ra-breadcrumb-item-has-child)
          .static-text,
        .is-disabled .ra-ui-text-button-icon {
          color: $text-inverted;
        }
      }

      &.ra-ui-readonly {
        .ra-breadcrumb-item ra-ui-static-text > .static-text,
        .ra-breadcrumb-text-icon,
        .ra-ui-text-button-icon {
          color: $text-inverted;
        }
      }

      .ra-breadcrumb-item-child,
      .ra-breadcrumb-chevron {
        mat-icon {
          color: $text-inverted;
        }
      }

      .mat-icon-button.default-color[disabled] .mat-icon {
        color: $icon-button-readonly-inverted;
      }
    }
  }

  .ra-breadcrumb-dropdown {
    .ra-breadcrumb-item-selected {
      .static-text {
        cursor: default !important;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// this value is defined in `legacy-nav-bar.component.ts`,
// if you change this one, you should also update value in ts, `iconActionWidthRelative`
$legacy-nav-bar-height: 2.8572em; //40px

//override material's opacity 0.12 on buttons in action-bar and legacy-nav-bar
%override-button-opacity {
  %opacity {
    opacity: 1;
  }

  button.mat-button:hover .mat-button-focus-overlay {
    @extend %opacity;
  }

  $buttons: mat-button mat-icon-button;
  $types-of-focused: cdk-keyboard-focused cdk-program-focused;

  @each $button in $buttons {
    @each $type in $types-of-focused {
      .#{$button}.#{$type} .mat-button-focus-overlay {
        @extend %opacity;
      }
    }
  }
}

@mixin ra-ui-main-menu-bar-component-theme($theme, $custom-typography) {
  //TODO: move constants from here and nav bar to _styles-variables.scss
  $menu-tone: inactive-hover;
  $contrast: ra-ui-contrast($theme, background, $menu-tone);
  $icon-width: ra-ui-font-size($custom-typography, body-1);
  $nav-bar-disabled-text-shade: 0.26;
  $nav-bar-text-shade: 0.7;
  $text: ra-ui-color($theme, foreground, text);
  $main-menu-bar-background-tone: interactive-component-03;
  $background: map-get($theme, background);

  //TODO: create a common mixin for duplicated rules between main menu and nav bar
  ra-ui-main-menu-bar {
    mat-toolbar.main-menu-bar-toolbar {
      padding: 0;
      @include ra-ui-typography-level-to-styles($custom-typography, body-1);
      background: ra-ui-color(
        $theme,
        background,
        $main-menu-bar-background-tone
      );

      .main-menu-bar-container {
        .main-menu-bar-items {
          align-items: center;

          .button-item {
            height: getRelative(36px, 12px);
          }
        }
      }

      .main-menu-bar-button {
        padding: 0 getRelative(8px, 12px);
        height: 100%;

        &.mat-menu-item,
        &.no-children-button {
          &:hover:not(.is-disabled),
          &[aria-expanded='true'],
          &.mat-menu-item-highlighted:not(.is-disabled) {
            background: ra-ui-color($theme, primary, $menu-hover);

            .static-text,
            mat-icon:before,
            &::after {
              color: ra-ui-contrast($theme, primary, $menu-hover);
            }
          }

          &:active:not(.is-disabled),
                    &[aria-expanded="true"],

                    // necessary fix for the TestCafe due to the stronger attribute rule when hover action is used in TestCafe
                    &[aria-expanded="true"]:hover:not(.is-disabled),
                    &.mat-menu-item-highlighted:not(.is-disabled) {
            background: ra-ui-color($theme, primary, $menu-selected);

            .static-text,
            mat-icon:before,
            &::after {
              color: ra-ui-contrast($theme, primary, $menu-selected);
            }
          }
        }
      }

      .main-menu-bar-button:not(.is-disabled),
      .default-color:not(.is-disabled),
      .mat-button:not(.ra-ui-button) {
        &.cdk-focused:active {
          background-color: ra-ui-color($theme, primary, $menu-selected);
          .static-text {
            color: ra-ui-contrast($theme, primary, $menu-selected);
          }
        }

        .mat-button-focus-overlay {
          background: rgba($contrast, $hover-opacity);
        }

        .static-text,
        &.mat-icon-button mat-icon,
        &.ra-ui-icon-with-text-button mat-icon {
          @include ra-ui-typography-level-to-styles($custom-typography, body-1);
          color: ra-ui-contrast($theme, background, interactive-component-03);
        }
      }

      .main-menu-bar-button.is-disabled,
      .default-color.is-disabled {
        ra-ui-static-text .static-text[disabled='true'] {
          @include ra-ui-typography-level-to-styles($custom-typography, body-1);
          color: ra-ui-color($theme, background, disabled);
        }

        &.mat-icon-button mat-icon {
          @include ra-ui-typography-level-to-styles($custom-typography, body-1);
          color: ra-ui-contrast($theme, primary, $menu-hover);
        }
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// this value is defined in `legacy-nav-bar.component.ts`,
// if you change this one, you should also update value in ts, `iconActionWidthRelative`
$legacy-nav-bar-height: 2.8572em; //40px

//override material's opacity 0.12 on buttons in action-bar and legacy-nav-bar
%override-button-opacity {
  %opacity {
    opacity: 1;
  }

  button.mat-button:hover .mat-button-focus-overlay {
    @extend %opacity;
  }

  $buttons: mat-button mat-icon-button;
  $types-of-focused: cdk-keyboard-focused cdk-program-focused;

  @each $button in $buttons {
    @each $type in $types-of-focused {
      .#{$button}.#{$type} .mat-button-focus-overlay {
        @extend %opacity;
      }
    }
  }
}

@mixin ra-ui-toolbar-component-theme($theme, $custom-typography) {
  ra-ui-toolbar {
    @include ra-ui-typography-level-to-styles($custom-typography, button);
    $toolbar-base-relative-fs: 14; // toolbar uses button typography level to achieve relative sizes

    $padding-group-horizontal-em: getRelative(4px, $toolbar-base-relative-fs);
    $padding-toolbar-vertical-em: getRelative(6px, $toolbar-base-relative-fs);

    $icon-button-height: ra-ui-font-size($custom-typography, icon-m);

    padding: $padding-toolbar-vertical-em 0px;
    height: calc(#{$icon-button-height} + #{2 * $small-icon-button-padding});

    .ra-ui-toolbar-roll-up-menu,
    .ra-ui-toolbar-group-items-container {
      padding: 0px $padding-group-horizontal-em;
    }

    background: ra-ui-color($theme, background, interactive-component-03);

    .ra-ui-toolbar-right-section {
      ra-ui-outlined-button,
      ra-ui-main-button,
      ra-ui-text-button {
        padding: 0 $padding-group-horizontal-em;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// this value is defined in `legacy-nav-bar.component.ts`,
// if you change this one, you should also update value in ts, `iconActionWidthRelative`
$legacy-nav-bar-height: 2.8572em; //40px

//override material's opacity 0.12 on buttons in action-bar and legacy-nav-bar
%override-button-opacity {
  %opacity {
    opacity: 1;
  }

  button.mat-button:hover .mat-button-focus-overlay {
    @extend %opacity;
  }

  $buttons: mat-button mat-icon-button;
  $types-of-focused: cdk-keyboard-focused cdk-program-focused;

  @each $button in $buttons {
    @each $type in $types-of-focused {
      .#{$button}.#{$type} .mat-button-focus-overlay {
        @extend %opacity;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// this value is defined in `legacy-nav-bar.component.ts`,
// if you change this one, you should also update value in ts, `iconActionWidthRelative`
$legacy-nav-bar-height: 2.8572em; //40px

//override material's opacity 0.12 on buttons in action-bar and legacy-nav-bar
%override-button-opacity {
  %opacity {
    opacity: 1;
  }

  button.mat-button:hover .mat-button-focus-overlay {
    @extend %opacity;
  }

  $buttons: mat-button mat-icon-button;
  $types-of-focused: cdk-keyboard-focused cdk-program-focused;

  @each $button in $buttons {
    @each $type in $types-of-focused {
      .#{$button}.#{$type} .mat-button-focus-overlay {
        @extend %opacity;
      }
    }
  }
}

@mixin ra-ui-navigation-tab-base-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  .ra-ui-navigation-tab-label {
    @include getCursor($custom-global-config);
    display: flex;
    align-items: center;
    position: relative;
  }
}

@mixin ra-ui-navigation-tab-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $background-color: ra-ui-color($theme, background, interactive-component-03);
  $text-primary: ra-ui-color($theme, foreground, text-primary);
  $text-label: ra-ui-color($theme, foreground, text-label);
  $inactive-color: ra-ui-color($theme, background, inactive);
  $base-size: 14px;
  $width-8px: getRelative(8px, $base-size);
  $width-6px: getRelative(6px, $base-size);
  $width-5px: getRelative(5px, $base-size);
  $width-4px: getRelative(4px, $base-size);
  $width-2px: getRelative(2px, $base-size);
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  // 12px is the icon size for the default typography
  $icon-padding: getRelative(8px, 12px);
  $focus-color: ra-ui-color($theme, primary, A200);
  $menu-trigger-width: getRelative(28px, $base-size);

  ra-ui-navigation-tab {
    @include ra-ui-typography-level-to-styles($custom-typography, body-2);

    &.ra-ui-inactive-state .ra-ui-navigation-tab {
      .ra-ui-navigation-tab-header .ra-ui-navigation-tab-labels {
        .ra-ui-navigation-tab-label.ra-ui-selected-tab {
          border-bottom: $width-4px solid
            ra-ui-color($theme, background, inactive);
        }

        ra-ui-nav-tab-item
          .ra-ui-navigation-tab-label:not(.ra-ui-selected-tab) {
          &:hover {
            border-bottom: $width-4px solid
              ra-ui-color($theme, background, inactive-hover);
          }
        }

        .ra-ui-tab-active .ra-ui-navigation-tab-label {
          ra-ui-static-text,
          .ra-ui-navigation-tab-label-icon {
            color: ra-ui-color($theme, background, inactive-hover);
          }
        }
      }
    }

    .ra-ui-navigation-tab {
      .ra-ui-navigation-tab-header {
        height: getRelative(36px, $base-size);
      }

      &:not(.ra-ui-navigation-tab-empty) {
        min-width: $menu-trigger-width;
      }

      @include ra-ui-navigation-tab-base-theme(
        $theme,
        $custom-typography,
        $custom-global-config
      );

      &__group {
        .ra-ui-navigation-tab-divider {
          border-top: getRelative(1px, $base-size) solid
            ra-ui-color($theme, background, interactive-component-06);
        }
        .ra-ui-navigation-tab-header {
          background-color: $background-color;

          &.ra-ui-has-navigation-tab-caret {
            padding-right: getRelative(28px, $base-size);
          }

          .ra-ui-navigation-tab-labels {
            ra-ui-responsive-list
              .responsive-list-visible-item.ra-ui-last-visible-item-overflown {
              min-width: 0;
              width: calc(100% - $menu-trigger-width);
            }

            .ra-ui-navigation-tab-label.ra-ui-selected-tab {
              border-bottom: $width-4px solid ra-ui-color($theme, primary, 600);
              padding-bottom: $width-6px;

              ra-ui-static-text,
              .ra-ui-navigation-tab-label-icon {
                color: $text-primary;
              }
            }

            .ra-ui-tab-active .ra-ui-navigation-tab-label {
              ra-ui-static-text,
              .ra-ui-navigation-tab-label-icon {
                color: ra-ui-color($theme, primary, 600);
              }
            }

            .ra-ui-navigation-tab-label-wrapper {
              min-width: 0;
            }

            ra-ui-nav-tab-item
              .ra-ui-navigation-tab-label:not(.ra-ui-selected-tab) {
              &:hover {
                border-bottom: $width-4px solid
                  ra-ui-color($theme, primary, 200);
                padding-bottom: $width-6px;
              }
            }

            ra-ui-nav-tab-item:not(.ra-ui-tab-active)
              .ra-ui-navigation-tab-label:hover {
              ra-ui-static-text,
              .ra-ui-navigation-tab-label-icon {
                color: $text-primary;
              }
            }

            .ra-ui-navigation-tab-label {
              height: getRelative(36px, $base-size);
              padding: $width-6px $width-8px getRelative(10px, $base-size)
                $width-8px;
              border-left: 0;
              border-right: 0;
              color: $inactive-color;

              .ra-ui-navigation-tab-label-icon {
                height: $icon-s-fs;
                width: $icon-s-fs;
                font-size: $icon-s-fs;
                padding-right: $icon-padding;
                color: inherit;
              }

              ra-ui-static-text {
                @include ra-ui-typography-level-to-styles(
                  $custom-typography,
                  body-2
                );
                color: $inactive-color;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// this value is defined in `legacy-nav-bar.component.ts`,
// if you change this one, you should also update value in ts, `iconActionWidthRelative`
$legacy-nav-bar-height: 2.8572em; //40px

//override material's opacity 0.12 on buttons in action-bar and legacy-nav-bar
%override-button-opacity {
  %opacity {
    opacity: 1;
  }

  button.mat-button:hover .mat-button-focus-overlay {
    @extend %opacity;
  }

  $buttons: mat-button mat-icon-button;
  $types-of-focused: cdk-keyboard-focused cdk-program-focused;

  @each $button in $buttons {
    @each $type in $types-of-focused {
      .#{$button}.#{$type} .mat-button-focus-overlay {
        @extend %opacity;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// this value is defined in `legacy-nav-bar.component.ts`,
// if you change this one, you should also update value in ts, `iconActionWidthRelative`
$legacy-nav-bar-height: 2.8572em; //40px

//override material's opacity 0.12 on buttons in action-bar and legacy-nav-bar
%override-button-opacity {
  %opacity {
    opacity: 1;
  }

  button.mat-button:hover .mat-button-focus-overlay {
    @extend %opacity;
  }

  $buttons: mat-button mat-icon-button;
  $types-of-focused: cdk-keyboard-focused cdk-program-focused;

  @each $button in $buttons {
    @each $type in $types-of-focused {
      .#{$button}.#{$type} .mat-button-focus-overlay {
        @extend %opacity;
      }
    }
  }
}

@mixin ra-ui-navigation-tab-base-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  .ra-ui-navigation-tab-label {
    @include getCursor($custom-global-config);
    display: flex;
    align-items: center;
    position: relative;
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// this value is defined in `legacy-nav-bar.component.ts`,
// if you change this one, you should also update value in ts, `iconActionWidthRelative`
$legacy-nav-bar-height: 2.8572em; //40px

//override material's opacity 0.12 on buttons in action-bar and legacy-nav-bar
%override-button-opacity {
  %opacity {
    opacity: 1;
  }

  button.mat-button:hover .mat-button-focus-overlay {
    @extend %opacity;
  }

  $buttons: mat-button mat-icon-button;
  $types-of-focused: cdk-keyboard-focused cdk-program-focused;

  @each $button in $buttons {
    @each $type in $types-of-focused {
      .#{$button}.#{$type} .mat-button-focus-overlay {
        @extend %opacity;
      }
    }
  }
}

// The values are taken from design for primary navigation tab
@mixin ra-ui-horizontal-scroll-container-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null,
  $base-size
) {
  $button-content-padding: getRelative(8, $base-size);

  ra-ui-horizontal-scroll-container {
    .ra-ui-scroll-content-container.does-overflow {
      margin: 0 $button-content-padding;
    }
  }
}

@mixin ra-ui-primary-navigation-tab-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  // sizes
  $subheading-base-size: 16px;
  $padding-right: getRelative(16px, $subheading-base-size);
  $padding-left: getRelative(32px, $subheading-base-size);
  $padding-bottom-top: getRelative(10px, $subheading-base-size);
  $tab-height: getRelative(48px, $subheading-base-size);
  $tab-min-width: getRelative(240px, $subheading-base-size);
  $tab-right-margin: getRelative(4px, $subheading-base-size);
  $border-radius-size: getRelative(8px, $subheading-base-size);

  // colors
  $selected-tab-background-color: ra-ui-color(
    $theme,
    background,
    interactive-component-01
  );
  $inactive-tab-background-color: ra-ui-color(
    $theme,
    background,
    interactive-component-03
  );
  $primary-600: ra-ui-color($theme, primary, 600);
  $primary-700: ra-ui-color($theme, primary, 700);

  ra-ui-primary-navigation-tab {
    @include ra-ui-typography-level-to-styles($custom-typography, subheading-2);
    @include ra-ui-navigation-tab-base-theme(
      $theme,
      $custom-typography,
      $custom-global-config
    );
    @include ra-ui-horizontal-scroll-container-theme(
      $theme,
      $custom-typography,
      $custom-global-config,
      $subheading-base-size
    );

    .ra-ui-navigation-tab--wrapper:not(.ra-ui-disabled-side-paddings) {
      $sidePadding: getRelative(32, $subheading-base-size);
      padding: 0 $sidePadding;
    }

    .ra-ui-navigation-tab-labels {
      gap: $tab-right-margin;
      // adding 4px space for top and bottom shadows
      height: calc($tab-height + 8px);
    }

    ra-ui-nav-tab-item {
      min-width: $tab-min-width;

      .ra-ui-navigation-tab-label {
        border-radius: $border-radius-size 0 0 0;
        padding: $padding-bottom-top $padding-right $padding-bottom-top
          $padding-left;
        background-color: $inactive-tab-background-color;
        box-shadow: 0px 4px 4px -4px rgba(26, 27, 36, 0.12);

        .ra-ui-navigation-tab-icon-container {
          $icon-text-label-padding: getRelative(16px, $subheading-base-size);
          margin-right: $icon-text-label-padding;
          height: ra-ui-line-height($custom-typography, subheading-2);
        }

        .ra-ui-navigation-tab-label-icon {
          @include ra-ui-typography-level-to-styles($custom-typography, icon-m);
          $icon-fs: ra-ui-font-size($custom-typography, icon-m);
          height: $icon-fs;
          width: $icon-fs;
          color: $primary-600;
        }

        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles(
            $custom-typography,
            subheading-2
          );
          color: $primary-600;
        }

        &.ra-ui-selected-tab {
          background-color: $selected-tab-background-color;
          box-shadow:
            0px -4px 4px -4px rgba(26, 27, 36, 0.12),
            -4px 0px 4px -4px rgba(26, 27, 36, 0.12),
            4px 0px 4px -4px rgba(26, 27, 36, 0.12);

          ra-ui-static-text {
            @include ra-ui-typography-level-to-styles(
              $custom-typography,
              subheading-2-bold
            );
          }
        }

        &:hover {
          ra-ui-static-text {
            @include ra-ui-typography-level-to-styles(
              $custom-typography,
              subheading-2-bold
            );
            color: $primary-700;
          }

          .ra-ui-navigation-tab-label-icon {
            color: $primary-700;
          }
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

$opacity: 1;

%resetMatButtonValues {
  min-height: 0;
  padding: 0;
}

@mixin disabled-icon-color($theme) {
  .is-disabled mat-icon {
    color: ra-ui-color($theme, foreground, text-disabled);
  }
}

@mixin fab-button($container-size, $icon-size, $padding) {
  @extend %resetMatButtonValues;
  border-radius: 50%;

  width: $container-size;
  height: $container-size;

  .mat-button-wrapper {
    padding: $padding 0;
    display: inline;
    line-height: 1.7143em; //24px;
    max-width: $container-size;
  }

  mat-icon {
    height: 1em;
    width: 1em;
    font-size: $icon-size;
  }
  &.immediate-arrow {
    max-width: calc(100% - #{$width-immediate-arrow} - 1px);
  }
}

// Mixin for building offset given different sizes. mixin from material angular
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;

    @include cdk.high-contrast() {
      outline: solid 0.0625em; //1px;
      border-radius: 0;
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size * 0.5;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size * 0.5;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size * 0.5;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size * 0.5;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size * 0.5;
      }
    }
  }
}

@mixin _ra-ui-override-material-badge($theme, $custom-typography) {
  $mat-badge-default-font-size: 0.86em; //12px;
  $mat-badge-small-font-size: $mat-badge-default-font-size * 0.5; //6px;
  $mat-badge-large-font-size: $mat-badge-default-font-size * 2; //24px;

  $difference: 0.429em; //6px
  $mat-badge-default-size: 1.833em; //22px;
  $mat-badge-small-size: $mat-badge-default-size - $difference; //16px;
  $mat-badge-large-size: $mat-badge-default-size + $difference; //28px;

  .mat-badge-content {
    font-size: $mat-badge-default-font-size;
    z-index: 1;
  }

  .mat-badge-small .mat-badge-content {
    font-size: $mat-badge-small-font-size;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-large-font-size;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }

  .mat-badge-medium.mat-badge-after,
  .mat-badge-medium.mat-badge-before {
    margin: 0 !important;
  }
}

@mixin _ra-ui-override-mat-button-focus($theme) {
  &.ra-ui-button .mat-button-focus-overlay {
    background: none;
  }

  &.ra-ui-keyboard-focus:not(.mat-button-disabled) {
    $size: getRelative(2, 14);

    .mat-button-focus-overlay {
      border: $size solid ra-ui-color($theme, primary, A200);
      opacity: $opacity;
    }
  }
}

// Mixin to set the button colors for Basic and Icon Label buttons
@mixin _ra-ui-set-transparent-background-button-colors($theme, $palette, $hue) {
  .static-text:not([disabled='true']) {
    color: ra-ui-color($theme, $palette, $hue);
  }

  &:not(.is-disabled) {
    &:active,
    &.ra-ui-active-state {
      background-color: ra-ui-color($theme, background, $hue, $active-opacity);
    }

    &:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $hover-opacity);
    }

    &:active:hover,
    &.ra-ui-active-state:hover {
      background-color: ra-ui-color($theme, $palette, $hue, $active-opacity);
    }
  }
}

@mixin _immediate-arrow-width($custom-typography) {
  $prop: get-immediate-arrow-properties($custom-typography);
  $width-immediate: map-get($prop, width);
  $padding-immediate: map-get($prop, padding);

  &.immediate-arrow {
    width: calc(100% - #{$width-immediate} - #{$padding-immediate});
    &.ra-ui-button {
      width: calc(100% - #{$width-immediate} - #{$padding-immediate} - 1px);
      vertical-align: inherit;
    }
  }
  .ra-ui-button {
    .static-text-wrapper {
      @include safari-only-specific() {
        width: calc(100% + 2px);
      }
    }
  }
}

%displaySettings {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

%overrideMaterialStyles {
  padding: 0 $button-padding; //16px
  border-radius: 0.1429em; //2px
  min-width: 2.2858em; //32px
  line-height: $button-line-height;
  min-height: $button-line-height;
  vertical-align: middle;
}

%widthFeatures {
  &,
  & button {
    &:not(.can-overflow) {
      max-width: 100%;
    }
    &.can-overflow {
      width: max-content;
    }
    &.fill-parent {
      width: 100%;
    }
  }
}

%fabButtonText {
  ra-ui-static-text {
    line-height: 1.7143em; //24px
  }
}

%buttonPosition {
  button span {
    position: relative;
  }
}

%raisedFabShadow {
  &:not(.is-disabled):active .mat-button-focus-overlay,
  &:not(.is-disabled).ra-ui-active-state .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

%immediateArrowCursor {
  ra-ui-immediate-arrow.button-immediate-arrow {
    cursor: default;
  }
}

%basicButtonOpacity {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: $opacity; //override material's default value 0.4
  }
}

// makes the component itself unclickable,
// and allow its descendant "actual" button to be clickable only when enabled
%pointerEvents {
  .ra-ui-button:not(:disabled) {
    pointer-events: auto;
  }

  pointer-events: none;

  // in safari, the fact that the `.static-text` has `pointer-events: auto`
  // by default causes the native click events to bubble all the way up to the button component
  // skipping the default prevention logic, since the elements on which that is registered actually have
  // `pointer-events: none`
  .ra-ui-button:disabled .static-text {
    pointer-events: none !important;
  }
}

%lineHeight {
  mat-icon {
    line-height: 1;
  }
}

%disableShadow {
  .ra-ui-button {
    &:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled):active:not([class*='mat-elevation-z']),
    &:not(.mat-button-disabled).ra-ui-active-state:not(
        [class*='mat-elevation-z']
      ) {
      box-shadow: none;
    }
  }
}

@mixin ra-ui-icon-button-size($custom-typography, $icon-size, $padding) {
  $icon-fs: ra-ui-font-size($custom-typography, $icon-size);
  $font-size: ra-ui-font-size($custom-typography, $icon-size);
  $line-height: ra-ui-line-height($custom-typography, $icon-size);
  width: $icon-fs;
  height: $icon-fs;
  line-height: $line-height;
  padding: $padding;
  .mat-icon {
    width: $icon-fs;
    height: $icon-fs;
    font-size: $font-size;
    line-height: $line-height;
  }

  $base-icon-font-sizes: (
    icon-s: 12px,
    icon-m: 16px,
    icon-l: 20px,
    icon-xl: 28px,
  );

  .mat-button-focus-overlay {
    font-size: $font-size;
  }
  // overrides default setting: base font-size by typography-level button
  &.ra-ui-keyboard-focus:not(.mat-button-disabled) .mat-button-focus-overlay {
    border-width: getRelative(2px, map-get($base-icon-font-sizes, $icon-size));
  }
}

@mixin ra-ui-icon-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $text: ra-ui-color($theme, foreground, text);
  $icon-button-enabled-icon: ra-ui-contrast(
    $theme,
    background,
    interactive-component-03
  );
  $icon-button-disabled-icon: ra-ui-color($theme, foreground, text-disabled);
  $icon-button-alt-disabled-icon: ra-ui-color(
    $theme,
    foreground,
    text-primary,
    0.35
  );
  $icon-button-hover-background: ra-ui-color($theme, primary, 200);
  $icon-button-hover-icon: ra-ui-contrast($theme, primary, 200);
  $icon-button-active-background: ra-ui-color($theme, primary, 800);
  $icon-button-active-icon: ra-ui-contrast($theme, primary, 800);

  $inverted-color: ra-ui-contrast($theme, background, interactive-component-07);
  $inverted-disabled-color: ra-ui-color($theme, foreground, text-disabled);
  $inverted-hover-background-color: ra-ui-color($theme, primary, 200);
  $inverted-hover-color: ra-ui-contrast($theme, primary, 200);
  $inverted-press-background-color: ra-ui-color($theme, primary, 800);
  $inverted-press-color: ra-ui-contrast($theme, primary, 800);
  $inverted-keyboard-focus-background-color: rgba(
    ra-ui-contrast($theme, background, interactive-component-07),
    $flourish-hover-opacity
  );

  $inactive-inverted-color: ra-ui-color($theme, background, interactive-alt-01);
  $inactive-inverted-hover-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05,
    400
  );
  $inactive-inverted-hover-background-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05,
    $flourish-hover-opacity
  );
  $inactive-inverted-press-color: ra-ui-color(
    $theme,
    background,
    interactive-alt-02
  );
  $inactive-inverted-press-background-color: ra-ui-color(
    $theme,
    background,
    interactive-alt-02,
    $flourish-active-opacity
  );

  $badge-selector: '.ra-ui-button:not(.is-disabled) ra-ui-badge.ra-ui-badge';

  ra-ui-icon-button {
    vertical-align: middle;

    &,
    .ra-ui-button,
    #{$badge-selector},
    #{$badge-selector} .ra-ui-badge--content,
    #{$badge-selector} .ra-ui-badge--content .static-text-inner {
      @include getCursor($custom-global-config);

      @include _immediate-arrow-width($custom-typography);
    }

    & {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %pointerEvents;
    @extend %disableShadow;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-icon-button {
      &.ra-ui-icon-size-s {
        @include ra-ui-icon-button-size(
          $custom-typography,
          icon-s,
          $small-icon-button-padding
        );
      }

      &.ra-ui-icon-size-m {
        @include ra-ui-icon-button-size(
          $custom-typography,
          icon-m,
          $small-icon-button-padding
        );
      }

      &.ra-ui-icon-size-l {
        @include ra-ui-icon-button-size(
          $custom-typography,
          icon-l,
          $large-icon-button-padding
        );
      }

      &.ra-ui-icon-size-xl {
        @include ra-ui-icon-button-size(
          $custom-typography,
          icon-xl,
          $large-icon-button-padding
        );
      }
    }
    .mat-icon-button {
      &.default-color {
        color: transparent;
        .mat-icon {
          color: $icon-button-enabled-icon;
        }
        &[disabled] .mat-icon {
          color: $icon-button-disabled-icon;
        }
      }
    }

    .mat-icon-button.is-disabled .ra-ui-badge {
      // using color of disabled material badge
      background-color: rgb(185, 185, 185);
    }

    .mat-icon-button.alt-disabled-color {
      &[disabled] .mat-icon {
        color: $icon-button-alt-disabled-icon;
      }
    }

    .mat-icon-button {
      @include _ra-ui-override-mat-button-focus($theme);
    }

    // colored shadow over icons during click
    .mat-icon-button {
      &.mat-primary:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state {
          background-color: ra-ui-color(
            $theme,
            primary,
            default,
            $hover-opacity
          );

          &:hover {
            background-color: ra-ui-color(
              $theme,
              primary,
              default,
              $active-opacity
            );
          }
        }
      }

      &.mat-accent:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state {
          background-color: ra-ui-color(
            $theme,
            accent,
            default,
            $hover-opacity
          );

          &:hover {
            background-color: ra-ui-color(
              $theme,
              accent,
              default,
              $active-opacity
            );
          }
        }
      }

      &.mat-warn:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state {
          background-color: ra-ui-color($theme, warn, default, $hover-opacity);

          &:hover {
            background-color: ra-ui-color(
              $theme,
              warn,
              default,
              $active-opacity
            );
          }
        }
      }

      &.default-color:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state,
        &:active:hover,
        &.ra-ui-active-state:hover {
          background-color: $icon-button-active-background;

          .mat-icon {
            color: $icon-button-active-icon;
          }
        }
      }

      &.default-color:not(.is-disabled):hover {
        background-color: $icon-button-hover-background;

        .mat-icon {
          color: $icon-button-hover-icon;
        }
      }
    }

    // inactive state styles
    &.ra-ui-inactive-state .mat-icon-button {
      &.mat-button-base {
        .mat-icon {
          color: ra-ui-color($theme, foreground, text-label);
        }

        &:hover {
          background-color: ra-ui-color(
            $theme,
            background,
            inactive-hover,
            $flourish-hover-opacity
          );
          .mat-icon {
            color: ra-ui-color($theme, background, inactive-hover);
          }
        }

        &:active,
        &:active:hover,
        &.ra-ui-active-state,
        &.ra-ui-active-state:hover {
          background-color: ra-ui-color(
            $theme,
            background,
            interactive-component-07,
            $flourish-active-opacity
          );
          .mat-icon {
            color: ra-ui-color($theme, background, interactive-component-07);
          }
        }
      }
    }

    &.ra-ui-inverted-mode {
      .ra-ui-button.default-color {
        &:not(.is-disabled) .ra-ui-icon {
          color: $inverted-color;
        }
        &.is-disabled .ra-ui-icon {
          color: $inverted-disabled-color;
        }

        &:not(.is-disabled):hover {
          background-color: $inverted-hover-background-color;

          .ra-ui-icon {
            color: $inverted-hover-color;
          }
        }

        &:not(.is-disabled):active,
        &:not(.is-disabled):active:hover,
        &:not(.is-disabled).ra-ui-active-state,
        &:not(.is-disabled).ra-ui-active-state:hover {
          background-color: $inverted-press-background-color;

          .ra-ui-icon {
            color: $inverted-press-color;
          }
        }
      }

      &.ra-ui-inactive-state .ra-ui-button.ra-ui-button {
        &.default-color {
          &:not(.is-disabled) .ra-ui-icon,
          .static-text:not([disabled='true']) {
            background-color: transparent;
            color: $inactive-inverted-color;
          }
        }

        &:hover {
          background-color: $inactive-inverted-hover-background-color;
          &:not(.is-disabled) .ra-ui-icon,
          .static-text:not([disabled='true']) {
            color: $inactive-inverted-hover-color;
          }
        }

        &:active,
        &:active:hover,
        &.ra-ui-active-state,
        &.ra-ui-active-state:hover {
          background-color: $inactive-inverted-press-background-color;

          &:not(.is-disabled) .ra-ui-icon,
          .static-text:not([disabled='true']) {
            color: $inactive-inverted-press-color;
          }
        }
      }
    }

    @include disabled-icon-color($theme);
  }
}

@mixin ra-ui-dropdown-button-icon($custom-typography, $icon-size) {
  $icon-fs: ra-ui-font-size($custom-typography, $icon-size);
  $line-height: ra-ui-line-height($custom-typography, $icon-size);
  &.mat-icon {
    width: $icon-fs;
    height: $icon-fs;
    font-size: $icon-fs;
    line-height: $line-height;
  }
}

@mixin ra-ui-dropdown-button-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $text: ra-ui-color($theme, foreground, text);
  $dropdown-button-enabled-icon: ra-ui-color(
    $theme,
    background,
    interactive-component-07
  );
  $dropdown-button-disabled-icon: ra-ui-color(
    $theme,
    foreground,
    text-disabled
  );
  $dropdown-button-alt-disabled-icon: ra-ui-color(
    $theme,
    foreground,
    text-primary,
    0.35
  );
  $dropdown-button-hover-background: ra-ui-color($theme, primary, 200);
  $dropdown-button-hover-icon: ra-ui-color(
    $theme,
    background,
    interactive-component-07
  );
  $dropdown-button-active-background: ra-ui-color($theme, primary, 800);
  $dropdown-button-active-icon: ra-ui-contrast($theme, primary, 800);

  $inverted-color: ra-ui-contrast($theme, background, interactive-component-07);
  $inverted-disabled-color: ra-ui-color($theme, foreground, text-disabled);
  $inverted-hover-background-color: ra-ui-color($theme, primary, 200);
  $inverted-hover-color: ra-ui-contrast($theme, primary, 200);
  $inverted-press-background-color: ra-ui-color($theme, primary, 800);
  $inverted-press-color: ra-ui-contrast($theme, primary, 800);
  $inverted-keyboard-focus-background-color: rgba(
    ra-ui-contrast($theme, background, interactive-component-07),
    $flourish-hover-opacity
  );
  $inverted-keyboard-focus-color: ra-ui-contrast(
    $theme,
    background,
    interactive-component-07
  );

  $inactive-inverted-color: ra-ui-color($theme, background, interactive-alt-01);
  $inactive-inverted-hover-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05,
    400
  );
  $inactive-inverted-hover-background-color: ra-ui-color(
    $theme,
    background,
    interactive-component-05,
    $flourish-hover-opacity
  );
  $inactive-inverted-press-color: ra-ui-color(
    $theme,
    background,
    interactive-alt-02
  );
  $inactive-inverted-press-background-color: ra-ui-color(
    $theme,
    background,
    interactive-alt-02,
    $flourish-active-opacity
  );

  $badge-selector: '.ra-ui-button:not(.is-disabled) ra-ui-badge.ra-ui-badge';

  $dropdown-button-fs: 14px;
  //$dropdown-button-fs: ra-ui-font-size($custom-typography, body-2-bold);
  $dropdown-button-padding: getRelative(8, $dropdown-button-fs);
  $dropdown-button-gap: getRelative(4, $dropdown-button-fs);

  ra-ui-dropdown-button {
    vertical-align: middle;

    &,
    .ra-ui-button,
    #{$badge-selector},
    #{$badge-selector} .ra-ui-badge--content,
    #{$badge-selector} .ra-ui-badge--content .static-text-inner {
      @include getCursor($custom-global-config);

      @include _immediate-arrow-width($custom-typography);
    }

    &,
    ra-ui-static-text {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        body-2-bold
      );
    }

    @extend %buttonPosition;
    @extend %immediateArrowCursor;
    @extend %pointerEvents;
    @extend %disableShadow;

    @include _ra-ui-override-material-badge($theme, $custom-typography);

    .mat-button {
      .ra-ui-icon-size-s {
        @include ra-ui-dropdown-button-icon($custom-typography, icon-s);
      }

      .ra-ui-icon-size-m {
        @include ra-ui-dropdown-button-icon($custom-typography, icon-m);
      }

      .ra-ui-icon-size-l {
        @include ra-ui-dropdown-button-icon($custom-typography, icon-l);
      }

      .ra-ui-icon-size-xl {
        @include ra-ui-dropdown-button-icon($custom-typography, icon-xl);
      }
    }
    .mat-button {
      &.default-color {
        color: transparent;
        .mat-icon,
        .static-text {
          color: $dropdown-button-enabled-icon;
        }
        &[disabled] .mat-icon,
        &[disabled] .static-text {
          color: $dropdown-button-disabled-icon;
        }
      }
    }

    .mat-button.is-disabled .ra-ui-badge {
      // using color of disabled material badge
      background-color: rgb(185, 185, 185);
    }

    .mat-button.alt-disabled-color {
      &[disabled] .mat-icon,
      &[disabled] .static-text {
        color: $dropdown-button-alt-disabled-icon;
      }
    }

    .mat-button {
      @include _ra-ui-override-mat-button-focus($theme);
    }

    // colored shadow over icons during click
    .mat-button {
      &.mat-primary:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state {
          background-color: ra-ui-color(
            $theme,
            primary,
            default,
            $hover-opacity
          );

          &:hover {
            background-color: ra-ui-color(
              $theme,
              primary,
              default,
              $active-opacity
            );
          }
        }
      }

      &.mat-accent:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state {
          background-color: ra-ui-color(
            $theme,
            accent,
            default,
            $hover-opacity
          );

          &:hover {
            background-color: ra-ui-color(
              $theme,
              accent,
              default,
              $active-opacity
            );
          }
        }
      }

      &.mat-warn:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state {
          background-color: ra-ui-color($theme, warn, default, $hover-opacity);

          &:hover {
            background-color: ra-ui-color(
              $theme,
              warn,
              default,
              $active-opacity
            );
          }
        }
      }

      &.default-color:not(.is-disabled) {
        &:active,
        &.ra-ui-active-state,
        &:active:hover,
        &.ra-ui-active-state:hover {
          background-color: $dropdown-button-active-background;

          .mat-icon,
          .static-text {
            color: $dropdown-button-active-icon;
          }
        }
      }

      &.default-color:not(.is-disabled):hover {
        background-color: $dropdown-button-hover-background;

        .mat-icon,
        .static-text {
          color: $dropdown-button-hover-icon;
        }
      }
    }

    // inactive state styles
    &.ra-ui-inactive-state .mat-button {
      &.mat-button-base {
        .mat-icon,
        .static-text {
          color: ra-ui-color($theme, foreground, text-label);
        }

        &:hover {
          background-color: ra-ui-color(
            $theme,
            background,
            inactive-hover,
            $flourish-hover-opacity
          );
          .mat-icon,
          .static-text {
            color: ra-ui-color($theme, background, inactive-hover);
          }
        }

        &:active,
        &:active:hover,
        &.ra-ui-active-state,
        &.ra-ui-active-state:hover {
          background-color: ra-ui-color(
            $theme,
            background,
            interactive-component-07,
            $flourish-active-opacity
          );
          .mat-icon,
          .static-text {
            color: ra-ui-color($theme, background, interactive-component-07);
          }
        }
      }
    }

    &.ra-ui-inverted-mode {
      .ra-ui-button.default-color {
        &:not(.is-disabled) .ra-ui-icon,
        &:not(.is-disabled) .static-text {
          color: $inverted-color;
        }
        &.is-disabled .ra-ui-icon,
        &.is-disabled .static-text {
          color: $inverted-disabled-color;
        }

        &:not(.is-disabled):hover {
          background-color: $inverted-hover-background-color;

          .ra-ui-icon,
          .static-text {
            color: $inverted-hover-color;
          }
        }

        &:not(.is-disabled):active,
        &:not(.is-disabled):active:hover,
        &:not(.is-disabled).ra-ui-active-state,
        &:not(.is-disabled).ra-ui-active-state:hover {
          background-color: $inverted-press-background-color;

          .ra-ui-icon,
          .static-text {
            color: $inverted-press-color;
          }
        }

        &.ra-ui-keyboard-focus {
          background-color: $inverted-keyboard-focus-background-color;
          &:not(.is-disabled) .ra-ui-icon,
          &:not(.is-disabled) .static-text {
            color: $inverted-keyboard-focus-color;
          }
        }
      }

      &.ra-ui-inactive-state .ra-ui-button.ra-ui-button {
        &.default-color {
          &:not(.is-disabled) .ra-ui-icon,
          .static-text:not([disabled='true']) {
            background-color: transparent;
            color: $inactive-inverted-color;
          }
        }

        &:hover {
          background-color: $inactive-inverted-hover-background-color;
          &:not(.is-disabled) .ra-ui-icon,
          .static-text:not([disabled='true']) {
            color: $inactive-inverted-hover-color;
          }
        }

        &:active,
        &:active:hover,
        &.ra-ui-active-state,
        &.ra-ui-active-state:hover {
          background-color: $inactive-inverted-press-background-color;

          &:not(.is-disabled) .ra-ui-icon,
          .static-text:not([disabled='true']) {
            color: $inactive-inverted-press-color;
          }
        }
      }
    }

    @include disabled-icon-color($theme);

    .ra-ui-dropdown-button-wrapper {
      padding: 0 $dropdown-button-padding;
      gap: $dropdown-button-gap;
    }
  }
}

@mixin ra-ui-product-header-menu-header-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null,
  $header-menu-fs
) {
  $header-menu-margin-8px: getRelative(8, $header-menu-fs);
  $header-menu-margin-16px: getRelative(16, $header-menu-fs);

  ra-ui-product-header-menu-header {
    div.ra-ui-product-header-menu-header {
      margin: $header-menu-margin-8px $header-menu-margin-8px
        $header-menu-margin-16px;
    }
  }
}

@mixin ra-ui-product-header-side-menu-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $side-menu-fs: 16px;

  ra-ui-product-header-side-menu {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      subheading-2-bold
    );
    @include ra-ui-product-header-menu-header-theme(
      $theme,
      $custom-typography,
      $custom-global-config,
      $side-menu-fs
    );

    ra-ui-category-tree {
      $category-tree-fs: 14px;
      $side-menu-margin-8px: getRelative(8, $category-tree-fs);

      ra-ui-accordion-item.tree-accordion {
        > div:last-child > div {
          border-bottom: 1px solid ra-ui-color($theme, foreground, divider);
        }
      }

      .accordion-header {
        margin-left: $side-menu-margin-8px;
        ra-ui-static-text {
          color: ra-ui-color($theme, foreground, text-disabled);
        }
      }

      &.no-header-section-label {
        .accordion-header {
          margin-left: $side-menu-margin-8px;
        }
      }
    }
  }
}

@mixin ra-ui-product-header-menu-header-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null,
  $header-menu-fs
) {
  $header-menu-margin-8px: getRelative(8, $header-menu-fs);
  $header-menu-margin-16px: getRelative(16, $header-menu-fs);

  ra-ui-product-header-menu-header {
    div.ra-ui-product-header-menu-header {
      margin: $header-menu-margin-8px $header-menu-margin-8px
        $header-menu-margin-16px;
    }
  }
}

@mixin ra-ui-product-header-notification-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null,
  $notification-fs
) {
  $notification-margin-8px: getRelative(8, $notification-fs);
  $notification-margin-16px: getRelative(16, $notification-fs);

  ra-ui-product-header-notification {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      subheading-2-bold
    );

    > div.product-header-notification-container {
      > div {
        margin: $notification-margin-8px;
      }

      > div.product-header-notification-chip-container ra-ui-static-text {
        @include ra-ui-typography-level-to-styles(
          $custom-typography,
          caption-bold
        );

        > ra-ui-badge {
          margin-right: 0.5em; // makes 4px between badge and chip
        }
      }

      .product-header-notification-message-container ra-ui-static-text {
        @include ra-ui-typography-level-to-styles($custom-typography, body-2);

        .static-text-inner {
          max-height: 6em;
        }
      }
    }
  }
}

@mixin ra-ui-product-header-notification-menu-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  $notification-menu-fs: 16px;
  //$notification-menu-fs: ra-ui-font-size($custom-typography, subheading-2-bold);
  $notification-menu-margin-8px: getRelative(8, $notification-menu-fs);
  $notification-menu-gap-8px: getRelative(8, $notification-menu-fs);
  $notification-menu-divider: 1px solid ra-ui-color($theme, foreground, divider);

  ra-ui-product-header-notification-menu {
    @include ra-ui-typography-level-to-styles(
      $custom-typography,
      subheading-2-bold
    );
    @include ra-ui-product-header-menu-header-theme(
      $theme,
      $custom-typography,
      $custom-global-config,
      $notification-menu-fs
    );
    @include ra-ui-product-header-notification-theme(
      $theme,
      $custom-typography,
      $custom-global-config,
      $notification-menu-fs
    );

    .product-header-notifications-section-container {
      gap: $notification-menu-gap-8px;

      .product-header-action-link-container {
        margin: 0 $notification-menu-margin-8px;
      }
    }

    .product-header-notification-with-divider {
      border-bottom: $notification-menu-divider;
    }
  }
}

@mixin ra-ui-product-header-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include ra-ui-dropdown-button-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-product-header-side-menu-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-product-header-notification-menu-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );

  $product-header-fs: 14px;
  $product-header-padding-10px: getRelative(10, $product-header-fs);
  $product-header-padding-16px: getRelative(16, $product-header-fs);
  $product-header-gap-8px: getRelative(8, $product-header-fs);
  $product-header-padding-8px: $product-header-gap-8px;
  $product-header-divider-margin-8px: getRelative(8, $product-header-fs);
  $product-header-36px: getRelative(36, $product-header-fs);
  $interactive-component-07-color: ra-ui-color(
    $theme,
    background,
    interactive-component-07
  );

  // 3 classes because we need to override rule from Material
  .mat-drawer-container .mat-drawer-backdrop.mat-drawer-shown {
    background-color: ra-ui-color($theme, background, overlay);
  }

  .product-header-organization-menu-item:first-child
    ra-ui-static-text.category-label
    > .static-text {
    margin-top: 0;
    $input-font-size: ra-ui-font-size(
      $custom-typography,
      input
    ); // input is menu-category's typography level
    // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
    $input-font-size: if(
      type-of($input-font-size) == string,
      strip-unit(rafl.$rafl-typography-utility-md-font-size) * 16px,
      $input-font-size
    );
    margin-left: getRelative(
      8,
      $input-font-size
    ); // input is menu-category's typography level
  }

  .product-header-user-account-menu-item {
    .ra-ui-menu-item .mat-menu-item.menu-item-button {
      ra-ui-static-text.primary-label-text {
        @include ra-ui-typography-level-to-styles($custom-typography, body-2);
      }

      .mat-icon {
        margin-left: 0;
      }
    }

    &:first-child .ra-ui-menu-item .mat-menu-item.menu-item-button {
      height: fit-content;
      $body-1-font-size: ra-ui-font-size(
        $custom-typography,
        body-1
      ); // body-1 is menu-item's typography level
      // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
      $body-1-font-size: if(
        type-of($body-1-font-size) == string,
        strip-unit(rafl.$rafl-typography-body-sm-font-size) * 16px,
        $body-1-font-size
      );
      padding-bottom: getRelative(
        16,
        $body-1-font-size
      ); // body-1 is menu-item's typography level
      cursor: default;

      ra-ui-static-text.primary-label-text {
        @include ra-ui-typography-level-to-styles($custom-typography, body-3);
        color: ra-ui-color($theme, foreground, text-primary);
        cursor: default;
      }

      .ra-ui-menu-item-caption > ra-ui-static-text {
        @include ra-ui-typography-level-to-styles($custom-typography, body-2);
        color: ra-ui-color($theme, foreground, text-label);

        .static-text-inner {
          cursor: default;
        }
      }

      &:hover,
      &:active {
        background-color: unset;
        .static-text {
          color: unset;
        }
      }
    }
  }

  ra-ui-product-header {
    .mat-toolbar {
      @include ra-ui-typography-level-to-styles($custom-typography, button);
      background: ra-ui-color($theme, background, interactive-component-01);
      padding: $product-header-padding-10px $product-header-padding-16px;
      height: getRelative(56, $product-header-fs);

      .vertical-divider {
        margin: 0 $product-header-divider-margin-8px;
        height: $product-header-36px;
        background: ra-ui-color($theme, foreground, divider);
      }

      ra-ui-image * {
        max-height: $product-header-36px;
      }

      > div {
        gap: $product-header-gap-8px;
      }

      ra-ui-icon-button {
        .mat-icon-button.default-color {
          &:hover .ra-ui-icon,
          .ra-ui-icon {
            color: $interactive-component-07-color;
          }
        }
      }
    }

    .mat-sidenav {
      width: 20.0625em;
      background-color: ra-ui-color(
        $theme,
        background,
        interactive-component-01
      );
    }

    .navbar-right-container .product-header-static-organization-container {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        body-2-bold
      );
      gap: getRelative(4, $product-header-fs);
      padding: $product-header-padding-8px;

      .static-text {
        @include ra-ui-typography-level-to-styles(
          $custom-typography,
          body-2-bold
        );
      }

      .ra-ui-icon-size-l {
        @include ra-ui-dropdown-button-icon($custom-typography, icon-l);
      }

      .ra-ui-icon-size-l,
      .static-text {
        color: $interactive-component-07-color;
      }
    }
  }
}

@mixin ra-ui-vertical-nav-item-pressed-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  &.ra-ui-vertical-nav-item-pressed {
    &,
    &:hover {
      background-color: dtu.rafl-color(color-state-active-pressed, $theme);
    }

    &,
    ra-ui-static-text {
      color: dtu.rafl-color(color-state-active-contrast-max, $theme);
    }
  }
}

@mixin ra-ui-vertical-nav-item-selected-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  .ra-ui-vertical-nav-item-selected {
    &.ra-ui-nav-item-wrapper,
    .ra-ui-nav-item-wrapper {
      background-color: dtu.rafl-color(color-state-active-default, $theme);

      &,
      ra-ui-static-text {
        color: dtu.rafl-color(color-state-active-contrast-max, $theme);
      }

      &:hover {
        background-color: dtu.design-token-var(
          rafl-color-state-active-hovered,
          rafl.$rafl-color-state-active-hovered
        );
      }
    }
  }
}

$vertical-main-navigation-standard-width: dtu.design-token-var(
  rafl-comp-vertical-main-navigation-dimension-standard-expanded-width,
  rafl.$rafl-comp-vertical-main-navigation-dimension-standard-expanded-width
);
$vertical-main-navigation-simplified-width: dtu.design-token-var(
  rafl-comp-vertical-main-navigation-dimension-simplified-width,
  rafl.$rafl-comp-vertical-main-navigation-dimension-simplified-width
);

@mixin ra-ui-vertical-common-item-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include ra-ui-vertical-nav-item-selected-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );

  cursor: pointer;
  user-select: none;
  .ra-ui-nav-item-wrapper {
    @include ra-ui-vertical-nav-item-pressed-theme(
      $theme,
      $custom-typography,
      $custom-global-config
    );

    $iconSize: dtu.rafl-dimension(icon-md, rafl.$rafl-dimension-icon-md);
    $caretIconSize: dtu.rafl-dimension(icon-sm, rafl.$rafl-dimension-icon-sm);
    display: grid;
    grid-template-columns: $iconSize 1fr $caretIconSize;
    align-items: center;

    &,
    ra-ui-static-text {
      @include dtu.rafl-typography(body-md, $custom-typography);
      $line-height: calc(
        dtu.design-token-var(
            rafl-typography-body-md-line-height,
            rafl.$rafl-typography-body-md-line-height
          ) *
          dtu.design-token-var(
            rafl-typography-body-md-font-size,
            rafl.$rafl-typography-body-md-font-size
          )
      );
      line-height: round(up, $line-height, 1px);
    }

    padding: dtu.design-token-var(
        rafl-comp-vertical-main-navigation-dimension-standard-item-padding-vertical,
        rafl.$rafl-comp-vertical-main-navigation-dimension-standard-item-padding-vertical
      )
      dtu.design-token-var(
        rafl-comp-vertical-main-navigation-dimension-standard-item-padding-horizontal,
        rafl.$rafl-comp-vertical-main-navigation-dimension-standard-item-padding-horizontal
      );

    // putting focus outline requires to decrease padding by outline's width
    &:focus-visible,
    &.ra-ui-vertical-nav-item-focused {
      $outline-width: dtu.rafl-core-dimension(
        px-2,
        rafl.$rafl-core-dimension-px-2
      );
      padding: calc(
          dtu.design-token-var(
              rafl-comp-vertical-main-navigation-dimension-standard-item-padding-vertical,
              rafl.$rafl-comp-vertical-main-navigation-dimension-standard-item-padding-vertical
            ) - $outline-width
        )
        calc(
          dtu.design-token-var(
              rafl-comp-vertical-main-navigation-dimension-standard-item-padding-horizontal,
              rafl.$rafl-comp-vertical-main-navigation-dimension-standard-item-padding-horizontal
            ) - $outline-width
        );
      margin: $outline-width;
      outline: dtu.rafl-color(color-state-focused, $theme) solid $outline-width;
    }

    gap: dtu.rafl-dimension(gap-lg, rafl.$rafl-dimension-gap-lg);

    .vertical-nav-item-icon-spacer {
      font-size: $iconSize;
      width: $iconSize;
      min-width: $iconSize;
      height: $iconSize;
    }

    &:hover {
      background-color: dtu.design-token-var(
        rafl-comp-menu-menu-item-color-hovered-background,
        rafl.$rafl-comp-menu-menu-item-color-hovered-background
      );
      /**
      * TODO: text and icon color on hover in dark mode is incorrect
      *   and should probably be fixed here.
      * Can't be fixed easily because there are inconsistencies in background colors coming
      * from design. Some work is needed from Flourish to look into this.
      * We should fix the problem once Flourish tackles the inconsistencies.
      * Deferring the fix is agreed upon with M. Taus from Iris.
      * See https://rockwellautomation.atlassian.net/browse/SWCFLOURSH-3818
      */
    }
  }

  &.vertical-nav-item-disabled .ra-ui-nav-item-wrapper {
    &,
    &:hover {
      background: unset;
    }
    * {
      color: dtu.design-token-var(
        rafl-color-state-disabled-default,
        rafl.$rafl-color-state-disabled-default
      );
    }
    cursor: default;
    outline: none;
  }
}

@mixin ra-ui-vertical-nav-item-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-vertical-navigation-item {
    @include ra-ui-vertical-common-item-theme(
      $theme,
      $custom-typography,
      $custom-global-config
    );
    width: $vertical-main-navigation-standard-width;
    max-width: 100%;

    .vertical-nav-item-caret {
      $iconSize: dtu.rafl-dimension(icon-sm, rafl.$rafl-dimension-icon-sm);
      font-size: $iconSize;
      width: $iconSize;
      height: $iconSize;
    }
  }
}

@mixin ra-ui-vertical-nav-item-pressed-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  &.ra-ui-vertical-nav-item-pressed {
    &,
    &:hover {
      background-color: dtu.rafl-color(color-state-active-pressed, $theme);
    }

    &,
    ra-ui-static-text {
      color: dtu.rafl-color(color-state-active-contrast-max, $theme);
    }
  }
}

@mixin ra-ui-vertical-nav-item-selected-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  .ra-ui-vertical-nav-item-selected {
    &.ra-ui-nav-item-wrapper,
    .ra-ui-nav-item-wrapper {
      background-color: dtu.rafl-color(color-state-active-default, $theme);

      &,
      ra-ui-static-text {
        color: dtu.rafl-color(color-state-active-contrast-max, $theme);
      }

      &:hover {
        background-color: dtu.design-token-var(
          rafl-color-state-active-hovered,
          rafl.$rafl-color-state-active-hovered
        );
      }
    }
  }
}

$vertical-main-navigation-standard-width: dtu.design-token-var(
  rafl-comp-vertical-main-navigation-dimension-standard-expanded-width,
  rafl.$rafl-comp-vertical-main-navigation-dimension-standard-expanded-width
);
$vertical-main-navigation-simplified-width: dtu.design-token-var(
  rafl-comp-vertical-main-navigation-dimension-simplified-width,
  rafl.$rafl-comp-vertical-main-navigation-dimension-simplified-width
);

@mixin ra-ui-vertical-common-item-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include ra-ui-vertical-nav-item-selected-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );

  cursor: pointer;
  user-select: none;
  .ra-ui-nav-item-wrapper {
    @include ra-ui-vertical-nav-item-pressed-theme(
      $theme,
      $custom-typography,
      $custom-global-config
    );

    $iconSize: dtu.rafl-dimension(icon-md, rafl.$rafl-dimension-icon-md);
    $caretIconSize: dtu.rafl-dimension(icon-sm, rafl.$rafl-dimension-icon-sm);
    display: grid;
    grid-template-columns: $iconSize 1fr $caretIconSize;
    align-items: center;

    &,
    ra-ui-static-text {
      @include dtu.rafl-typography(body-md, $custom-typography);
      $line-height: calc(
        dtu.design-token-var(
            rafl-typography-body-md-line-height,
            rafl.$rafl-typography-body-md-line-height
          ) *
          dtu.design-token-var(
            rafl-typography-body-md-font-size,
            rafl.$rafl-typography-body-md-font-size
          )
      );
      line-height: round(up, $line-height, 1px);
    }

    padding: dtu.design-token-var(
        rafl-comp-vertical-main-navigation-dimension-standard-item-padding-vertical,
        rafl.$rafl-comp-vertical-main-navigation-dimension-standard-item-padding-vertical
      )
      dtu.design-token-var(
        rafl-comp-vertical-main-navigation-dimension-standard-item-padding-horizontal,
        rafl.$rafl-comp-vertical-main-navigation-dimension-standard-item-padding-horizontal
      );

    // putting focus outline requires to decrease padding by outline's width
    &:focus-visible,
    &.ra-ui-vertical-nav-item-focused {
      $outline-width: dtu.rafl-core-dimension(
        px-2,
        rafl.$rafl-core-dimension-px-2
      );
      padding: calc(
          dtu.design-token-var(
              rafl-comp-vertical-main-navigation-dimension-standard-item-padding-vertical,
              rafl.$rafl-comp-vertical-main-navigation-dimension-standard-item-padding-vertical
            ) - $outline-width
        )
        calc(
          dtu.design-token-var(
              rafl-comp-vertical-main-navigation-dimension-standard-item-padding-horizontal,
              rafl.$rafl-comp-vertical-main-navigation-dimension-standard-item-padding-horizontal
            ) - $outline-width
        );
      margin: $outline-width;
      outline: dtu.rafl-color(color-state-focused, $theme) solid $outline-width;
    }

    gap: dtu.rafl-dimension(gap-lg, rafl.$rafl-dimension-gap-lg);

    .vertical-nav-item-icon-spacer {
      font-size: $iconSize;
      width: $iconSize;
      min-width: $iconSize;
      height: $iconSize;
    }

    &:hover {
      background-color: dtu.design-token-var(
        rafl-comp-menu-menu-item-color-hovered-background,
        rafl.$rafl-comp-menu-menu-item-color-hovered-background
      );
      /**
      * TODO: text and icon color on hover in dark mode is incorrect
      *   and should probably be fixed here.
      * Can't be fixed easily because there are inconsistencies in background colors coming
      * from design. Some work is needed from Flourish to look into this.
      * We should fix the problem once Flourish tackles the inconsistencies.
      * Deferring the fix is agreed upon with M. Taus from Iris.
      * See https://rockwellautomation.atlassian.net/browse/SWCFLOURSH-3818
      */
    }
  }

  &.vertical-nav-item-disabled .ra-ui-nav-item-wrapper {
    &,
    &:hover {
      background: unset;
    }
    * {
      color: dtu.design-token-var(
        rafl-color-state-disabled-default,
        rafl.$rafl-color-state-disabled-default
      );
    }
    cursor: default;
    outline: none;
  }
}

@mixin ra-ui-vertical-navigation-sub-item-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-vertical-navigation-sub-item {
    @include ra-ui-vertical-common-item-theme(
      $theme,
      $custom-typography,
      $custom-global-config
    );
  }
}

@mixin ra-ui-vertical-nav-tree-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-vertical-navigation-tree {
    @include ra-ui-vertical-nav-item-theme(
      $theme,
      $custom-typography,
      $custom-global-config
    );
    @include ra-ui-vertical-navigation-sub-item-theme(
      $theme,
      $custom-typography,
      $custom-global-config
    );
  }
}

@mixin ra-ui-vertical-nav-product-identity-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-product-identity {
    ra-ui-image {
      $height: dtu.rafl-core-dimension(px-24, rafl.$rafl-core-dimension-px-24);
      $vertical-padding: dtu.rafl-dimension(
        padding-sm,
        rafl.$rafl-dimension-padding-sm
      );
      $horizontal-padding: dtu.design-token-var(
        rafl-comp-vertical-main-navigation-dimension-product-identity-padding-horizontal,
        rafl.$rafl-comp-vertical-main-navigation-dimension-product-identity-padding-horizontal
      );
      padding: $vertical-padding $horizontal-padding;
      // need to set height explicitly, because ra-ui-image-not-found does not accept supplied config
      height: calc($height + 2 * $vertical-padding);
    }
  }
}

@mixin ra-ui-vertical-main-navigation-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  ra-ui-vertical-main-navigation {
    $border-thickness: dtu.rafl-dimension(
      stroke-sm,
      rafl.$rafl-dimension-stroke-sm
    );
    $vertical-padding: dtu.rafl-dimension(
      padding-sm,
      rafl.$rafl-dimension-padding-sm
    );
    $simplified-width: $vertical-main-navigation-simplified-width;
    $standard-width: $vertical-main-navigation-standard-width;
    // adding $border-thickness is necessary due to box-sizing: border-box; on ra-ui-vertical-main-navigation
    $simplified-width-incl-border: calc($simplified-width + $border-thickness);
    $standard-width-incl-border: calc($standard-width + $border-thickness);
    // using custom css tokens to avoid hardcoding values for animation specification in .ts file
    --ra-ui-simplified-width-incl-border: #{$simplified-width-incl-border};
    --ra-ui-standard-width-incl-border: #{$standard-width-incl-border};

    .ra-ui-vertical-main-navigation-spacer-for-toggle-on-hover {
      min-width: $simplified-width-incl-border;
    }

    .ra-ui-vertical-main-navigation-bar-container {
      $padding-xs: dtu.rafl-dimension(
        padding-xs,
        rafl.$rafl-dimension-padding-xs
      );

      @include ra-ui-vertical-nav-product-identity-theme(
        $theme,
        $custom-typography,
        $custom-global-config
      );
      @include ra-ui-vertical-nav-tree-theme(
        $theme,
        $custom-typography,
        $custom-global-config
      );

      padding-top: $vertical-padding;
      gap: dtu.rafl-dimension(gap-md, rafl.$rafl-dimension-gap-md);
      border-right: $border-thickness solid
        dtu.rafl-color(color-outline-contrast-middle, $theme);
      background: dtu.rafl-color(color-background-container, $theme);

      &.ra-ui-vertical-main-navigation-without-bottom-icon-btn {
        padding-bottom: $vertical-padding;
      }

      .ra-ui-vertical-main-navigation-bottom-icon-btn-container {
        border-top: $border-thickness solid
          dtu.rafl-color(color-outline-contrast-low, $theme);
        padding: $padding-xs $padding-xs $padding-xs 0;
      }
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// File to include additional scss variables and mixins

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

$button-line-height: 2.5715em;

$mat-icon-button-size: getRelative(40px, 14px); // button typography level = 14
$small-icon-button-padding: getRelative(4px, 14);
$large-icon-button-padding: getRelative(8px, 14);
$text-button-min-height: getRelative(24, 14);

$button-padding: 1.1429em;

// this value is defined in `legacy-nav-bar.component.ts`,
// if you change this one, you should also update value in ts, `iconActionWidthRelative`
$legacy-nav-bar-height: 2.8572em; //40px

//override material's opacity 0.12 on buttons in action-bar and legacy-nav-bar
%override-button-opacity {
  %opacity {
    opacity: 1;
  }

  button.mat-button:hover .mat-button-focus-overlay {
    @extend %opacity;
  }

  $buttons: mat-button mat-icon-button;
  $types-of-focused: cdk-keyboard-focused cdk-program-focused;

  @each $button in $buttons {
    @each $type in $types-of-focused {
      .#{$button}.#{$type} .mat-button-focus-overlay {
        @extend %opacity;
      }
    }
  }
}

@mixin navigation-theme(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include ra-ui-action-bar-component-theme($theme, $custom-typography);
  @include ra-ui-legacy-nav-bar-component-theme($theme, $custom-typography);
  @include ra-ui-breadcrumb-component-theme($theme, $custom-typography);
  @include ra-ui-main-menu-bar-component-theme($theme, $custom-typography);
  @include ra-ui-toolbar-component-theme($theme, $custom-typography);
  @include ra-ui-navigation-tab-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-primary-navigation-tab-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-product-header-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-vertical-main-navigation-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin override-material-dialog-backdrop($theme) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing.ra-ui-dialog-backdrop {
    background-color: ra-ui-color($theme, background, overlay);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin override-material-dialog-backdrop($theme) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing.ra-ui-dialog-backdrop {
    background-color: ra-ui-color($theme, background, overlay);
  }
}

@mixin ra-ui-banner-theme($theme, $custom-typography) {
  $background-color: ra-ui-color($theme, background, interactive-component-07);
  $text-color: ra-ui-contrast($theme, background, interactive-component-07);
  $horizontal-wrapper-padding: getRelative(8px, 14px);
  $vertical-wrapper-padding: getRelative(16px, 14px);
  $icon-margin: getRelative(
    8px,
    14px
  ); // this ratio is specified in ts as well; don't forget to modify it there if it ever changes
  $buttons-margin: getRelative(
    16px,
    14px
  ); // this ratio is specified in ts as well; don't forget to modify it there if it ever changes

  ra-ui-banner {
    @include ra-ui-typography-level-to-styles($custom-typography, body-2);
    .ra-ui-banner-wrapper {
      max-width: 100%;
      background-color: $background-color;
      padding: $horizontal-wrapper-padding $vertical-wrapper-padding;
      cursor: default;

      &.ra-ui-use-notification-background {
        &.ra-ui-notification-info {
          background-color: map_get(
            ra-ui-status-palette($theme, $status-palette),
            information
          );
        }
        &.ra-ui-notification-success {
          background-color: map_get(
            ra-ui-status-palette($theme, $status-palette),
            success
          );
        }
        &.ra-ui-notification-warning {
          background-color: map_get(
            ra-ui-status-palette($theme, $status-palette),
            warning
          );
        }
        &.ra-ui-notification-error {
          background-color: map_get(
            ra-ui-status-palette($theme, $status-palette),
            error
          );
        }
      }

      &:not(.ra-ui-use-notification-background).ra-ui-notification-warning {
        .ra-ui-banner-message .static-text {
          color: $text-color;
        }
      }
      &.ra-ui-notification-info,
      &.ra-ui-notification-success,
      &.ra-ui-notification-error {
        .ra-ui-banner-message .static-text {
          color: $text-color;
        }
      }
      &.ra-ui-use-notification-background.ra-ui-notification-warning {
        .ra-ui-banner-icon,
        .ra-ui-banner-close-icon {
          mat-icon {
            color: ra-ui-color($theme, foreground, text-primary);
          }
        }
      }

      .ra-ui-banner-message.ra-ui-icon-visible {
        margin-left: $icon-margin;
      }

      .ra-ui-banner-message ra-ui-static-text {
        @include ra-ui-typography-level-to-styles($custom-typography, body-2);
      }

      .ra-ui-banner-clickable-elements-wrapper,
      .ra-ui-banner-icon {
        $line-height-body-2: ra-ui-line-height($custom-typography, body-2);
        height: if(
          type-of($line-height-body-2) == string,
          calc(
            $line-height-body-2 * ra-ui-font-size($custom-typography, body-2)
          ),
          $line-height-body-2
        );
      }

      .ra-ui-banner-buttons-wrapper {
        margin-left: $buttons-margin;
      }

      .ra-ui-banner-icon {
        @include ra-ui-typography-level-to-styles($custom-typography, icon-m);
        width: 1em;

        mat-icon {
          @include ra-ui-typography-level-to-styles($custom-typography, icon-m);
          cursor: default;
        }

        ra-ui-status-icon {
          cursor: default;
        }
      }

      .ra-ui-banner-icon,
      .ra-ui-banner-close-icon {
        mat-icon {
          color: $text-color;
          height: 1em;
          width: 1em;
        }
      }

      .ra-ui-banner-close-icon {
        @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
        height: 1em;
        width: 1em;
        // This element has typography icon-s but we need the margin to be relative to a different typography, hence the computation
        $font-size-icon-s: ra-ui-font-size($custom-typography, icon-s);
        $font-size-icon-s: if(
          type-of($font-size-icon-s) == string,
          rafl.$rafl-dimension-icon-sm,
          $font-size-icon-s
        );
        $body-2-font-size: ra-ui-font-size($custom-typography, body-2);
        $body-2-font-size: if(
          type-of($body-2-font-size) == string,
          rafl.$rafl-typography-body-md-font-size,
          $body-2-font-size
        );
        margin-left: $icon-margin *
          strip-unit(math.div($body-2-font-size, $font-size-icon-s));
        cursor: pointer;

        mat-icon {
          @include ra-ui-typography-level-to-styles($custom-typography, icon-s);
        }
      }
    }
  }
}

// removes default focus style defined by browser
// this style is applying on an global overlay, which it's defined outside mixin scope
.cdk-overlay-pane {
  &.ra-ui-flyout-panel,
  ra-ui-flyout {
    outline: none;
  }
}

@mixin ra-ui-flyout-theme($theme, $custom-typography) {
  $base-font-size: 12px;

  ra-ui-flyout {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    padding: getRelative(8px, $base-font-size);
    background-color: ra-ui-color($theme, background, interactive-component-01);
    border: 1px solid ra-ui-color($theme, background, interactive-component-06);
    border-radius: 0 0 0 getRelative(4px, $base-font-size);
    box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    box-sizing: border-box;
    overflow: auto;

    min-height: getRelative(40px, $base-font-size);
    min-width: getRelative(40px, $base-font-size);
    width: 100%;

    &[class*='below'] {
      margin-top: getRelative(4px, $base-font-size);
    }
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin override-material-dialog-backdrop($theme) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing.ra-ui-dialog-backdrop {
    background-color: ra-ui-color($theme, background, overlay);
  }
}

@mixin ra-ui-dialog-theme($theme, $custom-typography) {
  $title-font-size: ra-ui-font-size($custom-typography, subheading-2);
  $base-body-2: 14;
  $base-button: 14;
  $dialog-border-radius: getRelative(8, $base-body-2) 0
    getRelative(8, $base-body-2) 0;
  $small-padding: getRelative(4, $base-body-2);
  $big-padding: getRelative(16, $base-body-2);
  $button-fs: ra-ui-font-size($custom-typography, button);
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $body-2-fs: ra-ui-font-size($custom-typography, body-2);

  @include override-material-dialog-backdrop($theme);

  .ra-ui-dialog-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-2);

    display: flex;
    flex-direction: column;

    .mat-dialog-container {
      position: relative;
      border-radius: $dialog-border-radius;
      padding: $big-padding;

      display: flex;
      flex-direction: column;

      flex: 1 1 auto;
      min-height: 0;

      overflow: unset;
      background: ra-ui-color($theme, background, interactive-component-01);
    }

    &.is-fullscreen {
      .mat-dialog-container {
        border-radius: 0;
      }
    }
  }

  ra-ui-dialog {
    display: flex;
    flex-direction: column;

    flex: 1 1 auto;
    min-height: 0;

    .mat-dialog-title {
      @include ra-ui-typography-level-to-styles(
        $custom-typography,
        subheading-2-bold
      );

      flex: 0 0 auto;

      overflow: hidden;
      margin-bottom: getRelative(4, 16);

      ra-ui-status-icon {
        cursor: default;
      }

      mat-icon {
        @include ra-ui-typography-level-to-styles($custom-typography, icon-m);
        height: 1em;
        width: 1em;
      }

      .title-container {
        @include ra-ui-typography-level-to-styles(
          $custom-typography,
          subheading-2-bold
        );

        $left-margin: getRelative(8, 16);
        // full width subtracted by title status icon and padding between icon and title
        $title-icon-max-width: calc(
          100% - #{$title-font-size} - #{$left-margin}
        );
        width: 100%;

        &.with-title-icon {
          margin-left: $left-margin;
          width: $title-icon-max-width;
        }

        flex-wrap: wrap;

        &.with-close-icon {
          // getting dialog space between content and outer border of dialog in px
          $right-dialog-space: calc(
            (16 / $base-body-2) * strip-unit($body-2-fs)
          );
          $right-dialog-space-px: $right-dialog-space + 'px';

          // getting padding space between outside of the close icon button
          $small-title-padding: calc(
            (4 / $base-body-2) * strip-unit($body-2-fs) * 2
          );
          $small-title-padding-px: $small-title-padding + 'px';

          // getting inner icon button padding to proper calculate its dimensions
          $button-padding: calc(
            (4 / $base-button) * strip-unit($button-fs) * 2
          );
          $button-padding-px: #{$button-padding} + 'px';

          // width of title - full stretch subtracted by dimensions of the close button, status icon and paddings
          width: calc(
            100% + #{$right-dialog-space-px} - #{$button-padding-px} - #{$icon-s-fs} -
              #{$small-title-padding-px}
          );

          &.with-title-icon {
            width: calc(
              #{$title-icon-max-width} + #{$right-dialog-space-px} - #{$button-padding-px} -
                #{$icon-s-fs} - #{$small-title-padding-px}
            );
          }
        }

        .title {
          width: 100%;
          ra-ui-static-text {
            @include ra-ui-typography-level-to-styles(
              $custom-typography,
              subheading-2-bold
            );
          }
        }
      }

      .primary-subtitle,
      .secondary-subtitle {
        @include ra-ui-typography-level-to-styles(
          $custom-typography,
          subheading-1
        );

        ra-ui-static-text {
          @include ra-ui-typography-level-to-styles(
            $custom-typography,
            subheading-1
          );
        }
      }

      .secondary-subtitle:not(:last-child) {
        margin-right: getRelative(16, 14);
      }
    }

    .dialog-close-icon-button {
      position: absolute;
      right: getRelative(4, $base-button);
      top: getRelative(4, $base-button);
      z-index: 100;
    }

    .mat-dialog-content {
      padding: 0; //override material's styles
      margin: 0; //override material's styles
      max-height: unset; //override material's styles
      max-width: 100%;

      flex: 1 1 auto;
      min-height: 0;

      .messages {
        overflow: auto;
        .main-message {
          @include ra-ui-typography-level-to-styles($custom-typography, body-2);
          ra-ui-static-text {
            @include ra-ui-typography-level-to-styles(
              $custom-typography,
              body-2
            );
            line-height: ra-ui-line-height($custom-typography, body-2);
          }
        }

        .optional-message {
          padding-top: 0.5em; //8px;
          ra-ui-static-text {
            @include ra-ui-typography-level-to-styles(
              $custom-typography,
              caption
            );
          }
        }
      }
    }

    .mat-dialog-actions {
      flex: 0 0 auto;

      padding: 0; //override material's styles
      margin-bottom: 0; //override material's styles
      min-height: 0; //override material's styles
    }

    .buttons {
      overflow: hidden;

      margin-top: getRelative(8, $base-button);

      .ra-ui-dialog-button {
        margin-left: getRelative(8, $base-button);
        margin-top: getRelative(8, $base-button);
      }

      ra-ui-raised-button {
        min-width: 0%;
      }
    }
  }
}

@mixin ra-ui-context-menu-component-theme($theme, $custom-typography) {
}
// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin _menu-keyboard-focused-border($color) {
  background: $color;
  outline-color: $color;
}

@mixin ra-ui-menu-component-theme(
  $theme,
  $custom-typography,
  $custom-global-config
) {
  $menu-tone: inactive-hover;
  $contrast: ra-ui-contrast($theme, background, $menu-tone);
  $hover-opacity: 1;
  $icon-m-font-size: mat.font-size($custom-typography, icon-m);
  $menu-disabled-text-shade: 0.26;
  $menu-text-shade: 0.7;
  $text: ra-ui-color($theme, foreground, text);
  $max-width-secondary-label: 5.5em;
  $secondary-label-margin-left: 1em;
  $primary-text-min-width: 1em;
  $padding-primary-text: 1em;
  $padding-caption-text: calc(
    strip-unit($padding-primary-text) *
      ra-ui-font-size($custom-typography, body-1)
  );

  $hover-color: ra-ui-color($theme, primary, $menu-hover);
  $padding-menu-item: 4px;
  $margin-padding-menu-panel: 4px; //if 4px ever changes, do not forget to reflect it in menu.types.ts
  $arrow: 5px; // arrow is from material and it is not relative
  $right-space: getRelative($padding-menu-item, 12px);
  $item-padding: getRelative($padding-menu-item, 12px);

  $padding-right-primary-text: getRelative(4px, 12);

  ra-ui-menu {
    .menu-item-button:hover:not(.is-disabled) {
      background: rgba($contrast, $hover-opacity);
    }

    .menu-item-button:not(.is-disabled),
    .default-color:not(.is-disabled),
    .mat-button:not(.ra-ui-button) {
      &.cdk-focused:active {
        background-color: rgba($contrast, $hover-opacity);
      }

      .mat-button-focus-overlay {
        background: rgba($contrast, $hover-opacity);
      }

      .static-text,
      &.mat-icon-button mat-icon,
      &.ra-ui-icon-with-text-button mat-icon {
        color: rgba($contrast, $menu-text-shade);
      }
    }

    .menu-item-button.is-disabled,
    .default-color.is-disabled {
      ra-ui-static-text .static-text[disabled='true'],
      &.mat-icon-button mat-icon {
        color: rgba($contrast, $menu-disabled-text-shade);
      }
    }
  }

  // these rules are outside of ra-ui-menu selector because they style overlays
  .ra-ui-menu,
  .menu-nested {
    &.ra-ui-menu-empty {
      // prevent opening menu with no items, still needed for cases when raUiMenuTrigger directive is not used
      display: none;
    }

    &.menu-virtual-scroll {
      overflow-y: hidden;
    }

    cdk-virtual-scroll-viewport.ra-ui-enable-virtual-scroll {
      min-height: 100%;
      height: calc(100vh - #{$mat-menu-height});

      .cdk-virtual-scroll-content-wrapper {
        max-width: 100%;
      }
    }

    .mat-menu-item:not(.is-disabled) {
      @include getCursor($custom-global-config);
    }

    &.mat-menu-panel.menu-nested {
      margin-top: -($margin-padding-menu-panel + $mat-menu-border-width);
      margin-bottom: -($margin-padding-menu-panel + $mat-menu-border-width);
    }

    &.mat-menu-panel .mat-menu-content {
      font-size: ra-ui-font-size($custom-typography, body-1);
      //if padding ever changes, do not forget to reflect it in menu.types.ts
      padding: $margin-padding-menu-panel 0;

      .mat-menu-item {
        font-size: ra-ui-font-size($custom-typography, body-1);
        padding: $item-padding;
        width: 100%;

        &-submenu-trigger .mat-menu-submenu-icon {
          font-size: ra-ui-font-size($custom-typography, button);
          border-width: #{$arrow} 0 #{$arrow} #{$arrow};
          //align to the arrow in the middle of the item padding.
          right: $right-space;
        }
      }

      // we need to rewrite styles for menu items with nested children when all children are hidden
      // this hides the parent arrow which used to stay there if even all the children were hidden, see RAIDWTUI01-384
      .mat-menu-item.menu-item-button.all-children-items-hidden {
        .mat-menu-submenu-icon {
          display: none;
        }

        background: transparent;

        .static-text,
        mat-icon:before {
          color: ra-ui-color($theme, foreground, text-primary);
        }

        &:hover:not(.is-disabled) {
          background: $hover-color;

          .static-text,
          mat-icon:before {
            color: ra-ui-contrast($theme, primary, $menu-hover);
          }
        }

        &:active:not(.is-disabled) {
          background: ra-ui-color($theme, primary, $menu-selected);

          .static-text,
          mat-icon:before {
            color: ra-ui-contrast($theme, primary, $menu-selected);
          }
        }
      }
    }

    .mat-menu-item {
      height: auto;
    }
    .mat-menu-item.ra-ui-default-template-menu-item {
      height: 2em;
    }
    .mat-menu-item.ra-ui-default-template-menu-item.ra-ui-menu-item-with-caption {
      $line-height-caption: ra-ui-line-height($custom-typography, caption);
      height: calc(2em + #{$line-height-caption});
    }
    .menu-item-texts-wrapper {
      width: 100%;
      overflow: hidden;
    }
    .menu-parent-item-texts-wrapper {
      .primary-label-text {
        padding-right: $right-space;
      }
      width: calc(100% - (#{$arrow}));
    }

    span.icon-wrapper:not(.preserve-two-icons)
      + .menu-parent-item-texts-wrapper {
      width: calc(100% - (#{$icon-m-font-size} + #{$arrow} + #{$right-space}));
    }

    span.icon-wrapper {
      &.preserve-two-icons + .menu-parent-item-texts-wrapper {
        width: calc(
          100% - ($icon-m-font-size * 2 + #{$arrow} + #{$right-space * 2})
        );
      }
      mat-icon {
        display: flex;
        align-items: center;
      }
    }

    .ra-ui-menu-item-caption {
      display: block;
      @include ra-ui-typography-level-to-styles($custom-typography, caption);
      ra-ui-static-text {
        @include ra-ui-typography-level-to-styles($custom-typography, caption);
        padding-right: 0 !important;
      }
    }

    .ra-ui-menu-leaf-item .ra-ui-menu-item-caption {
      width: calc(100% - #{$padding-caption-text});
    }

    .menu-item-button.mat-menu-item {
      display: flex;
      margin: 0px;

      $icon-width: 1em;

      .icon-wrapper {
        display: flex;
      }

      .icon-padding-right {
        padding-right: $item-padding;
      }

      mat-icon {
        color: $text;
        height: $icon-width;
        line-height: ra-ui-line-height($custom-typography, body-1);
        min-width: $icon-width;
        width: $icon-width;
        font-size: $icon-m-font-size;
        overflow: unset;
        &.empty-icon {
          width: $icon-width;
        }
      }

      .menu-item-first-line-texts-wrapper {
        display: flex;
        line-height: 140%;
        justify-content: space-between;
        &.justify-end {
          justify-content: end;
        }

        ra-ui-static-text:not(.static-text-in-toolbar) {
          &.primary-label-text {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &.primary-label-padding-right {
              padding-right: $padding-right-primary-text;
            }
            & + .secondary-label-text {
              max-width: calc(
                100% - #{$padding-right-primary-text} - #{$primary-text-min-width}
              );
            }
          }

          &.secondary-label-text {
            vertical-align: super;
            text-align: right;
          }
        }
      }

      .wrapper-leaf-content {
        line-height: ra-ui-line-height($custom-typography, body-1);

        flex-grow: 1;
        //fixes shrinking flex children in flex container
        min-width: 0;

        &:not(.has-secondary-label) .primary-label-text {
          width: 100%;
        }

        mat-icon {
          flex: 0 0 $icon-width;
        }
      }

      &.is-disabled {
        mat-icon {
          color: ra-ui-color($theme, foreground, text-disabled);
        }
      }

      &:hover:not(.is-disabled),
      &.mat-menu-item-highlighted:not(.is-disabled) {
        background: $hover-color;

        .static-text,
        mat-icon:before,
        .mat-menu-submenu-icon {
          color: ra-ui-contrast($theme, primary, $menu-hover);
        }
      }

      &:active:not(.is-disabled),
      &.mat-menu-item-highlighted:not(.is-disabled) {
        background: ra-ui-color($theme, primary, $menu-selected);

        .static-text,
        mat-icon:before,
        .mat-menu-submenu-icon {
          color: ra-ui-contrast($theme, primary, $menu-selected);
        }
      }
    }
  }

  .ra-menu-item {
    ra-ui-static-text {
      &.primary-label-text {
        min-width: $primary-text-min-width;
      }
      &.category-label {
        display: flex;

        > .static-text {
          @include ra-ui-typography-level-to-styles($custom-typography, input);
          padding: getRelative($padding-menu-item, 14px);
          color: ra-ui-color($theme, background, inactive);
          margin-top: 4px;
        }
      }
    }

    .ra-ui-menu-item .menu-item-button.item-with-category {
      padding-left: getRelative($padding-menu-item, 12px) * 2;
    }
  }

  .ra-ui-menu-hidden {
    display: none;
  }

  .ra-ui-menu .mat-divider {
    border-top-color: ra-ui-color($theme, background, interactive-component-06);

    &.mat-divider-horizontal {
      margin: getRelative(2px, 12px) 0;
    }
  }

  .ra-ui-menu,
  .menu-nested {
    &.mat-menu-panel .mat-menu-content .mat-menu-item {
      outline: 2px solid transparent;
      outline-offset: -2px; // due to outline behaviour in menu container, outline has to have offset of -2px
    }
  }

  .mat-menu-item.cdk-keyboard-focused:not([disabled]):not(
      .main-menu-bar-button
    ) {
    @include _menu-keyboard-focused-border($hover-color);
  }
  .ra-ui-parent-item {
    .mat-menu-item.cdk-program-focused:not([disabled]),
    .mat-menu-item.cdk-keyboard-focused:not([disabled]) {
      @include _menu-keyboard-focused-border($hover-color);
    }
  }
  .menu-nested {
    .mat-menu-item.cdk-program-focused:not([disabled]) {
      @include _menu-keyboard-focused-border($hover-color);
    }
  }
}

@mixin ra-ui-link-menu-theme($theme, $custom-typography) {
  /**
    * TODO: Remove the following rule after RAIDWTUI01-3397 (MR !3492) is merged
    * Also remove ra-ui-link-menu-no-items class from the component template.
    *
    * This is ugly hotfix to make night pipeline works, so we can release.
    * In menu-trigger.directive.ts, there is a function changePointerEventsStyle that adds
    * pointer-events: none to link menu's button whenever there are no menu items.
    * This breaks the tests for default/invalid states on link menu. With none pointer events,
    * the hover and pressed styling do not work. This needs to be overridden with !important
    * because menu trigger adds it as inline styles, so it is impossible to override otherwise.
    * The rule is applied only when there are no items so we don't break other functionality when
    * there items (e.g. click on link menu trigger, menu opens, click on link menu trigger again -
    * when the rule was applied all the time, the opened menu started blinking).
    */
  ra-ui-text-button.ra-ui-link-menu-no-items .ra-ui-button {
    pointer-events: all !important;
  }
}

@mixin ra-ui-toast-theme($custom-typography) {
  $ra-ui-toast-min-height: getRelative(48px, 12px);
  $ra-ui-toast-spacing-margin: getRelative(2px, 12px) 0;
  $ra-ui-toast-margin-between: getRelative(8px, 12px);

  ra-ui-toast {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
  }
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

@mixin override-material-dialog-backdrop($theme) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing.ra-ui-dialog-backdrop {
    background-color: ra-ui-color($theme, background, overlay);
  }
}

@mixin ra-ui-simple-toast-theme($theme, $custom-typography) {
  $status-icon-width: if(
    contains($custom-typography, icon-m),
    ra-ui-font-size($custom-typography, icon-m),
    16px
  );
  $close-icon-width: if(
    contains($custom-typography, icon-s),
    ra-ui-font-size($custom-typography, icon-s),
    12px
  );

  $padding-container: getRelative(16px, 12px);
  $space-status-icon-content: getRelative(8px, 12px);
  $space-content-close-button: getRelative(16px, 12px);
  $padding-buttons: getRelative(8px, 12px);
  $padding-left-button: getRelative(8px, 12px);

  $left-right-space-content: $space-status-icon-content +
    $space-content-close-button;
  $ra-ui-toast-min-height: getRelative(48px, 12px);
  $ra-ui-toast-with-buttons-min-height: getRelative(72px, 12px);
  $body-1-font-size: ra-ui-font-size($custom-typography, body-1);
  $body-1-line-height: ra-ui-line-height($custom-typography, body-1);
  // line-height in tokens is a coefficient, so the coefficient must be multiplied by font-size
  $body-1-line-height: if(
    type-of($body-1-line-height) == string,
    calc($body-1-line-height * $body-1-font-size),
    $body-1-line-height
  );

  // Icon is located centered inline with text (first row of text, in case of multiline text)
  // I have to calculate the size to the middle of the text from the top
  $toast-icon-margin-top: calc(($body-1-line-height - $body-1-font-size) * 0.5);

  ra-ui-simple-toast {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);

    background-color: ra-ui-color(
      $theme,
      background,
      interactive-component-07,
      1
    );
    position: relative;
    display: block !important;
    cursor: pointer;
    border-radius: 4px 0;
    box-sizing: border-box;
    padding: $padding-container;
    min-height: $ra-ui-toast-min-height;

    &.toast-with-buttons {
      padding-bottom: 0;
      min-height: $ra-ui-toast-with-buttons-min-height;
    }
    ra-ui-static-text,
    .ra-icon-ide-sm-cross {
      color: ra-ui-contrast($theme, background, interactive-component-07);
    }

    .content {
      //added 1px fixes differences in rounding
      max-width: calc(
        100% - ($status-icon-width + $close-icon-width) -
          $left-right-space-content + 1px
      );

      //added 1px fixes differences in rounding
      &.close-button-hidden {
        max-width: calc(
          100% - #{$status-icon-width} - #{$left-right-space-content} + 1px
        );
      }

      &,
      > * {
        width: 100%;
      }

      margin-left: $space-status-icon-content;
      margin-right: $space-content-close-button;
    }

    .mat-icon {
      font-size: $status-icon-width;

      height: 1em;
      width: 1em;
      font-weight: bold;
    }

    .template-icon,
    .close-icon {
      height: $body-1-line-height;
    }

    .ra-icon-ide-sm-cross {
      font-size: $close-icon-width;
    }

    .ra-ui-toast-buttons-wrapper {
      padding: $padding-buttons 0;

      margin-left: calc(#{$space-status-icon-content} + #{$status-icon-width});
      margin-right: calc(#{$space-content-close-button} + #{$close-icon-width});

      .ra-ui-toast-button-wrapper {
        padding-left: $padding-left-button;
      }
    }
  }
}

@mixin popups-theme($theme, $custom-typography, $custom-global-config: null) {
  @include ra-ui-banner-theme($theme, $custom-typography);
  @include ra-ui-flyout-theme($theme, $custom-typography);
  @include ra-ui-dialog-theme($theme, $custom-typography);
  @include ra-ui-toast-theme($custom-typography);
  @include ra-ui-simple-toast-theme($theme, $custom-typography);
  @include ra-ui-context-menu-component-theme($theme, $custom-typography);
  @include ra-ui-menu-component-theme(
    $theme,
    $custom-typography,
    $custom-global-config
  );
  @include ra-ui-link-menu-theme($theme, $custom-typography);
}

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

//The function calculate relative value. from expected value and base font.
//example: For element with font-size: 16px and padding: 8px you want the padding to be relative to the font-size. To achieve that you call getRelative(8px, 16px) to get the padding in EMs.
//In the example above, it returns 0.5em
//The both arguments should have the same units.
@function getRelative($expected-value, $base-font) {
  $expected-value: strip-unit($expected-value);
  $base-font: strip-unit($base-font);

  @return math.div($expected-value, $base-font) * 1em;
}

$default-hue: interactive-component-06;

$active-opacity: 0.24;
$hover-opacity: 0.12;
$flourish-active-opacity: 0.2;
$flourish-hover-opacity: 0.1;
$menu-hover: 200;
$menu-selected: 600;

$mat-menu-height: 3em;

$mat-menu-padding-horizontal: 1em;
$mat-menu-vertical: 4px;
$mat-menu-padding: $mat-menu-vertical $mat-menu-padding-horizontal;
$mat-menu-border-radius: 0 0 4px 0;
$mat-menu-border-width: 1px;
$border-radius-top-left: 4px;
$border-radius-top-right: 0px;
$border-radius-bottom-right: 4px;
$border-radius-bottom-left: 0px;
$classic-border-radius: $border-radius-top-left $border-radius-top-right
  $border-radius-bottom-right $border-radius-bottom-left;

$mat-menu-min-width: 16.6666em; // 200px - base fz: 12px
$mat-menu-max-width: 33.3333em; // 400px - base fz: 12px

//if you update collection, you should update TypographyLevels enum in `ra-ui-types` file, too
$typography-levels: (
  display-4,
  display-3,
  display-3-bold,
  display-2,
  display-2-bold,
  display-1,
  display-1-bold,
  headline,
  headline-bold,
  title,
  title-bold,
  subheading-2,
  subheading-2-bold,
  subheading-1,
  subheading-1-bold,
  body-1,
  body-1-bold,
  body-2,
  body-2-bold,
  body-3,
  body-3-bold,
  button,
  button-with-icon,
  caption,
  caption-bold,
  hint-message,
  form-field-text,
  overline,
  overline-bold,
  input,
  icon-s,
  icon-m,
  icon-l,
  icon-xl,
  mono
);

@function get-global-config() {
  @return (useWindowsCursor: false);
}

@function merge-global-config($custom-global-config: null) {
  $default-global-config: get-global-config();

  @if ($custom-global-config == null) {
    @return $default-global-config;
  }

  $new-config: map-merge($default-global-config, $custom-global-config);

  @return $new-config;
}

@mixin getCursor($custom-global-config: null) {
  $cursor: pointer;

  $newGlobalConfig: merge-global-config($custom-global-config);
  $useWindowsCursor: map_get($newGlobalConfig, useWindowsCursor);

  @if ($useWindowsCursor == true) {
    $cursor: default;
  }

  cursor: $cursor;
}

@mixin mat-menu-override($theme, $custom-typography) {
  .ra-ui-menu.mat-menu-panel,
  .menu-nested.mat-menu-panel {
    @include ra-ui-typography-level-to-styles($custom-typography, body-1);
    $line-height-body-1: ra-ui-line-height($custom-typography, body-1);
    $font-size-body-1: ra-ui-font-size($custom-typography, body-1);

    //convert to string
    $line-height-string: $line-height-body-1 + '';
    //checks line height is defined like relative
    $is-line-height-relative: str-index($line-height-string, 'px') == null;

    @if ($is-line-height-relative) {
      $line-height-body-1: calc($line-height-body-1 * $font-size-body-1);
    }

    $dialog-color: ra-ui-color($theme, background, dialog, 0.7);
    $base-color: ra-ui-color($theme, foreground, base, 0.2);
    // validation if the value is not set
    @if ($dialog-color == null) {
      $dialog-color: white;
    }
    $mat-menu-box-shadow:
      0 1px 3px -1px rgba(26, 27, 36, 0.12),
      0 2px 4px 0 rgba(26, 27, 36, 0.08);
    $mat-menu-border: 1px solid
      ra-ui-color($theme, background, interactive-component-06);

    box-sizing: border-box;
    border: $mat-menu-border;
    border-radius: $mat-menu-border-radius;
    box-shadow: $mat-menu-box-shadow;
    max-height: calc(100vh - #{$mat-menu-height});
    min-width: $mat-menu-min-width;
    max-width: $mat-menu-max-width;

    min-height: initial;

    &.menu-nested {
      margin-top: 3px;
    }

    .mat-menu-item .mat-icon {
      //override material material margin right
      margin-right: 0px;
    }
  }
}

@mixin mat-select-panel($custom-typography, $custom-global-config: null) {
  %override-mat-option {
    @include ra-ui-typography-level-to-styles($custom-typography, input);
    padding: $mat-menu-padding;
  }

  .ra-ui-select.mat-select-panel .mat-option:not(.mat-option-disabled) {
    @include getCursor($custom-global-config);
  }

  //div tag used only for created more specific selector and override material's styles
  .ra-ui-select mat-option.mat-option,
  .ra-ui-search-input mat-option.mat-option {
    @extend %override-mat-option;
  }

  .ra-ui-search-input.mat-autocomplete-panel .mat-option {
    height: $mat-menu-height;
    line-height: $mat-menu-height;
  }

  .ra-ui-select.mat-select-panel {
    .mat-optgroup-label,
    .mat-option {
      @extend %override-mat-option;
    }
  }
}

@mixin material-override-styles(
  $theme,
  $custom-typography,
  $custom-global-config: null
) {
  @include mat-menu-override($theme, $custom-typography);
  @include mat-select-panel($custom-typography, $custom-global-config);
}

$custom-background-keys: (
  ui-background-01,
  interactive-component-02,
  interactive-component-03,
  interactive-component-04,
  interactive-component-05,
  interactive-component-06,
  disabled,
  inactive-hover,
  inactive,
  interactive-component-07,
  interactive-alt-01,
  interactive-alt-02,
  progress,
  interactive-component-01
);

@mixin ra-ui-background-palette-colors($theme) {
  @each $background in map-keys(map-get($theme, background)) {
    &.background-#{$background} {
      $bg-color: ra-ui-color($theme, background, $background);
      @if type-of($bg-color) == 'map' {
        background-color: map-get($bg-color, background);
      } @else {
        background-color: $bg-color;
      }
    }
  }
}

// the methods returns map of custom background colors with defaults color.
@function get-custom-background-palette($theme) {
  $result: ();
  @each $hue in $custom-background-keys {
    $new: (
      $hue: ra-ui-color($theme, background, $hue),
    );

    $result: map-merge($result, $new);
  }

  @return $result;
}

@mixin inner-border($width, $color) {
  -webkit-box-shadow: inset 0px 0px 0px $width $color;
  -moz-box-shadow: inset 0px 0px 0px $width $color;
  box-shadow: inset 0px 0px 0px $width $color;
}

@mixin safari-only-specific() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin firefox-only-specific() {
  @-moz-document url-prefix() {
    @content;
  }
}

//function checks, whether a key exists in a collection
@function contains($list, $key) {
  @return (null != map_get($list, $key));
}

//function returns value by key if exits or it returns default
@function getValue($list, $key, $default) {
  $value: map_get($list, $key);

  @return if(null != $value, $value, $default);
}

/**
* Should be applied to ra-ui-static-text element to mimic the hint message appearance
*/
@mixin hint-message-appearance($theme, $custom-typography) {
  @include ra-ui-typography-level-to-styles(
    $custom-typography,
    form-field-text
  );
  color: ra-ui-color($theme, foreground, text-label);
}

// The function returns width and padding of a immediate arrow in pixel unit.
@function get-immediate-arrow-properties($custom-typography) {
  $icon-s-fs: ra-ui-font-size($custom-typography, icon-s);
  $icon-s-fs: if(
    type-of($icon-s-fs) == string,
    strip-unit(rafl.$rafl-core-font-size-75) * 16px,
    $icon-s-fs
  ); // For more info see https://rockwellautomation.atlassian.net/wiki/spaces/XQMJF/pages/3531112875/Using+css+tokens
  $width: round($icon-s-fs);
  $padding: round(strip-unit(getRelative(8px, 12px)) * $width);

  @return (width: $width, padding: $padding);
}

$width-immediate-arrow: 1em;
$width-flat-mode-error: 1em;
$width-dirty-flag: 0.4375em;

$static-text-scrollbar-padding: 0.5625em;
$label-padding-right: getRelative(
  8,
  if(
    unit(rafl.$rafl-typography-body-sm-font-size) == rem,
    rafl.$rafl-typography-body-sm-font-size * 16,
    rafl.$rafl-typography-body-sm-font-size
  )
);

$form-field-vertical-padding: getRelative(4px, 14px);

@mixin ra-ui-error-message-colors($theme) {
  div {
    @each $name, $color in ra-ui-status-palette($theme, $status-palette) {
      &.error-message-color-#{$name} {
        .static-text,
        ra-ui-dirty-flag {
          color: $color;
        }
      }
    }
  }
}

// here we need to override color of icon for selected node.
$failed-icon-color: #ff3100 !important;
$passed-icon-color: #17c72b !important;
$expected-failed-icon-color: #f9b414 !important;

@mixin test-results-theme($theme, $custom-typography) {
}
