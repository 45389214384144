@use 'sass:math';
@use '@angular/material' as mat;
@use 'ra-flourish-design-tokens/build/scss/tokens-compact-FT-native' as rafl;

// TODO: if you decide to convert following imports into use, do not forget to bundle them

// Represents a collection of typography levels.
@function ra-ui-typography-config(
  $font-family:
    var(
      --rafl-core-font-family-base,
      rafl.$rafl-core-font-family-base,
      'Helvetica Neue',
      sans-serif
    ),
  $display-4: mat.define-typography-level(112px, 112px, 300),
  // Equivalent of display-4 does not exist in new design system
  $display-3:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(
        --rafl-typography-headline-lg-font-weight,
        rafl.$rafl-typography-headline-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-lg-font-size,
        rafl.$rafl-typography-headline-lg-font-size
      ),
      var(
        --rafl-typography-headline-lg-line-height,
        rafl.$rafl-typography-headline-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-lg-letter-spacing,
          rafl.$rafl-typography-headline-lg-letter-spacing
        )
    ),
  $display-2:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(
        --rafl-typography-headline-md-font-weight,
        rafl.$rafl-typography-headline-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-md-font-size,
        rafl.$rafl-typography-headline-md-font-size
      ),
      var(
        --rafl-typography-headline-md-line-height,
        rafl.$rafl-typography-headline-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-md-letter-spacing,
          rafl.$rafl-typography-headline-md-letter-spacing
        )
    ),
  $display-1:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(
        --rafl-typography-headline-sm-font-weight,
        rafl.$rafl-typography-headline-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $display-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-headline-sm-font-size,
        rafl.$rafl-typography-headline-sm-font-size
      ),
      var(
        --rafl-typography-headline-sm-line-height,
        rafl.$rafl-typography-headline-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-headline-sm-letter-spacing,
          rafl.$rafl-typography-headline-sm-letter-spacing
        )
    ),
  $body-1:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(
        --rafl-typography-body-sm-font-weight,
        rafl.$rafl-typography-body-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-sm-font-size,
        rafl.$rafl-typography-body-sm-font-size
      ),
      var(
        --rafl-typography-body-sm-line-height,
        rafl.$rafl-typography-body-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-sm-letter-spacing,
          rafl.$rafl-typography-body-sm-letter-spacing
        )
    ),
  $body-2:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(
        --rafl-typography-body-md-font-weight,
        rafl.$rafl-typography-body-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  $body-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-md-font-size,
        rafl.$rafl-typography-body-md-font-size
      ),
      var(
        --rafl-typography-body-md-line-height,
        rafl.$rafl-typography-body-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-md-letter-spacing,
          rafl.$rafl-typography-body-md-letter-spacing
        )
    ),
  // Equivalent of body-2-bold does not exist in new design system
  $body-3:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(
        --rafl-typography-body-lg-font-weight,
        rafl.$rafl-typography-body-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $body-3-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-body-lg-font-size,
        rafl.$rafl-typography-body-lg-font-size
      ),
      var(
        --rafl-typography-body-lg-line-height,
        rafl.$rafl-typography-body-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-body-lg-letter-spacing,
          rafl.$rafl-typography-body-lg-letter-spacing
        )
    ),
  $button:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $button-with-icon:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85),
      22px,
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing: 0.4px
    ),
  $input:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-md-font-size,
        rafl.$rafl-typography-utility-md-font-size
      ),
      /**
                             * Line-height must be unit-less fraction of the font-size.
                             * There is no token related to input of 20/14 (pre-token value), so 20 is replaced by 1.25 = 20/16.
                             * TODO: it might by worth to set line-height to $rafl-typography-utility-md-line-height (1.4), but observe that is not 20/14 ~ 1.429
                             */
        math.div(1.25rem, rafl.$rafl-typography-utility-md-font-size),
      var(
        --rafl-typography-utility-md-font-weight,
        rafl.$rafl-typography-utility-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-md-letter-spacing,
          rafl.$rafl-typography-utility-md-letter-spacing
        )
    ),
  $headline:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(
        --rafl-typography-title-lg-font-weight,
        rafl.$rafl-typography-title-lg-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $headline-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-lg-font-size,
        rafl.$rafl-typography-title-lg-font-size
      ),
      var(
        --rafl-typography-title-lg-line-height,
        rafl.$rafl-typography-title-lg-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-lg-letter-spacing,
          rafl.$rafl-typography-title-lg-letter-spacing
        )
    ),
  $title:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(
        --rafl-typography-title-md-font-weight,
        rafl.$rafl-typography-title-md-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $title-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-md-font-size,
        rafl.$rafl-typography-title-md-font-size
      ),
      var(
        --rafl-typography-title-md-line-height,
        rafl.$rafl-typography-title-md-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-md-letter-spacing,
          rafl.$rafl-typography-title-md-letter-spacing
        )
    ),
  $subheading-1:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(
        --rafl-typography-title-xs-font-weight,
        rafl.$rafl-typography-title-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-1-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-xs-font-size,
        rafl.$rafl-typography-title-xs-font-size
      ),
      var(
        --rafl-typography-title-xs-line-height,
        rafl.$rafl-typography-title-xs-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-xs-letter-spacing,
          rafl.$rafl-typography-title-xs-letter-spacing
        )
    ),
  $subheading-2:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(
        --rafl-typography-title-sm-font-weight,
        rafl.$rafl-typography-title-sm-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $subheading-2-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-title-sm-font-size,
        rafl.$rafl-typography-title-sm-font-size
      ),
      var(
        --rafl-typography-title-sm-line-height,
        rafl.$rafl-typography-title-sm-line-height
      ),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(
          --rafl-typography-title-sm-letter-spacing,
          rafl.$rafl-typography-title-sm-letter-spacing
        )
    ),
  $caption:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-font-size,
        rafl.$rafl-typography-utility-xs-font-size
      ),
      var(
        --rafl-typography-utility-xs-line-height,
        rafl.$rafl-typography-utility-xs-line-height
      ),
      var(
        --rafl-typography-utility-xs-font-weight,
        rafl.$rafl-typography-utility-xs-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-letter-spacing,
          rafl.$rafl-typography-utility-xs-letter-spacing
        )
    ),
  $caption-bold:
    mat.define-typography-level(
      var(
        --rafl-typography-utility-xs-bold-font-size,
        rafl.$rafl-typography-utility-xs-bold-font-size
      ),
      var(
        --rafl-typography-utility-xs-bold-line-height,
        rafl.$rafl-typography-utility-xs-bold-line-height
      ),
      var(
        --rafl-typography-utility-xs-bold-font-weight,
        rafl.$rafl-typography-utility-xs-bold-font-weight
      ),
      $letter-spacing:
        var(
          --rafl-typography-utility-xs-bold-letter-spacing,
          rafl.$rafl-typography-utility-xs-bold-letter-spacing
        )
    ),
  $overline:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-medium, rafl.$rafl-core-font-weight-medium),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $overline-bold:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-bold, rafl.$rafl-core-font-weight-bold),
      $letter-spacing:
        var(--rafl-core-dimension-px-2, rafl.$rafl-core-dimension-px-2)
    ),
  $form-field-text:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $hint-message:
    mat.define-typography-level(
      var(--rafl-core-font-size-75, rafl.$rafl-core-font-size-75),
      var(--rafl-core-dimension-rem-100, rafl.$rafl-core-dimension-rem-100),
      var(--rafl-core-font-weight-regular, rafl.$rafl-core-font-weight-regular),
      $letter-spacing:
        var(--rafl-core-letter-spacing-250, rafl.$rafl-core-letter-spacing-250)
    ),
  $icon-s:
    mat.define-typography-level(
      var(--rafl-dimension-icon-sm, rafl.$rafl-dimension-icon-sm)
    ),
  $icon-m:
    mat.define-typography-level(
      var(--rafl-dimension-icon-md, rafl.$rafl-dimension-icon-md)
    ),
  $icon-l:
    mat.define-typography-level(
      var(--rafl-dimension-icon-lg, rafl.$rafl-dimension-icon-lg)
    ),
  $icon-xl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xl, rafl.$rafl-dimension-icon-xl)
    ),
  $icon-xxl:
    mat.define-typography-level(
      var(--rafl-dimension-icon-xxl, rafl.$rafl-dimension-icon-xxl)
    ),
  $mono:
    mat.define-typography-level(
      var(--rafl-core-font-size-85, rafl.$rafl-core-font-size-85)
    )
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-4: $display-4,
    display-3: $display-3,
    display-3-bold: $display-3-bold,
    display-2: $display-2,
    display-2-bold: $display-2-bold,
    display-1: $display-1,
    display-1-bold: $display-1-bold,
    headline: $headline,
    headline-bold: $headline-bold,
    title: $title,
    title-bold: $title-bold,
    subheading-2: $subheading-2,
    subheading-2-bold: $subheading-2-bold,
    subheading-1: $subheading-1,
    subheading-1-bold: $subheading-1-bold,
    body-1: $body-1,
    body-1-bold: $body-1-bold,
    body-2: $body-2,
    body-2-bold: $body-2-bold,
    body-3: $body-3,
    body-3-bold: $body-3-bold,
    button: $button,
    button-with-icon: $button-with-icon,
    caption: $caption,
    caption-bold: $caption-bold,
    hint-message: $hint-message,
    form-field-text: $form-field-text,
    overline: $overline,
    overline-bold: $overline-bold,
    input: $input,
    icon-s: $icon-s,
    icon-m: $icon-m,
    icon-l: $icon-l,
    icon-xl: $icon-xl,
    icon-xxl: $icon-xxl,
    mono: $mono,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      // This is required for the `icon` typography level to work properly.
      $new-level: map-merge(
        $level,
        (
          font-family: if(str-index($key, 'icon-'), 'ra-icons', $font-family),
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

/**
    Overrides material functions/mixins
 */

// Gets the font size for a level inside a typography config.
@function ra-ui-font-size($config, $level) {
  @return mat.font-size($config, $level);
}

// Gets the line height for a level inside a typography config.
@function ra-ui-line-height($config, $level) {
  @return mat.line-height($config, $level);
}

// Gets the font weight for a level inside a typography config.
@function ra-ui-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}

// Gets the letter spacing for a level inside a typography config.
@function ra-ui-letter-spacing($config, $level) {
  @return mat.letter-spacing($config, $level);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function ra-ui-font-family($config, $level: null) {
  @return mat.font-family($config, $level);
}

// Converts a typography level into CSS styles.
@mixin ra-ui-typography-level-to-styles($config, $level) {
  @include mat.typography-level($config, $level);
}

// Sets typography based on flourish tokens and default value from typography config.
@mixin ra-ui-typography-level-to-styles-tokens(
  $custom-typography,
  $level,
  $font-size,
  $font-weight,
  $line-height,
  $font-family,
  $letter-spacing
) {
  font-size: var($font-size, ra-ui-font-size($custom-typography, $level));
  font-weight: var($font-weight, ra-ui-font-weight($custom-typography, $level));
  line-height: var($line-height, ra-ui-line-height($custom-typography, $level));
  font-family: var($font-family, ra-ui-font-family($custom-typography, $level));
  letter-spacing: var(
    $letter-spacing,
    ra-ui-letter-spacing($custom-typography, $level)
  );
}

// Gets a color from a theme palette (the output of mat-palette).
// The hue can be one of the standard values (500, A400, etc.), one of the three preconfigured
// hues (default, lighter, darker), or any of the aforementioned prefixed with "-contrast".
//
// @param $theme The theme contains palettes created by material function (foreground, background, primary, secondary, warn).
// @param $palette The key of palette in $theme.
// @param $hue The hue from the palette to use.
// @param $opacity The alpha channel value for the color.
@function ra-ui-color($theme, $palette, $hue: default, $opacity: null) {
  @if (
    index((foreground, background, primary, accent, warn), $palette) == null
  ) {
    @error 'The #{$palette} does not exist';
  }

  $searched-pallet: map-get($theme, $palette);
  $color: map-get($searched-pallet, $hue);

  //A colors does not exist on a material pallets, checks searching in custom pallets.
  @if ($color == null) {
    $defaults: ();
    @if ($palette == foreground) {
      $defaults: (
        text-label:
          var(--rafl-color-foreground-label, rafl.$rafl-color-foreground-label),
        text-disabled:
          var(
            --rafl-color-state-disabled-default,
            rafl.$rafl-color-state-disabled-default
          ),
        text-primary:
          var(
            --rafl-color-foreground-primary,
            rafl.$rafl-color-foreground-primary
          ),
        text-value-disabled: #7e5986,
        // non existing variable token
        force-value-disabled:
          #bd4200,
        // non existing variable token
        immediate-commit:
          var(
            --rafl-color-state-immediate-commit,
            rafl.$rafl-color-state-immediate-commit
          ),
      );
    } @else if($palette == background) {
      $defaults: (
        interactive-component-01: #ffffff,
        interactive-component-02: #f8fbfc,
        interactive-component-03: #f1f4f6,
        interactive-component-04: #e8ebed,
        interactive-component-05: #dbdfe2,
        interactive-component-06: #b8bcbf,
        interactive-component-07: #3e4144,
        interactive-alt-01: #c9d5dd,
        interactive-alt-02: #889cac,
        progress: #57d9a3,

        ui-background-01:
          var(--rafl-color-background-ui, rafl.$rafl-color-background-ui),
        disabled:
          var(
            --rafl-color-state-disabled-default,
            rafl.$rafl-color-state-disabled-default
          ),
        inactive-hover:
          var(
            --rafl-color-state-inactive-hovered,
            rafl.$rafl-color-state-inactive-hovered
          ),
        inactive:
          var(
            --rafl-color-state-inactive-default,
            rafl.$rafl-color-state-inactive-default
          ),
      );
    }

    $color: map-get($defaults, $hue);
  }

  @if (type-of($color) == string) {
    // color is CSS variable
    @if ($opacity == null) {
      @return $color;
    }
    // If the $color resolved to something a CSS variable, color-mix can transparentize the color in CSS variable
    @return color-mix(in srgb, $color $opacity * 100%, transparent);
  }

  @if (type-of($color) != color) {
    // If the $color resolved to something different from a color (e.g. a CSS variable),
    // we can't apply the opacity anyway so we return the value as is, otherwise Sass can
    // throw an error or output something invalid.
    @return $color;
  }

  @return rgba($color, if($opacity == null, opacity($color), $opacity));
}

// For a given hue in a palette, return the contrast color from the map of contrast palettes.
// Contains default contrast color of background pallet
// @param $color-map
// @param $hue
@function ra-ui-contrast($theme, $palette, $hue) {
  @if (index((background, primary, accent, warn), $palette) == null) {
    @error 'The #{$palette} does not exist';
  }

  $searched-pallet: map-get($theme, $palette);
  $contrast-pallet: map-get($searched-pallet, contrast);

  // map of contrast does not exist.
  @if ($contrast-pallet == null) {
    $defaults: ();
    @if ($palette == background) {
      // NOTE - contrast palettes do not have tokens yet (0.2.0)
      $defaults: (
        ui-background-01: #000000,
        interactive-component-01: #000000,
        interactive-component-02: #000000,
        interactive-component-03: #000000,
        interactive-component-04: #000000,
        interactive-component-05: #000000,
        interactive-component-06: #000000,
        interactive-component-07: #ffffff,
        disabled: #000000,
        inactive-hover: #ffffff,
        inactive: #ffffff,

        interactive-alt-01: #000000,
        interactive-alt-02: #000000,
        progress: #000000,
      );
    }

    $color: map-get($defaults, $hue);

    @return $color;
  }

  @return map-get($contrast-pallet, $hue);
}

/**
* TODO: remove $status-palette
* Ideally, this shouldn't be here at all. It serves as a default for the following functions.
* Removing it breaks CI. Reworking the functions would result in severe breaking changes.
* This needs to be tackled when we decide to clean up this whole theme mess.
 */
$status-palette: (
  alarm: #e51400,
  error: #aa0000,
  success: #12850a,
  warning: #ffcc00,
  information: #235d9f,
  pending: #8f2ad2,
);

// Creates a container object for a light theme to be given to individual component theme mixins.
@function ra-ui-light-theme(
  $primary,
  $accent: mat.define-palette(mat.$green-palette, 500, 100, 900),
  $warn: mat.define-palette(mat.$red-palette),
  $foreground: $ra-ui-light-theme-foreground,
  $background: $ra-ui-light-theme-background,
  $status-palette: $status-palette
) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $foreground,
    background: $background,
    status-palette: $status-palette
  );
}

// Creates a container object for a dark theme to be given to individual component theme mixins.
@function ra-ui-dark-theme(
  $primary,
  $accent: mat.define-palette(mat.$pink-palette),
  $warn: mat.define-palette(mat.$red-palette),
  $foreground: mat.$dark-theme-foreground-palette,
  $background: mat.$dark-theme-background-palette,
  $status-palette: $status-palette
) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: true,
    foreground: $foreground,
    background: $background,
    status-palette: $status-palette
  );
}

/**
* Returns status palette WITHOUT the contrast colors.
 */
@function ra-ui-status-palette($theme, $status-palette) {
  $theme-status-palette: map-get($theme, status-palette);
  /**
  * status palette exists in the theme and has contrast defined
  * --> strip contrast and return the theme's status palette without the contrast
   */
  @if $theme-status-palette and map-get($theme-status-palette, contrast) {
    $theme-contrast-status-palette: map-remove($theme-status-palette, contrast);
    @return $theme-contrast-status-palette;
  }
  /**
  * status palette exists in the theme, but has no contrast defined (if it did, previous if-branch would be executed)
  * --> simply return the theme's status palette
   */
  @else if $theme-status-palette {
    @return $theme-status-palette;
  }
  /**
  * when there is no status palette in the theme
   */
  @else {
    @return $status-palette;
  }
}

/**
* Returns ONLY the contrast colors of status palette.
 */
@function ra-ui-status-palette-contrast($theme, $status-palette) {
  $theme-status-palette: map-get($theme, status-palette);
  // when there is no status palette in the theme
  @if ($theme-status-palette == null) {
    @return $status-palette;
  }

  $status-contrast: map-get($theme-status-palette, contrast); //
  @return if($status-contrast, $status-contrast, $status-palette);
}

$flourish-typography: ra-ui-typography-config(
  // Equivalent of display-4 does not exist in new design system
  $display-4: mat.define-typography-level(64px, 68px, 400)
);

// palettes provided by UXDT
$flourish-primary-palette: (
  50:
    var(
      --rafl-core-color-primary-native-100,
      rafl.$rafl-core-color-primary-native-100
    ),
  100:
    var(
      --rafl-core-color-primary-native-200,
      rafl.$rafl-core-color-primary-native-200
    ),
  200:
    var(
      --rafl-core-color-primary-native-300,
      rafl.$rafl-core-color-primary-native-300
    ),
  300:
    var(
      --rafl-core-color-primary-native-400,
      rafl.$rafl-core-color-primary-native-400
    ),
  400:
    var(
      --rafl-core-color-primary-native-500,
      rafl.$rafl-core-color-primary-native-500
    ),
  500:
    var(
      --rafl-core-color-primary-native-800,
      rafl.$rafl-core-color-primary-native-800
    ),
  600:
    var(
      --rafl-core-color-primary-native-900,
      rafl.$rafl-core-color-primary-native-900
    ),
  700:
    var(
      --rafl-core-color-primary-native-1000,
      rafl.$rafl-core-color-primary-native-1000
    ),
  800:
    var(
      --rafl-core-color-primary-native-1300,
      rafl.$rafl-core-color-primary-native-1300
    ),
  900:
    var(
      --rafl-core-color-primary-native-1400,
      rafl.$rafl-core-color-primary-native-1400
    ),
  A100: var(--rafl-color-state-focused, rafl.$rafl-color-state-focused),
  // There is no token for A200
  A200: #00d6ff,
  // There is no token for A300
  A300: #00a7db,
  // There is no token for A400
  A400: #007db7,
  contrast: (
    50:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    100:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    200:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    300:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    400:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    500:
      var(
        --rafl-color-foreground-inverted,
        rafl.$rafl-color-foreground-inverted
      ),
    600:
      var(
        --rafl-color-foreground-inverted,
        rafl.$rafl-color-foreground-inverted
      ),
    700:
      var(
        --rafl-color-foreground-inverted,
        rafl.$rafl-color-foreground-inverted
      ),
    800:
      var(
        --rafl-color-foreground-inverted,
        rafl.$rafl-color-foreground-inverted
      ),
    900:
      var(
        --rafl-color-foreground-inverted,
        rafl.$rafl-color-foreground-inverted
      ),
    A100:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    A200:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    A300:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    A400:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
  ),
);

$flourish-light-theme-background: (
  background: var(--rafl-color-background-ui, rafl.$rafl-color-background-ui),
  ui-background-01:
    var(--rafl-color-background-ui, rafl.$rafl-color-background-ui),
  hover: rgba(black, 0.04),
  card: white,
  dialog: var(--rafl-color-background-ui, rafl.$rafl-color-background-ui),
  disabled-button: rgba(black, 0.12),
  focused-button: rgba(black, 0.12),
  // NOT Flourish approved colors should not be used >
  raised-button: white,
  selected-button: #e0e0e0,
  selected-disabled-button: #bdbdbd,
  disabled-button-toggle: #eeeeee,
  unselected-chip: #e0e0e0,
  disabled-list-option: #eeeeee,
  // < NOT Flourish approved colors should not be used
  // Flourish abandoned interactive colors.
  interactive-component-01: #ffffff,
  interactive-component-02: #f8fbfc,
  interactive-component-03: #f1f4f6,
  interactive-component-04: #e8ebed,
  interactive-component-05: #dbdfe2,
  interactive-component-06: #b8bcbf,
  interactive-component-07: #3e4144,
  interactive-alt-01: #c9d5dd,
  interactive-alt-02: #889cac,

  disabled:
    var(
      --rafl-color-state-disabled-default,
      rafl.$rafl-color-state-disabled-default
    ),
  inactive-hover:
    var(
      --rafl-color-state-inactive-hovered,
      rafl.$rafl-color-state-inactive-hovered
    ),
  inactive-pressed:
    var(
      --rafl-color-state-inactive-pressed,
      rafl.$rafl-color-state-inactive-pressed
    ),
  inactive:
    var(
      --rafl-color-state-inactive-default,
      rafl.$rafl-color-state-inactive-default
    ),
  progress: #57d9a3,
  fab-background:
    var(
      --rafl-color-background-container,
      rafl.$rafl-color-background-container
    ),
  background-container:
    var(
      // same as inactive-hover in light theme
      --rafl-color-state-inactive-hovered,
      rafl.$rafl-color-state-inactive-hovered
    ),
  //should be --rafl-color-foreground-primary, but it is not possible to apply opacity to token like this
  overlay: rgba(#000000, 0.5),
  contrast: (
    ui-background-01:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    interactive-component-01:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    interactive-component-02:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    interactive-component-03:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    interactive-component-04:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    interactive-component-05:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    interactive-component-06:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    interactive-component-07:
      var(
        --rafl-color-foreground-inverted,
        rafl.$rafl-color-foreground-inverted
      ),
    interactive-alt-01:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    interactive-alt-02:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    disabled:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
    inactive-hover:
      var(
        --rafl-color-foreground-inverted,
        rafl.$rafl-color-foreground-inverted
      ),
    inactive-pressed:
      var(
        --rafl-color-state-inactive-contrast-max,
        rafl.$rafl-color-state-inactive-contrast-max
      ),
    inactive:
      var(
        --rafl-color-foreground-inverted,
        rafl.$rafl-color-foreground-inverted
      ),
    progress:
      var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
  ),
);

// NOTE - missing tokens for these (0.2.0)
$flourish-light-theme-foreground: (
  // NOT SOLVED YET --- component specific colors in generic foreground map
  base:
    var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
  divider: rgba(black, 0.12),
  dividers: rgba(black, 0.12),
  disabled: rgba(black, 0.38),
  disabled-button: rgba(black, 0.26),
  disabled-text: rgba(black, 0.26),
  hint-text: rgba(black, 0.38),
  secondary-text: rgba(black, 0.54),
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
  text:
    var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
  text-label:
    var(--rafl-color-foreground-label, rafl.$rafl-color-foreground-label),
  text-disabled:
    var(
      --rafl-color-state-disabled-default,
      rafl.$rafl-color-state-disabled-default
    ),
  text-primary:
    var(--rafl-color-foreground-primary, rafl.$rafl-color-foreground-primary),
  text-primary-contrast:
    var(--rafl-color-foreground-inverted, rafl.$rafl-color-foreground-inverted),
  text-value-disabled: #7e5986,
  // not used anywhere should be removed
  force-value-disabled: #bd4200,
  // not used anywhere should be removed
  immediate-commit:
    var(
      --rafl-color-state-immediate-commit,
      rafl.$rafl-color-state-immediate-commit
    ),
  outline:
    var(
      --rafl-color-outline-contrast-middle,
      rafl.$rafl-color-outline-contrast-middle
    ),
  foreground-container:
    var(--rafl-color-foreground-inverted, rafl.$rafl-color-foreground-inverted)
);

$flourish-primary: mat.define-palette(
  $flourish-primary-palette
); //500, 100, 700

$status-palette: (
  alarm:
    var(
      --rafl-color-status-alarm-default,
      rafl.$rafl-color-status-alarm-default
    ),
  error:
    var(
      --rafl-color-status-error-default,
      rafl.$rafl-color-status-error-default
    ),
  success:
    var(
      --rafl-color-status-success-default,
      rafl.$rafl-color-status-success-default
    ),
  warning:
    var(
      --rafl-color-status-warning-default,
      rafl.$rafl-color-status-warning-default
    ),
  information:
    var(
      --rafl-color-status-information-default,
      rafl.$rafl-color-status-information-default
    ),
  pending:
    var(
      --rafl-color-status-pending-default,
      rafl.$rafl-color-status-pending-default
    ),
  offline:
    var(
      --rafl-color-status-offline-default,
      rafl.$rafl-color-status-offline-default
    ),
  contrast: (
    alarm:
      var(
        --rafl-color-status-alarm-contrast-max,
        rafl.$rafl-color-status-alarm-contrast-max
      ),
    error:
      var(
        --rafl-color-status-error-contrast-max,
        rafl.$rafl-color-status-error-contrast-max
      ),
    success:
      var(
        --rafl-color-status-success-contrast-max,
        rafl.$rafl-color-status-success-contrast-max
      ),
    warning:
      var(
        --rafl-color-status-warning-contrast-max,
        rafl.$rafl-color-status-warning-contrast-max
      ),
    information:
      var(
        --rafl-color-status-information-contrast-max,
        rafl.$rafl-color-status-information-contrast-max
      ),
    pending:
      var(
        --rafl-color-status-pending-contrast-max,
        rafl.$rafl-color-status-pending-contrast-max
      ),
    offline:
      var(
        --rafl-color-status-offline-contrast-max,
        rafl.$rafl-color-status-offline-contrast-max
      ),
  ),
);

$flourish-theme: ra-ui-light-theme(
  $flourish-primary,
  $foreground: $flourish-light-theme-foreground,
  $background: $flourish-light-theme-background,
  $status-palette: $status-palette
);
$flourish-is-dark: map-get($flourish-theme, is-dark);
