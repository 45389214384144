/** Include global styles here */
.ra-common-icon {
  font-size: 20px;
}

.icon-badge-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-badge-icon {
  font-size: 30px;
  position: relative;
}

.icon-badge {
  background-color: red;
  font-size: 9px;
  color: #fff;
  text-align: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-overlay-pane {
  margin-top: 10px;
}

.lang-menu-item .mat-menu-item {
  line-height: 25px;
}

.ra-ui-menu.menu-with-lang .menu-item-button.mat-menu-item .mat-icon,
.menu-nested.menu-with-lang .menu-item-button.mat-menu-item .mat-icon {
  margin-top: 0.3333em !important;
}

.ra-ui-menu.menu-with-lang
  .menu-item-button.mat-menu-item
  .wrapper-leaf-content,
.menu-nested.menu-with-lang
  .menu-item-button.mat-menu-item
  .wrapper-leaf-content {
  line-height: 25px !important;
}

.lang-menu-item svg.mat-menu-submenu-icon {
  display: none;
}

.lang-menu-item .mat-menu-item-highlighted {
  color: #fff;
}
